import React from 'react'
import API from '../api';

export const getRepairList = async (params: any) => {
    try {
        const response = await API.get('admin/repair', { params: params }).then(
            (res) => res.data
        );
        if (response.success) {
            const listData = [...response.data];
            listData.forEach((item: { key: any; _id: any }) => {
                item.key = item._id;
            });
            return { data: listData, total: response.totalItem };
        }
    } catch (error) {
        throw error;
    }
}

export const createRepair = async (params: any) => {
    try {
        const response = await API.post(`admin/repair`, params).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const updateRepair = async (id: any, params: any) => {
    try {
        const response = await API.put(`admin/repair/${id}`, params).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getRepairDetail = async (id: any) => {
    try {
        const response = await API.get(`admin/repair/${id}`).then(
            (res) => res.data
        );
        if (response.success) {
            const data = {...response.data};
            return { data: data};
        }
    } catch (error) {
        throw error;
    }
}

export const deleteRepair = async (id: any) => {
    try {
        const response = await API.delete(`admin/repair/${id}`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getAllRouteWithDate = async (id:any,date: any) => {
    try {
        const response = await API.get(`admin/vehicle/${id}/route`, { params: { date: date } }).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}


