import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Typography,
  Divider,
  Select,
  Spin,
  InputNumber,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useParams, useHistory, Link } from "react-router-dom";
import API from "../../../shared/api/api";
import { dateFormat } from "../../../shared/dataFormat";
import {
  ACTIVE_TYPE,
  ASSIGN_TYPE,
  NUMBER_OF_AXLES_OPTION,
  NUMBER_OF_FEETS_OPTIONS,
  OWNERSHIP_TYPE,
  PAYLOAD_OPTION,
  PRESERVATION_OPTIONS,
  VEHICLE_OPTION,
} from "../../../shared/define";
import { rule_number, rule_require } from "../../../shared/validateInput";
import moment from "moment";
import MomentDatePicker from "../../../Components/MomentDatePicker";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Option } = Select;
const { Title } = Typography;
function DetailVehicle() {
  const [form] = Form.useForm();
  const [vehicleType, setVehicleType] = useState<any>("");
  const [spinning, setSpinning] = useState(true);
  let { id }: any = useParams();
  const history = useHistory();
  const [ownership, setOwnership] = useState(OWNERSHIP_TYPE[0].value);

  useEffect(() => {
    loadData();
    return () => {};
  }, []);
  const loadData = () => {
    setSpinning(true);
    API.get(`admin/vehicle/${id}`)
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          setVehicleType(listData?.type.name);
          form.setFieldsValue({
            ...listData,
            registerDate: moment(listData?.registerDate),
            registrationExpireDate: moment(listData?.registrationExpireDate),
            physicalDamageCoverageExpireDate: moment(
              listData?.physicalDamageCoverageExpireDate
            ),
            civilLiabilityInsuranceExpireDate: moment(
              listData?.civilLiabilityInsuranceExpireDate
            ),
            transportBadgeExpireDate: moment(
              listData?.transportBadgeExpireDate
            ),
            manufacturingYearDate: moment(listData?.manufacturingYearDate),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSpinning(false);
      });
  };
  return (
    <Row style={{ width: "100%" }}>
      <Spin spinning={spinning}>
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>
        <Form form={form} {...layout} style={{ width: "100%" }}>
          <Row gutter={[32, 0]} style={{ width: "100%" }}>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Đơn vị sở hữu xe"
                name={["truckVendor", "name"]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Tên lái xe trong hệ thống"
                name={["driver", "name"]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Biển số xe" name="licensePlate">
                <Input placeholder="Nhập biển số xe" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4} md={4} lg={4} xl={4}>
              <Form.Item label="Loại xe" name={["type", "name"]}>
                <Select placeholder="Chọn loại xe" disabled>
                  {VEHICLE_OPTION.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {vehicleType === "CONTAINER" && (
              <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                <Form.Item
                  label="Số trục"
                  name={["type", "numberOfAxles"]}
                  rules={[rule_require]}
                >
                  <Select disabled placeholder="Chọn số trục">
                    {NUMBER_OF_AXLES_OPTION.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {vehicleType === "CONTAINER" && (
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Kết cấu vỏ"
                  name="preservation"
                  rules={[rule_require]}
                >
                  <Select disabled placeholder="Chọn vỏ">
                    {PRESERVATION_OPTIONS.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {vehicleType === "CONTAINER" && (
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Loại Container"
                  name={["type", "numberOfFeets"]}
                  rules={[rule_require]}
                >
                  <Select disabled placeholder="Chọn Loại Container">
                    {NUMBER_OF_FEETS_OPTIONS.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {vehicleType === "TRUCK" && (
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Tải trọng"
                  name={["type", "payload"]}
                  rules={[rule_require]}
                >
                  <Select disabled placeholder="Chọn tải trọng">
                    {PAYLOAD_OPTION.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={8}>
              <Form.Item label="Năm sản xuất" name="manufacturingYearDate">
                <MomentDatePicker
                  disabled
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Trạng thái xe" name="isActive">
                <Select disabled>
                  {ACTIVE_TYPE.map((item: any) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Divider style={{ margin: "14px" }} />

            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Hình thức sở hữu" name="ownership">
                <Select disabled onSelect={(value: any) => setOwnership(value)}>
                  {OWNERSHIP_TYPE.map((item: any) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {ownership == OWNERSHIP_TYPE[0].value && (
              <>
                <Col xs={24} sm={8}>
                  <Form.Item label="Giá trị" name="price">
                    <InputNumber
                      disabled
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={4}>
                  <Form.Item
                    label="Thời gian khấu hao"
                    name="amortizationPeriod"
                  >
                    <InputNumber
                      disabled
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={4}>
                  <Form.Item label="Khoán" name="isAssign">
                    <Select disabled>
                      {ASSIGN_TYPE.map((item: any, index: any) => (
                        <Option key={index} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
            {ownership == OWNERSHIP_TYPE[1].value && (
              <>
                <Col xs={24} sm={4}>
                  <Form.Item
                    label="Phí thuê khô theo tháng"
                    name="dryRentalFeePerMonth"
                  >
                    <InputNumber
                      disabled
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {ownership == OWNERSHIP_TYPE[2].value && (
              <>
                <Col xs={24} sm={4}>
                  <Form.Item
                    label="Hoa hồng mỗi đơn hàng"
                    name="commissionPercentagePerRoute"
                  >
                    <InputNumber
                      disabled
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {ownership == OWNERSHIP_TYPE[3].value && (
              <>
                <Col xs={24} sm={4}>
                  <Form.Item
                    label="Phí điều hành vận tải"
                    name="directOperatingPerRoute"
                  >
                    <InputNumber
                      disabled
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Divider style={{ margin: "14px" }} />
            <Col xs={24} sm={8}>
              <Form.Item label="Mức dầu: 0 Tấn" name={["oil", "equal0"]}>
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="Mức dầu: <20 Tấn" name={["oil", "from0to20"]}>
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Mức dầu: 20-25 Tấn"
                name={["oil", "from20to25"]}
              >
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="Mức dầu: >25 Tấn" name={["oil", "moreThan25"]}>
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Thể tích bình dầu"
                name={"oilTankVolume"}
                rules={[rule_number, rule_require]}
              >
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Divider style={{ margin: "14px" }} />
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Thời gian đăng ký" name="registerDate">
                <MomentDatePicker
                  disabled
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Giá tiền" name="registerPrice">
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Thời gian khấu hao" name="registerAmortization">
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Hạn đăng kiểm" name="registrationExpireDate">
                <MomentDatePicker
                  disabled
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Giá tiền" name="registrationPrice">
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="registrationAmortization"
              >
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Hạn bảo hiểm vật chất"
                name="physicalDamageCoverageExpireDate"
              >
                <MomentDatePicker
                  disabled
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Giá tiền" name="physicalDamageCoveragePrice">
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="physicalDamageCoverageAmortization"
              >
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Hạn bảo hiểm nhân sự"
                name="civilLiabilityInsuranceExpireDate"
              >
                <MomentDatePicker
                  disabled
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Giá tiền" name="civilLiabilityInsurancePrice">
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="civilLiabilityInsuranceAmortization"
                rules={[rule_require]}
              >
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Hạn phù hiệu" name="transportBadgeExpireDate">
                <MomentDatePicker
                  disabled
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Giá tiền" name="transportBadgePrice">
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="transportBadgeAmortization"
              >
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Giá bến bãi theo tháng" name="parkingPrice">
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8}>
              <Form.Item label="Lệ phí đường bộ" name="roadTollPrice">
                <InputNumber
                  disabled
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Thời gian khấu hao" name="roadTollAmortization">
                <Input disabled suffix="Tháng" />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
            <Link
              to={{
                pathname: `/resource/vehicle/edit/${id}`,
              }}
            >
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "#03A9F4",
                  color: "white",
                }}
              >
                Cập nhật
              </Button>
            </Link>
          </Col>
        </Form>
      </Spin>
    </Row>
  );
}

export default DetailVehicle;
