import React, { useEffect, useState } from 'react'
import { Modal, Table, Tooltip, Space, Button, Row, Col, Select  } from 'antd'
import { getAllBookMark } from '../../../shared/api/Order/BookMark'
import { dateFormat } from '../../../shared/dataFormat'
import API from '../../../shared/api/api'
const {Option} = Select

function BookMarkModel(props:any) {
    const {isModalOpen,setIsModalOpen,fillForm, currentOrder} = props
    const [customerList, setCustomerList] = useState([]);
    const [bookmarkList, setBookmarkList] = useState<any>([])
    const [filter, setFilter] = useState({})
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0
    })

    useEffect(() => {
        fetchListProvince();
    }, [filter, pagination.current, pagination.pageSize])

    useEffect(() => {
        getCustomerList()
    }, [])

    const fetchListProvince = async () => {
        const response: any = await getAllBookMark({...filter, current: pagination.current, pageSize: pagination.pageSize})
        setBookmarkList(response.data)

    }

    const getCustomerList = () => {

        API.get('admin/customer/all')
            .then(
                res => {
                    if (res.data.success) {
                        setCustomerList(res.data.data)
                    }
                }
            )
            .catch(error => {
                console.log(error);
            })
    }

    const columns = [
        {
            title: 'Ngày tạo',
            dataIndex: 'receipt',
            render: (receipt: any) => <>{dateFormat(receipt?.[0]?.time)}</>
        },
        {
            title: 'Tên khách hàng',
            dataIndex: ['customer', 'name'],
        },
        {
            title: 'Tên hàng',
            dataIndex: ['goods', 'name'],
        },
        {
            title: 'Điểm nhận hàng',
            dataIndex: 'receipt',
            render: (delivery: any) =>
                <Tooltip placement="topLeft" title={`${delivery?.[0]?.area?.area}--${delivery?.[0]?.area?.province}`}>
                    <div className="address">{delivery?.[0]?.area?.area}--{delivery?.[0]?.area?.province}</div>
                </Tooltip>
        },
        {
            title: 'Điểm trả hàng',
            dataIndex: 'delivery',
            render: (delivery: any) =>
                <Tooltip placement="topLeft" title={`${delivery?.[0]?.area?.area}--${delivery?.[0]?.area?.province}`}>
                    <div className="address">{delivery?.[0]?.area?.area}--{delivery?.[0]?.area?.province}</div>
                </Tooltip>

        },
        {
            title: 'Loại xe',
            dataIndex: ['vehicle', 'name']
        },
        {
            title: 'Trọng tải',
            dataIndex: 'vehicle',
            render: (vehicle: any) => <>{vehicle?.name === 'CONTAINER' ? `${vehicle?.numberOfFeets} feet` : `${vehicle?.payload} tấn`}</>
        },
        {
            title: 'Tác vụ',
            render: (order:any) => order._id === currentOrder ? <Button type='primary' disabled>Đã chọn</Button> : <Button onClick={()=>onSelect(order)}>Chọn</Button>
        }
    ]
    const onSelect = (param : any) =>{
        fillForm(param)
        setIsModalOpen(false)
    }
    
    return (
        <Modal
            title="Phương án kinh doanh"
            visible={isModalOpen}
            width={1200}
            okButtonProps={{ style: {display:'none'} }}
            cancelButtonProps={{ style: {display:'none'}  }}
            onCancel={()=>setIsModalOpen(false)}
            >
            <Row justify="end" gutter={6}>
                <Col>
                    <Space size={6}>
                        <Select
                            showSearch
                            size="small"
                            style={{ width: 150 }}
                            placeholder="Chọn khách hàng"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={(value) => setFilter({ ...filter, customer: value })}
                        >
                            <Option key='ALL' value='ALL'>Tất cả</Option>
                            {customerList.map((item: any) =>
                                <Option key={item._id} value={item._id}>{item.name}</Option>
                            )}
                        </Select>
                    </Space>
                </Col>
            </Row>
            <br />
            <Table
                scroll={{ y: 'max-content' }}
                bordered={false}
                columns={columns}
                dataSource={bookmarkList}
                pagination={pagination.total > pagination.pageSize && pagination}
                onChange={(value: any) => setPagination({ ...pagination, current: value.current })}
            />
        </Modal>
    )
}

export default BookMarkModel
