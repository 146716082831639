import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, Image, Anchor, Typography, Divider, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import API from '../../../shared/api/api';
import { useHistory, useParams, Link } from 'react-router-dom'


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Link: LinkURL } = Anchor;
const { Title } = Typography;
function DetailForwarder() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [forwarderData, setForwarderData] = useState<any>({});
    const [spinning, setSpinning] = useState(true);
    let { id }: any = useParams();
    useEffect(() => {
        loadData();
        return () => {
            setForwarderData([])
        }
    }, [])
    const loadData = () => {
        setSpinning(true);
        API.get(`admin/forwarder/${id}`)
            .then(
                res => {
                    const { success, data } = res.data;
                    if (success) {
                        setForwarderData(data);
                    }
                }
            )
            .catch(error => {
                console.log(error);
            })
            .finally(() => { setSpinning(false) })
    }
    return (
        <Row style={{ width: '100%' }}>
            <Spin spinning={spinning}>
                <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}><span onClick={() => { history.goBack() }}><LeftOutlined />Quay lại</span></Title>
                <Form initialValues={{ addressCountry: 'Việt Nam' }} form={form} {...layout} style={{ width: '100%' }} >
                    <Row gutter={[32, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Email"
                                name="email"
                            >
                                <Input prefix={forwarderData.owner?.email ? `${forwarderData.owner.email}` : 'Chưa có'} disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="SĐT"
                                        name="phoneNumber"
                                    >
                                        <Input prefix={forwarderData.owner?.phoneNumber ? `${forwarderData.owner?.phoneNumber}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Mật khẩu"
                                        name="password"
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Col />
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[32, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Ảnh doanh nghiệp"
                                name="avatar"
                                valuePropName="avatar"
                            >
                                <Image width={150} height={150} src={(forwarderData.avatar) ? forwarderData.avatar : '/id.png'} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Tên doanh nghiệp"
                                        name="name"
                                    >
                                        <Input prefix={forwarderData.name ? `${forwarderData.name}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Hotline"
                                        name="hotline"
                                    >
                                        <Input prefix={forwarderData.hotline ? `${forwarderData.hotline}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Tên người đại diện"
                                        name="representativeName"
                                    >
                                        <Input prefix={forwarderData.representativeName ? `${forwarderData.representativeName}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                    >
                                        <Input prefix={forwarderData.owner?.email ? `${forwarderData.owner.email}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Divider />
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="CMND/CCCD Người đại diện"
                                        name={['IDCard', 'number']}
                                    >
                                        <Input prefix={forwarderData.IDCard?.number ? `${forwarderData.IDCard.number}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                                {forwarderData.IDCard?.front &&
                                    <Col xs={24} sm={12} >
                                        <Form.Item
                                            name={['IDCard', 'front']}
                                            label='Mặt trước'
                                        >
                                            <Image width={120} height={120} src={(forwarderData.IDCard?.front) ? forwarderData.IDCard?.front : '/id.png'} />

                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    forwarderData.IDCard?.back &&
                                    <Col xs={24} sm={12} >
                                        <Form.Item
                                            name={['IDCard', 'back']}
                                            label='Mặt sau'
                                        >
                                            <Image width={120} height={120} src={(forwarderData.IDCard?.back) ? forwarderData.IDCard?.back : '/id.png'} />

                                        </Form.Item>
                                    </Col>
                                }

                            </Row>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="Số giấy phép kinh doanh"
                                        name={['businessLicense', 'text']}
                                    >
                                        <Input prefix={forwarderData.businessLicense?.text ? `${forwarderData.businessLicense.text}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                                {forwarderData.businessLicense?.front &&
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item
                                            name={['businessLicense', 'front']}
                                            label='Mặt trước'
                                        >
                                            <Anchor>

                                                <LinkURL href={forwarderData.businessLicense?.front ? `${forwarderData.businessLicense.front}` : ''} target='_blank' title={forwarderData.businessLicense?.front ? `${forwarderData.businessLicense.front}` : ''} />
                                            </Anchor>
                                        </Form.Item>
                                    </Col>
                                }
                                {forwarderData.businessLicense?.back &&
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item
                                            name={['businessLicense', 'back']}
                                            label='Mặt sau'
                                        >
                                            <Anchor>

                                                <LinkURL href={forwarderData.businessLicense?.back ? `${forwarderData.businessLicense.back}` : ''} target='_blank' title={forwarderData.businessLicense?.back ? `${forwarderData.businessLicense.back}` : ''} />
                                            </Anchor>
                                        </Form.Item>
                                    </Col>
                                }

                            </Row>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Thế mạnh"
                                name="strengths"
                            >
                                <Input.TextArea rows={5} placeholder={forwarderData.strengths ? forwarderData.strengths : 'Chưa có'} disabled />
                            </Form.Item>
                        </Col>
                        <Divider></Divider>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Quốc gia"
                                name={['address', 'country']}
                            >
                                <Input prefix={forwarderData.address?.country ? `${forwarderData.address.country}` : 'Chưa có'} disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Tỉnh/TP"
                                name={['address', 'province']}
                            >
                                <Input prefix={forwarderData.address?.province ? `${forwarderData.address.province}` : 'Chưa có'} disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Địa chỉ"
                                name={['address', 'text']}
                            >
                                <Input prefix={forwarderData.address?.text ? `${forwarderData.address.text}` : 'Chưa có'} disabled />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
                            <Link to={{
                                pathname: `/partner/forwarder/edit/${id}`,
                            }}>
                            <Button style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }}>
                                Chỉnh sửa
                        </Button>
                        </Link>
                    </Col>

                </Form>
            </Spin>
        </Row>
    )
}

export default DetailForwarder
