import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Upload,
  Typography,
  Modal,
  Select,
  Space,
  Card,
  InputNumber,
  AutoComplete,
} from "antd";
import {
  LeftOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  MinusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { useHistory } from "react-router-dom";
import {
  rule_number,
  rule_phoneNumber,
  rule_require,
  rule_bigger_than_5b,
} from "../../../../shared/validateInput";
import {
  beforeUpload,
  onPreview,
  propsImage,
} from "../../../../shared/imageHandle";
import {
  DANGER_OPTION,
  INSURANCE_VALUE_OPTION,
  NUMBER_OF_FEETS_OPTIONS,
  ORIGIN_OPTION,
  PAYLOAD_OPTION,
  PRESERVATION_OPTIONS,
  VEHICLE_OPTION,
  FORBIDDEN_OPTIONS,
  SPECIFICATION_TYPE,
  COMBINE_TYPE,
  COMBINE_RATE,
  DELIVERY_TYPE,
} from "../../../../shared/define";
import { getAllForwarder } from "../../../../shared/api/Forwarder";
import {
  getListDistrict,
  getListProvince,
} from "../../../../shared/api/Province";
import { createPendingOrder } from "../../../../shared/api/Order/PendingOrder";
import { getListArea } from "../../../../shared/api/Freight";
import { getAllCustomer } from "../../../../shared/api/Customer";
import BookMarkModel from "../../BookMark";
import moment from "moment";
import { getAllShip } from "../../../../shared/api/Ship";
import { searchPlaces } from "../../../../shared/api/Route";
import * as _ from "lodash";
import API from "../../../../shared/api/api";
import MomentDatePicker from "../../../../Components/MomentDatePicker";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
// const DatePickerCustom  =( props:any)  => {
//     const cloneProps = {...props}
//     cloneProps.value = moment(props.value)
//     cloneProps.onChange = (value:any,stringValue: string) =>{
//         console.log(value)
//          return props.onChange(moment(value).format('YYYY-DD-MM HH'),stringValue)
//     }
//     return  <MomentDatePicker {...cloneProps} />
// }

const { Option } = Select;
const { Title, Text } = Typography;
function CreateOrder() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [formIncurred] = Form.useForm();
  const [currentOrder, setCurrentOrder] = useState("");
  const [cityList, setCityList] = useState<any>([]);
  const [forwarderList, setForwarderList] = useState<any>([]);
  const [customerList, setCustomerList] = useState<any>([]);
  const [shipList, setShipList] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [vehicleType, setVehicleType] = useState("CONTAINER");
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [preservation, setPreservation] = useState("");
  const [goodValue, setGoodValue] = useState("");
  const [combine, setCombine] = useState(COMBINE_TYPE[0].value);
  const [receiptDistrictName, setReceiptDistrictName] = useState("");
  const [receiptProvinceName, setReceiptProvinceName] = useState("");
  const [deliveryDistrictName, setDeliveryDistrictName] = useState("");
  const [deliveryProvinceName, setDeliveryProvinceName] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [receiptProvinceList, setReceiptProvinceList] = useState<any>([]);
  const [receiptAreaList, setReceiptAreaList] = useState<any>([]);

  const [deliveryProvinceList, setDeliveryProvinceList] = useState<any>([]);
  const [deliveryAreaList, setDeliveryAreaList] = useState<any>([]);

  const [incurredModal, setIncurredModal] = useState({
    visible: false,
    data: [],
  });
  const [incurredTotal, setIncurredTotal] = useState("");
  const [combineRate, setCombineRate] = useState("");
  const [combinations, setCombinations] = useState<any[]>([]);
  const [receiptId, setReceiptId] = useState("");
  const [deliveryId, setDeliveryId] = useState("");
  const [customerId, setCustomerId] = useState("");

  const fetchFreightCombination = async () => {
    const searchParams = {
      receipt: receiptId,
      delivery: deliveryId,
      customer: customerId,
    };
    const response: any = await API.get("admin/freight/combination", {
      params: searchParams,
    });
    const { data } = response;

    if (data.success) {
      setCombinations(data?.data);
    }
  };

  useEffect(() => {
    if (receiptId && deliveryId) {
      fetchFreightCombination();
    }
  }, [receiptId, deliveryId, customerId]);

  useEffect(() => {
    fetchListProvince();
    fetchForwarderList();
    fetchCustomerList({});
    fetchShipList({});
  }, []);

  const fetchListDistrict = async (id: string) => {
    const response: any = await getListDistrict(id);
    if (response && response.data) {
      setReceiptAreaList(response.data);
      //   setCityList(response.data);
    }
  };
  const fetchListArea = async (
    province: string,
    index: number,
    type: string
  ) => {
    const param = { params: { province: province } };
    const response: any = await getListArea(param);
    if (response.success) {
      let listData = response.data;
      let customData = [];
      for (let i = 0; i < listData.length; i++) {
        customData.push({
          area: listData[i].area,
          _id: listData[i]._id,
          code: listData[i].code,
        });
      }
      if (type === "RECEIPT") {
        let arr = [...receiptAreaList];
        arr[index] = customData;
        setReceiptAreaList(arr);
      }
      if (type === "DELIVERY") {
        let arr = [...deliveryAreaList];
        arr[index] = customData;
        setDeliveryAreaList(arr);
      }
    }
  };
  const fetchForwarderList = async () => {
    const response: any = await getAllForwarder();
    setForwarderList(response.data);
  };
  const fetchCustomerList = async (param: any) => {
    const response: any = await getAllCustomer(param);
    setCustomerList(response.data);
  };
  const fetchShipList = async (param: any) => {
    const response: any = await getAllShip(param);
    setShipList(response.data);
  };
  const fetchListProvince = async () => {
    const response: any = await getListProvince();
    if (response && response.data) {
      setCityList(response.data);
    }
  };

  const onCreate = async () => {
    setLoadingBtn(true);
    let imageArray: any[] = [];
    for (let i = 0; i < fileList.length; i++) {
      imageArray.push(fileList[i].response?.data.url);
    }
    const customData = {
      ...form.getFieldsValue(),
      price: form.getFieldsValue().price?.toString(),
      goods: {
        ...form.getFieldsValue().goods,
        avatars: imageArray,
      },
      incurreds: incurredModal.data,
    };

    customData.receipt.map((item: any) => {
      item.time = moment.utc(item.timeBefore).format("YYYY-MM-DD HH:mm:ssZ");
      //   item.area = item.area._id;
    });
    customData.delivery.map((item: any, index: any) => {
      item.time = moment.utc(item.timeBefore).format("YYYY-MM-DD HH:mm:ssZ");
      //   item.area = item.area._id;
    });

    const response = await createPendingOrder(customData);
    if (response.success) history.push("/order/pending");
    setLoadingBtn(false);
  };
  const onChange = ({ fileList: newFileList }: { fileList: any }) => {
    setFileList(newFileList);
  };
  const onRemoveFormList = (index: number, type: string) => {
    if (type === "RECEIPT") {
      const provinceArr = [...receiptProvinceList];
      const areaArr = [...receiptAreaList];
      provinceArr.splice(index, 1);
      areaArr.splice(index, 1);
      setReceiptAreaList(areaArr);
      setReceiptProvinceList(provinceArr);
    }
    if (type === "DELIVERY") {
      const provinceArr = [...deliveryProvinceList];
      const areaArr = [...deliveryAreaList];
      provinceArr.splice(index, 1);
      areaArr.splice(index, 1);
      setDeliveryAreaList(areaArr);
      setDeliveryProvinceList(provinceArr);
    }
  };
  const isEmtyFormList = (type: string) => {
    if (form.getFieldValue(type).length <= 1) {
      return true;
    } else {
      return false;
    }
  };
  const fillForm = (param: any) => {
    setCurrentOrder(param._id);
    converData(param);
  };
  const converData = (data: any) => {
    let listData = data;
    let imageArray: any[] = [];
    for (let i = 0; i < listData.goods?.avatars.length; i++) {
      imageArray.push({
        uid: i,
        name: i,
        url: listData.goods.avatars[i],
        thumbUrl: listData.goods.avatars[i],
        response: { data: { url: listData.goods.avatars[i] } },
      });
    }
    setFileList(imageArray);
    setVehicleType(listData.vehicle.name);
    for (let i = 0; i < listData.delivery.length; i++) {
      listData.delivery[i] = {
        ...listData.delivery[i],
        timeBefore: moment(listData.delivery[i]?.time),
      };
      fetchListArea(listData.delivery[i].area.province, i, "DELIVERY");
    }
    for (let i = 0; i < listData.receipt.length; i++) {
      listData.receipt[i] = {
        ...listData.receipt[i],
        timeBefore: moment(listData.receipt[i]?.time),
      };
      fetchListArea(listData.receipt[i].area.province, i, "RECEIPT");
    }
    listData.shippingLines = { _id: listData.shippingLines };
    form.setFieldsValue({ ...listData });
    setPreservation(form.getFieldValue(["goods", "preservation"]));
  };
  const closeIncurredModal = () => {
    setIncurredModal({ ...incurredModal, visible: false });
    formIncurred.setFieldsValue({ incurreds: [] });
  };
  const onOk = () => {
    let amount = 0;
    formIncurred.getFieldValue("incurreds")?.forEach((incurred: any) => {
      if (incurred.verified && incurred.type === "PLAN")
        amount += parseInt(incurred.amount);
    });
    setIncurredTotal(new Intl.NumberFormat().format(amount));
    setIncurredModal({
      data: formIncurred.getFieldValue("incurreds"),
      visible: false,
    });
  };

  const [options, setOptions] = React.useState([]);

  const handleSearch = async (txt: string) => {
    if (txt) {
      const response: any = await searchPlaces(txt);
      if (response) {
        if (response.data) {
          const result = response.data.map((item: any) => {
            return {
              label: `${item.name}, ${item.address}`,
              value: `${item.name}, ${item.address}`,
              item: item,
            };
          });
          setOptions(result);
        }
      }
    }
  };

  const debouncedChangeHandler = useMemo(
    () => _.debounce((userInput) => handleSearch(userInput), 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const onSearch = (text: string) => {
    debouncedChangeHandler(text);
  };

  return (
    <div
      style={{
        display: "flex",
        padding: 12,
        width: "100%",
        backgroundColor: "#F0F2F5",
      }}
    >
      <BookMarkModel
        currentOrder={currentOrder}
        setPreservation={setPreservation}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        fillForm={fillForm}
        setFileList={setFileList}
        setVehicleType={setVehicleType}
      />
      <Row style={{ width: "100%" }}>
        <Space
          align="center"
          style={{
            width: "100%",
            marginBottom: 12,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title level={5} style={{ marginBottom: 0, cursor: "pointer" }}>
            <span
              onClick={() => {
                history.goBack();
              }}
            >
              <LeftOutlined />
              Quay lại
            </span>
          </Title>
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            Phương án kinh doanh
          </Button>
        </Space>
        <Form
          form={form}
          initialValues={{
            receipt: [""],
            delivery: [""],
            goods: {
              value: "<5",
              origin: "LOCAL",
              volume: "28",
              preservation: "NORMAL",
            },
            vehicle: {
              number: 1,
              numberOfFeets: 20,
              name: "CONTAINER",
              numberOfAxles: 2,
            },
            specification: "IMPORT",
            combinedType: "COMBINE",
          }}
          {...layout}
          style={{ width: "100%" }}
          onFinish={onCreate}
          onFinishFailed={() => {
            setLoadingBtn(false);
          }}
        >
          <Row gutter={16}>
            <Col
              span={24}
              style={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                marginBottom: 24,
                padding: "12px 12px 0 12px ",
              }}
            >
              <Title level={5} style={{ marginBottom: 8 }}>
                Thông tin khách hàng
              </Title>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    label="Chủ hàng"
                    name={["forwarder", "_id"]}
                    rules={[rule_require]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn forwarder"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(value: any) =>
                        fetchCustomerList({ forwarder: value })
                      }
                    >
                      {forwarderList.map((forwarder: any) => (
                        <Option key={forwarder._id} value={forwarder._id}>
                          {forwarder.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Tên khách hàng"
                    name={["customer", "_id"]}
                    rules={[rule_require]}
                  >
                    <Select
                      onChange={(e) => {
                        setCustomerId(e);
                      }}
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn khách hàng"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {customerList.map((cus: any) => (
                        <Option key={cus._id} value={cus._id}>
                          {cus.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Tên hãng tàu"
                    name={["shippingLines", "_id"]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn hãng tàu"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {shipList.map((ship: any) => (
                        <Option key={ship._id} value={ship._id}>
                          {ship.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              span={24}
              style={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                marginBottom: 24,
                padding: "12px 12px 0 12px ",
              }}
            >
              <Title level={5} style={{ marginBottom: 8 }}>
                Thông tin vận chuyển
              </Title>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    label="Số xe cần chở"
                    name={["vehicle", "number"]}
                    rules={[rule_require, rule_number]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Nhập số xe cần chở"
                      type="number"
                      parser={(value: any) => {
                        return value.substring(0, 1);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Loại xe"
                    name={["vehicle", "name"]}
                    rules={[rule_require]}
                  >
                    <Select
                      placeholder="Chọn loại xe"
                      onSelect={(type: string) => setVehicleType(type)}
                    >
                      {VEHICLE_OPTION.map((item: any, index: any) => (
                        <Option key={index} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {vehicleType === VEHICLE_OPTION[0].value && (
                  <Col span={6}>
                    <Form.Item
                      label="Loại Container"
                      name={["vehicle", "numberOfFeets"]}
                      rules={[rule_require]}
                    >
                      <Select placeholder="Chọn Loại Container">
                        {NUMBER_OF_FEETS_OPTIONS.map(
                          (item: any, index: any) => (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {vehicleType === VEHICLE_OPTION[0].value && (
                  <Col span={6}>
                    <Form.Item label="Số cont" name={["vehicle", "container"]}>
                      <Input placeholder="Nhập số container" />
                    </Form.Item>
                  </Col>
                )}
                {vehicleType === VEHICLE_OPTION[1].value && (
                  <Col span={6}>
                    <Form.Item
                      label="Tải trọng"
                      name={["vehicle", "payload"]}
                      rules={[rule_require]}
                    >
                      <Select placeholder="Chọn tải trọng">
                        {PAYLOAD_OPTION.map((item: any, index: any) => (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
            <Col
              span={24}
              style={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                marginBottom: 24,
                padding: "12px 12px 0 12px ",
              }}
            >
              <Title level={5} style={{ marginBottom: 8 }}>
                Thông tin hàng hóa
              </Title>
              <Row gutter={16}>
                <Col span={6}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Ảnh hàng (Không bắt buộc)"
                        name={["goods", "avatars"]}
                        extra={
                          <Space direction="vertical">
                            <Text>Ảnh không quá 1mb</Text>
                            <Text>Size 200x200px</Text>
                          </Space>
                        }
                      >
                        <ImgCrop rotate>
                          <Upload
                            listType="picture-card"
                            {...propsImage}
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            beforeUpload={beforeUpload}
                          >
                            {fileList.length < 3 && "+ Upload"}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={18}>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item
                        label="Tên hàng"
                        name={["goods", "name"]}
                        rules={[rule_require]}
                      >
                        <Input placeholder="Nhập tên hàng" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Khối lượng"
                        name={["goods", "volume"]}
                        rules={[rule_require, rule_number]}
                      >
                        <Input
                          placeholder="Nhập khối lượng hàng"
                          suffix="tấn"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Giá trị đơn hàng"
                        name={["goods", "value"]}
                        rules={[rule_require]}
                      >
                        <Select
                          placeholder="Chọn giá trị đơn hàng"
                          onSelect={(value: string) => setGoodValue(value)}
                        >
                          {INSURANCE_VALUE_OPTION.map(
                            (item: any, index: any) => (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    {goodValue === INSURANCE_VALUE_OPTION[0].value && (
                      <Col span={6}>
                        <Form.Item
                          label="Giá trị chính xác"
                          name={["goods", "valueNumber"]}
                          rules={[rule_bigger_than_5b]}
                        >
                          <InputNumber
                            placeholder="Nhập số tiền"
                            formatter={(value) =>
                              ` ${value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} đ`
                            }
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={6}>
                      <Form.Item
                        label="Loại hàng"
                        name={["goods", "origin"]}
                        rules={[rule_require]}
                      >
                        <Select placeholder="Chọn loại hàng">
                          {ORIGIN_OPTION.map((item: any, index: any) => (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Quy cách"
                        name="specification"
                        // rules={[rule_require]}
                      >
                        <Select placeholder="Chọn quy cách">
                          {SPECIFICATION_TYPE.map((item: any, index: any) => (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Tính chất hàng hóa"
                        name={["goods", "preservation"]}
                        rules={[rule_require]}
                      >
                        <Select
                          placeholder="Chọn tính chất hàng hóa"
                          onSelect={(value: string) => setPreservation(value)}
                        >
                          {PRESERVATION_OPTIONS.map((item: any, index: any) => (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {preservation == PRESERVATION_OPTIONS[2].value && (
                      <Col span={6}>
                        <Form.Item
                          label="Mức độ nguy hiểm"
                          name={["goods", "dangerLevel"]}
                          rules={[rule_require]}
                        >
                          <Select placeholder="Chọn mức độ nguy hiểm">
                            {DANGER_OPTION.map((item: any, index: any) => (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={6}>
                      <Form.Item
                        label="Loại kết hợp"
                        name="combinedType"
                        // rules={[rule_require]}
                      >
                        <Select
                          placeholder="Chọn loại kết hợp"
                          onSelect={(value: any) => setCombine(value)}
                        >
                          {COMBINE_TYPE.map((item: any, index: any) => (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {combine === COMBINE_TYPE[0].value && (
                      <Col span={6}>
                        <Form.Item
                          label="Chỉ số kết hợp"
                          name="combinedRate"
                          // rules={[rule_float_number, rule_require]}
                        >
                          <Select
                            {...(combineRate != "CY" && { suffixIcon: "%" })}
                            onChange={(value: any) => setCombineRate(value)}
                          >
                            {COMBINE_RATE.map((item: any) => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={6}>
                      <Row gutter={0}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Form.Item
                            label="Yêu cầu kĩ thuật xe"
                            name={["goods", "technicalRequirement"]}
                            // rules={[rule_require]}
                          >
                            <Input.TextArea
                              placeholder="Nhập yêu cầu kĩ thuật"
                              rows={1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Lưu ý khi đóng (nhận) hàng"
                        name={["goods", "packingInstruction"]}
                        // rules={[rule_require]}
                      >
                        <Input.TextArea
                          placeholder="Nhập Lưu ý khi đóng (trả) hàng"
                          rows={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Giá cước"
                        name="price"
                        rules={[rule_number]}
                      >
                        <InputNumber
                          placeholder="hệ thống tính toán nếu không nhập"
                          formatter={(value) =>
                            ` ${value
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                          }
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CPPS Dự kiến" rules={[rule_number]}>
                        <Input
                          value={incurredTotal}
                          style={{ width: "100%" }}
                          suffix={
                            <EditOutlined
                              onClick={() =>
                                setIncurredModal({
                                  ...incurredModal,
                                  visible: true,
                                })
                              }
                              style={{ fontSize: 16, color: "#3fa3ff" }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Số chì" name="seal">
                        <Input placeholder="Nhập số chì" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Hình thức giao nhận"
                        name="deliveryForm"
                        rules={[rule_require]}
                      >
                        <Select placeholder="Chọn hình thức giao nhận">
                          {DELIVERY_TYPE.map((item: any, index: any) => (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Title level={5} style={{ color: "rgba(3, 169, 244, 1)" }}>
                Danh sách điểm nhận hàng
              </Title>
            </Col>
            <Form.List name="receipt">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field: any, index: number) => (
                    <Card
                      key={field.key}
                      style={{ margin: 0, position: "relative" }}
                    >
                      {!isEmtyFormList("receipt") && (
                        <span
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            zIndex: 1,
                          }}
                        >
                          <CloseCircleOutlined
                            style={{ color: "#f5222d", fontSize: "1.25rem" }}
                            onClick={() => {
                              remove(field.name);
                              onRemoveFormList(field.name, "RECEIPT");
                            }}
                          />
                        </span>
                      )}
                      <Row
                        gutter={[32, 0]}
                        style={{ marginLeft: "0", marginRight: "0" }}
                      >
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item
                            label="Địa chỉ "
                            // name={[field.name, 'text']}
                            // fieldKey={[field.fieldKey, 'text']}
                            rules={[rule_require]}
                          >
                            <AutoComplete
                              options={options}
                              style={{ width: "100%" }}
                              onSearch={onSearch}
                              // inputValue={defaultValue}
                              onChange={(value, option: any) => {
                                setReceiptDistrictName(option?.item?.dName);
                                setReceiptId(option?.item?.dId);
                                setReceiptProvinceName(option?.item?.pName);
                                form.setFields([
                                  {
                                    name: ["receipt", index, "text"],
                                    value: value,
                                  },
                                  {
                                    name: ["receipt", index, "place"],
                                    value: option?.item?._id,
                                  },
                                  {
                                    name: ["receipt", index, "area"],
                                    value: option?.item?.dId,
                                  },
                                ]);
                              }}
                              placeholder={"Nhập địa chỉ"}
                              filterOption={false}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item label="Tỉnh/Thành phố">
                            <Input
                              placeholder="Tỉnh/Thành phố"
                              value={receiptProvinceName}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item label="Quận/Huyện">
                            <Input
                              disabled
                              placeholder="Quận/Huyện"
                              value={receiptDistrictName}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item
                            label="Tên người liên hệ "
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey, "name"]}
                            rules={[rule_require]}
                          >
                            <Input placeholder="Nhập tên người liên hệ" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item
                            label="Số điện thoại"
                            name={[field.name, "phoneNumber"]}
                            fieldKey={[field.fieldKey, "phoneNumber"]}
                            rules={[rule_require, rule_phoneNumber]}
                          >
                            <Input placeholder="Nhập số điện thoại" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={4}>
                          <Form.Item
                            label="Thời gian nhận hàng"
                            name={[field.name, "timeBefore"]}
                            rules={[rule_require]}
                          >
                            <MomentDatePicker
                              showTime
                              format="DD-MM-YYYY HH"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={4}>
                          <Form.Item
                            label="Đường cấm "
                            initialValue="NOT_FORBIDDEN"
                            name={[field.name, "forbiddenRoad"]}
                            fieldKey={[field.fieldKey, "forbiddenRoad"]}
                            rules={[rule_require]}
                          >
                            <Select placeholder="Chọn loại đường">
                              {FORBIDDEN_OPTIONS.map(
                                (item: any, index: any) => (
                                  <Option key={index} value={item.value}>
                                    {item.label}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item
                            label="Địa chỉ "
                            name={[field.name, 'text']}
                            fieldKey={[field.fieldKey, 'text']}
                            rules={[rule_require]}
                          >
                            <Input.TextArea
                              placeholder="Nhập địa chỉ"
                              rows={1}
                            />
                          </Form.Item>
                        </Col> */}
                      </Row>
                    </Card>
                  ))}
                  <Col
                    xs={24}
                    style={{
                      backgroundColor: "#FAFAFB",
                      padding: "8px 0",
                      cursor: "pointer",
                    }}
                    onClick={() => add()}
                  >
                    <span
                      style={{
                        position: "relative",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <PlusOutlined
                        style={{
                          color: "rgba(3, 169, 244, 1)",
                          fontSize: "1.5rem",
                        }}
                      />
                    </span>
                  </Col>
                </>
              )}
            </Form.List>

            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Title
                level={5}
                style={{ color: "rgba(255, 193, 7, 1)", marginTop: 12 }}
              >
                Danh sách điểm trả hàng
              </Title>
            </Col>
            <Form.List name="delivery">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field: any, index: number) => (
                    <Card
                      key={field.key}
                      style={{ margin: 0, position: "relative" }}
                    >
                      {!isEmtyFormList("delivery") && (
                        <span
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            zIndex: 1,
                          }}
                        >
                          <CloseCircleOutlined
                            style={{ color: "#f5222d", fontSize: "1.25rem" }}
                            onClick={() => {
                              remove(field.name);
                              onRemoveFormList(field.name, "DELIVERY");
                            }}
                          />
                        </span>
                      )}
                      <Row
                        gutter={[32, 0]}
                        style={{ marginLeft: "0", marginRight: "0" }}
                      >
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item
                            label="Địa chỉ "
                            // name={[field.name, 'text']}
                            // fieldKey={[field.fieldKey, 'text']}
                            rules={[rule_require]}
                          >
                            <AutoComplete
                              options={options}
                              style={{ width: "100%" }}
                              onSearch={onSearch}
                              // inputValue={defaultValue}
                              onChange={(value, option: any) => {
                                setDeliveryId(option?.item?.dId);
                                setDeliveryDistrictName(option?.item?.dName);
                                setDeliveryProvinceName(option?.item?.pName);
                                form.setFields([
                                  {
                                    name: ["delivery", index, "text"],
                                    value: value,
                                  },
                                  {
                                    name: ["delivery", index, "place"],
                                    value: option?.item?._id,
                                  },
                                  {
                                    name: ["delivery", index, "area"],
                                    value: option?.item?.dId,
                                  },
                                ]);
                              }}
                              placeholder={"Nhập địa chỉ"}
                              filterOption={false}
                            />
                            {/* <Input.TextArea
                              placeholder="Nhập địa chỉ"
                              rows={1}
                            /> */}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item label="Tỉnh/Thành phố">
                            <Input
                              placeholder="Tỉnh/Thành phố"
                              value={deliveryProvinceName}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item label="Quận/Huyện">
                            <Input
                              disabled
                              placeholder="Quận/Huyện"
                              value={deliveryDistrictName}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item
                            label="Tên người liên hệ "
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey, "name"]}
                            rules={[rule_require]}
                          >
                            <Input placeholder="Nhập tên người liên hệ" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                          <Form.Item
                            label="Số điện thoại"
                            name={[field.name, "phoneNumber"]}
                            fieldKey={[field.fieldKey, "phoneNumber"]}
                            rules={[rule_require, rule_phoneNumber]}
                          >
                            <Input placeholder="Nhập số điện thoại" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={4}>
                          <Form.Item
                            label="Thời gian trả hàng"
                            name={[field.name, "timeBefore"]}
                            // fieldKey={[field.fieldKey, 'time']}
                            rules={[rule_require]}
                          >
                            <MomentDatePicker
                              showTime
                              format="DD-MM-YYYY HH"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={4}>
                          <Form.Item
                            label="Đường cấm "
                            name={[field.name, "forbiddenRoad"]}
                            fieldKey={[field.fieldKey, "forbiddenRoad"]}
                            rules={[rule_require]}
                            initialValue="NOT_FORBIDDEN"
                          >
                            <Select placeholder="Chọn loại đường">
                              {FORBIDDEN_OPTIONS.map(
                                (item: any, index: any) => (
                                  <Option key={index} value={item.value}>
                                    {item.label}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <Col
                    xs={24}
                    style={{
                      backgroundColor: "#FAFAFB",
                      cursor: "pointer",
                      padding: "8px 0",
                    }}
                    onClick={() => add()}
                  >
                    <span
                      style={{
                        position: "relative",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <PlusOutlined
                        style={{
                          color: "rgba(255, 193, 7, 1)",
                          fontSize: "1.5rem",
                        }}
                      />
                    </span>
                  </Col>
                </>
              )}
            </Form.List>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Card key={"freight"} style={{ margin: 0, position: "relative" }}>
                <Form.Item
                  label="Tuyến"
                  initialValue=""
                  name={"freight"}
                  rules={[rule_require]}
                >
                  <Select placeholder="Chọn tuyến">
                    {combinations?.map((item) => {
                      return (
                        <Option
                          value={item._id}
                        >{`[${item?.code}] ${item?.description}`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Card>
            </Col>
          </Row>
          <Modal
            width={800}
            closable={true}
            title="Chi phí phát sinh"
            visible={incurredModal.visible}
            onCancel={closeIncurredModal}
            onOk={() => onOk()}
          >
            <Form form={formIncurred}>
              <Form.List name="incurreds">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Row
                        style={{
                          borderBottom: "1px solid #eee",
                          marginBottom: 20,
                          padding: 10,
                        }}
                        gutter={8}
                      >
                        <Col span={8}>
                          <Form.Item
                            label="Tên"
                            name={[field.name, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "Tên phát sinh là bắt buộc",
                              },
                            ]}
                          >
                            <Input placeholder="Tên phát sinh" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Chi phí"
                            name={[field.name, "amount"]}
                            rules={[
                              {
                                required: true,
                                message: "Chi phí là bắt buộc",
                              },
                            ]}
                          >
                            <Input placeholder="Số tiền" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Loại"
                            name={[field.name, "type"]}
                            initialValue="PLAN"
                          >
                            <Select disabled>
                              <Option value="PLAN">Dự kiến</Option>
                              <Option value="ACTUAL">Thực tế</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Trạng thái"
                            name={[field.name, "verified"]}
                            initialValue="TRUE"
                          >
                            <Select>
                              <Option value="TRUE">Duyệt</Option>
                              <Option value="FALSE">Không duyệt</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            label="Mô tả"
                            name={[field.name, "description"]}
                          >
                            <Input.TextArea rows={1} placeholder="Mô tả" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item>
                            <Button
                              danger
                              type="dashed"
                              onClick={() => remove(field.name)}
                              block
                              icon={<MinusOutlined />}
                            ></Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      ></Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </Modal>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} offset={20}>
            <Button
              loading={isLoadingBtn}
              htmlType="submit"
              style={{
                width: "100%",
                backgroundColor: "#03A9F4",
                color: "white",
                marginTop: 12,
              }}
            >
              Thêm đơn chờ
            </Button>
          </Col>
        </Form>
      </Row>
    </div>
  );
}

export default CreateOrder;
