import {useState, useEffect} from 'react'
import { Form, Row, Col, Input, Divider, Button, Typography } from 'antd';
import API from '../shared/api/api';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const {Title} = Typography

function UserUpdateInfomation(props: any) {
    const {owner} = props

    // const getOwnerData = async (req, res) => {
    //     await 
    // }

    useEffect(() => {
        infoForm.setFieldsValue(owner)
    }, [])

    const [loading, setLoading] = useState({
        info: false,
        password: false
    })

    const [infoForm] = Form.useForm();
    const [passwordForm] = Form.useForm();

    const updateInfo = async (value: any) => {
        setLoading({...loading, info: true})
        const response = await API.put(`admin/owner/${owner._id}`, value).then(res => res.data)
        setLoading({...loading, info: false})
    }

    const updatePassword = async (value: any) => {
        setLoading({...loading, password: true})
        const response = await API.put(`admin/owner/${owner._id}/password`, value).then(res => res.data)
        setLoading({...loading, password: false})
    }

    return(
        <>
        <Title style={{marginTop: 10}} level={4}>Thông tin đăng nhập</Title>
            <Form {...layout} form={infoForm}  style={{ width: '100%' }} onFinish={updateInfo} onFinishFailed={() => { setLoading({...loading, info: false }); }}>
                <Row gutter={[32, 0]}>
                    {!props.hideEmail && <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                            label="Email"
                            name="email"
                            initialValue={owner.email}
                        >
                            <Input placeholder='Email' />
                        </Form.Item>
                    </Col>}
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                            label="Số điện thoại"
                            name="phoneNumber"
                            initialValue={owner.phoneNumber}
                        >
                            <Input placeholder="Số điện thoại" />
                        </Form.Item>
                    </Col>
                </Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
                    <Button loading={loading.info} htmlType='submit' style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }}>Cập nhật</Button>
                </Col>
            </Form>
            <Divider />
            <Form {...layout} style={{ width: '100%' }} onFinish={updatePassword} onFinishFailed={() => { setLoading({...loading, password: false }); }}>
                <Row gutter={[32, 0]}>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                            label="Mật khẩu mới"
                            name="password"
                        >
                            <Input type="password" placeholder="Mật khẩu" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                            label="Nhập lại mật khẩu mới"
                            name="repeatPassword"
                        >
                            <Input type="password" placeholder="Mật khẩu" />
                        </Form.Item>
                    </Col>
                </Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
                    <Button loading={loading.password} htmlType='submit' style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }}>Cập nhật</Button>
                </Col>
            </Form>
        </>
    )
}

export default UserUpdateInfomation