import { Button, Col, Row, Typography } from 'antd';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { SearchBox } from '../../Components/SearchBox';
import { IPlace } from './interface';
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { AutocompleteSearch } from '../../Components/AutocompleteSearch';

interface IProps {
  setList: Dispatch<SetStateAction<IPlace[]>>;
  list: IPlace[];
  disabledStartPoint?: Boolean;
  disabledMiddlePoint?: Boolean;
  disabledFinishPoint?: Boolean;
}

export const ListSearchLocation = (props: IProps) => {
  const { setList, list, disabledStartPoint, disabledMiddlePoint,disabledFinishPoint  } = props;

  const listPositions = list.map((item, index) => {
    if (index === 0 || index === list.length - 1) {
      return { ...item, position: index === 0 ? 'start' : 'end' };
    }
    return item;
  });

  const inputRef = useRef<any>(null);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.SearchBox>();
  const [newItem, setNewItem] = useState<IPlace>();

  const insert = (arr: Array<IPlace>, index: number, newItem: IPlace) => [
    ...arr.slice(0, index),

    newItem,

    ...arr.slice(index),
  ];

  const handleClickAddNew = (currentIndex: number) => {
    if (newItem) {
      const result = insert(list, currentIndex + 1, newItem);

      setList(result);
    }

    // inputRef.current.value = '';
  };

  const handleAddFirstRoute = (currentIndex: number) => {
    if (newItem) {
      const temp = list;
      const result = temp.map((item, index) => {
        if (index === currentIndex) {
          return newItem;
        }
        return item;
      });

      setList(result);
    }

    // inputRef.current.value = '';
  };

  const onLoad = (e: google.maps.places.SearchBox) => {
    setAutocomplete(e);
  };

  const handleClickRemoveItem = (index: number) => {
    if (index === 1 && list.length === 3) {
      const newList = list.map((item, i) => {
        if (i === index) {
          return { lat: 0, lng: 0 };
        }
        return item;
      });
      setList(newList);
    } else {
      const newList = list.filter((item, i) => i !== index);
      setList(newList);
    }
  };

  const calculatePolyLineNewItem = (
    overViewPolyline: string,
    distance: number,
    currentItem: IPlace
  ) => {
    const temp = {
      ...currentItem,
      polyline: overViewPolyline,
      distance: Math.round(distance / 1000),
    };
    setNewItem(temp);
  };

  const onPlacesChanged = (currentIndex: number) => {
    const place = autocomplete?.getPlaces()?.[0]?.formatted_address;
    const lat = autocomplete?.getPlaces()?.[0]?.geometry?.location?.lat();
    const lng = autocomplete?.getPlaces()?.[0]?.geometry?.location?.lng();
    const id = autocomplete?.getPlaces()?.[0]?.place_id;

    if (place && lat && lng) {
      const currentItem = {
        address: place,
        lat: lat,
        lng: lng,
        _id: id,
      };
      if (list.length > 0) {
        const directionService = new google.maps.DirectionsService();
        directionService.route(
          {
            destination: new google.maps.LatLng({ lat: lat, lng: lng }),
            origin: new google.maps.LatLng({
              lat: list[currentIndex - 1].lat,
              lng: list[currentIndex - 1].lng,
            }),
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (rs: any) => {
            calculatePolyLineNewItem(
              rs.routes[0]?.overview_polyline,
              rs.routes[0]?.legs[0]?.distance?.value,
              currentItem
            );
          }
        );
      } else {
        setNewItem(currentItem);
      }
    }
  };

  const listRoutes = listPositions.filter((item) => !item?.position);
  console.log({ listRoutes });
  console.log({ listPositions });
  console.log({ list });

  return (
    <Row style={{ width: '100%' }}>
      {list.map((item, index) => {
        if (item.position === 'start') {
          return (
            <Row style={{ padding: 6, width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img
                  alt={'delivery'}
                  src="/icon/delivery.png"
                  style={{ width: '7%', marginRight: '11px' }}
                />
                <AutocompleteSearch
                  setList={setList}
                  list={list}
                  disabled={disabledStartPoint}
                  defaultValue={item?.address ?? ''}
                  isStart
                  placeholder="Nhập điểm xuất phát"
                />
              </div>
            </Row>
          );
        }
        if (item.position === 'end') {
          return (
            <Row style={{ padding: 6, width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img
                  alt={'receipt'}
                  src="/icon/receipt.png"
                  style={{ width: '7%', marginRight: '11px' }}
                />
                <AutocompleteSearch
                  setList={setList}
                  disabled={disabledFinishPoint}
                  list={list}
                  defaultValue={item?.address ?? ''}
                  placeholder="Nhập điểm kết thúc"
                />
              </div>
            </Row>
          );
        } else if (item.lat && item.lng) {
          return (
            !disabledMiddlePoint &&
            <>
              <Row
                style={{ padding: 6, width: '100%' }}
                key={`${item}+${index}`}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    style={{ borderColor: 'white', width: '10%' }}
                    icon={<MoreOutlined />}
                    disabled
                  ></Button>
                  <SearchBox
                    list={list}
                    index={index}
                    defaultValue={item.address ?? ''}
                    setList={setList}
                  ></SearchBox>

                  <Button
                    style={{ borderColor: 'white' }}
                    onClick={() => handleClickRemoveItem(index)}
                    type="dashed"
                    icon={<CloseCircleOutlined />}
                    // disabled={list.length < 4}
                  ></Button>
                </div>
              </Row>
              {index === list.length - 2 && (
                <Row style={{ padding: 6, display: 'flex', width: '100%' }}>
                  <Button
                    style={{ borderColor: 'white', width: '10%' }}
                    icon={<PlusCircleOutlined />}
                    onClick={() => handleClickAddNew(index)}
                    disabled={inputRef?.current?.value === ''}
                  ></Button>
                  <div style={{ width: '83%' }}>
                    <StandaloneSearchBox
                      onLoad={onLoad}
                      onPlacesChanged={() => onPlacesChanged(index)}
                    >
                      <>
                        <input
                          ref={inputRef}
                          // disabled={list.length <= 1}
                          placeholder="Nhập địa điểm"
                          key={'newItem'}
                          defaultValue=""
                          style={{
                            fontSize: '14px',
                            width: '100%',
                            lineHeight: 1.7,
                          }}
                        ></input>
                      </>
                    </StandaloneSearchBox>
                  </div>
                </Row>
              )}
            </>
          );
        }
        return (
          <Row style={{ padding: 6, display: 'flex', width: '100%' }}>
            <Button
              style={{ borderColor: 'white', width: '10%' }}
              icon={<PlusCircleOutlined />}
              onClick={() => handleAddFirstRoute(index)}
              disabled={inputRef?.current?.value === ''}
            ></Button>
            <div style={{ width: '83%' }}>
              <StandaloneSearchBox
                onLoad={onLoad}
                onPlacesChanged={() => onPlacesChanged(index)}
              >
                <>
                  <input
                    ref={inputRef}
                    // disabled={list.length <= 1}
                    placeholder="Nhập địa điểm"
                    key={'newItem'}
                    defaultValue=""
                    style={{
                      fontSize: '14px',
                      width: '100%',
                      lineHeight: 1.7,
                    }}
                  ></input>
                </>
              </StandaloneSearchBox>
            </div>
          </Row>
        );
      })}
    </Row>
  );
};
