import React, { useEffect, useState } from 'react';
import API from '../../../shared/api/api';
import { Col, Row, Form, Input, Button, InputNumber, Divider, Select, Typography } from 'antd';
import { useHistory } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons';
import { dateFormatYMD } from '../../../shared/dataFormat';
import { rule_number, rule_require } from '../../../shared/validateInput';
import axios from 'axios';
import { createBot } from '../../../shared/api/BOT';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Option } = Select;
function BotCreate() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [isLoadingBtn, setLoadingBtn] = useState(false);
    const [cityList, setCityList] = useState<any>([]);
    useEffect(() => {
        getListCity();
        return () => {
        }
    }, [])
    const getListCity = () => {
        axios.get('https://vapi.vnappmob.com/api/province')
            .then((res: any) => {
                if (res.status === 200) {
                    setCityList(res.data.results)
                }
            }).catch((error) => {
                console.log(error)
            })
    }
    const onCreate = async () => {
        setLoadingBtn(true)
        const response = await createBot(form.getFieldsValue())
        if (response.success) history.push('/partner/bot');
        setLoadingBtn(false)
    }
    return (
        <Row style={{ width: '100%' }}>
            <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}><span onClick={() => { history.goBack() }}><LeftOutlined />Quay lại</span></Title>
            <Form form={form} {...layout} style={{ width: '100%' }} onFinish={onCreate} onFinishFailed={() => { setLoadingBtn(false) }}>
                <Row gutter={[32, 0]} style={{ width: '100%' }}>
                    <Col span={8}>
                        <Form.Item
                            label="Tên trạm"
                            name='name'
                            rules={[rule_require]}
                        >
                            <Input placeholder='Tên' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Tỉnh/TP"
                            name={['address','province']}
                            rules={[rule_require]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Chọn thành phố"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {cityList.map((city: any) =>
                                    <Option key={city.province_id} value={city.province_name}>{city.province_name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Địa điểm"
                            name={['address','text']}
                            rules={[rule_require]}
                        >
                            <Input placeholder='Địa điểm' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Vé 20"
                            name={['turn','twenty']}
                            rules={[rule_number]}
                        >
                            <InputNumber
                                formatter={value => ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Vé 40"
                            name={['turn','forty']}
                            rules={[rule_number]}
                        >
                            <InputNumber
                                formatter={value => ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                        <Form.Item
                            label="Vé tháng"
                            name='monthly'
                            rules={[rule_number]}
                        >
                            <InputNumber
                                formatter={value => ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Vé quý"
                            name='quarterly'
                            rules={[rule_number]}
                        >
                            <InputNumber
                                formatter={value => ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Vé năm"
                            name='yearly'
                            rules={[rule_number]}
                        >
                            <InputNumber
                                formatter={value => ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
                    <Button loading={isLoadingBtn} htmlType='submit' style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }} >Thêm mới</Button>
                </Col>
            </Form>
        </Row>
    )
}

export default BotCreate
