import API from "../api";

export const getAllIncurred = async (params: any) => {
    try {
        const response = await API.post('admin/incurred', params).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}