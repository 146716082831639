import API from "../../api";

export const getAllAssigningOrder = async (params: object) => {
    try {
        const response = await API.get('admin/assigning-order', { params: params }).then(
          (res) => res.data
        );
        if (response.success) {
            const listData = [...response.data];
            listData.forEach((item: { key: any; _id: any }) => {
                item.key = item._id;
            });
            const customData = listData.map((item: any) => (
              {
                  ...item,
                  customRoutes: {
                      orderId: item._id,
                      receipt: item.receipt,
                      delivery: item.delivery,
                      routes: item.routes,
                  }
              }))
            return { data: customData, total: response.totalItem };
        }
    } catch (error) {
        throw error;
    }
}

export const cancelAssigningOrder = async (params:string) => {
    try {
        const response = await API.put(`admin/assigning-order/${params}/cancel`).then(
          (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const verifyAssigningOrder = async (params:string, data: Object = {all: false}) => {
    try {
        const response = await API.put(`admin/assigning-order/${params}/verify`, data).then(
          (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const assignTruckVendorRoute = async (params:{orderId: any, route: any, truckVendorId: any}) => {
    const {orderId, route, truckVendorId} = params;
    try {
        const response = await API.put(`admin/assigning-order/${orderId}/route/${route._id}/assign-truck-vendor/${truckVendorId}/`).then(
          (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}
export const setExpenseAmountForOrder = async (params:{orderId: any, amount: any}) => {
    const {orderId, amount} = params;
    const body = {
        expenseAmount: amount
    }
    try {
        return await API.put(`admin/assigning-order/${orderId}/expense`, body).then(
          (res) => res.data
        )
    } catch (error) {
        throw error;
    }
}

export const assignRouteToVehicle = async (params:{ routeId: any, vehicleId: any}) => {
    const { routeId, vehicleId} = params;
    try {
        const response = await API.put(`admin/route/${routeId}/assign-vehicle/${vehicleId}`).then(
          (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}
