import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, Layout, Typography, Image, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import API from '../../shared/api/api';
import { setTokenUser } from '../../shared/authoration';
import history from '../../shared/history'
import { checkTokenExpired } from '../../shared/authoration';
import './Login.css';
import { rule_max_character, rule_min_character, rule_phoneNumber, rule_require } from '../../shared/validateInput';
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const tailLayoutLeft = {
    wrapperCol: { offset: 0, span: 24 },
};
const tailLayoutRight = {
    wrapperCol: { offset: 8, span: 16 },
};
const { Option } = Select;
const { Title } = Typography;

const { Header, Content } = Layout;
function Login() {
    const [accType, setAccType] = useState('Admin');
    const [form] = Form.useForm();
    useEffect(() => {
        if (checkTokenExpired()) {
            history.push('/order/pending');
        }
    }, [])
    const handleSelect = (value: any) => {
        setAccType(value);
    }
    const onFinish =  () => {
         API.post('login', form.getFieldsValue())
            .then(
                res => {
                    if (res.data.success) {
                        const params = {
                            token: res.data.data.token,
                        }
                        setTokenUser(params);
                        history.push('/order/pending');
                    }
                })
    }
    return (
        <Layout id='login-layout' style={{ minHeight: '120vh' }}>
            <Header className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <img alt='logo' style={{ height: '19px', width: '175px', position: 'relative', top: '50%', transform: 'translateY(-50%)' }} src='./icon/alo-truck-logo.png' />
                <Space size='large'>
                    <Title level={5} style={{color:'white'}}>Trang chủ</Title>
                    <Title level={5} style={{color:'white'}}>Giới thiệu</Title>
                    <Title level={5} style={{color:'white'}}>Sản phẩm</Title>
                    <Title level={5} style={{color:'white'}}>Bảng giá</Title>
                    <Title level={5} style={{color:'white'}}>Hướng dẫn</Title>
                    <Title level={5} style={{color:'white'}}>Liên hệ</Title>
                </Space>
                <Space>
                    <Button id='sign-in-btn'>Đăng nhập</Button>
                    <Button id='sign-up-btn'>Đăng ký</Button>
                </Space>
            </Header>
            <Content style={{ padding: '0 50px' }}>
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    style={{ position: 'relative', top: '30px', left: '50%', transform: 'translateX(-50%)', backgroundColor: '#ffffff', width: '400px', padding: '42px 32px' }}
                >
                    <Title level={5} style={{ width: '100%', textAlign: 'center', marginBottom: '30px' }}>Đăng nhập AloTruck</Title>
                    {/* <Image src={`/${accType}.png`} width={170} height={140} style={{ position: 'relative', left: '50%', transform: 'traslateX(-50%)' }} /> */}
                    <Form.Item
                        label="Email/Phone"
                        name="emailOrPhoneNumber"
                        rules={[rule_require]}
                    >
                        <Input placeholder='Nhập email hoặc số điện thoại'/>
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[rule_require,rule_min_character,rule_max_character]}
                    >
                        <Input.Password />
                    </Form.Item>
                    {/* <Form.Item
                        label='Chọn tài khoản'
                        name='type'
                        initialValue="Admin"
                    >
                        <Select style={{ width: '100%' }} onSelect={handleSelect}>
                            <Option value="Admin">Admin</Option>
                            <Option value="Truck-Vendor">Truck Vendor</Option>
                            <Option value="Forwarder">Forwarder</Option>
                        </Select>
                    </Form.Item> */}
                    <Form.Item {...tailLayoutLeft} valuePropName="checked">
                        <Checkbox style={{ marginRight: '105px' }}>Remember me</Checkbox>
                        <Link to='/'>Quên mật khẩu ?</Link>
                    </Form.Item>
                    {/* <Form.Item {...tailLayoutLeft} name="accept" valuePropName="checked">
                        <Checkbox>Đồng ý với điều khoản</Checkbox>
                    </Form.Item> */}
                    <Form.Item {...tailLayoutRight}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    )
}

export default Login
