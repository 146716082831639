import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { AutoComplete } from "antd";
import { searchRegion } from "../shared/api/Route";
import * as _ from "lodash";
import { IPlace } from "../Modules/Route/interface";

interface IProps {
  placeholder: string;
  setList: Dispatch<SetStateAction<IPlace[]>>;
  list: IPlace[];
  isStart?: boolean;
  defaultValue?: any;
  disabled?: Boolean;
}

export const AutocompleteSearch = (props: IProps) => {
  const { placeholder, setList, disabled, list, isStart, defaultValue } = props;

  const [options, setOptions] = React.useState([]);

  const handleSearch = async (txt: string) => {
    if (txt) {
      const response: any = await searchRegion(txt);
      if (response) {
        if (response.data) {
          const result = response.data.map((item: any) => {
            return {
              label: `${item.name}, ${item.pName}`,
              value: `${item.name}, ${item.pName}`,
              item: item,
            };
          });
          setOptions(result);
        }
      }
    }
  };

  const debouncedChangeHandler = useMemo(
    () => _.debounce((userInput) => handleSearch(userInput), 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const onSearch = (text: string) => {
    debouncedChangeHandler(text);
  };

  const createOrigin = () => {
    if (list[1].lat && list[1].lng && list.length >= 3) {
      //valid route
      return {
        lat: list[list.length - 2]?.lat,
        lng: list[list.length - 2]?.lng,
      };
    } else {
      return { lat: list[0]?.lat, lng: list[0]?.lng };
    }
  };

  return (
    <>
      <AutoComplete
        options={options}
        disabled={disabled}
        style={{ width: "83%" }}
        onSearch={onSearch}
        defaultValue={defaultValue}
        onChange={(value, option: any) => {
          if (Object.keys(option).length > 0) {
            const convertedOption = {
              ...option?.item,
              lat: option?.item?.location?.lat,
              lng: option?.item?.location?.lng,
              position: isStart ? "start" : "end",
              address: `${option?.item?.name}, ${option?.item?.pName}`,
            };
            delete convertedOption.location;
            delete convertedOption.name;
            delete convertedOption.pName;
            delete convertedOption.pId;

            if (isStart) {
              if (list.length === 0) {
                return setList([convertedOption]);
              }
              const temp = list;
              const result = temp.map((item, index) => {
                if (index === 0) {
                  return convertedOption;
                }
                return item;
              });
              console.log("resultresult", result);

              setList(result);
            } else {
              const destination = {
                lat: option?.item?.location?.lat,
                lng: option?.item?.location?.lng,
              };
              const origin = createOrigin();
              const directionService = new google.maps.DirectionsService();
              directionService.route(
                {
                  destination: new google.maps.LatLng(destination),
                  origin: new google.maps.LatLng(origin),
                  travelMode: google.maps.TravelMode.DRIVING,
                },
                (rs: any) => {
                  convertedOption.polyline = rs.routes[0]?.overview_polyline;
                  convertedOption.distance = Math.round(
                    rs.routes[0]?.legs[0]?.distance?.value / 1000
                  );
                }
              );
              const temp = list;
              const result = temp.map((item, index) => {
                if (index === list.length - 1) {
                  return convertedOption;
                }
                return item;
              });
              setList(result);
            }
          }
        }}
        placeholder={placeholder}
        filterOption={false}
        // notFoundContent={fetching ? <Spin size="small" /> : null}
      />
    </>
  );
}; // Usage of DebounceSelect

// export const AutocompleteSearch = () => {
//   const [value, setValue] = React.useState([]);
//   return (
//     <DebounceSelect
//       value={value}
//       placeholder="Select users"
//       onChange={(newValue: any) => {
//         setValue(newValue);
//       }}
//       style={{
//         width: '100%',
//       }}
//     />
//   );
// };
