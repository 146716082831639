import React, { useState, useEffect } from 'react';
import { Col, Row, Table, Typography, Select, Space, Input, Button, Modal, Form } from 'antd';
import API from '../../../shared/api/api';
import { rule_require } from '../../../shared/validateInput';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
function MessageModal(props: any) {
    const [form] = Form.useForm();
    const [isLoadingBtn, setLoadingBtn] = useState<boolean>(false);

    const handleOk = () => {
        props.setVisible(false);
        sendMessage();
    };

    const handleCancel = () => {
        props.setVisible(false);
    };
    const sendMessage = async () => {
        setLoadingBtn(true)
        const response = await API.post(`admin/tracking/order/${props.id}/notify`,form.getFieldsValue()).then(res => res.data)
        setLoadingBtn(false);
    }
    return (
        <Modal title={props.title} visible={props.visible} onOk={handleOk} onCancel={handleCancel}>
            <Form form={form} {...layout} style={{ width: '100%' }} onFinish={sendMessage} onFinishFailed={() => { setLoadingBtn(false) }}>
                <Row gutter={[32, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Tiêu đề thông báo"
                            name="title"
                            initialValue="Hệ thống AloTruck"
                            rules={[rule_require]}
                        >
                            <Input placeholder="Nhập tiêu đề"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label="Nội dung thông báo"
                            name="body"
                            rules={[rule_require]}
                        >
                            <Input.TextArea rows={3} placeholder="Nhập nội dung"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default MessageModal
