import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Image,
  Spin,
  Typography,
  Divider,
  Select,
  Space,
  Card,
  Table,
  Avatar,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import axios from "axios";
import API from "../../../shared/api/api";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { dateFormat, dateFormatYMD } from "../../../shared/dataFormat";
import {
  NUMBER_OF_AXLES_OPTION,
  NUMBER_OF_FEETS_OPTIONS,
  PAYLOAD_OPTION,
  PRESERVATION_OPTIONS,
  VEHICLE_OPTION,
} from "../../../shared/define";
import { rule_phoneNumber, rule_require } from "../../../shared/validateInput";
import MomentDatePicker from "../../../Components/MomentDatePicker";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Option } = Select;
const { Title } = Typography;
function CreateRoute() {
  let { id }: any = useParams();
  const history = useHistory();
  const [listImage, setListImage] = useState<any>([]);
  const [spinning, setSpinning] = useState(true);
  const [form] = Form.useForm();
  const [transitForm] = Form.useForm();
  const [vehicleType, setVehicleType] = useState<any>("");
  const [isloading, setIsLoading] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [vendorSelected, setVendorSelected] = useState("");
  const [cityList, setCityList] = useState<any>([]);
  const [deliveryAreaList, setDeliveryAreaList] = useState<any>([]);
  const [isLoadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    getListCity();
    getTruckVendorList();
    loadData();
    return () => {};
  }, []);
  const getListCity = () => {
    axios
      .get("https://vapi.vnappmob.com/api/province")
      .then((res: any) => {
        if (res.status === 200) {
          setCityList(res.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getListArea = (province: string) => {
    const param = { params: { province: province } };
    API.get(`admin/freight/area`, param)
      .then((res) => {
        if (res.data.success) {
          let listData = res.data.data;
          let customData = [];
          for (let i = 0; i < listData.length; i++) {
            customData.push({ area: listData[i].area, _id: listData[i]._id });
          }
          setDeliveryAreaList(customData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const loadData = () => {
    setSpinning(true);
    API.get(`admin/assigning-order/${id}/route/create`)
      .then((res) => {
        if (res.data.success) {
          let listData = res.data.data;
          for (let i = 0; i < listData.receipt.length; i++) {
            listData.receipt[i] = {
              ...listData.receipt[i],
              time: dateFormat(listData.receipt[i].time),
            };
          }
          form.setFieldsValue(listData);
          setListImage(listData?.goods?.avatars);
          setVehicleType(listData.vehicle.name);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSpinning(false);
      });
  };
  const getTruckVendorList = () => {
    setIsLoading(true);
    API.get("admin/truck-vendor/")
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setVendorList(listData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onCreate = async () => {
    setLoadingBtn(true);
    let customData = { ...transitForm.getFieldsValue() };
    customData.time = dateFormatYMD(customData.time);

    const response = await API.post(`admin/assigning-order/${id}/route`, {
      delivery: [customData],
      truckVendor: vendorSelected,
    })
      .then((res) => res.data)
      .finally(() => setLoadingBtn(false));
    if (response.success) history.push("/order/assign/route");
  };
  const columns: Array<object> = [
    {
      title: "",
      dataIndex: "avatar",
      render: (avatar: string) => <Avatar size="large" src={avatar} />,
    },
    {
      title: "CEO",
      dataIndex: "representativeName",
    },
    {
      title: "Số xe",
      dataIndex: "numberOfVehicles",
    },
    {
      title: "Thế mạnh",
      dataIndex: "strengths",
    },
    {
      title: "Công ty",
      align: "center",
      dataIndex: "name",
    },
    {
      title: "Hotline",
      align: "center",
      dataIndex: "hotline",
    },
    {
      title: "Tác vụ",
      align: "center",
      width: 120,
      render: (vendor: any) => (
        <Space size="middle">
          <Button
            type={vendor._id == vendorSelected ? "primary" : "default"}
            onClick={() => {
              if (vendor._id == vendorSelected) {
                setVendorSelected("");
              } else {
                setVendorSelected(vendor._id);
              }
            }}
          >
            {vendor._id == vendorSelected ? <>Đã chọn</> : <>Chọn</>}
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <Row style={{ width: "100%" }}>
      <Spin spinning={spinning}>
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>
        <Form form={form} {...layout} style={{ width: "100%" }}>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Ảnh hàng" name={["goods", "avatar"]}>
                <Space>
                  {listImage.map((image: string, index: any) => (
                    <Image key={index} width={110} height={110} src={image} />
                  ))}
                </Space>
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} md={16} lg={16} xl={16}>
              <Row gutter={[32, 0]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Tên khách hàng" name={["customer", "name"]}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Số điện thoại"
                    name={["customer", "phoneNumber"]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Tên hãng tàu" name={"shippingLines"}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Mã đơn" name={"code"}>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="Tên hàng" name={["goods", "name"]}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="Khối lượng" name={["goods", "volume"]}>
                <Input disabled suffix="tấn" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Tính chất hàng hóa"
                name={["goods", "preservation"]}
              >
                <Select disabled>
                  {PRESERVATION_OPTIONS.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Loại"
                name={["vehicle", "name"]}
                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
              >
                <Select disabled>
                  {VEHICLE_OPTION.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {vehicleType === "CONTAINER" && (
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Số trục xe"
                  name={["vehicle", "numberOfAxles"]}
                  // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                >
                  <Select disabled>
                    {NUMBER_OF_AXLES_OPTION.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {vehicleType === "CONTAINER" && (
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Loại Container"
                  name={["vehicle", "numberOfFeets"]}
                  // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                >
                  <Select disabled>
                    {NUMBER_OF_FEETS_OPTIONS.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {vehicleType === "TRUCK" && (
              <Col xs={24} sm={8}>
                <Form.Item label="Tải trọng" name={["vehicle", "payload"]}>
                  <Select disabled>
                    {PAYLOAD_OPTION.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {vehicleType === "TRUCK" && <Col xs={24} sm={8}></Col>}
            <Col xs={24} sm={8}>
              <Form.Item label="Chủ hàng" name={["forwarder", "name"]}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Lưu ý khi đóng (trả) hàng"
                name={["goods", "packingInstruction"]}
              >
                <Input.TextArea rows={4} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Yêu cầu kĩ thuật xe"
                name={["goods", "technicalRequirement"]}
              >
                <Input.TextArea rows={4} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Title level={5} style={{ marginBottom: "25px" }}>
            Thông tin chặng
          </Title>
          <Form.List name="receipt">
            {(fields) => (
              <>
                {fields.map((field: any) => (
                  <Card
                    key={field.key}
                    style={{
                      margin: "0px 10px 10px 10px",
                      position: "relative",
                    }}
                  >
                    <Row
                      key={field.key}
                      gutter={[32, 0]}
                      style={{ marginLeft: "0", marginRight: "0" }}
                    >
                      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                          label="Tên người liên hệ "
                          name={[field.name, "name"]}
                          fieldKey={[field.fieldKey, "name"]}
                          // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                          label="Số điện thoại"
                          name={[field.name, "phoneNumber"]}
                          fieldKey={[field.fieldKey, "phoneNumber"]}
                          // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                          label="Thời điểm khởi hành"
                          name={[field.name, "time"]}
                          fieldKey={[field.fieldKey, "time"]}
                          // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                          label="Khu vực nhận hàng dự kiến"
                          name={[field.name, "area", "province"]}
                          fieldKey={[field.fieldKey, "province"]}
                          // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                          label="Địa chỉ nhận hàng dự kiến"
                          name={[field.name, "text"]}
                          fieldKey={[field.fieldKey, "text"]}
                          // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </>
            )}
          </Form.List>
        </Form>
        <Form
          form={transitForm}
          {...layout}
          style={{ width: "100%" }}
          onFinish={onCreate}
          onFinishFailed={() => {
            setLoadingBtn(false);
          }}
        >
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Tên người liên hệ điểm trung chuyển"
                name="name"
                rules={[rule_require]}
              >
                <Input placeholder="Nhập tên người liên hệ" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Số điện thoại điểm trung chuyển"
                name="phoneNumber"
                rules={[rule_phoneNumber]}
              >
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={8}></Col> */}
            <Col xs={24} sm={8}>
              <Form.Item label="Ngày tháng" name="time" rules={[rule_require]}>
                <MomentDatePicker />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Thành phố trung chuyển dự kiến"
                rules={[rule_require]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn thành phố"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(value: string) => {
                    transitForm.setFieldsValue({ area: "" });
                    getListArea(value);
                  }}
                >
                  {cityList.map((city: any) => (
                    <Option key={city.province_id} value={city.province_name}>
                      {city.province_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Khu vực trung chuyển dự kiến"
                name="area"
                rules={[rule_require]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn khu vực"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {deliveryAreaList.map((area: any) => (
                    <Option key={area._id} value={area._id}>
                      {area.area}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Địa chỉ trung chuyển dự kiến"
                name="text"
                rules={[rule_require]}
              >
                <Input placeholder="Nhập địa chỉ trung chuyển" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thông tin hướng dẫn nhận hoặc trả hàng"
                name="packingInstruction"
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Nhập hướng dẫn nhận trả"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Yêu cầu kĩ thuật" name="technicalRequirement">
                <Input.TextArea rows={4} placeholder="Nhập yêu cầu kĩ thuật" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Title level={5} style={{ marginBottom: "25px" }}>
            Danh sách nhà xe
          </Title>
          <Table
            // rowSelection={{ type: 'radio',onSelect:(record, selected, selectedRows, nativeEvent)=>{}}}
            columns={columns}
            dataSource={vendorList}
            loading={isloading}
          />
          <Col xs={4} sm={4} md={4} lg={4} xl={4} offset={20}>
            <Button
              loading={isLoadingBtn}
              htmlType="submit"
              style={{
                width: "100%",
                backgroundColor: "#03A9F4",
                color: "white",
              }}
            >
              Hoàn tất
            </Button>
          </Col>
        </Form>
      </Spin>
    </Row>
  );
}

export default CreateRoute;
