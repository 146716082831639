import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Button,
  Row,
  Typography,
  Modal,
  Form,
  InputNumber,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { dateFormat, dateFormatYMD } from "../../../shared/dataFormat";
import { createOil, getAllOil, updateOil } from "../../../shared/api/Oil";
import MomentDatePicker from "../../../Components/MomentDatePicker";

const { Title } = Typography;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
function OilList() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [id, setId] = useState("");

  const [oilPrice, setOilPrice] = useState({
    list: [],
    loading: false,
    filter: {},
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setOilPrice({ ...oilPrice, loading: true });
    const response: any = await getAllOil();
    setOilPrice({ ...oilPrice, list: response?.data, loading: false });
  };
  const columns: Array<object> = [
    {
      title: "STT",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Ngày",
      dataIndex: "date",
      render: (value: any) => dateFormat(value),
    },
    {
      title: "Giá dầu",
      dataIndex: "price",
      render: (value: any) => new Intl.NumberFormat().format(value) + "VNĐ",
    },
    // {
    //     title: 'Tác vụ',
    //     align: "center",
    //     width: 100,
    //     render: (oil: any) => (
    //         <Space size="middle">
    //             <Tooltip title="Sửa">
    //                 <EditOutlined style={{ fontSize: 16 }} onClick={() => {
    //                     setId(oil._id);
    //                     setIsModalUpdateVisible(true)
    //                     formUpdate.setFieldsValue({date:moment(oil.date),price:oil.price})
    //                 }} />
    //             </Tooltip>
    //         </Space>
    //     ),
    // }
  ];
  const handleOk = () => {
    form.submit();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form.setFieldsValue({});
    setIsModalVisible(false);
  };
  const handleOkUpdate = () => {
    formUpdate.submit();
    setIsModalUpdateVisible(false);
  };

  const handleCancelUpdate = () => {
    formUpdate.setFieldsValue({});
    setIsModalUpdateVisible(false);
  };
  const onCreate = async () => {
    const response = await createOil({
      ...form.getFieldsValue(),
      date: dateFormatYMD(form.getFieldsValue()?.date),
    });
    if (response.success) fetchData();
  };
  const onUpdate = async () => {
    const response = await updateOil(id, {
      ...formUpdate.getFieldsValue(),
      date: dateFormatYMD(formUpdate.getFieldsValue()?.date),
    });
    if (response.success) fetchData();
  };
  return (
    <>
      <Modal
        title="Tạo giá dầu"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form {...layout} form={form} onFinish={onCreate}>
          <Form.Item name="price" label="Giá dầu">
            <InputNumber
              formatter={(value) =>
                ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item name="date" label="Ngày">
            <MomentDatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Cập nhật giá dầu"
        visible={isModalUpdateVisible}
        onOk={handleOkUpdate}
        onCancel={handleCancelUpdate}
      >
        <Form form={formUpdate} onFinish={onUpdate}>
          <Form.Item name="price" label="Giá dầu">
            <InputNumber
              formatter={(value) =>
                ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item name="date" label="Ngày">
            <MomentDatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
      <Row justify="space-between" gutter={6}>
        <Title level={4}>Quản lý giá dầu</Title>
        <Space size={6}>
          <Button
            onClick={() => {
              setIsModalVisible(true);
            }}
            type="primary"
            size="small"
            icon={<PlusOutlined />}
          >
            Tạo mới
          </Button>
        </Space>
      </Row>
      <br />
      <Table
        scroll={{ x: "max-content" }}
        rowKey="_id"
        loading={oilPrice.loading}
        columns={columns}
        pagination={
          oilPrice.pagination.total > oilPrice.pagination.pageSize &&
          oilPrice.pagination
        }
        dataSource={oilPrice.list}
        onChange={(value: any) =>
          setOilPrice({
            ...oilPrice,
            pagination: { ...oilPrice.pagination, current: value.current },
          })
        }
      />
    </>
  );
}

export default OilList;
