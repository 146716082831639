import React, { useState } from 'react'
import {Form, Row, Col, Input, Button, Space, Upload, Typography, Divider, InputNumber} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import API from '../../../shared/api/api';
import { useHistory } from 'react-router-dom'
import { rule_require, rule_phoneNumber } from '../../../shared/validateInput'
import { beforeUpload, onPreview, propsImage } from '../../../shared/imageHandle';
import AddressForm from '../../../shared/Components/AddressForm.tsx/AddressForm';
import BusinessLicense from '../../../shared/Components/BusinessLicense/BusinessLicense';
import UserInfoForm from '../../../shared/Components/UserInfoForm/UserInfoForm';


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Title, Text } = Typography;
function CreateForwarder() {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const history = useHistory();
    const [isLoadingBtn, setLoadingBtn] = useState(false)
    const [idFront, setIdFront] = useState<any>([])
    const [idBack, setIdBack] = useState<any>([])

    const parseData = () =>{
        let customdata = { ...form.getFieldsValue() };
        customdata = {
            ...customdata,
            IDCard: {
                ...customdata.IDCard,
                front: idFront[0]?.response.data.url,
                back: idBack[0]?.response.data.url,
            },
            businessLicense: {
                ...customdata.businessLicense,
                front: customdata.businessLicense?.front.file.response.data.url,
                back: customdata.businessLicense?.back.file.response.data.url,
            },
            avatar: fileList[0]?.response?.data?.url,
            email:form.getFieldValue(['owner','email']),
            phoneNumber:form.getFieldValue(['owner','phoneNumber']),
            password:form.getFieldValue('password'),
        }
        return customdata;
    }
    const onCreate = async () => {
        setLoadingBtn(true);
        
        const response = await API.post('admin/forwarder', parseData()).then(res => res.data)
        if (response.success) history.push('/partner/forwarder');
        setLoadingBtn(false)
    }
    const onChange = ({ fileList: newFileList }: { fileList: any }) => {
        setFileList(newFileList);
    };
    return (
        <Row style={{ width: '100%' }}>
            <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}><span onClick={() => { history.goBack() }}><LeftOutlined />Quay lại</span></Title>
            <Form initialValues={{ address:{country:'Việt Nam'}}} form={form} {...layout} style={{ width: '100%' }} onFinish={onCreate}>
                <UserInfoForm/>
                <Divider />
                <Row gutter={[32, 0]}>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                            label="Ảnh doanh nghiệp"
                            name="avatar"
                            valuePropName="avatar"
                            extra={<Space direction="vertical"><Text>Ảnh không quá 1mb</Text><Text>Size 200x200px</Text></Space>}
                        >
                            <ImgCrop rotate>
                                <Upload
                                    listType="picture-card"
                                    {...propsImage}
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={onPreview}
                                    beforeUpload={beforeUpload}
                                >
                                    {fileList.length < 1 && '+ Upload'}
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                        <Row gutter={[16,0]}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Tên doanh nghiệp"
                                    name="name"
                                    rules={[rule_require]}
                                >
                                    <Input placeholder='Nhập tên doanh nghiệp'/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Hotline"
                                    name="hotline"
                                    rules={[rule_require,rule_phoneNumber]}
                                >
                                    <Input placeholder='Nhập số hotline'/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Tên người đại diện"
                                    name="representativeName"
                                    rules={[rule_require]}
                                >
                                    <Input placeholder='Nhập tên người đại diện'/>
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item
                                  name={['operationManagedCost', 'ownerTruckVendorCost']}
                                  rules={[rule_require]}
                                  labelCol={{ span: 24 }}
                                  label='Lương điều hành xe nhà'
                                >
                                    <InputNumber
                                        formatter={value => ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                                        style={{ width: '100%' }}
                                    />
                                    {/*<Input placeholder='Số tiền'/>*/}
                                </Form.Item>
                                <Form.Item
                                  name={['operationManagedCost', 'contractorTruckVendorCost']}
                                  rules={[rule_require]}
                                  labelCol={{ span: 24 }}
                                  label='Lương điều hành xe dịch vụ'
                                >
                                    <InputNumber
                                      formatter={value => ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                                      style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Divider />
                    <Row gutter={[32, 0]} style={{ padding: '0 16px' }}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item
                                            label="CMND/CCCD Người đại diện"
                                            name={['IDCard', 'number']}
                                            // rules={[rule_require, rule_number]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <Input placeholder='Nhập số CMND/CCCD'/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            name={['IDCard', 'front']}
                                            labelCol={{ span: 24 }}
                                            label='Mặt trước'
                                        >
                                            <ImgCrop aspect={3/2} rotate>
                                                <Upload
                                                    listType="picture-card"
                                                    {...propsImage}
                                                    fileList={idFront}
                                                    onChange={
                                                        ({ fileList: newFileList }: { fileList: any }) => {
                                                            setIdFront(newFileList)
                                                        }}
                                                    onPreview={onPreview}
                                                    beforeUpload={beforeUpload}
                                                >
                                                    {idFront.length < 1 && '+ Upload'}
                                                </Upload>
                                            </ImgCrop>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            name={['IDCard', 'back']}
                                            labelCol={{ span: 24 }}
                                            label='Mặt sau'
                                        >
                                            <ImgCrop aspect={3/2} rotate>
                                                <Upload
                                                    listType="picture-card"
                                                    {...propsImage}
                                                    fileList={idBack}
                                                    onChange={
                                                        ({ fileList: newFileList }: { fileList: any }) => {
                                                            setIdBack(newFileList)
                                                        }}
                                                    onPreview={onPreview}
                                                    beforeUpload={beforeUpload}
                                                >
                                                    {idBack.length < 1 && '+ Upload'}
                                                </Upload>
                                            </ImgCrop>
                                        </Form.Item>
                                    </Col>
                                </Row>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <BusinessLicense />
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Thế mạnh"
                                name="strengths"
                            >
                                <Input.TextArea rows={4} placeholder='Nhập thế mạnh'/>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Divider />
                    <AddressForm />
                </Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
                    <Button loading={isLoadingBtn} htmlType='submit' style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }}>Thêm doanh nghiệp</Button>
                </Col>

            </Form>
        </Row>
    )
}

export default CreateForwarder
