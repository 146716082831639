import API from "../api";

export const createShip= async (params: object) => {
    try {
        const response = await API.post('admin/shipping-line', params).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getShipPagination= async (params: object) => {
    try {
        const response = await API.get('admin/shipping-line', { params: params })
        .then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getShipDetail= async (id:string) => {
    try {
        const response = await API.get(`admin/shipping-line/${id}`)
        .then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const updateShip = async (id:string ,values:object) => {
    try {
        const response = await API.put(`admin/shipping-line/${id}`, values)
        .then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getAllShip= async (param : any = {}) => {
    try {
        const response = await API.get('admin/shipping-line/all',{params:param}).then(
            (res) => res.data
        );
        if (response.success) {
            const listData = [...response.data];
            listData.forEach((item: { key: any; _id: any }) => {
                item.key = item._id;
            });
            return { data: listData};
        }
    } catch (error) {
        throw error;
    }
}



