import React, { useState, useEffect } from "react";
import {
  Row,
  Typography,
  Form,
  Button,
  Col,
  Select,
  Input,
  Radio,
  AutoComplete,
  InputNumber,
} from "antd";
import { LeftOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import API from "../../../shared/api/api";
import {
  rule_float_number,
  rule_number,
  rule_require,
} from "../../../shared/validateInput";

const { Title } = Typography;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Option } = Select;
function CreateBasicRoute() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [receiptAreaList, setReceiptAreaList] = useState<any>([]);
  const [deliveryAreaList, setDeliveryAreaList] = useState<any>([]);
  useEffect(() => {
    getListCity();
  }, []);
  const onCreate = async () => {
    setLoadingBtn(true);
    const response = await API.post(
      "admin/freight",
      form.getFieldsValue()
    ).then((res) => res.data);
    if (response.success) history.push("/setting/price");
    setLoadingBtn(false);
  };
  const getListCity = () => {
    axios
      .get("https://vapi.vnappmob.com/api/province")
      .then((res: any) => {
        if (res.status === 200) {
          setCityList(res.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getListArea = (province: string, type: "RECEIPT" | "DELIVERY") => {
    const param = { params: { province: province } };
    API.get(`admin/freight/area`, param)
      .then((res) => {
        if (res.data.success) {
          let listData = res.data.data;
          let customData = [];
          for (let i = 0; i < listData.length; i++) {
            customData.push({ value: listData[i].area });
          }
          if (type == "RECEIPT") {
            setReceiptAreaList(customData);
          }
          if (type == "DELIVERY") {
            setDeliveryAreaList(customData);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Row style={{ width: "100%" }}>
      <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
        <span
          onClick={() => {
            history.goBack();
          }}
        >
          <LeftOutlined />
          Quay lại
        </span>
      </Title>
      <Form
        form={form}
        {...layout}
        style={{ width: "100%" }}
        onFinish={onCreate}
        onFinishFailed={() => {
          setLoadingBtn(false);
        }}
      >
        <Row gutter={[32, 0]} style={{ width: "100%" }}>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Mã điểm nhận hàng"
              name={["receipt", "code"]}
              rules={[rule_require]}
            >
              <Input placeholder="Nhập mã tuyến" />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={16}></Col> */}
          <Col xs={24} sm={8}>
            <Form.Item
              label="Điểm nhận hàng"
              name={["receipt", "province"]}
              rules={[rule_require]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Chọn điểm nhận hàng"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(value: string) => {
                  getListArea(value, "RECEIPT");
                  form.setFieldsValue({ receipt: { area: "" } });
                }}
              >
                {cityList.map((city: any) => (
                  <Option key={city.province_id} value={city.province_name}>
                    {city.province_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Khu vực đến"
              name={["receipt", "area"]}
              rules={[rule_require]}
            >
              <AutoComplete
                options={receiptAreaList}
                placeholder="Nhập khu vực nhận hàng"
                filterOption={(inputValue: any, option: any) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Mã điểm trả hàng"
              name={["delivery", "code"]}
              rules={[rule_require]}
            >
              <Input placeholder="Nhập mã tuyến" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Điểm trả hàng"
              name={["delivery", "province"]}
              rules={[rule_require]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Chọn điểm trả hàng"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(value: string) => {
                  getListArea(value, "DELIVERY");
                  form.setFieldsValue({ delivery: { area: "" } });
                }}
              >
                {cityList.map((city: any) => (
                  <Option key={city.province_id} value={city.province_name}>
                    {city.province_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Khu vực đi"
              name={["delivery", "area"]}
              rules={[rule_require]}
            >
              <AutoComplete
                options={deliveryAreaList}
                placeholder="Nhập khu vực nhận hàng"
                filterOption={(inputValue: any, option: any) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item label="Km có hàng(km)" name="haveGoodsDistance">
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item label="Km không hàng(km)" name="noGoodsDistance">
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Tiền đi đường"
              name="salary"
              rules={[rule_float_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}></Col> */}
          <Col xs={24} sm={8}>
            <Form.Item label="Địa hình" name="roadType" rules={[rule_require]}>
              <Radio.Group>
                <Radio value={"WATERWAY"}>Đường thủy</Radio>
                <Radio value={"ROAD"}>Đường bộ</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={0} sm={16}></Col>
          <Col xs={24} sm={8}>
            <Title level={5}>Đường bộ</Title>
          </Col>
          <Col xs={0} sm={16}></Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label="Giá cước cont 20"
              name={["price", "cont20"]}
              rules={[rule_number, rule_float_number]}
            >
              <Input placeholder={"Nhập giá cước"} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Giá cước cont 40"
              name={["price", "cont40"]}
              rules={[rule_number, rule_float_number]}
            >
              <Input placeholder={"Nhập giá cước"} />
            </Form.Item>
          </Col>
          <Col sm={8}></Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Giá CY cont 20"
              name={["price", "cy20"]}
              rules={[rule_float_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Giá CY cont 40"
              name={["price", "cy40"]}
              rules={[rule_float_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col sm={8}></Col>

          <Col xs={24} sm={6}>
            <Form.Item
              label="Giá cước xe tải"
              name={["price", "truck"]}
              rules={[rule_number, rule_float_number]}
            >
              <Input placeholder={"Nhập giá cước"} />
            </Form.Item>
          </Col>
        </Row>
        <Col xs={6} offset={18}>
          <Button
            loading={isLoadingBtn}
            htmlType="submit"
            style={{
              width: "100%",
              backgroundColor: "#03A9F4",
              color: "white",
            }}
          >
            Thêm chặng
          </Button>
        </Col>
      </Form>
    </Row>
  );
}

export default CreateBasicRoute;
