import React, { useEffect, useState } from 'react';
import { Space, Button, Table, Typography, Input, Avatar, Col, Select, Row, Tooltip ,Popconfirm} from 'antd'
import { Link } from 'react-router-dom';
import { PlusOutlined, EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import API from '../../../shared/api/api'
import { successNotification } from '../../../shared/notification'
import { REPAIR_TYPE, ROUTE_STATUS_OPTIONS } from '../../../shared/define';
import moment from 'moment';
import { getRepairList, deleteRepair } from '../../../shared/api/Repair';

const { Title } = Typography;
const { Search } = Input
const { Option } = Select;

function RepairList() {
    const [repairList, setRepairList] = useState([]);
    const [isloading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState({})
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0
    })
    useEffect(() => {
        setIsLoading(true);
        loadList();
    }, [filter, pagination.current]);


    const loadList = async () => {
        setIsLoading(true)
        const response: any = await getRepairList({ ...filter, current: pagination.current, pageSite: pagination.pageSize })
        if (response) {
            setRepairList(response.data)
            setPagination({ ...pagination, total: response.total })
            setIsLoading(false)
        }

    }

    const handleClick = async (id: any) => {
        const res = await deleteRepair(id)
        if (res.success) loadList()
    }
    const columns: Array<object> = [
        {
            title: 'STT',
            dataIndex: '_id',
            render: (id: any, record: any, index: any) => index + 1
        },
        {
            title: 'Tên sửa chữa',
            dataIndex: 'name',
        },
        {
            title: 'Loại sửa chữa',
            dataIndex: 'type',
            render: (type: any) => REPAIR_TYPE.find((item: any) => item.value === type)?.label
        },
        {
            title: 'Giá',
            dataIndex: 'amount',
        },
        {
            title: 'Ngày sửa chữa',
            dataIndex: 'date',
            render: (dateOfBirth: any) => moment(dateOfBirth).format('DD/MM/YYYY')
        },
        {
            title: 'Xe',
            dataIndex: ['vehicle', 'licensePlate'],
        },
        {
            title: 'Tác vụ',
            align: "center",
            width: 100,
            render: (item: any) => (
                <Space size="middle">
                    <Tooltip title="Sửa">
                        <Link to={{
                            pathname: `/resource/repair/${item._id}/edit`,
                        }}>
                            <EditOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip>
                    <Popconfirm
                        title="Bạn có muốn xóa?"
                        onConfirm={()=>handleClick(item._id)}
                        okText="Có"
                        cancelText="Hủy"
                    >
                        <DeleteOutlined style={{ fontSize: 16, color: "#FF4E4F", cursor: "pointer" }} />
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    return (
        <>
            <Title level={4}>Quản lý chi phí sửa chữa ({pagination.total})</Title>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Search
                    disabled
                    placeholder="Search"
                    allowClear
                    size="small"
                    style={{ width: 150, margin: '0 10px' }}
                    onSearch={(value) => setFilter({ ...filter, text: value })}
                />
                <Link to={{
                    pathname: '/resource/repair/create',
                }} style={{ color: 'white' }}>
                    <Button size="small" style={{ color: 'white', border: 'none' }} type="primary" icon={<PlusOutlined />}>
                        Tạo mới
                    </Button>
                </Link>
            </Space>
            <br />
            <Table
                columns={columns}
                rowKey={(repair: any) => repair._id}
                pagination={pagination}
                onChange={(value: any) => setPagination({ ...pagination, current: value.current })}
                dataSource={repairList}
                loading={isloading}
            />
        </>
    )
}

export default RepairList