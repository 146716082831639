import React, { useEffect, useState } from 'react';
import API from '../../../shared/api/api';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  DatePicker,
  Divider,
  Select,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { dateFormatYMD } from '../../../shared/dataFormat';
import { rule_require } from '../../../shared/validateInput';
import AddressForm from '../../../shared/Components/AddressForm.tsx/AddressForm';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Option } = Select;
function CustomerCreate() {
  const history = useHistory();
  const [form] = Form.useForm();

  const [forwarders, setFowarders] = useState([]);
  const [isLoadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    fetchForwarder();
  }, []);

  const fetchForwarder = async () => {
    const response: any = await API.get('admin/forwarder/all').then(
      (res) => res.data
    );
    if (response.success) {
      setFowarders(response.data);
    }
  };

  const onCreate = async (values: any) => {
    setLoadingBtn(true);

    const response = await API.post('admin/customer', values).then(
      (res) => res.data
    );

    if (response.success) history.push('/partner/customer');

    setLoadingBtn(false);
  };

  return (
    <Row style={{ width: '100%' }}>
      <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}>
        <span
          onClick={() => {
            history.goBack();
          }}
        >
          <LeftOutlined />
          Quay lại
        </span>
      </Title>
      <Form
        form={form}
        {...layout}
        style={{ width: '100%' }}
        onFinish={onCreate}
        onFinishFailed={() => {
          setLoadingBtn(false);
        }}
      >
        <Row gutter={[32, 0]} style={{ width: '100%' }}>
          <Col span={8}>
            <Form.Item label="Tên" name="name" rules={[rule_require]}>
              <Input placeholder="Tên" />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="Tên đầy đủ"
              name="fullName"
              rules={[rule_require]}
            >
              <Input placeholder="Tên đầy đủ" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Mã số thuế"
              name={['businessLicense', 'text']}
              rules={[rule_require]}
            >
              <Input placeholder="Mã số thuế" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Số điện thoại"
              name="phoneNumber"
              rules={[rule_require]}
            >
              <Input placeholder="Số điện thoại" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Forwarder"
              name={['forwarder', '_id']}
              rules={[rule_require]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Chọn forwarder"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {forwarders.map((vendor: any) => (
                  <Option key={vendor._id} value={vendor._id}>
                    {vendor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <AddressForm />
          <Col span={8}>
            <Form.Item label="Mã khách hàng" name="code" rules={[rule_require]}>
              <Input placeholder="Mã khách hàng" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Nhân viên CSKH"
              name="accountManager"
              rules={[rule_require]}
            >
              <Input placeholder="Nhân viên CSKH" />
            </Form.Item>
          </Col>
        </Row>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
          <Button
            loading={isLoadingBtn}
            htmlType="submit"
            style={{
              width: '100%',
              backgroundColor: '#03A9F4',
              color: 'white',
            }}
          >
            Thêm khách hàng
          </Button>
        </Col>
      </Form>
    </Row>
  );
}

export default CustomerCreate;
