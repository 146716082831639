import React, { useState, useEffect } from 'react';
import { Row, Typography, Form, Button, Col, Input, Card, Spin } from 'antd';
import { LeftOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import API from '../../../shared/api/api';
import { rule_float_number, rule_number } from '../../../shared/validateInput';

const { Title } = Typography;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
function CreateAttributeRoute() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [isLoadingBtn, setLoadingBtn] = useState(false);
    const [spinning, setSpinning] = useState<boolean>(true);
    useEffect(() => {
        loadData();
    }, [])
    const loadData = () => {
        setSpinning(true)
        API.get('admin/freight/setting')
            .then(
                res => {
                    if (res.data.success) {
                        let listData = res.data.data;
                        form.setFieldsValue(listData)
                    }
                }
            )
            .catch(error => {
                console.log(error);
            })
            .finally(() => { setSpinning(false) })
    }
    const onUpdating = async () => {
        setLoadingBtn(true)
        const response = await API.put('admin/freight/setting', form.getFieldsValue())
            .then(res => res.data)
        if (response.success) history.push('/setting/price')
        setLoadingBtn(false)
    }
    return (
        <Row style={{ width: '100%' }}>
            <Spin spinning={spinning}>
                <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}><span onClick={() => { history.goBack() }}><LeftOutlined />Quay lại</span></Title>
                <Form form={form} {...layout} style={{ width: '100%' }} onFinish={onUpdating} onFinishFailed={() => { setLoadingBtn(false) }}>
                    <StyledContainer>
                        <Row gutter={[32, 0]}>
                            <Col xs={24} sm={8}><Title level={5}>BẢO HIỂM</Title></Col>
                            <Col xs={24} sm={24}>
                                <Card>
                                    <Row gutter={[32, 0]}>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Giá trị hàng hóa lớn hơn"
                                                name={['insurance', 'over']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input disabled placeholder='Nhập giá tiền' suffix='đ' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Tỉ lệ"
                                                name={['insurance', 'rate']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập tỉ lệ' suffix='%' addonAfter={<InfoCircleTwoTone style={{ fontSize: '1.25rem' }} />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={8}><Title level={5}>HÀNG NỘI/NGOẠI</Title></Col>
                            <Col xs={24}>
                                <Card>
                                    <Row gutter={[32, 0]}>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Giá trị hàng nội"
                                                name={['origin', 'local']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập tỉ lệ' suffix='%' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Giá trị hàng ngoại"
                                                name={['origin', 'foreign']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập tỉ lệ' suffix='%' addonAfter={<InfoCircleTwoTone style={{ fontSize: '1.25rem' }} />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={8}><Title level={5}>KHỐI LƯỢNG HÀNG</Title></Col>
                            <Col xs={24}>
                                <Card>
                                    <Row gutter={[32, 0]}>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Khối lượng lớn hơn"
                                                name={['weight', 'over']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập khối lượng' suffix='tấn' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Giá"
                                                name={['weight', 'price']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập giá tiền ' suffix='đ' addonAfter={<InfoCircleTwoTone style={{ fontSize: '1.25rem' }} />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={8}><Title level={5}>KÍCH THƯỚC CONTAINER</Title></Col>
                            <Col xs={24}>
                                <Card>
                                    <Row gutter={[32, 0]}>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Giá 20 feet"
                                                name={['numberOfFeets', 'twenty']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập giá tiền ' suffix='đ' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Giá 40 feet"
                                                name={['numberOfFeets', 'forty']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập giá tiền' suffix='đ' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Giá 45 feets"
                                                name={['numberOfFeets', 'fortyFive']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập giá tiền' suffix='đ' addonAfter={<InfoCircleTwoTone style={{ fontSize: '1.25rem' }} />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={8}><Title level={5}>HÀNG NGUY HIỂM</Title></Col>
                            <Col xs={24}>
                                <Card>
                                    <Row gutter={[32, 0]}>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Nhóm 1-3"
                                                name={['dangerLevel', 'from1to3']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập giá tiền' suffix='đ' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Nhóm 4-6"
                                                name={['dangerLevel', 'from4to6']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập giá tiền' suffix='đ' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Nhóm 7-9"
                                                name={['dangerLevel', 'from7to9']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập giá tiền' suffix='đ' addonAfter={<InfoCircleTwoTone style={{ fontSize: '1.25rem' }} />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={8}><Title level={5}>HÀNG LẠNH</Title></Col>
                            <Col xs={24}>
                                <Card>
                                    <Row gutter={[32, 0]}>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Giá máy phát"
                                                name={['cold', 'generator']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập giá tiền' suffix='đ' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item
                                                label="Giá trên 1km"
                                                name={['cold', 'perKm']}
                                                rules={[rule_float_number]}
                                            >
                                                <Input placeholder='Nhập giá tiền' suffix='đ' addonAfter={<InfoCircleTwoTone style={{ fontSize: '1.25rem' }} />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16} style={{ marginTop: '20px' }}>
                            <Button loading={isLoadingBtn} htmlType='submit' style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }} >Cập nhật</Button>
                        </Col>
                    </StyledContainer>
                </Form>
            </Spin>
        </Row>
    )
}

const StyledContainer = styled.div`
    .ant-card-body {
    padding: 0px 12px;
    }
    h5.ant-typography, .ant-typography h5{
        margin-bottom:2px;
        margin-top:15px;
    }
    .ant-input-group-addon{
        border:none;
        background-color:white;
        padding-left:30px;
    }
`
export default CreateAttributeRoute
