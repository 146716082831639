import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Table,
  Select,
  Typography,
  Space,
  InputNumber,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { rule_number, rule_require } from "../../../shared/validateInput";
import moment from "moment";
import { getAllVehicleWithoutPagination } from "../../../shared/api/Vehicle";
import { REPAIR_TYPE } from "../../../shared/define";
import {
  getAllRouteWithDate,
  getRepairDetail,
  updateRepair,
} from "../../../shared/api/Repair";
import { useHistory, useParams } from "react-router-dom";
import MomentDatePicker from "../../../Components/MomentDatePicker";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
function RepairUpdate() {
  const history = useHistory();
  let { id }: any = useParams();
  const [form] = Form.useForm();
  const [orderList, setOrderList] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [repairType, setRepairType] = useState("");
  const [selectedRoute, setSelectRoute] = useState("");
  const [vehicleId, setVehicleId] = useState("");

  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (vehicleList.length > 0) loadForm();
  }, [vehicleList]);
  const loadData = async () => {
    const response = await getAllVehicleWithoutPagination();
    if (response.success) {
      setVehicleList(response.data);
    }
  };
  const handleChangeDate = async (value: any) => {
    setIsLoading(true);
    if (value) {
      const date = moment(value).format("MM-DD-YYYY");
      const res = await getAllRouteWithDate(vehicleId, date);
      if (res.success) {
        setOrderList(res.data);
      }
    } else {
      onSelectVehicle(vehicleId);
    }
    setIsLoading(() => false);
  };
  const loadForm = async () => {
    const res = await getRepairDetail(id);
    form.setFieldsValue({
      ...res?.data,
      vehicle: { _id: res?.data?.vehicle },
      date: moment(res?.data?.date),
    });
    setRepairType(res?.data?.type);
    onSelectVehicle(res?.data?.vehicle);
    // setVehicleId(res?.data?.vehicle)
    if (res?.data?.type == "SMALL") {
      setSelectRoute(res?.data?.route);
    }
  };
  const onSelectVehicle = async (id: any) => {
    setIsLoading(true);
    const date = moment(form.getFieldValue("date")).format("MM-DD-YYYY");
    const response = await getAllRouteWithDate(id, date);
    if (response.success) {
      setVehicleId(id);
      setOrderList(response.data);
    }
    setIsLoading(() => false);
  };
  const columns: Array<object> = [
    {
      title: "Ngày",
      dataIndex: "receipt",
      render: (receipt: any) => moment(receipt?.[0]?.time).format("DD/MM/YYYY"),
    },
    {
      title: "Mã đơn",
      dataIndex: "code",
    },
    {
      title: "Tên khách hàng",
      dataIndex: ["order", "customer", "name"],
    },
    {
      title: "Số cont",
      dataIndex: "container",
    },
    {
      title: "Điểm bốc hàng",
      dataIndex: "receipt",
      render: (item: any) => item?.[0].text,
    },
    {
      title: "Điểm dỡ hàng",
      dataIndex: "delivery",
      render: (item: any) => item?.[0].text,
    },
  ];
  const onCreate = async () => {
    const res = await updateRepair(id, {
      ...form.getFieldsValue(),
      ...(selectedRoute && { route: { _id: selectedRoute } }),
    });
    if (res.success) history.push("/resource/repair");
  };
  return (
    <Row style={{ width: "100%" }}>
      <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
        <span
          onClick={() => {
            history.goBack();
          }}
        >
          <LeftOutlined />
          Quay lại
        </span>
      </Title>
      <Form
        form={form}
        {...layout}
        style={{ width: "100%" }}
        onFinish={onCreate}
        onFinishFailed={() => {
          setLoadingBtn(false);
        }}
      >
        <Row gutter={[32, 0]} style={{ width: "100%" }}>
          <Col span={8}>
            <Form.Item label="Tên sửa chữa" name="name" rules={[rule_require]}>
              <Input placeholder="Tên" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Giá tiền" name="amount" rules={[rule_require]}>
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Ngày sửa chữa" name="date" rules={[rule_require]}>
              <MomentDatePicker
                format="DD/MM/YYYY"
                onChange={(value) => handleChangeDate(value)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Chọn xe"
              name={["vehicle", "_id"]}
              rules={[rule_require]}
            >
              <Select onSelect={(value: any) => onSelectVehicle(value)}>
                {vehicleList.map((item: any) => (
                  <Option value={item._id} key={item._id}>
                    {item.licensePlate}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Loại sửa chữa" name="type" rules={[rule_require]}>
              <Select onSelect={(value: any) => setRepairType(value)}>
                {REPAIR_TYPE.map((item: any) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {(repairType == "BIG" ||
            repairType == "TIRE" ||
            repairType == "TOOLS") && (
            <Col span={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="amortizationPeriod"
                rules={[rule_number]}
              >
                <Input suffix="Tháng" />
              </Form.Item>
            </Col>
          )}
          {repairType == "SMALL" && (
            <Col span={24}>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 16,
                }}
              >
                <Title level={5}>Thông tin đơn chạy</Title>
              </Space>
              <Table
                rowSelection={{
                  selectedRowKeys: [selectedRoute],
                  type: "radio",
                  onSelect: (record: any) => {
                    setSelectRoute(record._id);
                  },
                }}
                columns={columns}
                rowKey={(order: any) => order._id}
                dataSource={orderList}
                loading={isloading}
              />
            </Col>
          )}
        </Row>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
          <Button
            loading={isLoadingBtn}
            htmlType="submit"
            style={{
              width: "100%",
              backgroundColor: "#03A9F4",
              color: "white",
            }}
          >
            Cập nhật chi phí sửa chữa
          </Button>
        </Col>
      </Form>
    </Row>
  );
}

export default RepairUpdate;
