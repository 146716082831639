import React, { useEffect, useState } from "react";
import API from "../../../shared/api/api";
import { Col, Row, Form, Input, Button, Spin, Select, Typography } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { dateFormatYMD } from "../../../shared/dataFormat";
import { rule_number, rule_require } from "../../../shared/validateInput";
import moment from "moment";
import { SHOCKABSORBER_TYPE, TRAILER_TYPE } from "../../../shared/define";
import MomentDatePicker from "../../../Components/MomentDatePicker";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Option } = Select;
function TrailerUpdate() {
  const [vendorList, setVendorList] = useState([]);
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [spinning, setSpinning] = useState(true);
  let { id }: any = useParams();

  useEffect(() => {
    getListVendor();
    loadData();
  }, []);

  const loadData = () => {
    setSpinning(true);
    API.get(`admin/trailer/${id}`)
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          form.setFieldsValue({
            ...listData,
            registrationExpireDate: moment(listData?.registrationExpireDate),
            buyedDate: moment(listData?.buyedDate),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSpinning(false);
      });
  };

  const getListVendor = () => {
    API.get("admin/truck-vendor/")
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setVendorList(listData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onUpdate = async () => {
    setLoadingBtn(true);
    let customdata = {
      ...form.getFieldsValue(),
      registrationExpireDate: dateFormatYMD(
        form.getFieldsValue().registrationExpireDate
      ),
      buyedDate: dateFormatYMD(form.getFieldsValue().buyedDate),
    };
    const response = await API.put(`admin/trailer/${id}`, customdata).then(
      (res) => res.data
    );
    if (response.success) history.push("/resource/trailer");
    setLoadingBtn(false);
  };
  return (
    <Spin spinning={spinning}>
      <Row style={{ width: "100%" }}>
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>
        <Form
          form={form}
          {...layout}
          style={{ width: "100%" }}
          onFinish={onUpdate}
          onFinishFailed={() => {
            setLoadingBtn(false);
          }}
        >
          <Row gutter={[32, 0]} style={{ width: "100%" }}>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Nhà xe"
                name={["truckVendor", "_id"]}
                rules={[rule_require]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn nhà xe"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {vendorList.map((vendor: any) => (
                    <Option key={vendor._id} value={vendor._id}>
                      {vendor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Biển số"
                name="licensePlate"
                rules={[rule_require]}
              >
                <Input placeholder="Nhập biển số" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Hạn đăng kiểm"
                name="registrationExpireDate"
                rules={[rule_require]}
              >
                <MomentDatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Loại" name="type" rules={[rule_require]}>
                <Select placeholder="Chọn loại Mooc">
                  {TRAILER_TYPE.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Loại giảm sóc"
                name="shockAbsorber"
                rules={[rule_require]}
              >
                <Select placeholder="Chọn loại giảm sóc">
                  {SHOCKABSORBER_TYPE.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời điểm khấu hao"
                name="amortizationPeriod"
                rules={[rule_number]}
              >
                <Input placeholder="Nhập số tháng" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Giá mua" name="price" rules={[rule_number]}>
                <Input placeholder="Nhập giá mua" suffix="VNĐ" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời điểm mua"
                name="buyedDate"
                rules={[rule_require]}
              >
                <MomentDatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
            <Button
              loading={isLoadingBtn}
              htmlType="submit"
              style={{
                width: "100%",
                backgroundColor: "#03A9F4",
                color: "white",
              }}
            >
              Cập nhật
            </Button>
          </Col>
        </Form>
      </Row>
    </Spin>
  );
}

export default TrailerUpdate;
