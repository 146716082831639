import React, { useEffect, useState } from 'react';
import { Space, Button, Table, Typography, Input, Avatar, Col, Select, Row, Tooltip } from 'antd'
import { Link } from 'react-router-dom';
import { PlusOutlined, EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import API from '../../../shared/api/api'
import { successNotification } from '../../../shared/notification'
import { DRIVER_TYPE, ROUTE_STATUS_OPTIONS } from '../../../shared/define';
import moment from 'moment';

const { Title } = Typography;
const { Search } = Input
const { Option } = Select;

function DriverList() {
    const [driverList, setDriverList] = useState([]);
    const [truckVendorList, setTruckVendorList] = useState([]);
    const [isloading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState({})
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0
    })
    useEffect(() => {
        setIsLoading(true);
        loadList();
    }, [filter, pagination.current]);

    useEffect(() => {
        getTruckVendorList()
    }, [])

    const loadList = () => {
        API.get('admin/driver', { params: { ...filter, current: pagination.current, pageSite: pagination.pageSize } })
            .then(res => {
                const { data, success } = res.data
                if (success) {
                    setDriverList(data)
                    setPagination({ ...pagination, total: res.data.totalItem })
                }
            })
            .catch(error => console.log(error))
            .finally(() => { setIsLoading(false) })
    }

    const onDelete = (driver: any) => {
        API.delete(`admin/driver/${driver._id}`)
            .then(
                res => {
                    if (res.data.success === false) {
                        return
                    } else {
                        successNotification('Xóa thành công', '');
                        setIsLoading(true);
                        loadList();
                        return
                    }

                })
    }

    const getTruckVendorList = () => {
        API.get('admin/truck-vendor/all')
            .then(
                res => {
                    if (res.data.success) {
                        setTruckVendorList(res.data.data)
                    }
                }
            )
            .catch(error => {
                console.log(error);
            })
    }

    const columns: Array<object> = [
        {
            title: 'STT',
            dataIndex: '_id',
            render: (id: any, record: any, index: any) => index + 1
        },
        {
            title: 'Tên',
            dataIndex: 'name',
            render: (name: any, record: any) => <><Avatar style={{ marginRight: 10 }} src={record.avatar} />{name}</>,
        },
        {
            title: 'Loại',
            dataIndex: 'type',
            render: (type: any) => DRIVER_TYPE.find((element: any) => (element as any).value === type)?.label
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'owner',
            align: 'left',
            render: (owner: any) => <>{owner?.phoneNumber}</>
        },
        {
            title: 'Chứng minh thư',
            align: "left",
            dataIndex: 'IDCard',
            render: (IDCard: any) => <>{IDCard?.number}</>
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'dateOfBirth',
            render: (dateOfBirth: any) => moment(dateOfBirth).format('DD/MM/YYYY')
        },
        {
            title: 'Công ty',
            align: "left",
            dataIndex: 'truckVendor',
            render: (truckVendor: any) => <>{truckVendor?.name}</>
        },
        {
            title: 'Số bằng',
            align: "left",
            dataIndex: ['license', 'number'],
            // render: (truckVendor: any) => <>{truckVendor?.name}</>
        },
        {
            title: 'Ngày cấp',
            align: "left",
            dataIndex: ['license', 'date'],
            render: (date: any) => moment(date).format('DD/MM/YYYY')
        },
        {
            title: 'Loại xe',
            align: "left",
            dataIndex: 'vehicle',
            render: (vehicle: any) => <>{vehicle ? vehicle.type.name : 'Chưa có'}</>
        },
        {
            title: 'Biển số',
            align: "left",
            dataIndex: 'vehicle',
            render: (vehicle: any) => <>{vehicle ? vehicle.licensePlate : 'Chưa có'}</>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'currentRoute',
            render: (currentRoute: any) => currentRoute ? ROUTE_STATUS_OPTIONS.find((element: any) => (element as any).value === currentRoute.status)?.label : 'Đang rảnh'
        },
        // {
        //     title: 'Tình trạng',
        //     align: "left",
        //     dataIndex: 'verified',
        //     render: (verified: any) => verified ? <>Đã duyệt</> : <>Chưa duyệt</>
        // },
        {
            title: 'Tác vụ',
            align: "center",
            width: 100,
            render: (driver: any) => (
                <Space size="middle">
                    {/* <Tooltip title="Xem">
                        <Link to={{
                            pathname: `/resource/driver/detail/${driver._id}`,
                        }}>
                            <EyeOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip> */}
                    <Tooltip title="Sửa">
                        <Link to={{
                            pathname: `/resource/driver/edit/${driver._id}`,
                        }}>
                            <EditOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip>
                    {/* <Tooltip title="Xóa">
                        <DeleteOutlined style={{ fontSize: 16, color: 'red' }} onClick={() => { onDelete(driver) }} />
                    </Tooltip> */}
                </Space>
            ),
        },

    ];

    return (
        <>
            <Title level={4}>Quản lý tài xế ({pagination.total})</Title>
            <Row justify="space-between" gutter={8}>
                <Col>
                    <Space size={8}>
                        <Select
                            dropdownMatchSelectWidth={false}
                            showSearch
                            size="small"
                            style={{ width: 220 }}
                            placeholder="Nhà xe"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={(value) => setFilter({ ...filter, truckVendor: value })}
                        >
                            <Option value="ALL">Tất cả</Option>
                            {truckVendorList.map((truckVendor: any) =>
                                <Option key={truckVendor._id} value={truckVendor._id}>{truckVendor.shortName}</Option>
                            )}
                        </Select>
                        <Select size="small" placeholder="Trạng thái" style={{ width: 150 }} onChange={(value) => setFilter({ ...filter, verified: value })}>
                            <Option value="ALL">Tất cả</Option>
                            <Option value="TRUE">Đã duyệt</Option>
                            <Option value="FALSE">Chưa duyệt</Option>
                        </Select>
                        <br /><br /><br />
                    </Space>
                </Col>
                <Space>
                    <Search
                        disabled
                        placeholder="Search"
                        allowClear
                        size="small"
                        style={{ width: 150, margin: '0 10px' }}
                        onSearch={(value) => setFilter({ ...filter, text: value })}
                    />
                    <Link to={{
                        pathname: '/resource/driver/create',
                    }} style={{ color: 'white' }}>
                        <Button size="small" style={{ color: 'white', border: 'none' }} type="primary" icon={<PlusOutlined />}>
                            Tạo mới
                        </Button>
                    </Link>
                </Space>
            </Row>
            <br />
            <Table
                columns={columns}
                rowKey={(driver: any) => driver._id}
                pagination={pagination}
                onChange={(value: any) => setPagination({ ...pagination, current: value.current })}
                dataSource={driverList}
                loading={isloading}
            />
        </>
    )
}

export default DriverList
