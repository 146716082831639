import React, { useState, useEffect } from "react";
import {
  Row,
  Typography,
  Form,
  Button,
  Col,
  Select,
  Input,
  Radio,
  Spin,
  InputNumber,
} from "antd";
import { LeftOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import API from "../../../shared/api/api";
import { useParams } from "react-router-dom";
import {
  rule_float_number,
  rule_number,
  rule_require,
} from "../../../shared/validateInput";

const { Title } = Typography;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Option } = Select;
function UpdateBasicRoute() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [spinning, setSpinning] = useState<boolean>(true);
  let { id }: any = useParams();
  useEffect(() => {
    getListCity();
    loadData();
  }, []);
  const loadData = () => {
    setSpinning(true);
    API.get(`admin/freight/${id}`)
      .then((res) => {
        if (res.data.success) {
          let listData = res.data.data;
          form.setFieldsValue(listData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSpinning(false);
      });
  };
  const getListCity = () => {
    axios
      .get("https://vapi.vnappmob.com/api/province")
      .then((res: any) => {
        if (res.status === 200) {
          setCityList(res.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onUpdate = async () => {
    setLoadingBtn(true);
    console.log(form.getFieldValue(["price", "container"]));
    if (
      form.getFieldValue(["price", "container"]) ||
      form.getFieldValue(["price", "truck"])
    ) {
      const customdata = { ...form.getFieldsValue() };
      if (form.getFieldValue(["price", "container"]) === null)
        customdata.price.container = 0;
      if (form.getFieldValue(["price", "truck"]) === null)
        customdata.price.truck = 0;

      const response = await API.put(`admin/freight/${id}`, customdata).then(
        (res) => res.data
      );
      if (response.success) history.push("/setting/price");
    }
    setLoadingBtn(false);
  };
  return (
    <Row style={{ width: "100%" }}>
      <Spin spinning={spinning}>
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>
        <Form
          form={form}
          {...layout}
          style={{ width: "100%" }}
          onFinish={onUpdate}
          onFinishFailed={() => {
            setLoadingBtn(false);
          }}
        >
          <Row gutter={[32, 0]} style={{ width: "100%" }}>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Mã điểm nhận hàng"
                name={["receipt", "code"]}
                rules={[rule_require]}
              >
                <Input disabled placeholder="Nhập mã tuyến" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Điểm nhận hàng"
                name={["receipt", "province"]}
                rules={[rule_require]}
              >
                <Select
                  disabled
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn vùng đến"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {cityList.map((city: any) => (
                    <Option key={city.province_id} value={city.province_name}>
                      {city.province_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Khu vực đến"
                name={["receipt", "area"]}
                rules={[rule_require]}
              >
                <Input
                  disabled
                  placeholder="Nhập vùng đến"
                  suffix={<EnvironmentOutlined />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Mã điểm trả hàng"
                name={["delivery", "code"]}
                rules={[rule_require]}
              >
                <Input disabled placeholder="Nhập mã tuyến" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Điểm trả hàng"
                name={["delivery", "province"]}
                rules={[rule_require]}
              >
                <Select
                  disabled
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn vùng đi"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {cityList.map((city: any) => (
                    <Option key={city.province_id} value={city.province_name}>
                      {city.province_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Khu vực đi"
                name={["delivery", "area"]}
                rules={[rule_require]}
              >
                <Input
                  disabled
                  placeholder="Nhập khu vực đi"
                  suffix={<EnvironmentOutlined />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="Km có hàng(km)" name="haveGoodsDistance">
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="Km không hàng(km)" name="noGoodsDistance">
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Tiền đi đường"
                name="salary"
                rules={[rule_float_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={8}></Col> */}
            <Col xs={24} sm={8}>
              <Form.Item
                label="Địa hình"
                name="roadType"
                rules={[rule_require]}
              >
                <Radio.Group>
                  <Radio value={"WATERWAY"}>Đường thủy</Radio>
                  <Radio value={"ROAD"}>Đường bộ</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={0} sm={16}></Col>
            <Col xs={24} sm={8}>
              <Title level={5}>Đường bộ</Title>
            </Col>
            <Col xs={0} sm={16}></Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Giá cước cont 20"
                name={["price", "cont20"]}
                rules={[rule_number, rule_float_number]}
              >
                <Input placeholder={"Nhập giá cước"} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Giá cước cont 40"
                name={["price", "cont40"]}
                rules={[rule_number, rule_float_number]}
              >
                <Input placeholder={"Nhập giá cước"} />
              </Form.Item>
            </Col>
            <Col sm={8}></Col>

            <Col xs={24} sm={8}>
              <Form.Item
                label="Giá CY cont 20"
                name={["price", "cy20"]}
                rules={[rule_float_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Giá CY cont 40"
                name={["price", "cy40"]}
                rules={[rule_float_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={8}></Col>

            <Col xs={24} sm={6}>
              <Form.Item
                label="Giá cước xe tải"
                name={["price", "truck"]}
                rules={[rule_number, rule_float_number]}
              >
                <Input placeholder={"Nhập giá cước"} />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
            <Button
              loading={isLoadingBtn}
              htmlType="submit"
              style={{
                width: "100%",
                backgroundColor: "#03A9F4",
                color: "white",
              }}
            >
              Cập nhật
            </Button>
          </Col>
        </Form>
      </Spin>
    </Row>
  );
}

export default UpdateBasicRoute;
