import { ProColumns } from "@ant-design/pro-components";
import { EditableProTable } from "@ant-design/pro-components";
import {
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import API from "../../../../shared/api/api";
import { getAllForwarder } from "../../../../shared/api/Forwarder";
import {
  createPendingOrder,
  duplicatePendingOrder,
  getAllPendingOrder,
  verifyPendingOrder,
} from "../../../../shared/api/Order/PendingOrder";
import { getAllShip } from "../../../../shared/api/Ship";
import { SPECIFICATION_TYPE } from "../../../../shared/define";
import { TagList } from "./TagsList";
import {
  CopyOutlined,
  CheckOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { CombinationList } from "./CombinationsList";

const { Text } = Typography;

type DataSourceType = {
  id: React.Key;
  key?: string;
  date?: string;
  receipt?: any[];
  delivery?: any[];
  goods?: { value?: string; name?: string; origin?: string; volume?: number };
  combinedRate?: number;
  combinedType?: string;
  deliveryForm?: string;
  vehicle?: any;
  numberOfFeets?: string;
  customer?: string;
  specification?: string;
  container?: string;
  freight?: any;
};

let defaultFreight = {
  _id: '1',
  price: {
    cont20: 0,
    cont40: 0,
    cont20cy: 0,
    con40cy: 0
  }
}

export const EditableTable = (props: any) => {
  const { orderList, loadList } = props;
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<readonly any[]>(orderList);
  const [forwarderList, setForwarderList] = useState<any>([]);
  const [shipList, setShipList] = useState<any>([]);
  const [isPopup, setPopup] = useState(false);
  const [inputDetailPopup, setInputDetailPopup] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [packageWeight, setPackageWeight] = useState(0);
  const [price, setPrice] = useState(0);
  const [copyNum, setCopyNum] = useState(0);
  const [copyId, setCopyId] = useState("");
  const [receiptId, setReceiptId] = useState("");
  const [deliveryId, setDeliveryId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [selectedFreight, setSelectedFreight] = useState(defaultFreight);
  const [selectedCont, setSelectedCont] = useState("20");
  const [combinations, setCombinations] = useState<any[]>([]);

  useEffect(() => {
    setDataSource(orderList);
  }, [orderList]);

  const handleOk = async () => {
    const response: any = await duplicatePendingOrder({
      copyId: copyId,
      copyNum: copyNum,
    });
    if (response.success) {
      refreshList();
      handleCancel();
    }
  };

  const handleCancel = () => {
    setPopup(false);
    setCopyId("");
    setCopyNum(0);
  };

  const refreshList = async () => {
    const result = await getAllPendingOrder({
      current: 1,
    });
    if (result && result.data) {
      const list = result?.data?.map((item: any) => {
        return { ...item, id: item._id };
      });
      setDataSource(list);
    }
  };

  const formRef = useRef<any>();
  const fetchForwarderList = async () => {
    const response: any = await getAllForwarder();
    setForwarderList(response.data);
  };

  const fetchShipList = async (param: any) => {
    const response: any = await getAllShip(param);
    setShipList(response.data);
  };

  const [customers, setCustomers] = useState<any>({});
  const [listPlaces, setListPlaces] = useState<any[]>([]);

  useEffect(() => {
    fetchCustomer();
    fetchForwarderList();
    fetchShipList({});
    // loadList();
  }, []);

  const fetchCustomer = async () => {
    const response: any = await API.get("admin/customer/all").then(
      (res) => res.data
    );

    if (response.success) {
      if (response.data) {
        response?.data.map((item: any) => {
          customers[item?._id] = { text: item?.name, status: item._id };
        });
        setCustomers(customers);
      }
    }
  };

  const fetchFreightCombination = async () => {
    const searchParams = {receipt: receiptId, delivery: deliveryId, customer: customerId};

    const response: any = await API.get("admin/freight/combination", {
      params: searchParams,
    });
    const { data } = response;

    if (data.success) {
      setCombinations(data?.data);
    }
  };

  useEffect(() => {
    if (receiptId && deliveryId) {
      fetchFreightCombination();
    }
  }, [receiptId, deliveryId, customerId]);

  const options: any[] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  const onAccept = async (id: any) => {
    const response: any = await verifyPendingOrder(id);
    if (response.success) {
      refreshList();
    }
  };

  const onCreatePendingOrder = async (data) => {
    let payload: any = data ?? {};
    payload = {
      ...data,
      receipt: [
        {
          ...data?.receipt?.[0],
          time: data?.date,
          forbiddenRoad: "NOT_FORBIDDEN",
        },
      ],
      delivery: [
        {
          ...data?.delivery?.[0],
          forbiddenRoad: "NOT_FORBIDDEN",
          time: data?.date,
        },
      ],
      forwarder: { _id: forwarderList?.[0]?._id },
      shippingLines: { _id: shipList?.[0]?._id },
      goods: {
        value: "<5",
        origin: "LOCAL",
        name: data?.goods?.name,
        volume: packageWeight || 28,
        preservation: "NORMAL",
      },
      customer: {
        _id: data?.customer,
      },
      price: price,
      combinedRate: 1,
      combinedType: "COMBINE",
      deliveryForm: "FULL_SEAL",
      vehicle: {
        name: "CONTAINER",
        number: 1,
        numberOfFeets: Number(data?.numberOfFeets),
        container: data?.container,
      },
      specification:
        SPECIFICATION_TYPE.find(
          (item) => item.value === data?.specification
        )?.value ?? "IMPORT",
    };

    const response = await createPendingOrder(payload);
    if (response && response.success) {
      refreshList();
    }
  };

  const columns: ProColumns<DataSourceType>[] = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (id: any, record: any, index: any) => (
        <Tooltip title={record.code}>{index + 1}</Tooltip>
      ),
      editable: () => {
        return false;
      },
      width: 50,
    },
    {
      title: "Ngày",
      key: "date",
      dataIndex: "date",
      valueType: "dateTime",
      fieldProps: {
        placeholder: "Chọn ngày",
        format: "YYYY-MM-DD HH:mm",
        showTime: true,
        required: true,
      },
      width: 230,

      // render: (_, row) => moment(row?.receipt?.[0]?.time).format("DD-MM-YYYY"),
      render: (_, row) => moment(row?.date).format("DD-MM-YYYY"),
      formItemProps: (form) => {
        return {
          rules: [{ required: true, message: "Vui lòng chọn ngày" }],
        };
      },
    },
    {
      title: "Khách hàng",
      key: "customer",
      dataIndex: ["customer", "name"],
      valueType: "select",
      valueEnum: customers,
      width: 170,
      // renderFormItem: () => <SelectCustomer errorMessage={errorCustomer} />,
      fieldProps: { placeholder: "Chọn khách hàng", required: true },
      // renderFormItem: () => (
      //     <TagList setId={setCustomerId} placeholder={"Chọn khách hàng"} />
      // ),
      formItemProps: (form) => {
        return {
          rules: [{ required: true, message: "Vui lòng chọn khách hàng" }],
        };
      },
    },
    {
      title: "Hàng hóa",
      width: 200,

      dataIndex: ["goods", "name"],
      fieldProps: { placeholder: "Tên hàng hóa", required: true },
      // renderFormItem: () => <InputItem />,
      formItemProps: (form) => {
        return {
          rules: [{ required: true, message: "Vui lòng nhập tên hàng hoá" }],
        };
      },
    },
    {
      title: "N/X/B",
      dataIndex: "specification",
      key: "specification",
      valueType: "select",
      width: 120,

      valueEnum: {
        IMPORT: { text: "Nhập", status: "N" },
        EXPORT: {
          text: "Xuất",
          status: "X",
        },
        ROAD: {
          text: "Bộ",
          status: "B",
        },
      },
      fieldProps: { placeholder: "N/X/B", required: true },
      formItemProps: (form) => {
        return {
          rules: [{ required: true, message: "Vui lòng chọn N/X/B" }],
        };
      },
    },
    {
      title: "Số cont",
      width: 240,
      key: "container",
      dataIndex: ["vehicle", "container"],
      fieldProps: { placeholder: "Số cont", required: true },
    },
    {
      title: "Loại cont",
      key: "numberOfFeets",
      valueType: "select",
      width: 120,

      valueEnum: {
        20: { text: "20", status: "20" },
        40: {
          text: "40",
          status: "40",
        },
      },
      dataIndex: ["vehicle", "numberOfFeets"],
      fieldProps: { placeholder: "Loại cont" },
      formItemProps: (form) => {
        return {
          rules: [{ required: true, message: "Vui lòng chọn loại cont" }],
        };
      },
    },
    {
      title: "Số SEAL",
      dataIndex: ["seal"],
      key: "seal",
      width: 200,

      fieldProps: { placeholder: "Số SEAL" },
    },
    {
      title: "Điểm nhận",
      key: "receipt",

      dataIndex: ["receipt"],
      valueType: "select",

      width: 300,
      renderFormItem: () => (
        <TagList setId={setReceiptId} placeholder={"Chọn điểm nhận hàng"} />
      ),
      render: (_, row) => row?.receipt?.[0]?.text,
      formItemProps: (form) => {
        return {
          rules: [{ required: true, message: "Vui lòng chọn điểm nhận" }],
        };
      },
    },
    {
      title: "Điểm trả",
      dataIndex: ["delivery"],
      key: "delivery",

      valueType: "select",
      renderFormItem: () => (
        <TagList setId={setDeliveryId} placeholder={"Chọn điểm trả hàng"} />
      ),
      width: 300,
      render: (_, row) => row?.delivery?.[0]?.text,
      formItemProps: (form) => {
        return {
          rules: [{ required: true, message: "Vui lòng chọn điểm trả" }],
        };
      },
    },
    {
      title: "Tuyến",
      key: "freight",
      dataIndex: ["freight"],
      render: (_, row) =>
        row?.freight
          ? `[${row?.freight?.code}] ${row?.freight?.description}`
          : "",

      valueType: "select",
      // valueEnum: combination,
      width: 330,
      renderFormItem: () => <CombinationList showCustomer={customerId !== ""} listOptions={combinations} onChange={(freight) => {
        setSelectedFreight(combinations.find(freightItem => freightItem._id === freight))
      }}/>,
      fieldProps: { placeholder: "Chọn tuyến", required: true },
      formItemProps: (form) => {
        return {
          rules: [{ required: true, message: "Vui lòng chọn tuyến" }],
        };
      },
    },
    {
      title: "Giá cước",
      // key: selectedFreight?._id,
      // fieldProps: { placeholder: "Giá cước" },
      dataIndex: ["price"],
      render: (id: any, record: any, index: any) =>
        record._id ?
          <Tooltip
            style={{alignItems: 'center'}}
            title={record?.freight?.code ?? ""}>{record.price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}đ
          </Tooltip>
          :
          <Tooltip
            style={{alignItems: 'center'}}
            title={record?.freight?.code ?? ""}>{selectedFreight?.price[`cont${selectedCont}`]?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? 0}đ
          </Tooltip>
      ,
      editable: () => {
        return false;
      },
      width: 150,
    },
    {
      title: "Ghi chú",
      dataIndex: ["note"],
      width: 100,
      fieldProps: { placeholder: "Ghi chú" },
    },
    {
      title: "Tác vụ",
      width: 200,
      valueType: "option",
      render: (text, record, _) => [
        <Space size="middle">
          <Tooltip title="Nhân bản">
            <CopyOutlined
              style={{ fontSize: 16 }}
              onClick={() => {
                setPopup(true);
                setCopyId(`${record.id}`);
              }}
            />
          </Tooltip>
          <Tooltip title="Duyệt">
            <CheckOutlined
              style={{ fontSize: 16, color: "green" }}
              onClick={() => {
                onAccept(record.id);
              }}
            />
          </Tooltip>
          <Tooltip title="Sửa">
            <Link
              to={{
                pathname: `/order/pending/edit/${record.key}`,
              }}
            >
              <EditOutlined style={{ fontSize: 16 }} />
            </Link>
          </Tooltip>
          <Popconfirm
            placement="topLeft"
            title={"Bạn có chắc xoá?"}
            onConfirm={async () => {
              const response = await API.delete(
                `admin/pending-order/${record.id}`
              ).then((res) => res.data);
              if (response.success) {
                refreshList();
              }
            }}
            okText="Có"
            cancelText="Không"
          >
            <Tooltip title="Xóa">
              <DeleteOutlined style={{ fontSize: 16, color: "red" }} />
            </Tooltip>
          </Popconfirm>
        </Space>,
      ],
    },
  ];

  return (
    <>
      <EditableProTable<DataSourceType>
        rowKey="id"
        maxLength={50}
        scroll={{
          x: 960,
        }}
        recordCreatorProps={{
          position: "bottom",
          // newRecordType: "cache",
          record: () => ({ id: (Math.random() * 1000000).toFixed(0) }),
          creatorButtonText: "Tạo mới",
        }}
        onValuesChange={(data, record) => {
          console.log('recordChange:', record)
          setSelectedCont(record.numberOfFeets)
          setCustomerId(record.customer)
        }}
        loading={false}
        columns={columns}
        value={dataSource}
        editableFormRef={formRef}
        editable={{
          type: "multiple",
          saveText: "Lưu",
          deleteText: "Xóa",
          deletePopconfirmMessage: "Bạn có chắc muốn xóa?",
          cancelText: "Hủy",
          editableKeys,
          onSave: async (key, data) => {
            if (data?.specification === 'ROAD') {
              setDetailData(data);
              setInputDetailPopup(true);
              return;
            }
            await onCreatePendingOrder(data)
          },
          onChange: setEditableRowKeys,
        }}
      />
      <Modal
        destroyOnClose={true}
        closable={false}
        title="Nhân bản đơn"
        visible={isPopup}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Text>Số lượng muốn nhân bản</Text>
        <Input
          onChange={(e: any) => {
            setCopyNum(e.target.value);
          }}
          type="number"
        />
        <Text>
          Lưu ý: Đơn hàng khi đã được nhân bản sẽ được gán các mã đơn khác nhau
        </Text>
      </Modal>
      <Modal
        destroyOnClose={true}
        closable={false}
        title="Nhập đơn cho xe đường dài"
        visible={inputDetailPopup}
        onOk={() => {
          onCreatePendingOrder(detailData);
          setInputDetailPopup(false);
        }}
        onCancel={() => {
          setInputDetailPopup(false);
        }}
      >
        <Text>Khối lượng vận chuyển</Text>
        <Input
          onChange={(e: any) => {
            setPackageWeight(e.target.value);
          }}
          type="number"
          value={'28'}
        />
        <Text>Giá cước vận chuyển</Text>
        <Input
          onChange={(e: any) => {
            setPrice(e.target.value);
          }}
          type="number"
        />
      </Modal>
    </>
  );
};
