import React, { useState, useEffect } from 'react'
import { Col, Row, Tooltip, Typography, Select, Space, Input, Button, Modal, Form, Collapse, InputNumber, Upload } from 'antd';
import { FileExcelOutlined,DeleteOutlined , PlusOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { rule_number } from '../../../shared/validateInput';
import { importExcel } from '../../../shared/api/Vehicle/index';


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Panel } = Collapse;
function InterestModal(props: any) {
    const { form, setVisible, visible } = props
    const [year, setYear] = useState<any>([])
    useEffect(() => {
        const yearList: any = []
        if (year?.length == 0) {
            form.getFieldsValue().interest?.map((item: any) => { yearList.push(item?.year) })
            setYear(yearList)
        }
    }, [visible])
    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const onChangeInput = (value: any, key: any) => {
        const data = [...year]
        data[key] = value
        setYear(data)
    }
    const uploadExcel = async (options: any) => {
        const { onSuccess, onError, file } = options;
        const yearList: any = []
        const formData = new FormData();
        formData.append(
            'file',
            file
        );
        const response: any = await importExcel(formData)
        response.map((item: any) => { yearList.push(item.year) })
        setYear(yearList)
        form.setFieldsValue({ interest: response })
    };
    
    return (
        <Modal title={<Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}><div>'Lãi vay theo năm'</div>
            <Space>
                <Upload showUploadList={false}
                    customRequest={uploadExcel}
                >
                    <Button size="small" style={{ backgroundColor: '#009688', borderColor: '#009688' }} type="primary" icon={<FileExcelOutlined />}>
                        Tải lên
                    </Button>
                </Upload>
                <Tooltip title="Tải file mẫu"><a href="/files/Lai-vay.xlsx" download><Button type="primary" size="small" icon={<VerticalAlignBottomOutlined />}></Button></a></Tooltip>
                
            </Space>

        </Space>} closable={false} visible={visible} onOk={handleOk} onCancel={handleCancel}>
            <Form form={form} {...layout} style={{ width: '100%', paddingTop: 15 }} >
                <Form.List name="interest" >
                    {(fields, { add, remove }) => (
                        <>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Thêm năm
                            </Button>
                            <Collapse bordered={false} expandIconPosition='right' >
                                {fields.map((field: any) => (
                                    // <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">

                                    //     {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                                    // </Space>
                                    <Panel style={{ backgroundColor: 'white' }} header={<div style={{ fontWeight: 'bold',display:'inline-block' }}>{year[field.key] || '...'}</div>} key={field.key} extra={<DeleteOutlined style={{cursor:'pointer',color:'#FF887D'}} onClick={() => remove(field.name)}/>}>
                                        <Row gutter={[8, 0]}>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...field}
                                                    label={`Năm`}
                                                    name={[field.name, `year`]}
                                                    fieldKey={[field.fieldKey, `year`]}
                                                    rules={[rule_number]}
                                                >
                                                    <Input onChange={(e: any) => onChangeInput(e.target.value, field.key)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={18}></Col>
                                            {
                                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value: any) =>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            {...field}
                                                            label={`Tháng ${value}`}
                                                            name={[field.name, `months`, `${value}`]}
                                                            fieldKey={[field.fieldKey, `month`, `${value}`]}
                                                            rules={[rule_number]}
                                                        >
                                                            <InputNumber
                                                                formatter={value => ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                                                                style={{ width: '100%' }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }

                                        </Row>
                                    </Panel>
                                ))}
                            </Collapse>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    )
}

export default InterestModal
