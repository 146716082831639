import React, { useState, useEffect } from "react";
import { Table, Space, Button, Row, Input, Typography, Tooltip } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { dateFormat } from "../../../shared/dataFormat";
import { getAllBOT } from "../../../shared/api/BOT";
const { Title } = Typography;

function BotList() {
  const [bot, setBot] = useState({
    list: [],
    loading: false,
    filter: {},
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setBot({ ...bot, loading: true });
    const response: any = await getAllBOT();
    setBot({ ...bot, list: response?.data, loading: false });
  };

  const columns: Array<object> = [
    {
      title: "STT",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Tên trạm",
      dataIndex: "name",
    },
    {
      title: "Địa điểm",
      dataIndex: "address",
      render: (value: any) => value?.text + "," + value?.province,
    },
    {
      title: "Vé lượt 20",
      width: 150,
      dataIndex: ["turn", "twenty"],
      render: (value: any) => new Intl.NumberFormat().format(value) + "VNĐ",
    },
    {
      title: "Vé lượt 40",
      width: 150,

      dataIndex: ["turn", "forty"],
      render: (value: any) => new Intl.NumberFormat().format(value) + "VNĐ",
    },
    {
      title: "Vé tháng 20",
      width: 150,

      dataIndex: ["monthly", "twenty"],
      render: (value: any) => new Intl.NumberFormat().format(value) + "VNĐ",
    },
    {
      title: "Vé tháng 40",
      width: 150,

      dataIndex: ["monthly", "forty"],
      render: (value: any) => new Intl.NumberFormat().format(value) + "VNĐ",
    },
    {
      title: "Vé quý 20",
      width: 150,

      dataIndex: ["quarterly", "twenty"],
      render: (value: any) => new Intl.NumberFormat().format(value) + "VNĐ",
    },
    {
      title: "Vé quý 40",
      width: 150,

      dataIndex: ["quarterly", "forty"],
      render: (value: any) => new Intl.NumberFormat().format(value) + "VNĐ",
    },
    {
      title: "Vé năm 20",
      width: 150,

      dataIndex: ["yearly", "twenty"],
      render: (value: any) => new Intl.NumberFormat().format(value) + "VNĐ",
    },
    {
      title: "Vé năm 40",
      width: 150,

      dataIndex: ["yearly", "forty"],
      render: (value: any) => new Intl.NumberFormat().format(value) + "VNĐ",
    },
    {
      title: "Tác vụ",
      align: "center",
      width: 100,
      render: (bot: any) => (
        <Space size="middle">
          <Tooltip title="Sửa">
            <Link
              to={{
                pathname: `/partner/bot/${bot._id}/edit/`,
              }}
            >
              <EditOutlined style={{ fontSize: 16 }} />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Row justify="space-between" gutter={6}>
        <Title level={4}>Quản lý trạm thu phí</Title>
        {/* <Space size={6}>
          <Link
            to={{
              pathname: "/partner/bot/create",
            }}
            style={{ color: "white" }}
          >
            <Button type="primary" size="small" icon={<PlusOutlined />}>
              Tạo mới
            </Button>
          </Link>
        </Space> */}
      </Row>
      <br />

      <Table
        scroll={{ x: "max-content" }}
        rowKey="_id"
        loading={bot.loading}
        columns={columns}
        pagination={
          bot.pagination.total > bot.pagination.pageSize && bot.pagination
        }
        dataSource={bot.list}
        onChange={(value: any) =>
          setBot({
            ...bot,
            pagination: { ...bot.pagination, current: value.current },
          })
        }
      />
    </>
  );
}

export default BotList;
