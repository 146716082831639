import axios from "axios";
import API from "../api";

export const getListProvince = async () => {
  try {
    const response = await API.get(`/admin/region/province`).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getListDistrict = async (id: string) => {
  try {
    const response = await API.get(`/admin/region/district/${id}`).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getListWard = async (id: string) => {
  try {
    const response = await API.get(`/admin/region/ward/${id}`).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
