import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, Image, Typography, Spin, Divider, Anchor, InputNumber } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useParams, useHistory, Link } from 'react-router-dom'
import API from '../../../shared/api/api';
import { rule_number } from '../../../shared/validateInput';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Link: LinkURL } = Anchor;
function DetailVendor() {
    const [form] = Form.useForm();
    const [vendorList, setVendorList] = useState<any>({});
    const [spinning, setSpinning] = useState(true);
    let { id }: any = useParams();
    const history = useHistory();

    useEffect(() => {
        loadData();
        return () => {
            setVendorList([])
        }
    }, [])
    const loadData = () => {
        setSpinning(true);
        API.get(`admin/truck-vendor/${id}`)
            .then(
                res => {
                    if (res.data.success) {
                        setVendorList(res.data.data);
                    }
                }
            )
            .catch(error => {
                console.log(error);
            })
            .finally(() => { setSpinning(false) })
    }
    return (
        <Row style={{ width: '100%' }}>
            <Spin spinning={spinning}>
                <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}><span onClick={() => { history.goBack() }}><LeftOutlined />Quay lại</span></Title>
                <Form form={form} {...layout} style={{ width: '100%' }}>
                    <Row gutter={[32, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Email"
                                name="email"
                            >
                                <Input prefix={vendorList.owner?.email ? `${vendorList.owner.email}` : 'Chưa có'} disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="SĐT"
                                        name="phoneNumber"
                                    >
                                        <Input prefix={vendorList.owner?.phoneNumber ? `${vendorList.owner?.phoneNumber}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Mật khẩu"
                                        name="password"
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Col />
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[32, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Ảnh doanh nghiệp"
                                name="avatar"
                                valuePropName="avatar"
                            >
                                <Image width={150} height={150} src={(vendorList.avatar) ? vendorList.avatar : '/id.png'} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row gutter={[16, 0]}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Tên doanh nghiệp"
                                        name="name"
                                    >
                                        <Input prefix={vendorList ? (vendorList.name ? `${vendorList.name}` : 'Chưa có') : ''} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Tên viết tắt"
                                        name="shortName"
                                    >
                                        <Input  prefix={vendorList ? (vendorList.shortName ? `${vendorList.shortName}` : 'Chưa có') : ''} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Hotline"
                                        name="hotline"
                                    >
                                        <Input prefix={vendorList ? (vendorList.hotline ? `${vendorList.hotline}` : 'Chưa có') : ''} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Số lượng xe tham gia hệ thống"
                                        name="numberOfVehicles"
                                    >
                                        <Input prefix={vendorList ? (vendorList.numberOfVehicles ? `${vendorList.numberOfVehicles}` : 'Chưa có') : ''} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Tên người đại diện"
                                        name="representativeName"
                                    >
                                        <Input prefix={vendorList ? (vendorList.representativeName ? `${vendorList.representativeName}` : 'Chưa có') : ''} disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Divider />
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="CMND/CCCD Người đại diện"
                                        name={['IDCard', 'number']}
                                    >
                                        <Input prefix={vendorList.IDCard?.number ? `${vendorList.IDCard.number}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                                {vendorList.IDCard?.front &&
                                    <Col xs={24} sm={12} >
                                        <Form.Item
                                            name={['IDCard', 'front']}
                                            label='Mặt trước'
                                        >
                                            <Image width={120} height={120} src={(vendorList.IDCard?.front) ? vendorList.IDCard?.front : '/id.png'} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    vendorList.IDCard?.back &&
                                    <Col xs={24} sm={12} >
                                        <Form.Item
                                            name={['IDCard', 'back']}
                                            label='Mặt sau'
                                        >
                                            <Image width={120} height={120} src={(vendorList.IDCard?.back) ? vendorList.IDCard?.back : '/id.png'} />
                                        </Form.Item>
                                    </Col>
                                }

                            </Row>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="Số giấy phép kinh doanh"
                                        name={['businessLicense', 'text']}
                                    >
                                        <Input prefix={vendorList.businessLicense?.text ? `${vendorList.businessLicense.text}` : 'Chưa có'} disabled />
                                    </Form.Item>
                                </Col>
                                {vendorList.businessLicense?.front &&
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item
                                            name={['businessLicense', 'front']}
                                            label='Mặt trước'
                                        >
                                            <Anchor>

                                                <LinkURL href={vendorList.businessLicense?.front ? `${vendorList.businessLicense.front}` : ''} target='_blank' title={vendorList.businessLicense?.front ? `${vendorList.businessLicense.front}` : ''} />
                                            </Anchor>
                                        </Form.Item>
                                    </Col>
                                }
                                {vendorList.businessLicense?.back &&
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item
                                            name={['businessLicense', 'back']}
                                            label='Mặt sau'
                                        >
                                            <Anchor>

                                                <LinkURL href={vendorList.businessLicense?.back ? `${vendorList.businessLicense.back}` : ''} target='_blank' title={vendorList.businessLicense?.back ? `${vendorList.businessLicense.back}` : ''} />
                                            </Anchor>
                                        </Form.Item>
                                    </Col>
                                }

                            </Row>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row>
                                <Col xs={24}>
                                    <Form.Item
                                        label="Thế mạnh"
                                        name="strengths"
                                    >
                                        <Input.TextArea placeholder={vendorList?.strengths} disabled rows={4} />
                                    </Form.Item>
                                </Col>
                                {/*<Col xs={24} >*/}
                                {/*    <Form.Item*/}
                                {/*        label="Chi phí ĐHVT"*/}
                                {/*        name="directOperating"*/}
                                {/*    >*/}
                                {/*        <Input*/}
                                {/*            disabled*/}
                                {/*            placeholder={new Intl.NumberFormat().format(vendorList?.directOperating)}*/}
                                {/*            style={{ width: '100%' }}*/}
                                {/*        />*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                            </Row>
                        </Col>
                        <Divider></Divider>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Quốc gia"
                                name="addressCountry"
                            >
                                <Input prefix={vendorList ? (vendorList.address?.country ? `${vendorList.address.country}` : 'Chưa có') : ''} disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Tỉnh/TP"
                                name="addressProvince"
                            >
                                <Input prefix={vendorList ? (vendorList.address?.province ? `${vendorList.address.province}` : 'Chưa có') : ''} disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Địa chỉ"
                                name="addressText"
                            >
                                <Input prefix={vendorList ? (vendorList.address?.text ? `${vendorList.address.text}` : 'Chưa có') : ''} disabled />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
                        <Link to={{
                            pathname: `/partner/truck-vendor/edit/${id}`,
                        }}>
                            <Button style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }}>
                                Chỉnh sửa
                        </Button>
                        </Link>
                    </Col>
                </Form>
            </Spin>
        </Row>
    )
}

export default DetailVendor
