import PlaceDetail from "./PlaceDetail";

function CreatePlace() {
  return (
    <PlaceDetail
      placeDetail={{ contact: [], state: 1, type: "Factory" }}
      createForm
    />
  );
}

export default CreatePlace;
