import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from "antd";
import { useHistory } from "react-router-dom";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { GOOGLE_MAP_KEY } from "../../shared/key";
import { rule_require } from "../../shared/validateInput";
import { useEffect, useState } from "react";
import { getListDistrict, getListProvince } from "../../shared/api/Province";
import { getAllCustomer } from "../../shared/api/Customer";
import { AutocompleteAddress } from "../../Components/AutocompleteAddress";
import { createPlace, updatePlace } from "../../shared/api/Route";

const { Title } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const containerStyle = {
  width: "100%",
  height: "100vh",
};

function PlaceDetail(props: any) {
  const { placeDetail, createForm } = props;
  const history = useHistory();
  const [form] = Form.useForm();

  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [cityList, setCityList] = useState<any>([]);
  const [selectedProvince, setSelectedProvince] = useState<any>([]);
  const [customerList, setCustomerList] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]);
  const [marker, setMarker] = useState<any>();

  useEffect(() => {
    getListCity();
    fetchCustomerList({});
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      getListDistricts();
    }
  }, [selectedProvince]);

  const fetchCustomerList = async (param: any) => {
    const response: any = await getAllCustomer(param);
    setCustomerList(response.data);
  };

  const getListCity = async () => {
    const response: any = await getListProvince();
    if (response && response.data) {
      setCityList(response.data);
    }
  };

  const getListDistricts = async () => {
    const response: any = await getListDistrict(selectedProvince);
    if (response && response.data) {
      setDistrictList(response.data);
    }
  };

  console.log("cityList", cityList);

  const [center, setCenter] = useState({
    lat: 21.028511,
    lng: 105.804817,
  });

  const onCreate = async () => {
    setLoadingBtn(true);
    const response = await createPlace(form.getFieldsValue());
    if (response.success) history.push("/place/all");
    setLoadingBtn(false);
  };

  const onUpdate = async () => {
    setLoadingBtn(true);
    const response = await updatePlace(
      form.getFieldValue("_id"),
      form.getFieldsValue()
    );
    if (response.success) history.push("/place/all");
    setLoadingBtn(false);
  };

  return (
    <>
      <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY} libraries={["places"]}>
        <Row>
          <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
            <span
              onClick={() => {
                history.goBack();
              }}
            >
              <LeftOutlined />
              Quay lại
            </span>
          </Title>
        </Row>
        <Row>
          <Col span={6}>
            <Form
              form={form}
              {...layout}
              initialValues={placeDetail}
              onFinish={createForm ? onCreate : onUpdate}
              onFinishFailed={() => {
                setLoadingBtn(false);
              }}
              layout={"vertical"}
              style={{ marginTop: 10 }}
            >
              <Col span={24}>
                <Form.Item
                  style={{ width: "100%" }}
                  label="Địa điểm"
                  name="description"
                >
                  <AutocompleteAddress
                    form={form}
                    placeholder={"Nhập địa điểm"}
                    defaultValue={form.getFieldValue("description")}
                    setMarker={setMarker}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Tên hiển thị" name="name">
                  <Input
                    placeholder={"Nhập tên hiển thị"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={22} xs={24} sm={8}>
                <Form.Item label="Địa chỉ" name="address">
                  <Input placeholder={"Nhập địa chỉ"} />
                </Form.Item>
              </Col>
              <Col lg={22} xs={24} sm={8}>
                <Form.Item label="Loại" name="type">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Chọn loại"
                    defaultValue={"Factory"}
                    options={[
                      { value: "Container Port", label: "Container Port" },
                      { value: "Inland Port", label: "Inland Port" },
                      { value: "Bulk Port", label: "Bulk Port" },
                      { value: "Depot", label: "Depot" },
                      { value: "ICD", label: "ICD" },
                      { value: "Port", label: "Port" },
                      { value: "Warehouse", label: "Warehouse" },
                      { value: "Factory", label: "Factory" },
                      { value: "Supermarket", label: "Supermarket" },
                      { value: "Gate", label: "Gate" },
                      { value: "Hub", label: "Hub" },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col lg={22} xs={24} sm={8}>
                <Form.Item label="Latitude" name={["location", "lat"]}>
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg={22} xs={24} sm={8}>
                <Form.Item label="Longitude" name={["location", "lng"]}>
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg={22} xs={24} sm={8}>
                <Form.Item label="Tỉnh/TP" name={["province"]}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Chọn thành phố"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setSelectedProvince(value);
                    }}
                  >
                    {cityList?.map((city: any) => (
                      <Option key={city.name} value={city._id}>
                        {city.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={8}></Col> */}
              <Col lg={22} xs={24} sm={8}>
                <Form.Item label="Quận/huyện" name={["district"]}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Chọn quận huyện"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {districtList?.map((district: any) => (
                      <Option key={district.name} value={district._id}>
                        {district.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={0} sm={16}></Col>
              <Col lg={22} xs={24} sm={8}>
                <Title level={5}>Khách hàng</Title>
                <Col span={24}>
                  <Form.List name="contact">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Row style={{ width: "100%" }}>
                            <Col style={{ width: "30%" }}>
                              <Form.Item
                                name={[field.name, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Tên phát sinh là bắt buộc",
                                  },
                                ]}
                              >
                                <Input
                                  style={{ width: "100%" }}
                                  placeholder="Tên"
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ width: "30%" }}>
                              <Form.Item
                                name={[field.name, "phone"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Tên phát sinh là bắt buộc",
                                  },
                                ]}
                              >
                                <Input placeholder="Số điện thoại" />
                              </Form.Item>
                            </Col>
                            <Col style={{ width: "40%" }}>
                              <Form.Item
                                name={[field.name, "customer"]}
                                rules={[rule_require]}
                              >
                                <Select
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Chọn khách hàng"
                                  optionFilterProp="children"
                                  filterOption={(input, option: any) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {customerList.map((cus: any) => (
                                    <Option key={cus._id} value={cus._id}>
                                      {cus.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                        <Col span={24}>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          ></Button>
                        </Col>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={8}>
                <Button
                  loading={isLoadingBtn}
                  htmlType="submit"
                  style={{
                    width: "100%",
                    backgroundColor: "#03A9F4",
                    color: "white",
                  }}
                >
                  Cập nhật
                </Button>
              </Col>
            </Form>
          </Col>
          <Col span={18}>
            <div style={containerStyle}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={10}
                center={center}
              >
                {marker && (
                  <Marker
                    position={{
                      lat: marker.lat,
                      lng: marker.lng,
                    }}
                    icon="/icon/marker.png"
                  />
                )}
              </GoogleMap>
            </div>
          </Col>
        </Row>
      </LoadScript>
    </>
  );
}

export default PlaceDetail;
