import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  createGasStation,
  getGasStation,
  updateGasStation,
} from "../../shared/api/Oil";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import layout from "antd/es/layout";
import {
  getListDistrict,
  getListProvince,
  getListWard,
} from "../../shared/api/Province";

const { Title } = Typography;

export const GasStation = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState();

  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();

  const [wardList, setWardList] = useState([]);

  const [selectedItem, setSelectedItem] = useState<any>();

  const [listGasStations, setListGasStations] = useState({
    list: [],
    loading: false,
    filter: {},
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();

  useEffect(() => {
    fetchData();
    fetchListProvince();
  }, []);

  useEffect(() => {
    if (selectedCity) {
      fetchListDistrict(selectedCity);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedDistrict) {
      fetchListWard(selectedDistrict);
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedItem && selectedItem.pId) {
      fetchListDistrict(selectedItem.pId);
    }
    if (selectedItem && selectedItem.dId) {
      fetchListWard(selectedItem.dId);
    }
  }, [selectedItem]);

  const fetchData = async () => {
    setListGasStations({ ...listGasStations, loading: true });
    const response: any = await getGasStation({});
    setListGasStations({
      ...listGasStations,
      list: response?.data,
      loading: false,
    });
  };

  const columns: Array<object> = [
    {
      title: "STT",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Mã",
      dataIndex: "code",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
    },
    {
      title: "Tác vụ",
      align: "center",
      width: 100,
      render: (record: any) => (
        <Space size="middle">
          <Tooltip title="Sửa">
            <EditOutlined
              style={{ fontSize: 16 }}
              onClick={() => {
                setOpenModal(true);
                setSelectedItem(record);
                form.setFieldsValue(record);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const fetchListProvince = async () => {
    const response: any = await getListProvince();
    setCityList(response.data);
  };

  const fetchListDistrict = async (pId: string) => {
    const response: any = await getListDistrict(pId);
    setDistrictList(response.data);
  };

  const fetchListWard = async (pId: string) => {
    const response: any = await getListWard(pId);
    setWardList(response.data);
  };

  const handleOk = () => {
    form.submit();
    setOpenModal(false);
  };

  const handleOkUpdate = () => {
    formUpdate.submit();
    setIsModalUpdateVisible(false);
  };

  const handleCancelUpdate = () => {
    formUpdate.setFieldsValue({});
    setIsModalUpdateVisible(false);
  };

  const handleSubmit = async () => {
    if (!selectedItem) {
      // Create
      const response = await createGasStation({ ...form.getFieldsValue() });
      if (response.success) fetchData();
    } else {
      // Update
      const response = await updateGasStation({
        ...form.getFieldsValue(),
      });
      if (response.success) fetchData();
    }
  };

  const onUpdate = async () => {};

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(undefined);
    form.resetFields();
  };

  return (
    <>
      <Modal
        title={selectedItem ? "Cập nhật trạm xăng dầu" : "Tạo trạm xăng dầu"}
        open={openModal}
        onOk={handleOk}
        onCancel={handleCloseModal}
      >
        <Form {...layout} form={form} onFinish={handleSubmit} layout="vertical">
          <Row gutter={[8, 0]}>
            <Form.Item name="_id" label="id" hidden>
              <Input type="text" disabled hidden />
            </Form.Item>
            <Col span={24}>
              <Form.Item name="name" label="Tên">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="code" label="Mã">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="address" label="Địa chỉ">
                <Input type="text" />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item name="pId" label="Tỉnh">
                <Select
                  options={cityList}
                  fieldNames={{ label: "name", value: "_id" }}
                  onSelect={(value) => {
                    setSelectedCity(value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="dId" label="Huyện">
                <Select
                  options={districtList}
                  fieldNames={{ label: "name", value: "_id" }}
                  onSelect={(value) => {
                    setSelectedDistrict(value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="wId" label="Xã">
                <Select
                  options={wardList}
                  fieldNames={{ label: "name", value: "_id" }}
                />
              </Form.Item>
            </Col> */}

            <Col span={12}>
              <Form.Item name={["location", "lng"]} label="Kinh độ">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["location", "lat"]} label="Vĩ độ">
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Row justify="space-between" gutter={6}>
        <Title level={4}>Quản lý trạm xăng dầu</Title>
        <Space size={6}>
          <Button
            onClick={() => {
              setOpenModal(true);
            }}
            type="primary"
            size="small"
            icon={<PlusOutlined />}
          >
            Tạo mới
          </Button>
        </Space>
      </Row>
      <br />
      <Table
        scroll={{ x: "max-content" }}
        rowKey="_id"
        loading={listGasStations.loading}
        columns={columns}
        pagination={
          listGasStations.pagination.total >
            listGasStations.pagination.pageSize && listGasStations.pagination
        }
        dataSource={listGasStations.list}
        onChange={(value: any) =>
          setListGasStations({
            ...listGasStations,
            pagination: {
              ...listGasStations.pagination,
              current: value.current,
            },
          })
        }
      />
    </>
  );
};
