import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, Image, Spin, Typography, Divider, Select, Space, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import API from '../../../../shared/api/api';
import { useHistory } from 'react-router-dom'
import { dateFormat } from '../../../../shared/dataFormat';
import { useParams, Link } from 'react-router-dom';
import { COMBINE_TYPE, PRESERVATION_OPTIONS, SPECIFICATION_TYPE } from '../../../../shared/define';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
const { Title } = Typography;

function DetailOrder() {
    let { id }: any = useParams();
    const history = useHistory();
    const [form] = Form.useForm();
    const [cityList, setCityList] = useState<any>([]);
    const [forwarderList, setForwarderList] = useState<any>([]);
    const [listImage, setListImage] = useState<any>([]);
    const [spinning, setSpinning] = useState(true);
    const [vehicleType, setVehicleType] = useState<any>('');

    useEffect(() => {
        getListCity();
        fetchForwarderList();
        loadData();
        return () => {
        }
    }, [])
    const loadData = () => {
        setSpinning(true)
        API.get(`admin/pending-order/${id}`)
            .then(
                res => {
                    if (res.data.success) {
                        let listData = res.data.data;
                        for (let i = 0; i < listData.delivery.length; i++) {
                            listData.delivery[i] = { ...listData.delivery[i], time: dateFormat(listData.delivery[i].time) }
                        }
                        for (let i = 0; i < listData.receipt.length; i++) {
                            listData.receipt[i] = { ...listData.receipt[i], time: dateFormat(listData.receipt[i].time) }
                        }
                        form.setFieldsValue(listData);
                        setListImage(listData?.goods?.avatars);
                        setVehicleType(listData.vehicle.name);
                    }
                }
            )
            .catch(error => {
                console.log(error);
            })
            .finally(() => { setSpinning(false) })
    }
    const fetchForwarderList = async () => {
        const response = await API.get('admin/forwarder/').then(res => res.data)
        if (response.success) setForwarderList(response.data)
    }
    const getListCity = () => {
        axios.get('https://vapi.vnappmob.com/api/province')
            .then((res: any) => {
                if (res.status === 200) {
                    setCityList(res.data.results)
                }
            }).catch((error) => {
                console.log(error)
            })
    }
    return (
        <Row style={{ width: '100%' }}>
            <Spin spinning={spinning}>
                <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}><span onClick={() => { history.goBack() }}><LeftOutlined />Quay lại</span></Title>
                <Form form={form} {...layout} style={{ width: '100%' }} >
                    <Row gutter={[32, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Ảnh hàng"
                                name={['goods', 'avatar']}
                            >
                                <Space>
                                    {listImage.map((image: string, index: any) => (
                                        <Image key={index} width={110} height={110} src={image} />
                                    ))}
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Tên khách hàng"
                                        name={['customer', 'name']}
                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Số điện thoại"
                                        name={['customer', 'phoneNumber']}
                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col> */}
                            </Row>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row gutter={0}>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="Tên hàng"
                                        name={['goods', 'name']}
                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="Lưu ý khi đóng (nhận) hàng"
                                        name={['goods', 'packingInstruction']}
                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Khối lượng"
                                        name={['goods', 'volume']}
                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Tính chất hàng hóa"
                                        name={['goods', 'packingInstruction']}
                                    >
                                        <Select>
                                            {PRESERVATION_OPTIONS.map((item: any, index: any) =>
                                                <Option key={index} value={item.value}>{item.label}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>
                        <Divider />
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row gutter={0}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="Yêu cầu kĩ thuật xe"
                                        name={['goods', 'technicalRequirement']}
                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                    >
                                        <Input.TextArea disabled rows={1}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="Chủ hàng"
                                        name={'forwarder'}
                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                    >
                                        <Select
                                            disabled
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {forwarderList.map((forwarder: any) =>
                                                <Option key={forwarder._id} value={forwarder._id}>{forwarder.name}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>

                                    <Form.Item
                                        label="Loại"
                                        name={['vehicle', 'name']}
                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                    >
                                        <Select disabled>
                                            <Option value='CONTAINER'>CONTAINER</Option>
                                            <Option value='TRUCK'>TRUCK</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* {vehicleType === 'CONTAINER' &&
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>

                                        <Form.Item
                                            label="Số trục xe"
                                            name={['vehicle', 'numberOfAxles']}
                                        // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                        >
                                            <Select disabled>
                                                <Option value={2}>2 trục</Option>
                                                <Option value={3}>3 trục</Option>
                                                <Option value={4}>4 trục</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                } */}
                                {vehicleType === 'CONTAINER' &&
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                        <Form.Item
                                            label="Loại Container"
                                            name={['vehicle', 'numberOfFeets']}
                                        // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                        >
                                            <Select disabled>
                                                <Option value={20}>20 feets</Option>
                                                <Option value={40}>40 feets</Option>
                                                <Option value={45}>45 feets</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {vehicleType === 'CONTAINER' &&
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                        <Form.Item
                                            label="Số cont"
                                            name={['vehicle', 'container']}
                                        >
                                            <Input placeholder='Nhập số container' />
                                        </Form.Item>
                                    </Col>
                                }
                                {vehicleType === 'TRUCK' &&
                                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                        <Form.Item
                                            label="Tải trọng"
                                            name={['vehicle', 'payload']}
                                        >
                                            <Select
                                                disabled
                                            >
                                                <Option value='<1'> nhỏ hơn 1 tấn </Option>
                                                <Option value='1-5'>1-5 tấn</Option>
                                                <Option value='5-7'>5-7 tấn</Option>
                                                <Option value='>7'>lớn hơn 7 tấn</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Row gutter={16}>
                            <Col xs={24}>
                                <Form.Item
                                    label="Quy cách"
                                    name='specification'
                                    // rules={[rule_require]}
                                >
                                    <Select
                                        placeholder='Chọn quy cách'
                                    >
                                        {SPECIFICATION_TYPE.map((item: any, index: any) =>
                                            <Option key={index} value={item.value}>{item.label}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Loại kết hợp"
                                    name='combinedType'
                                    // rules={[rule_require]}
                                >
                                    <Select
                                        placeholder='Chọn loại kết hợp' 
                                    >
                                        {COMBINE_TYPE.map((item: any, index: any) =>
                                            <Option key={index} value={item.value}>{item.label}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                        <Divider></Divider>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Title level={5}>Danh sách điểm nhận hàng</Title>
                        </Col>
                        <Form.List name='receipt'>
                            {(fields) => (
                                <>
                                    {fields.map((field: any) => (
                                        <Card key={field.key} style={{ margin: '0px 10px 10px 10px', position: 'relative' }}>
                                            <Row key={field.key} gutter={[32, 0]} style={{ marginLeft: '0', marginRight: '0' }}>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="Tên người liên hệ "
                                                        name={[field.name, 'name']}
                                                        fieldKey={[field.fieldKey, 'name']}
                                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                    >
                                                        <Input disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="Số điện thoại"
                                                        name={[field.name, 'phoneNumber']}
                                                        fieldKey={[field.fieldKey, 'phoneNumber']}
                                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                    >
                                                        <Input disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="Thời điểm nhận hàng"
                                                        name={[field.name, 'time']}
                                                        fieldKey={[field.fieldKey, 'time']}
                                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                    >
                                                        <Input disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="Khu vực"
                                                        name={[field.name, 'area', 'province']}
                                                        fieldKey={[field.fieldKey, 'province']}
                                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                    >
                                                        <Select
                                                            disabled
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {cityList.map((city: any) =>
                                                                <Option key={city.province_id} value={city.province_name}>{city.province_name}</Option>
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="Địa chỉ "
                                                        name={[field.name, 'text']}
                                                        fieldKey={[field.fieldKey, 'text']}
                                                    // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                    >
                                                        <Input disabled />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                    )}
                                </>
                            )}
                        </Form.List>
                        <Divider />
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Title level={5}>Danh sách điểm giao hàng</Title>
                        </Col>
                        <Form.List name='delivery'>
                            {(fields) => (
                                <>
                                    {fields.map((field: any) => (
                                        <Row key={field.key} gutter={[32, 0]} style={{ marginLeft: '0', marginRight: '0' }}>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Tên người liên hệ"
                                                    name={[field.name, 'name']}
                                                    fieldKey={[field.fieldKey, 'name']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Số điện thoại"
                                                    name={[field.name, 'phoneNumber']}
                                                    fieldKey={[field.fieldKey, 'phoneNumber']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Thời điểm nhận hàng"
                                                    name={[field.name, 'time']}
                                                    fieldKey={[field.fieldKey, 'time']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Khu vực"
                                                    name={[field.name, 'area', 'province']}
                                                    fieldKey={[field.fieldKey, 'province']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Select
                                                        disabled
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option: any) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {cityList.map((city: any) =>
                                                            <Option key={city.province_id} value={city.province_name}>{city.province_name}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Địa chỉ"
                                                    name={[field.name, 'text']}
                                                    fieldKey={[field.fieldKey, 'text']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            )}
                        </Form.List>
                        
                    </Row>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4} offset={20}>
                        <Link to={{
                            pathname: `/order/pending/edit/${id}`,
                        }}>
                            <Button htmlType='submit' style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }}>
                                Cập nhật
                        </Button>
                        </Link>
                    </Col>
                </Form>
            </Spin>
        </Row>
    )
}

export default DetailOrder
