import API from "../../api";

export const getAllPendingOrder = async (params: object) => {
    try {
        const response = await API.get('admin/pending-order', { params: params }).then(
            (res) => res.data
        );
        if (response.success) {
            const listData = [...response.data];
            listData.forEach((item: { key: any; _id: any }) => {
                item.key = item._id;
            });
            return { data: listData, total: response.totalItem };
        }
    } catch (error) {
        throw error;
    }
}

export const getPendingOrder = async (params:string) => {
    try {
        const response = await API.get(`admin/pending-order/${params}`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const deletePendingOrder = async (params: any) => {
    try {
        const response = await API.delete(`admin/pending-order/${params.key}`).then(
            (res) => res.data
        );
        if (response.success) return { deleted: true }
        else return { deleted: false }
    } catch (error) {
        throw error;
    }
}

export const createPendingOrder = async (params: any) => {
    try {
        const response = await API.post(`admin/pending-order`, params).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const updatePendingOrder = async (params: {id:string,data:object}) => {
    const {id,data} = params
    try {
        const response =await API.put(`admin/pending-order/${id}`, data).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const importExcel = async (params: FormData) => {
    try {
        const response = await API.post('admin/pending-order/import/haft', params).then(
            (res) => res.data.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const duplicatePendingOrder = async (params: { copyId: string, copyNum: number }) => {
    const { copyId, copyNum } = params
    try {
        const response = await API.post(`admin/pending-order/${copyId}/duplicate/${copyNum}`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const verifyPendingOrder = async (param: string, data: Object = {all: false}) => {
    try {
        const response = await API.put(`admin/pending-order/${param}/verify`, data).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const deleteAllPendingOrder = async () => {
    try {
        const response = await API.delete(`admin/pending-order/delete-all`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const verifyAllPendingOrder = async () => {
    try {
        const response = await API.delete(`admin/pending-order/verify-all`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}