import API from "../api";

export const getRoutes = async (params: object) => {
  try {
    const response = await API.get("admin/route", { params: params }).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateCY = async (id: any) => {
  try {
    const response = await API.put(`admin/route/${id}/cy`).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchPlaces = async (text: string) => {
  try {
    const response = await API.get(`/admin/place/search`, {
      params: { searchText: text },
    }).then((res) => res.data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createPlace = async (params: object) => {
  try {
    const response = await API.post(`/admin/place`, params).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getListPlaces = async (params: object) => {
  try {
    const response = await API.get(`/admin/place`, params).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updatePlace = async (id: object, payload: object) => {
  try {
    const response = await API.put(`/admin/place/${id}`, payload).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchRegion = async (text: string) => {
  try {
    const response = await API.get(`/admin/region/search`, {
      params: { searchText: text },
    }).then((res) => res.data);
    return response;
  } catch (error) {
    throw error;
  }
};
