import API from "../../api";

export const getAllTrackingOrder= async (params: object) => {
    try {
        const response = await API.get('admin/tracking/order', { params: params }).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getLocationOrder= async (params: string) => {
    try {
        const response = await API.get(`admin/tracking/order/${params}`).then(
            (res) => res.data
        );
        if(response.success) return response
    } catch (error) {
        throw error;
    }
}
