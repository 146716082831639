import { message } from "antd";

export const rule_require: any = { required: true, message: 'Không được để trống !' };

export const rule_not_require: any = { required: false };
export const rule_min_character: any = { min: 6, message: 'Tối thiểu 6 kí tự' };
export const rule_max_character: any = { max: 29, message: 'Tối đa 30 kí tự' };
export const rule_email: any = {
  pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  message: 'Email không đúng định dạng',
}
export const rule_phoneNumber: any = {
  pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3,4}\)?[\s.-]?\d{3}[\s.-]?\d{3,4}$/,
  message: 'SĐT không đúng định dạng',
}
export const rule_number: any = {
  pattern: /^[0-9]*$/,
  message: 'Chỉ được nhập số'
}
export const rule_float_number: any = {
  pattern: /^\d*\.?\d*$/,
  message: 'Chỉ được nhập số'
}

export const rule_bigger_than_5b: any = { 
  required: true, 
  type: "number", 
  min: 5000000000 ,
  message: 'Giá trị phải lớn hơn 5 tỉ'
}
// export const rules_no_special_char : any = {
//   pattern :/![`~,.<>;':"/[\]|{}()=_+-]/,
//   message:'Không chứa kí tự đặc biệt'
// }
export const rule_min_max_quantity: any = { min: 1,max:10, message: 'Tối thiểu 1 tối đa 10' };

