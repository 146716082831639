import React, { useRef, useEffect } from 'react'
import { Form, Col, Row, Input, Select } from 'antd'
import { rule_email, rule_phoneNumber, rule_min_character, rule_max_character, rule_require, rule_number } from '../../validateInput'
import { DRIVER_TYPE } from '../../define'

const { Option } = Select
function UserInfoForm(props: any = {}) {
    const inputRef: any = useRef()
    useEffect(() => {
        focus();
    }, [])
    const focus = () => {
        inputRef.current!.focus({
            cursor: 'end',
        })
    }
    return (
        <Form.Item wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
            <Row gutter={[32, 0]}>
                {
                    !props.hideEmail &&
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                            label="Email"
                            name={['owner', 'email']}
                            rules={[rule_require, rule_email, rule_max_character]}
                            labelCol={{ span: 24 }}
                        >
                            <Input ref={inputRef} placeholder='example@gmail.com' />
                        </Form.Item>
                    </Col>
                }

                <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="SĐT"
                                name={['owner', 'phoneNumber']}
                                rules={[rule_require, rule_phoneNumber, rule_number]}
                                labelCol={{ span: 24 }}
                            >
                                <Input ref={inputRef} placeholder='Nhập số điện thoại' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="Mật khẩu"
                                name="password"
                                rules={props.isEdit ? [] : [rule_require, rule_min_character, rule_max_character]}
                                labelCol={{ span: 24 }}
                            >
                                <Input.Password placeholder='Nhập mật khẩu' />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Col />
                </Col>
                {
                    props.type == 'DRIVER' &&
                    < Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item
                            label="Loại tài xế"
                            name='type'
                            labelCol={{ span: 24 }}
                            initialValue='DRIVER'
                        >
                            <Select onChange={(value:any)=>props.driverType.setType(value)}>
                                {
                                    DRIVER_TYPE.map((item: any, index: any) =>
                                        <Option key={index} value={item.value}>{item.label}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                }
            </Row>
        </Form.Item >
    )
}

export default UserInfoForm
