import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Row, Input, Typography, Tooltip} from 'antd';
import API from '../../../shared/api/api';
import { PlusOutlined, EditOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../../shared/dataFormat';
import { getShipPagination } from '../../../shared/api/Ship';
const { Title } = Typography;
const { Search } = Input

function ShipList() {
    const [ship, setShip] = useState({
        list: [],
        loading: false,
        filter: {
            text: ''
        },
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0
        }
    })

    useEffect(() => {
        fetchShip()
    }, [ship.filter, ship.pagination.current])

    const fetchShip = async () => {
        setShip({...ship, loading: true})
        const response: any = await getShipPagination({ ...ship.filter, current: ship.pagination.current, pagesize: ship.pagination.pageSize })
        if (response.success) {
            setShip({
                ...ship,
                list: response.data,
                pagination: {
                    ...ship.pagination,
                    total: response.totalItem
                },
                loading: false
            })
        }
    }

    const columns: Array<object> = [
        {
            title: 'STT',
            width: 50,
            render: (id: any, record: any, index: any) => index + 1
        },
        {
            title: 'Tên viết tắt',
            dataIndex: 'name',
        },
        {
            title: 'Tên đầy đủ',
            dataIndex:'fullName',
        },
        {
            title: 'Mã số thuể',
            dataIndex: ['businessLicense', 'text'],
        },
        {
            title: 'Tác vụ',
            align: "center",
            width: 100,
            render: (ship: any) => (
                <Space size="middle">
                    {/* <Tooltip title="Xem">
                        <Link to={{
                            pathname: `/resource/driver/detail/${driver._id}`,
                        }}>
                            <EyeOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip> */}
                    <Tooltip title="Sửa">
                        <Link to={{
                            pathname: `/partner/ship/${ship._id}/edit/`,
                        }}>
                            <EditOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip>
                    {/* <Tooltip title="Xóa">
                        <DeleteOutlined style={{ fontSize: 16, color: 'red' }} onClick={() => {onDelete(trailer._id)}} />
                    </Tooltip> */}
                </Space>
            ),
        }
    ]
    return (
        <>
            <Row justify="space-between" gutter={6}>
            <Title level={4}>Quản lý hãng tàu</Title>
                <Space size={6}>
                    <Search
                        // disabled
                        placeholder="Search"
                        size="small"
                        allowClear
                        style={{ width: 150, margin: '0 10px' }}
                        onSearch={(value) => setShip({ ...ship, filter: { ...ship.filter, text: value } })}
                    />
                    <Button type="primary" size="small" icon={<PlusOutlined />}>
                        <Link to={{
                                pathname: '/partner/ship/create',
                            }} style={{ color: 'white' }}>
                                Tạo mới
                        </Link>
                    </Button>
                </Space>
            </Row>
            <br />
            <Table
                scroll={{ x: 'max-content'}}
                rowKey='_id'
                loading={ship.loading}
                columns={columns}
                pagination={ship.pagination.total > ship.pagination.pageSize && ship.pagination}
                dataSource={ship.list}
                onChange={(value: any) => setShip({ ...ship, pagination: {...ship.pagination, current: value.current }})}
            />
        </>
    )
}

export default ShipList
