import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Button,
  Row,
  Input,
  Typography,
  Modal,
  Col,
  Select,
  DatePicker,
  Tooltip,
  Form,
  Upload,
  Descriptions,
  Alert,
  Tabs,
} from "antd";
import {
  PlusOutlined,
  CopyOutlined,
  ReloadOutlined,
  InfoCircleOutlined,
  VerticalAlignBottomOutlined,
  CheckOutlined,
  EditOutlined,
  DeleteOutlined,
  FileExcelOutlined,
  MinusOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  deletePendingOrder,
  duplicatePendingOrder,
  getAllPendingOrder,
  importExcel,
  verifyPendingOrder,
  deleteAllPendingOrder,
} from "../../../../shared/api/Order/PendingOrder";
import { getAllForwarder } from "../../../../shared/api/Forwarder";
import { getAllIncurred } from "../../../../shared/api/Incurred";
import {
  NUMBER_OF_FEETS_OPTIONS,
  PRESERVATION_OPTIONS,
  SPECIFICATION_TYPE,
} from "../../../../shared/define";
import { successNotification } from "../../../../shared/notification";
import { EditableTable } from "./EditableTable";

const { Option } = Select;
const { Search } = Input;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { TabPane } = Tabs;

function OrderList() {
  const [orderList, setOrderList] = useState([]);
  const [forwarderList, setForwarderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDeleteAll, setLoadingDeleteAll] = useState(false);
  const [loadingVerifyAll, setLoadingVerifyAll] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [copyNum, setCopyNum] = useState(0);
  const [copyId, setCopyId] = useState("");
  const [filter, setFilter] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [incurredModal, setIncurredModal] = useState({
    visible: false,
    data: [],
    orderId: "",
  });
  const [importModal, setImportModal] = useState({
    visible: false,
    success: [],
    total: 0,
    assigned: 0,
    errors: [],
  });
  const [form] = Form.useForm();

  useEffect(() => {
    loadList();
  }, [filter, pagination.current]);

  useEffect(() => {
    getForwarderList();
  }, []);
  const loadList = async () => {
    setLoading(true);
    const response: any = await getAllPendingOrder({
      ...filter,
      current: pagination.current,
    });
    setOrderList(response.data);
    setPagination({ ...pagination, total: response.total });
    setLoading(false);
  };
  const onDelete = async (order: any) => {
    const response: any = await deletePendingOrder(order);
    if (response.deleted) loadList();
  };
  const uploadExcel = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append("file", file);
    const response: any = await importExcel(formData);
    if (response.success) {
      setImportModal({
        ...importModal,
        visible: true,
        success: response.success,
        errors: response.errors,
        total: response.total,
        assigned: response.assigned,
      });
      loadList();
    } else return;
  };

  const getForwarderList = async () => {
    const response: any = await getAllForwarder();
    setForwarderList(response.data);
  };
  const handleOk = async () => {
    const response: any = await duplicatePendingOrder({
      copyId: copyId,
      copyNum: copyNum,
    });
    if (response.success) {
      loadList();
      handleCancel();
    }
  };

  const onAccept = async (id: any) => {
    const response: any = await verifyPendingOrder(id);
    if (response.success) loadList();
  };

  const handleCancel = () => {
    setPopup(false);
    setCopyId("");
    setCopyNum(0);
  };

  const addIncurreds = async (values: any) => {
    const response: any = await getAllIncurred({
      ...values,
      order: incurredModal.orderId,
    });
    if (response.success) {
      setIncurredModal({ ...incurredModal, visible: false });
      loadList();
      form.setFieldsValue({ incurreds: [] });
    } else return;
  };

  const closeIncurredModal = () => {
    setIncurredModal({ ...incurredModal, visible: false });
    form.setFieldsValue({ incurreds: [] });
  };

  const deleteAll = async () => {
    setLoadingDeleteAll(true);
    const response = await deleteAllPendingOrder();
    loadList();
    if (response.success) setLoadingDeleteAll(false);
  };

  const verifyAll = async () => {
    setLoadingVerifyAll(true);
    let total = orderList.length;
    let success = 0;
    await Promise.all(
      orderList.map(async (order: any) => {
        let response = await verifyPendingOrder(order._id, { all: true });
        if (response.success) success++;
      })
    );
    successNotification(`Duyệt thành công ${success}/${total}`);
    setLoadingVerifyAll(false);
    loadList();
  };

  const incurredColumns = [
    {
      title: "STT",
      dataIndex: "_id",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "title",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      render: (description: any) => (description ? description : "Không có"),
    },
    {
      title: "Giá tiền",
      dataIndex: "amount",
      render: (amount: any) =>
        (amount ? new Intl.NumberFormat().format(amount) : 0) + " đ",
    },
    {
      title: "Loại",
      dataIndex: "type",
      render: (type: any) => (type === "PLAN" ? "Dự kiến" : "Thực tế"),
    },
    {
      title: "Trạng thái",
      dataIndex: "verified",
      render: (verified: any) =>
        verified === true ? "Đã duyệt" : "Chưa duyệt",
    },
  ];

  const importColumns: Array<object> = [
    {
      title: "STT",
      dataIndex: "stt",
      width: 50,
      // render: (id: any, record: any, index: any) => index + 1
    },

    {
      title: "Ngày",
      dataIndex: "date",
    },
    {
      title: "Số cont",
      dataIndex: "vehicleContainer",
    },
    {
      title: "N/X",
      dataIndex: "specification",
    },
    {
      title: "Mã chủ hàng",
      dataIndex: "forwarderBusinessLicense",
      render: (item: any) => (item ? item : "HAFC"),
    },
    {
      title: "Tên chủ hàng",
      dataIndex: "forwarderName",
      render: (item: any) => (item ? item : "HAFC"),
    },
    {
      title: "Mã chủ thầu",
      dataIndex: "truckVendorBusinessLicense",
      render: (item: any) => (item ? item : "HAFT"),
    },
    {
      title: "Tên chủ thầu",
      dataIndex: "truckVendorName",
      render: (item: any) => (item ? item : "HAFT"),
    },
    {
      title: "Mã nhận",
      dataIndex: "receiptCode",
    },
    {
      title: "Mã trả",
      dataIndex: "deliveryCode",
    },
  ];

  return (
    <>
      <Title level={4}>
        Quản lý đơn chờ ({pagination.total}){" "}
        <Tooltip title="Đơn được lên kế hoạch, chưa chỉ định cho nhà xe và tài xế. Được tạo bởi Forwarder">
          <InfoCircleOutlined style={{ color: "#0082fb" }} />
        </Tooltip>
      </Title>
      <Row justify="space-between" gutter={6}>
        <Col>
          <Space size={6}>
            <Select
              showSearch
              size="small"
              style={{ width: 220 }}
              placeholder="Forwarder"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              onSelect={(value) => setFilter({ ...filter, forwarder: value })}
            >
              <Option value="ALL">Tất cả</Option>
              {forwarderList.map((forwarder: any) => (
                <Option key={forwarder._id} value={forwarder._id}>
                  {forwarder.shortName}
                </Option>
              ))}
            </Select>

            <Select
              placeholder="Loại hàng"
              size="small"
              dropdownMatchSelectWidth={false}
              style={{ width: 120 }}
              onChange={(value) =>
                setFilter({ ...filter, preservation: value })
              }
            >
              <Option value="ALL">Tất cả</Option>
              {PRESERVATION_OPTIONS.map((item: any, index: any) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>

            <RangePicker
              size="small"
              style={{ width: 180 }}
              onChange={(date, dateString) =>
                setFilter({
                  ...filter,
                  range: [
                    moment.utc(date?.[0]).format("YYYY-MM-DD HH:mm:ssZ"),
                    moment.utc(date?.[1]).format("YYYY-MM-DD HH:mm:ssZ"),
                  ],
                })
              }
            />

            <Select
              placeholder="Loại container"
              size="small"
              style={{ width: 120 }}
              onChange={(value) =>
                setFilter({ ...filter, numberOfFeets: value })
              }
            >
              <Option value="ALL">Tất cả</Option>
              {NUMBER_OF_FEETS_OPTIONS.map((item: any, index: any) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Space size={6}>
          <Button
            size="small"
            onClick={loadList}
            icon={<ReloadOutlined />}
          ></Button>
          {/* <Search
                        placeholder="Search"
                        disabled
                        size="small"
                        allowClear
                        style={{ width: 150, margin: '0 10px' }}
                        onSearch={(value) => setFilter({ ...filter, text: value })}
                    /> */}
          <Upload showUploadList={false} customRequest={uploadExcel}>
            <Button
              size="small"
              style={{ backgroundColor: "#009688", borderColor: "#009688" }}
              type="primary"
              icon={<FileExcelOutlined />}
            >
              Tải lên
            </Button>
          </Upload>
          <Tooltip title="Tải file mẫu">
            <a href="/files/don-hang-mau.xlsx" download>
              <Button
                type="primary"
                size="small"
                icon={<VerticalAlignBottomOutlined />}
              ></Button>
            </a>
          </Tooltip>
          <Button
            type="primary"
            loading={loadingVerifyAll}
            size="small"
            onClick={verifyAll}
            icon={<CheckCircleOutlined />}
          >
            Duyệt tất cả
          </Button>
          <Button
            type="primary"
            danger
            loading={loadingDeleteAll}
            size="small"
            onClick={deleteAll}
            icon={<DeleteOutlined />}
          >
            Xóa tất cả
          </Button>
          <Link
            to={{
              pathname: "/order/pending/create",
            }}
            style={{ color: "white" }}
          >
            <Button size="small" type="primary" icon={<PlusOutlined />}>
              Tạo mới
            </Button>
          </Link>
        </Space>
      </Row>
      <br />
      {/* <Table
        loading={loading}
        bordered={false}
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={orderList}
        pagination={pagination.total > pagination.pageSize && pagination}
        onChange={(value: any) =>
          setPagination({ ...pagination, current: value.current })
        }
      /> */}
      <EditableTable
        orderList={orderList.map((item: any) => {
          return { ...item, id: item._id };
        })}
        loadList={loadList}
        setPopupCopy={setPopup}
      />

      <Modal
        destroyOnClose={true}
        closable={false}
        title="Nhân bản đơn"
        visible={isPopup}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Text>Số lượng muốn nhân bản</Text>
        <Input
          onChange={(e: any) => {
            setCopyNum(e.target.value);
          }}
          type="number"
        />
        <Text>
          Lưu ý: Đơn hàng khi đã được nhân bản sẽ được gán các mã đơn khác nhau
        </Text>
      </Modal>
      <Modal
        width={800}
        destroyOnClose={true}
        closable={true}
        title="Chi phí phát sinh"
        visible={incurredModal.visible}
        onCancel={closeIncurredModal}
        onOk={() => {
          form.submit();
        }}
      >
        <Table
          bordered={false}
          columns={incurredColumns}
          dataSource={incurredModal.data}
          pagination={false}
        />
        <br />
        <Form {...layout} form={form} onFinish={addIncurreds}>
          <Form.List name="incurreds">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Row
                    style={{
                      borderBottom: "1px solid #eee",
                      marginBottom: 20,
                      padding: 10,
                    }}
                    gutter={8}
                  >
                    <Col span={8}>
                      <Form.Item
                        label="Tên"
                        name={[field.name, "title"]}
                        rules={[
                          {
                            required: true,
                            message: "Tên phát sinh là bắt buộc",
                          },
                        ]}
                      >
                        <Input placeholder="Tên phát sinh" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Chi phí"
                        name={[field.name, "amount"]}
                        rules={[
                          { required: true, message: "Chi phí là bắt buộc" },
                        ]}
                      >
                        <Input placeholder="Số tiền" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Loại"
                        name={[field.name, "type"]}
                        initialValue="PLAN"
                      >
                        <Select disabled>
                          <Option value="PLAN">Dự kiến</Option>
                          <Option value="ACTUAL">Thực tế</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Trạng thái"
                        name={[field.name, "verified"]}
                        initialValue="TRUE"
                      >
                        <Select>
                          <Option value="TRUE">Duyệt</Option>
                          <Option value="FALSE">Không duyệt</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        label="Mô tả"
                        name={[field.name, "description"]}
                      >
                        <Input.TextArea rows={1} placeholder="Mô tả" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          danger
                          type="dashed"
                          onClick={() => remove(field.name)}
                          block
                          icon={<MinusOutlined />}
                        ></Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  ></Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
      <Modal
        footer={false}
        title={`Kết quả`}
        visible={importModal.visible}
        width={1000}
        onCancel={() => setImportModal({ ...importModal, visible: false })}
        onOk={() => setImportModal({ ...importModal, visible: false })}
      >
        <Title style={{ textAlign: "center", margin: "20px 0px" }} level={3}>
          Tải lên thành công {importModal.success.length}/{importModal.total}
          <br /> ({importModal.assigned} đã chỉ định nhà xe)
        </Title>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <CheckCircleOutlined />
                Thành công ({importModal.success.length})
              </span>
            }
            key="1"
          >
            {importModal.success.length > 0 ? (
              <Table
                tableLayout="auto"
                scroll={{ x: "max-content" }}
                columns={importColumns}
                dataSource={importModal.success}
                pagination={false}
              />
            ) : (
              "Không có"
            )}
          </TabPane>
          <TabPane
            tab={
              <span>
                <CloseCircleOutlined />
                Thất bại ({importModal.errors.length})
              </span>
            }
            key="2"
          >
            {importModal.errors.length === importModal.total && (
              <>
                <Alert
                  message="Lưu ý"
                  description="Có thể file upload không đúng với cấu trúc file quy định, sử dụng đúng mẫu file của AloTruck."
                  type="warning"
                  showIcon
                  closable
                />
                <br />
                <br />
              </>
            )}
            {importModal.errors.length > 0 ? (
              <Descriptions size="small" bordered>
                <Descriptions.Item span={3} label="STT">
                  Nội dung
                </Descriptions.Item>
                {importModal &&
                  importModal.errors.map((error: any) => (
                    <Descriptions.Item span={3} label={error.stt}>
                      {error.error.map((item: any) => (
                        <>
                          {item}
                          <br />
                        </>
                      ))}
                    </Descriptions.Item>
                  ))}
              </Descriptions>
            ) : (
              "Không có"
            )}
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default OrderList;
