

export const checkTokenExpired = () => {
    const token = localStorage.getItem('assetToken');
    if(token){
        return true
    }else{
        return false;
    }
}

export const setTokenUser = (params : any) => {
    const {token} = params;
    localStorage.setItem('assetToken', token);
}

export const clearLocalStorage = () => {
    localStorage.clear();
}