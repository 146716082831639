import React, { useEffect, useState } from "react";
import API from "../../../shared/api/api";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import {
  EditOutlined,
  EnvironmentOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from "axios";

const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;

function PriceContractorList() {
  const [priceList, setPriceList] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [truckVendorList, setTruckVendorList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [drawerVisible, setDrawerVisible] = useState<Boolean>(false);
  const [id, setId] = useState("");
  const [route, setRoute] = useState("");
  useEffect(() => {
    loadlist();
  }, [filter, pagination.current, pagination.pageSize]);

  useEffect(() => {
    // getListProvince();
    getProvinces();
    getTruckVendor();
  }, []);

  const loadlist = () => {
    setIsLoading(true);
    API.get("admin/contractorFreight", {
      params: {
        ...filter,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    })
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setPriceList(listData);
          setPagination({ ...pagination, total: res.data.totalItem });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getProvinces = () => {
    API.get("admin/region/province", {
      params: {},
    })
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const getTruckVendor = () => {
    API.get("admin/truck-vendor", {
      params: {},
    })
      .then((res) => {
        if (res.data.success) {
          setTruckVendorList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const columns: Array<object> = [
    {
      title: "STT",
      dataIndex: "_id",
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Nhà thầu",
      dataIndex: "truckVendor",
      render: (truckVendor: any) => (
        <Tooltip
          placement="topLeft"
          title={`${truckVendor.shortName}`}
        >
          <div className="address">
            {truckVendor.shortName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mã nhận",
      dataIndex: ["receipt", "code"],
    },
    {
      title: "Điểm nhận",
      dataIndex: "receipt",
      render: (receipt: any) => (
        <Tooltip
          placement="topLeft"
          title={`${receipt.name} -- ${receipt.pName}`}
        >
          <div className="address">
            {receipt.name} -- {receipt.pName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mã trả",
      dataIndex: ["delivery", "code"],
    },
    {
      title: "Điểm trả",
      dataIndex: "delivery",
      render: (delivery: any) => (
        <Tooltip
          placement="topLeft"
          title={`${delivery.name} -- ${delivery.pName}`}
        >
          <div className="address">
            {delivery.name} -- {delivery.pName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mô tả",
      dataIndex: "description",
    },
    {
      title: "Giá container 20",
      dataIndex: ["price", "cont20"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá container 40",
      dataIndex: ["price", "cont40"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá CY 20",
      dataIndex: ["price", "cy20"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá CY 40",
      dataIndex: ["price", "cy40"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Tác vụ",
      render: (price: any) => (
        <Tooltip title="Sửa">
          <Link
            to={{
              pathname: `/route-contractor-with-path/edit/${price._id}`,
              // pathname: `/basic-route/edit/${price._id}`,
            }}
          >
            <EditOutlined style={{ fontSize: 16 }} />
          </Link>
        </Tooltip>
      ),
    },
  ];
  const uploadExcel = (options: any) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append("file", file);
    API.post("admin/contractorFreight/import", formData)
      .then((res) => {
        if (res.data.success) {
          onSuccess("Ok");
          loadlist();
        }
      })
      .catch((err) => {
        onError({ err });
        console.log("Error: ", err);
      });
  };
  return (
    <>
      <Title level={4}>Quản lý cước chi phí ({pagination.total})</Title>
      <Row justify="space-between" gutter={8}>
        <Col>
          <Space size={8}>
            Nhà xe
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{ width: 150 }}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({ ...filter, truckVendor: value })
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {truckVendorList.map((truckVendor: any) => (
                <Option key={truckVendor._id} value={truckVendor._id}>
                  {truckVendor.shortName} {/*+ "(" + truckVendor.code + ")"}*/}
                </Option>
              ))}
            </Select>
            Điểm nhận
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{ width: 150 }}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({ ...filter, receipt: value })
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {provinceList.map((province: any) => (
                <Option key={province._id} value={province._id}>
                  {province.name}
                </Option>
              ))}
            </Select>
            Điểm trả
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{ width: 150 }}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({ ...filter, delivery: value })
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {provinceList.map((province: any) => (
                <Option key={province._id} value={province._id}>
                  {province.name}
                </Option>
              ))}
            </Select>
            Hiển thị
            <Select
              style={{ width: 80 }}
              size="small"
              placeholder="Search to Select"
              defaultValue={pagination.pageSize}
              onSelect={(value: number) =>
                setPagination({ ...pagination, pageSize: value })
              }
            >
              {[10, 20, 30, 40, 50].map((pageSize: any) => (
                <Option key={pageSize} value={pageSize}>
                  {pageSize}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Space>
          <Upload showUploadList={false} customRequest={uploadExcel}>
            <Button
              size="small"
              style={{ backgroundColor: "#009688", borderColor: "#009688" }}
              type="primary"
              icon={<FileExcelOutlined />}
            >
              Upload
            </Button>
          </Upload>
          {/*<Link*/}
          {/*  to={{*/}
          {/*    // pathname: '/route-with-path/create',*/}
          {/*    pathname: "/basic-route/create",*/}
          {/*  }}*/}
          {/*  style={{ color: "white" }}*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    size="small"*/}
          {/*    style={{ backgroundColor: "#2E3D80", borderColor: "#2E3D80" }}*/}
          {/*    type="primary"*/}
          {/*    icon={<EnvironmentOutlined />}*/}
          {/*  >*/}
          {/*    Tạo chặng (cũ)*/}
          {/*  </Button>*/}
          {/*</Link>*/}
          <Link
            to={{
              pathname: "/route-contractor-with-path/create",
              // pathname: '/basic-route/create',
            }}
            style={{ color: "white" }}
          >
            <Button
              size="small"
              style={{ backgroundColor: "#2E3D80", borderColor: "#2E3D80" }}
              type="primary"
              icon={<EnvironmentOutlined />}
            >
              Tạo chặng
            </Button>
          </Link>
          <Link
            to={{
              pathname: "/attribute-route/create",
            }}
            style={{ color: "white" }}
          >
            <Button size="small" type="primary" icon={<PlusOutlined />}>
              Thuộc tính
            </Button>
          </Link>
        </Space>
      </Row>
      <br />
      <Table
        scroll={{ x: "max-content" }}
        loading={isloading}
        bordered={false}
        columns={columns}
        dataSource={priceList}
        pagination={pagination.total > pagination.pageSize && pagination}
        onChange={(value: any) =>
          setPagination({ ...pagination, current: value.current })
        }
      />
    </>
  );
}

export default PriceContractorList;
