import React, { useState, useEffect } from "react";
import { Table, Space, Button, Row, Input, Typography, Tooltip } from "antd";
import { PlusOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getListPlaces } from "../../shared/api/Route";
const { Title } = Typography;
const { Search } = Input;

function PlacesList() {
  const [listPlaces, setListPlaces] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [filter, setFilter] = useState({});

  useEffect(() => {
    fetchData();
  }, [filter, pagination.current]);

  const fetchData = async () => {
    setIsLoading(true);
    const response: any = await getListPlaces({
      params: { ...filter, current: pagination.current },
    });
    if (response.success) {
      setIsLoading(false);
      setListPlaces(response?.data);
      setPagination({ ...pagination, total: response.totalItem });
    }
  };

  const columns: Array<object> = [
    {
      title: "STT",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Loại",
      width: 100,
      dataIndex: "type",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
    },
    {
      title: "Quận/Huyện",
      dataIndex: "dName",
    },
    {
      title: "Tỉnh/Thành Phố",
      dataIndex: "pName",
    },

    {
      title: "Tác vụ",
      align: "center",
      width: 100,
      render: (place: any) => (
        <Space size="middle">
          <Tooltip title="Sửa">
            <Link
              to={{
                pathname: `/place/${place._id}/edit`,
                state: place,
              }}
            >
              <EditOutlined style={{ fontSize: 16 }} />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" gutter={6}>
        <Title level={4}>Quản lý điểm giao nhận</Title>
        <Row justify="space-between" gutter={8}>
          <Space>
            <Button
              size="small"
              onClick={fetchData}
              icon={<ReloadOutlined />}
            ></Button>
            <Search
              size="small"
              placeholder="Search"
              allowClear
              style={{ width: 150, margin: "0 10px" }}
              onSearch={(value) => {
                setFilter({ ...filter, text: value });
                setPagination({ ...pagination, current: 1 });
              }}
            />
            <Link
              to={{
                pathname: "/place/create",
              }}
              style={{ color: "white" }}
            >
              <Button size="small" type="primary" icon={<PlusOutlined />}>
                Tạo mới
              </Button>
            </Link>
          </Space>
        </Row>
      </Row>
      <br />

      <Table
        scroll={{ x: "max-content" }}
        rowKey="_id"
        loading={isloading}
        columns={columns}
        dataSource={listPlaces}
        pagination={pagination}
        onChange={(value: any) =>
          setPagination({ ...pagination, current: value.current })
        }
      />
    </>
  );
}

export default PlacesList;
