import { StandaloneSearchBox } from '@react-google-maps/api';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IPlace } from '../Modules/Route/interface';

interface IProps {
  defaultValue: string;
  setList: Dispatch<SetStateAction<IPlace[]>>;
  list: IPlace[];
  index: number;
}
export const SearchBox = (props: IProps) => {
  const { defaultValue, setList, list, index } = props;
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.SearchBox>();

  const calculatePolyLine = (
    overViewPolyline: string,
    distance: number,
    listTemp: IPlace[]
  ) => {
    const temp = listTemp;
    const result = temp.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          polyline: overViewPolyline,
          distance: Math.round(distance / 1000),
        };
      } else {
        return item;
      }
    });
    setList(result);
  };
  useEffect(() => {}, [list]);

  const onPlacesChanged = () => {
    const place = autocomplete?.getPlaces()?.[0]?.formatted_address;
    const lat = autocomplete?.getPlaces()?.[0]?.geometry?.location?.lat();
    const lng = autocomplete?.getPlaces()?.[0]?.geometry?.location?.lng();
    const id = autocomplete?.getPlaces()?.[0]?.place_id;

    if (place && lat && lng) {
      const currentItem = {
        address: place,
        lat: lat,
        lng: lng,
        _id: id,
      };
      if (index === 0) {
        setList([currentItem]);
      } else {
        const temp = list;
        const result = temp.map((item, i) => {
          if (i === index) {
            return currentItem;
          } else {
            return item;
          }
        });
        const directionService = new google.maps.DirectionsService();
        directionService.route(
          {
            destination: new google.maps.LatLng({ lat: lat, lng: lng }),
            origin: new google.maps.LatLng({
              lat: list[index - 1].lat,
              lng: list[index - 1].lng,
            }),
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (rs: any) => {
            calculatePolyLine(
              rs.routes[0]?.overview_polyline,
              rs.routes[0]?.legs[0]?.distance?.value,

              result
            );
          }
        );
        setList(result);
      }
    }
  };

  const onLoad = (e: google.maps.places.SearchBox) => {
    setAutocomplete(e);
  };

  return (
    <div style={{ width: '90%' }}>
      <StandaloneSearchBox
        key={`${index},${defaultValue}`}
        onLoad={onLoad}
        onPlacesChanged={onPlacesChanged}
      >
        <>
          <input
            placeholder="Nhập địa chỉ"
            defaultValue={defaultValue}
            // key={1}
            style={{
              fontSize: '14px',
              width: '100%',
              lineHeight: 1.7,
            }}
          ></input>
        </>
      </StandaloneSearchBox>
    </div>
  );
};
