import React, {useEffect, useState} from 'react';
import {
  Space,
  Button,
  Table,
  Typography,
  Row,
  Col,
  Select,
  Menu,
  Input,
  Tooltip,
  Modal,
  Descriptions,
  Upload
} from 'antd';
import {Link} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {
  ReloadOutlined,
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
  FileExcelOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import API from '../../../shared/api/api';
import {successNotification} from '../../../shared/notification'
import {isLoading} from '../../../store/common'
import axios from 'axios';

const {Title} = Typography;
const {Search} = Input;
const {Option} = Select;

function TruckVendorList() {
  const [vendorList, setVendorList] = useState([]);
  const [isloading, setIsLoading] = useRecoilState(isLoading);
  const [filter, setFilter] = useState({})
  const [provinceList, setProvinceList] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0
  })
  const [modal, setModal] = useState({
    visible: false,
    total: 0,
    success: 0,
    errors: [{
      name: '',
      error: []
    }]
  })

  useEffect(() => {
    loadlist();
  }, [filter, pagination.current])

  useEffect(() => {
    getListProvince()
  }, [])

  const loadlist = () => {
    setIsLoading(true);
    API.get('admin/truck-vendor', {
      params: {
        ...filter,
        current: pagination.current,
        pageSize: pagination.pageSize,
      }
    })
      .then(
        res => {
          if (res.data.success) {
            const listData = res.data.data;
            listData.forEach((item: { key: any; _id: any; }) => {
              item.key = item._id;
            });
            setVendorList(listData);
            setPagination({...pagination, total: res.data.totalItem})
            setIsLoading(false)
          }
        }
      )
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getListProvince = () => {
    axios.get('https://vapi.vnappmob.com/api/province')
      .then((res: any) => {
        if (res.status === 200) {
          setProvinceList(res.data.results)
        }
      }).catch((error) => {
      console.log(error)
    })
  }

  const onSearch = (value: string) => console.log(value);
  const onDelete = async (vendor: any) => {
    const response = await API.delete(`admin/truck-vendor/${vendor._id}`)
    if (response.data.success) loadlist()

  }
  const uploadExcel = (options: any) => {
    const {onSuccess, onError, file} = options;
    const formData = new FormData();
    formData.append(
      'file',
      file
    );
    console.log(file)
    API.post('admin/truck-vendor/import', formData)
      .then(
        res => {
          if (res.data.success) {
            setModal({
              ...modal,
              visible: true,
              success: res.data.data.success,
              total: res.data.data.total,
              errors: res.data.data.errors
            })
            loadlist()
          }
        }
      )
      .catch(err => {
        onError({err});
        console.log("Error: ", err);
      })
  };
  const columns: Array<object> = [
    {
      title: 'STT',
      dataIndex: '_id',
      render: (id: any, record: any, index: any) => index + 1
    },
    {
      title: 'Tên',
      dataIndex: 'name',
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'shortName',
    },
    {
      title: 'Thế mạnh',
      dataIndex: 'strengths',
    },
    {
      title: 'Số điện thoại',
      dataIndex: ['owner', 'phoneNumber'],
    },
    {
      title: 'Hotline',
      dataIndex: 'owner',
      align: "left",
      render: (owner: any) => <>{owner?.phoneNumber}</>
    },
    {
      title: 'Mã số thuế',
      dataIndex: ['businessLicense', 'text'],
    },
    {
      title: 'Chi phí ĐHVT',
      dataIndex: 'directOperating',
      render: (value: any) => value ? new Intl.NumberFormat().format(value) : ''
    },
    {
      title: 'Số xe',
      align: "center",
      dataIndex: 'numberOfVehicles',
    },
    {
      title: 'Tình trạng',
      align: 'center',
      dataIndex: 'verified',
      render: (verified: boolean) => verified ? <>Đã duyệt</> : <>Chưa duyệt</>
    },
    {
      title: 'Tác vụ',
      align: "center",
      width: 100,
      render: (vendor: any) => (
        <Space size="middle">
          <Tooltip title="Xem">
            <Link to={{
              pathname: `/partner/truck-vendor/detail/${vendor._id}`,
            }}>
              <EyeOutlined style={{fontSize: 16}}/>
            </Link>
          </Tooltip>
          <Tooltip title="Sửa">
            <Link to={{
              pathname: `/partner/truck-vendor/edit/${vendor._id}`,
            }}>
              <EditOutlined style={{fontSize: 16}}/>
            </Link>
          </Tooltip>
          <Tooltip title="Xóa">
            <DeleteOutlined style={{fontSize: 16, color: 'red'}} onClick={() => {
              onDelete(vendor)
            }} type="primary"/>
          </Tooltip>
        </Space>
      ),
    }
  ];
  return (
    <>
      <Title level={4}>Quản lý nhà xe ({pagination.total})</Title>
      <Row justify="space-between" gutter={8}>
        <Col>
          <Space size={8}>
            Khu vực
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{width: 150}}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) => setFilter({...filter, province: value})}
            >
              <Option key="0" value="ALL">Tất cả</Option>
              {provinceList.map((city: any) =>
                <Option key={city.province_id} value={city.province_name}>{city.province_name}</Option>
              )}
            </Select>
          </Space>

        </Col>
        <Space>
          <Button size="small" onClick={loadlist} icon={<ReloadOutlined/>}></Button>
          <Search
            placeholder="Search"
            allowClear
            size="small"
            style={{width: 150, margin: '0 10px'}}
            onSearch={(value) => setFilter({...filter, text: value})}
          />
          <Upload showUploadList={false}
                  customRequest={uploadExcel}
          >
            <Button size="small" style={{backgroundColor: '#009688', borderColor: '#009688'}} type="primary"
                    icon={<FileExcelOutlined/>}>
              Upload
            </Button>
          </Upload>
          <Link to={{
            pathname: '/partner/truck-vendor/create',
          }} style={{color: 'white'}}>
            <Button size="small" type="primary" icon={<PlusOutlined/>}>
              Tạo mới
            </Button>
          </Link>
        </Space>
      </Row>
      <br/>
      <Table
        scroll={{x: 'max-content'}}
        columns={columns}
        dataSource={vendorList}
        loading={isloading}
        pagination={pagination}
        onChange={(value: any) => setPagination({...pagination, current: value.current})}
      />
      <Modal title={`Kết quả`} visible={modal.visible} width={700} onCancel={() => setModal({...modal, visible: false})}
             onOk={() => setModal({...modal, visible: false})}>
        <Title style={{textAlign: 'center', margin: '20px 0px'}} level={3}>Tải lên thành
          công {modal.success}/{modal.total}</Title>
        {modal.errors.length > 0 && <Descriptions size="small" title="Các mục lỗi" bordered>
          {modal.errors.map(error => <Descriptions.Item span={3}
                                                        label={error.name}>{error.error.map(item => <>{item}<br/></>)}</Descriptions.Item>)}
        </Descriptions>}
      </Modal>
    </>
  )
}

const menu = (
  <Menu onClick={() => {
  }}>
    <Menu.Item key="1">1st menu item</Menu.Item>
    <Menu.Item key="2">2nd memu item</Menu.Item>
    <Menu.Item key="3">3rd menu item</Menu.Item>
  </Menu>
);
export default TruckVendorList
