import PlaceDetail from "./PlaceDetail";
import { useLocation } from "react-router-dom";

function EditPlace(props: any) {
  const location = useLocation();
  const { state } = location;

  return <PlaceDetail placeDetail={state} />;
}

export default EditPlace;
