import {notification} from 'antd';
export const errorNotification = (title : string,message : any = '') => {
    notification.error({
      message: title,
      description:message,
    });
  };
  export const successNotification = (title : string,message : any = '') => {
    notification.success({
      message: title,
      description:message,
    });
  };