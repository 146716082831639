import API from "../api";

export const getRouteOfVehicle = async (params: object) => {
    try {
        const response = await API.get('admin/vehicle/route', { params: params }).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getAllVehicleOrder = async (id: string) => {
    try {
        const response = await API.get(`admin/vehicle/${id}/route`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getAllVehicle = async () => {
    try {
        const response = await API.get(`admin/vehicle`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}
export const getAllVehicleWithoutPagination = async () => {
    try {
        const response = await API.get(`admin/vehicle/all`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getBotOfVehicle = async (id: any) => {
    try {
        const response = await API.get(`admin/vehicle/${id}/bot`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getUnassignBotOfVehicle = async (id: any) => {
    try {
        const response = await API.get(`admin/vehicle/${id}/bot/unassign`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const updateBotOfVehicle = async (id: any, data: any) => {
    try {
        const response = API.put(`admin/vehicle/${id}/bot`, { bots: data }).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const importExcel = async (params: FormData) => {
    try {
        const response = await API.post('admin/vehicle/import-interest', params).then(
            (res) => res.data.data
        );
        return response
    } catch (error) {
        throw error;
    }
}




