import React , {useEffect,useState}from 'react'
import { Space, Typography, Progress, Select } from 'antd'
import { CheckCircleFilled, CloseCircleFilled, DropboxOutlined } from '@ant-design/icons';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import API from '../../../shared/api/api';

const { Text, Title } = Typography
const { Option } = Select

const data = [
    {
        time: '22/02',
        number1: 20,
        number2: 25,
    },
    {
        time: '1/03',
        number1: 30,
        number2: 35,
    },
    {
        time: '8/03',
        number1: 35,
        number2: 40,
    },
    {
        time: '15/03',
        number1: 40,
        number2: 50,
    },
    {
        time: '22/03',
        number1: 45,
        number2: 50,
    },
    {
        time: '29/03',
        number1: 55,
        number2: 56,
    },
    {
        time: '05/04',
        number1: 60,
        number2: 70,
    },
];
function Dashboard() {
    const [dataDashboard , setDataDashboard] = useState<any>({})
    useEffect(() => {
        getDataDashboard()
    }, [])

    const getDataDashboard = async () => {
        const response = await API.get('admin/dashboard').then(res => res.data.data)
        setDataDashboard(response)
    }
    return (
        <div style={{ display: 'flex', padding: 6, width: '100%', backgroundColor: '#F0F2F5' }}>
            <Space size={24} direction="vertical" style={{ width: '40%', marginRight: 24 }}>
                <div style={{ padding: 24, backgroundColor: 'white', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ width: '50%', marginBottom: 12 }}>
                        <CheckCircleFilled style={{ color: '#03A9F4', fontSize: 24 }} />
                        <Title level={5}>Complete order</Title>
                        <Title style={{ marginTop: 0 }}>1022</Title>
                    </div>
                    <div style={{ width: '50%', marginBottom: 12 }}>
                        <img alt='icon' style={{ height: '18px', width: '26px', marginBottom: '8px' }} src='/icon/greenstruck.png' />
                        <Title level={5}>Shipping order</Title>
                        <Title style={{ marginTop: 0 }}>24</Title>
                    </div>
                    <div style={{ width: '50%' }}>
                        <img alt='icon' style={{ height: '22px', width: '24px', marginBottom: '3.5px' }} src='/icon/waitingpackage.png' />
                        <Title level={5}>Pending order</Title>
                        <Title style={{ marginTop: 0 }}>12</Title>
                    </div>
                    <div style={{ width: '50%' }}>
                        <CloseCircleFilled style={{ color: '#EF5350', fontSize: 24 }} />
                        <Title level={5}>Canceled order</Title>
                        <Title style={{ marginTop: 0 }}>109</Title>
                    </div>
                </div>
                <div style={{ padding: 12, backgroundColor: 'white', width: '100%' }}>
                    <Title style={{ fontSize: 18, textAlign: 'center', }}>Average completion rate</Title>
                    <Title style={{ fontSize: 14, textAlign: 'center', color: '#78909C', marginBottom: 30 }}>(Completed orders out of the total orders)</Title>
                    <div style={{ width: '100%', display: 'flex',justifyContent:'space-around' }}>
                        <Progress  strokeColor='#03A9F4' strokeWidth={10} type="circle" percent={Math.round(0.86 * 100)} />
                        {/* <div style={{ paddingLeft: 12 , width:'50%' }}>
                            <Title style={{ marginBottom: 0, marginTop: 0, fontSize: 14, color: '#78909C' }}><img alt='icon' style={{ height: '14px', width: '14px', marginBottom: '4px', marginRight: 4 }} src='/icon/greydot.png' />Đơn đã hoàn thành</Title>
                            <Title style={{ margin: '0 0 12px 18px', fontSize: 14 }}>{data2[0]}</Title>
                            <Title style={{ marginBottom: 0, marginTop: 0, fontSize: 14, color: '#78909C' }}><img alt='icon' style={{ height: '14px', width: '14px', marginBottom: '4px', marginRight: 4 }} src='/icon/bluedot.png' />Đơn đã ghép</Title>
                            <Title style={{ margin: '0 0 12px 18px', fontSize: 14 }}>{data2[1]}</Title>
                        </div> */}
                    </div>

                </div>
                <div style={{ padding: 12, backgroundColor: 'white', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ width: '50%', marginBottom: 12 }}>
                        <Title level={4}>Forwarder</Title>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div><Title style={{ fontSize: 36, marginRight: 4 }}>2</Title></div>
                            {/* <div>
                                <Title style={{ margin: '6px 0 0 0', fontSize: 14, color: '#009688' }}></Title>
                                <Title style={{ margin: 0, fontSize: 14 }}></Title>
                            </div> */}
                        </div>
                    </div>
                    <div style={{ width: '50%', marginBottom: 12 }}>
                        <Title level={4}>Truckvendor</Title>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div><Title style={{ fontSize: 36, marginRight: 4 }}>3</Title></div>
                            {/* <div>
                                <Title style={{ margin: '6px 0 0 0', fontSize: 14, color: '#009688' }}></Title>
                                <Title style={{ margin: 0, fontSize: 14 }}>hàng tháng</Title>
                            </div> */}
                        </div>
                    </div>
                    <div style={{ width: '50%', marginBottom: 12 }}>
                        <Title level={4}>Container</Title>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div><Title style={{ fontSize: 36, marginRight: 4 }}>61</Title></div>
                            {/* <div>
                                <Title style={{ margin: '6px 0 0 0', fontSize: 14, color: '#009688' }}></Title>
                                <Title style={{ margin: 0, fontSize: 14 }}>hàng tháng</Title>
                            </div> */}
                        </div>
                    </div>
                    <div style={{ width: '50%', marginBottom: 12 }}>
                        <Title level={4}>Driver</Title>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div><Title style={{ fontSize: 36, marginRight: 4 }}>65</Title></div>
                            {/* <div>
                                <Title style={{ margin: '6px 0 0 0', fontSize: 14, color: '#009688' }}></Title>
                                <Title style={{ margin: 0, fontSize: 14 }}>hàng tháng</Title>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Space>

            <Space size={24} direction="vertical" style={{ width: '58%' }}>
                <div style={{ padding: 12, backgroundColor: 'white', height: 400, width: '100%' }}>
                    <Title level={5}>Tổng số đơn</Title>
                    <Select bordered={false} defaultValue='monthly'>
                        <Option value="weekly">Hàng tuần</Option>
                        <Option value="monthly">Hàng tháng</Option>
                    </Select>
                    <ResponsiveContainer height='90%' width='100%'>
                        <AreaChart
                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 20
                            }}
                        >
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="number2" stroke="rgba(255,193,7, 0.4)" fill="rgba(255,193,7, 0.4)" />
                            <Area type="monotone" dataKey="number1" stroke="rgba(3, 169, 244, 0.4)" fill="rgba(3, 169, 244, 0.4)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

                <div style={{ padding: 12, backgroundColor: 'white', height: 400, width: '100%' }}>
                    <Title level={5}>Tổng số đơn</Title>
                    <Select bordered={false} defaultValue='monthly'>
                        <Option value="weekly">Hàng tuần</Option>
                        <Option value="monthly">Hàng tháng</Option>
                    </Select>
                    <ResponsiveContainer height='90%' width='100%'>
                        <AreaChart
                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 20
                            }}
                        >
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="number1" stroke="rgba(3, 169, 244, 0.4)" fill="rgba(3, 169, 244, 0.4)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

                <div style={{ padding: 12, backgroundColor: 'white', height: 400, width: '100%' }}>
                    <Title level={5}>Tổng số đơn</Title>
                    <Select bordered={false} defaultValue='monthly'>
                        <Option value="weekly">Hàng tuần</Option>
                        <Option value="monthly">Hàng tháng</Option>
                    </Select>
                    <ResponsiveContainer height='90%' width='100%'>
                        <AreaChart
                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 20
                            }}
                        >
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="number2" stroke="rgba(255,193,7, 0.4)" fill="rgba(255,193,7, 0.4)" />
                            <Area type="monotone" dataKey="number1" stroke="rgba(3, 169, 244, 0.4)" fill="rgba(3, 169, 244, 0.4)" />                        </AreaChart>
                    </ResponsiveContainer>
                </div>

                <div style={{ padding: 12, backgroundColor: 'white', height: 400, width: '100%' }}>
                    <Title level={5}>Tổng số đơn</Title>
                    <Select bordered={false} defaultValue='monthly'>
                        <Option value="weekly">Hàng tuần</Option>
                        <Option value="monthly">Hàng tháng</Option>
                    </Select>
                    <ResponsiveContainer height='90%' width='100%'>
                        <AreaChart
                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 20
                            }}
                        >
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="number1" stroke="rgba(3, 169, 244, 0.4)" fill="rgba(3, 169, 244, 0.4)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

            </Space>
        </div>
    )
}

export default Dashboard
