import React from 'react';
import './App.css';
import Login from './Modules/Login/Login'
import Main from './Components/Main'
import Policy from './policy'
import Home from './Home'
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import {checkTokenExpired} from './shared/authoration';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/login'>
          <Login />
        </Route>
        <Route exact path='/policy'>
          <Policy />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <PrivateRoute
          path='/'
          isAuthenticated={checkTokenExpired()}
          component={Main}
        />
      </Switch>
    </Router>
  );
}
const PrivateRoute = ({component, isAuthenticated, ...rest}: any) => {
  const routeComponent = (props: any) => (
      (isAuthenticated)
          ? React.createElement(component, props)
          : <Redirect to={{pathname: '/login'}}/>
  );
  return <Route {...rest} render={routeComponent}/>;
};
export default App;
