import API from "../api";

export const getListArea = async (params : {params : {province :string}}) => {
    try {
        const response = API.get(`admin/freight/area`, params).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const createDirection = async (params : any) => {
    const {id,data} = params
    try {
        const response = API.post(`admin/freight/${id}/direction`, data).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getBotbyFreight = async (id : any) => {
    try {
        const response = API.get(`admin/freight/${id}/bot`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const getUnAssignBotbyFreight = async (id : any) => {
    try {
        const response = API.get(`admin/freight/${id}/bot/unassign`).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const updateBotOfFreight = async (params : any) => {
    const {id,data} = params
    try {
        const response = API.put(`admin/freight/${id}/bot`,{bots:data}).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}