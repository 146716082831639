import{message } from 'antd';
import { urlApi } from './api/api';

export const getBase64 = (img :any, callback : any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
export const beforeUpload = (file : any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Tệp tải lên không đúng định dạng!');
    }
    const isLt1M = file.size / 1024 / 1024 < 0.18;
    if (!isLt1M) {
      message.error('Tệp tải lên phải nhỏ hơn 1MB!');
    }
    return isJpgOrPng && isLt1M;
  }
export const onPreview = async (file: any) => {
  let src = file.url;
  if (!src) {
      src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
      });
  }
  const image: HTMLImageElement = new window.Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};
export const propsImage = {
  action: `${urlApi}upload`,
  onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
          console.log(info.file.response.data);
      }
      if (status === 'done') {
          message.success(`Tải thành công ${info.file.name} `);
      } else if (status === 'error') {
          message.error(`Tải thất bại ${info.file.name}`);
      }
  },
};