import React, {useEffect, useState} from "react";
import {DirectionsRenderer, GoogleMap, InfoWindow, LoadScript, Marker, Polyline,} from "@react-google-maps/api";
import {GOOGLE_MAP_KEY} from "../../../shared/key";
import {Button, Col, DatePicker, Input, Row, Select, Space, Steps, Table, Tooltip, Typography,} from "antd";
import {InfoCircleOutlined, MessageOutlined, ReloadOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {dateFormat, dateFormatYMDHms} from "../../../shared/dataFormat";
import {ORDER_STATUS_OPTIONS, PRESERVATION_OPTIONS, ROUTE_STATUS_OPTIONS,} from "../../../shared/define";
import MessageModal from "../MessageModal/MessageModal";
import {getAllTrackingOrder, getLocationOrder,} from "../../../shared/api/Tracking/Order";
import {getAllForwarder} from "../../../shared/api/Forwarder";
import {getListProvince} from "../../../shared/api/Province";

const {Title, Text} = Typography;
const {Search} = Input;
const {Option} = Select;
const {RangePicker} = DatePicker;

const containerStyle = {
  width: "100%",
  height: "100vh",
};
const { Step } = Steps;
function TrackingOrder() {
  const [orderList, setOrderList] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [rowId, setRowId] = useState("");
  const [forwarderList, setForwarderList] = useState([]);
  const [response, setResponse] = useState<any>(null);
  const [receiptAreaList, setReceiptAreaList] = useState<any>([]);
  const [deliveryAreaList, setDeliveryAreaList] = useState<any>([]);
  const [currentPosition, setCurrentPosition] = useState<any>({});
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [order, setOrder] = useState<any>({});
  const [center, setCenter] = useState({
    lat: 21.028511,
    lng: 105.804817,
  });
  const [path, setPath] = useState<any>([]);
  const [provinceList, setProvinceList] = useState<any>([]);
  const [filter, setFilter] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [start, setStart] = useState<any>(undefined);
  const [des, setDes] = useState<any>(undefined);
  const [step, setStep] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  console.log("response", response);

  useEffect(() => {
    loadlist();
  }, [filter, pagination.current]);

  useEffect(() => {
    getForwarderList();
    getProvince();
  }, []);

  const isEmty = (key: any) => {
    return Object.keys(key).length === 0 && key.constructor === Object;
  };

  const loadlist = async () => {
    setIsLoading(true);
    const response: any = await getAllTrackingOrder({
      ...filter,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    if (response.success) {
      const listData = response.data;
      listData.map((item: any, index: any) => {
        item.key = index + 1;
        item.location = {
          receipt: item.receipt?.[0]?.area?.province,
          delivery: item.delivery?.[0]?.area?.province,
        };
      });
      setOrderList(listData);
      clickRowHandle(listData[0]);
      setPagination({ ...pagination, total: response.totalItem });
    }
    setIsLoading(false);
  };

  const getForwarderList = async () => {
    const response: any = await getAllForwarder();
    setForwarderList(response.data);
  };

  const getProvince = async () => {
    const response: any = await getListProvince();
    setProvinceList(response.results);
  };
  const findStep = (step: string) => {
    const index = ORDER_STATUS_OPTIONS.findIndex(
      (element: any) => (element as any).value === step
    );
    return index;
  };
  const clickRowHandle = async (record: any) => {
    console.log("record", record);
    if (!record) return;
    setOrder(record);
    setRowId(record._id);
    setStep(findStep(record.status));
    const receiptLocation = record.receipt?.[0].place?.location;
    const deliveryLocation = record.delivery?.[0].place?.location;
    setStart(receiptLocation);
    setDes(deliveryLocation);

    await loadDir(
      record._id,
      receiptLocation,
      deliveryLocation,
      record.freight
    );
    let receiptAreaList = [];
    let deliveryAreaList = [];
    for (let i = 0; i < record?.receipt?.length; i++) {
      receiptAreaList.push(record.receipt?.[i].place?.location);
    }
    for (let i = 0; i < record?.delivery?.length; i++) {
      deliveryAreaList.push(record.delivery?.[i].place?.location);
    }
    setReceiptAreaList(receiptAreaList);
    setDeliveryAreaList(deliveryAreaList);
  };
  // console.log(receiptAreaList)
  // console.log(deliveryAreaList)
  const onClickRow = (record: any, index: any) => {
    return {
      onClick: () => {
        clickRowHandle(record);
        // setExpanded([index + 1])
      },
    };
  };
  const setRowClassName = (record: any) => {
    return record._id === rowId ? "clickRowStyl" : "";
  };
  const getTime = (route: any, status: any) => {
    switch (status) {
      case 'ASSIGNING':
        return dateFormatYMDHms(route?.assignDriverAt);
      case 'PENDING':
        return dateFormatYMDHms(route?.createdAt);
      case 'START_PICK_UP_GOODS':
        return dateFormatYMDHms(route?.startPickUpAt);
      case 'TAKEN_GOODS':
        return dateFormatYMDHms(route?.takenAt);
      case 'DROPPING_GOODS':
        return dateFormatYMDHms(route?.droppingAt);
      case 'FINISH':
        return dateFormatYMDHms(route?.finishAt);
      default:
        return 'N/A';
    }
  };
  const columns: Array<object> = [
    {
      title: "STT",
      dataIndex: "key",
      width: 50,
    },
    {
      title: "Xe",
      dataIndex: "routes",
      render: (routes: any) =>
        routes[0]?.vehicle?.licensePlate
          ? routes[0]?.vehicle?.licensePlate
          : "Chưa có",
    },

    {
      title: "Điểm trả hàng",
      dataIndex: "location",
      render: (location: any, record: any, routes: any) => (
        <>
          {record.receipt?.[0]?.place?.name} <br /> -&gt;{" "}
          {record.delivery?.[0]?.place?.name} <br />
          {ROUTE_STATUS_OPTIONS.find((element: any) => (element as any).value === record.routes[0]?.status)?.label ?? "N/A"}
          {" "} lúc: {getTime(record.routes[0], record.routes[0]?.status)}
        </>
      ),
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      align: "center",
      width: 50,
      render: () => (
        <>
          {/* <Button type="text"><EyeOutlined style={{ fontSize: 16, color: '#0082fb' }} /></Button> */}
          <Tooltip title="Gửi thông báo">
            <Button type="text">
              <MessageOutlined
                onClick={showModal}
                style={{ fontSize: 16, color: "#0082fb" }}
              />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  const options = {
    strokeColor: "#52C41A",
    strokeOpacity: 0.8,
    strokeWeight: 5,
    fillColor: "#52C41A",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 99,
  };
  const setCurrentRoute = (data: any) => {
    data?.data?.currentLocation?.position
      ? setCurrentPosition(data?.data?.currentLocation?.position)
      : setCurrentPosition({});
    const path = data?.data?.locations?.map((item: any) => ({
      ...item.position,
    }));
    setPath(path);
  };
  const loadDir = async (
    orderId: string,
    origin: any,
    destination: any,
    freightId: any
  ) => {
    const response: any = await getLocationOrder(orderId);
    if (response.success) setCurrentRoute(response);
    // if (response.data.direction) setResponse(response.data.direction)
    // else {
    const directionService = new google.maps.DirectionsService();
    directionService.route(
      {
        destination: new google.maps.LatLng(destination),
        origin: new google.maps.LatLng(origin),
        travelMode: google.maps.TravelMode.DRIVING,
        avoidHighways: true
      },
      async (response, status) => {
        setResponse(response);
        // if (status === google.maps.DirectionsStatus.OK) {
        //     const res: any = await createDirection({ id: freightId, data: response })
        //     setResponse(response)
        // }
      }
    );
    // }

    // setPath(response.data.locations.map((item: any) => item.position))
  };
  function handleMarkerOpen() {
    setIsHidden(!isHidden);
  }
  return (
    <StyledContainer>
      <MessageModal
        title="GỬI THÔNG BÁO CHO TÀI XẾ"
        id={rowId}
        visible={isModalVisible}
        setVisible={(e: boolean) => setIsModalVisible(e)}
      />
      <Title level={4}>
        Đơn đang vận chuyển ({pagination.total}){" "}
        <Tooltip title="Danh sách các đơn hàng đang được vận chuyển. Thông tin điểm nhận, trả. Lộ trình gợi ý và lộ trình thực tế của Đơn hàng.">
          <InfoCircleOutlined style={{ color: "#0082fb" }} />
        </Tooltip>
      </Title>
      <Row justify="space-between" gutter={6}>
        <Col>
          <Space size={6}>
            <Select
              size="small"
              showSearch
              // defaultValue="ALL"
              style={{ width: 220 }}
              placeholder="Công ty"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(value) => setFilter({ ...filter, forwarder: value })}
            >
              {/* <Option value="ALL">Tất cả</Option> */}
              {forwarderList?.map((forwarder: any) => (
                <Option key={forwarder._id} value={forwarder._id}>
                  {forwarder.shortName}
                </Option>
              ))}
            </Select>
            <Select
              size="small"
              showSearch
              placeholder="Điểm nhận"
              style={{ width: 120 }}
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(value) => setFilter({ ...filter, province: value })}
            >
              {/* <Option key='ALL' value='ALL'>Tất cả</Option> */}
              {provinceList?.map((city: any) => (
                <Option key={city.province_id} value={city.province_name}>
                  {city.province_name}
                </Option>
              ))}
            </Select>
            <RangePicker
              size="small"
              style={{ width: 240 }}
              onChange={(date, dateString) =>
                setFilter({
                  ...filter,
                  range: dateString[0] && dateString[1] && dateString,
                })
              }
            />
          </Space>
        </Col>
        <Space size={6}>
          <Button
            size="small"
            onClick={loadlist}
            icon={<ReloadOutlined />}
          ></Button>
          <Search
            size="small"
            disabled
            placeholder="Search"
            allowClear
            style={{ width: 150, margin: "0 10px" }}
            onSearch={(value) => setFilter({ ...filter, text: value })}
          />
        </Space>
      </Row>
      <Row gutter={10} style={{ margin: "15px 0px" }}>
        <Col>
          <img
            style={{ width: 25 }}
            src="/icon/currentLocation.png"
            alt="Vị trí hiện tại"
          />{" "}
          Vị trí hiện tại
        </Col>
        <Col>
          <Space style={{ marginTop: 4 }}>
            <div
              style={{
                borderRadius: 5,
                width: 50,
                height: 10,
                backgroundColor: "#73b8fa",
              }}
            ></div>{" "}
            Lộ trình gợi ý
          </Space>
        </Col>
        <Col>
          <Space style={{ marginTop: 4 }}>
            <div
              style={{
                borderRadius: 5,
                width: 50,
                height: 10,
                backgroundColor: "#76c845",
              }}
            ></div>{" "}
            Lộ trình thực tế
          </Space>
        </Col>
        <Col>
          <Space style={{ marginTop: 4 }}>
            <img
              style={{ width: 20 }}
              src="/icon/receipt.png"
              alt="Điểm đóng"
            />{" "}
            Điểm đóng
          </Space>
        </Col>
        <Col>
          <Space style={{ marginTop: 4 }}>
            <img
              style={{ width: 20 }}
              src="/icon/delivery.png"
              alt="Điểm nhận"
            />{" "}
            Điểm nhận
          </Space>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <Row gutter={[4, 0]} style={{ width: "100vw" }}>
          <Col xs={24} sm={16}>
            <Row>
              <Col
                xs={24}
                style={{ height: "100vh", border: "1px solid greyMa" }}
              >
                <Col xs={24}>
                  <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      zoom={6}
                      center={center}
                    >
                      {path && <Polyline path={path} options={options} />}
                      {!isEmty(currentPosition) && (
                        <MarkerWithInfoWindow
                          showModal={showModal}
                          order={order}
                          currentPosition={currentPosition}
                        />
                      )}
                      {start && (
                        <Marker
                          position={{
                            lat: parseFloat(start?.lat),
                            lng: parseFloat(start?.lng),
                          }}
                          icon="/icon/receipt.png"
                        />
                      )}
                      {des && (
                        <Marker
                          position={{
                            lat: parseFloat(des?.lat),
                            lng: parseFloat(des?.lng),
                          }}
                          icon="/icon/delivery.png"
                        />
                      )}
                      {receiptAreaList.map((marker: any, index: any) => (
                        <Marker
                          key={index}
                          position={{
                            lat: parseFloat(marker?.lat),
                            lng: parseFloat(marker?.lng),
                          }}
                          icon="/icon/receiptOther.png"
                        />
                      ))}
                      {deliveryAreaList.map((marker: any, index: any) => (
                        <Marker
                          key={index}
                          position={{
                            lat: parseFloat(marker?.lat),
                            lng: parseFloat(marker?.lng),
                          }}
                          icon="/icon/deliveryOther.png"
                        />
                      ))}

                      {response !== null && (
                        <DirectionsRenderer
                          options={{
                            directions: response,
                            markerOptions: {
                              icon: "/icon/receipt.png",
                            },
                            suppressMarkers: true,
                          }}
                        />
                      )}
                    </GoogleMap>
                  </LoadScript>
                </Col>
                {/* } */}
              </Col>
              <Col xs={24} style={{ paddingTop: "20px" }}>
                <Steps size="default" current={step}>
                  {ORDER_STATUS_OPTIONS.map((item: any, index: any) => (
                    <Step key={index} title={item.label} />
                  ))}
                </Steps>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={8}>
            <Table
              scroll={{ y: "100vh", x: true }}
              loading={isloading}
              dataSource={orderList}
              columns={columns}
              rowKey="_id"
              onRow={onClickRow}
              rowClassName={setRowClassName}
              pagination={pagination.total > pagination.pageSize && pagination}
              onChange={(value: any) =>
                setPagination({ ...pagination, current: value.current })
              }
            />
          </Col>
        </Row>
      </Row>
    </StyledContainer>
  );
}
const StyledContainer = styled.div`
  /* height:'100vh'; */
  .clickRowStyl {
    background-color: #e6f7ff;
  }
  .ant-table-tbody > .clickRowStyl:hover > td {
    background-color: #e6f7ff;
  }

  .ant-table-cell .ant-table-row-expand-icon-cell {
    width: 0px;
  }
`;
function MarkerWithInfoWindow(props: any) {
  const { currentPosition, order, showModal } = props;
  const [open, setOpen] = useState(false);

  function handleMarkerOpen() {
    setOpen(!open);
  }

  return (
    <Marker
      onClick={handleMarkerOpen}
      position={currentPosition}
      icon={"/icon/currentLocation.png"}
    >
      {open && (
        <InfoWindow options={{ disableAutoPan: true }}>
          <Space
            direction="vertical"
            style={{
              fontSize: 10,
              fontWeight: "bolder",
              width: 230,
              padding: 0,
              borderRadius: 10,
              backgroundColor: "white",
              position: "relative",
            }}
          >
            {/* <CloseCircleOutlined style={{ color: 'red', fontSize: 16, position: 'absolute', top: '10px', right: '10px' }} onClick={handleMarkerOpen} /> */}
            <Title level={5}>Mã đơn: {order?.code}</Title>
            <Text>Tên khách hàng: {order?.customer?.name}</Text>
            <Text>Tên hàng: {order?.goods?.name}</Text>
            <Text>Tên tài xế: {order?.currentRoute?.driver?.name}</Text>
            <Text>
              SĐT tài xế: {order?.currentRoute?.driver?.owner?.phoneNumber}
            </Text>
            <Text>
              Đặc tính:{" "}
              {
                PRESERVATION_OPTIONS.find(
                  (element: any) =>
                    (element as any).value == order?.goods?.preservation
                )?.label
              }
            </Text>
            <Text>Trọng lượng : {order?.goods?.volume} tấn</Text>
            {order?.receipt &&
              order?.receipt.map((receipt: any, index: any) => (
                <Text key={index}>
                  {" "}
                  Điểm nhận {order?.receipt.length > 1 ? index + 1 : ""}: Ngày{" "}
                  {dateFormat(receipt.time)} tại {receipt.text}{" "}
                  {receipt.area.area} {receipt.area.rovince}
                </Text>
              ))}
            {order?.receipt &&
              order?.delivery.map((delivery: any, index: any) => (
                <Text key={index}>
                  {" "}
                  Điểm nhận {order?.receipt.length > 1 ? index + 1 : ""}: Ngày{" "}
                  {dateFormat(delivery.time)} tại {delivery.text}{" "}
                  {delivery.area.area} {delivery.area.rovince}
                </Text>
              ))}
            <Text>Ngày tạo : {dateFormat(order?.createdAt)}</Text>
            <Text>Tọa độ: {currentPosition.lat}, {currentPosition.lng}</Text>
            <Row
              gutter={6}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              <Col span={12}>
                <Button style={{ width: "100%" }}>Chi tiết</Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={showModal}
                  type="primary"
                  style={{ width: "100%" }}
                >
                  Gửi thông báo
                </Button>
              </Col>
            </Row>
          </Space>
        </InfoWindow>
      )}
    </Marker>
  );
}
export default TrackingOrder;
