import React, { useState, useEffect } from 'react'
import { Drawer, Col, Row, Typography, Divider, Space, Input, Button, Spin } from 'antd';
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { getBotbyFreight, getUnAssignBotbyFreight, updateBotOfFreight } from '../../../shared/api/Freight';

const { Title } = Typography
const { Search } = Input
function AssignBot(props: any) {
    const { id, route, visible, loadlist } = props
    const [isLoading, setIsLoading] = useState(false)
    const [assignBot, setAssignBot] = useState<any>([])
    const [unassignBot, setUnassignBot] = useState<any>([])
    useEffect(() => {
        getBotList()
    }, [id])
    const onClose = () => {
        visible.setDrawerVisible(false)
    };
    const getBotList = async () => {
        if(id){
            setIsLoading(true)
            const response1: any = await getBotbyFreight(id)
            const response2: any = await getUnAssignBotbyFreight(id)
            setAssignBot(response1.data)
            setUnassignBot(response2.data)
            if (response1 && response2) setIsLoading(false)
        }
    }
    const addBot = (index: any) => {
        const assign = [...assignBot]
        const unAssign = [...unassignBot]
        assign.push(unAssign[index])
        unAssign.splice(index, 1)
        setAssignBot(assign)
        setUnassignBot(unAssign)
    }
    const deleteBot = (index: any) => {
        const assign = [...assignBot]
        const unAssign = [...unassignBot]
        unAssign.push(assign[index])
        assign.splice(index, 1)
        setAssignBot(assign)
        setUnassignBot(unAssign)
    }
    const onUpdate = async () => {
        const response: any = await updateBotOfFreight({ id: id, data: assignBot })
        if (response.success) {
            loadlist()
            onClose()
        }
    }
    return (
        <Drawer
            width={520}
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible.drawerVisible}
            title={<Row>
            <Col span={12}>Đã thêm</Col>
                    <Col span={12}><Space style={{ display: 'flex', justifyContent: 'space-between' }}><CloseOutlined onClick={() => onClose()} style={{ position: 'relative', top: -4 }} /></Space></Col>
                    </Row>}
        >
            <Spin spinning={isLoading}>
                <Row gutter={[8, 0]}>
                    <Col span={12}><Title level={5}>Thêm BOT</Title></Col>
                    <Col span={12}><Space style={{ display: 'flex', justifyContent: 'space-between' }}><Title level={5}>Tuyến đường {route}</Title><CloseOutlined onClick={() => onClose()} style={{ position: 'relative', top: -16 }} /></Space></Col>
                    <Divider />
                    <Col span={12}>
                        <Row gutter={[8, 0]}>
                            {/* <Col span={12}><Search></Search></Col>
                            <Col span={12}><Input></Input></Col> */}

                            <Col span={24} style={{ marginTop: 0 }}>
                                {
                                    unassignBot?.map((item: any, index: any) =>
                                        <div style={{ border: "1px solid #CFD8DC", borderRadius: 5, padding: '3px 12px', marginTop: 4, display: 'flex', justifyContent: 'space-between' }}>{item.name}<PlusOutlined onClick={() => addBot(index)} style={{ position: 'relative', top: 4 }} /></div>
                                    )
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} >
                        <Row>
                            <Col span={24} style={{ marginTop: 0 }}>
                                <Col span={24} >
                                    {
                                        assignBot.map((item: any, index: any) =>
                                            <div style={{ border: "1px solid #CFD8DC", borderRadius: 5, padding: '3px 12px', marginTop: 4, display: 'flex', justifyContent: 'space-between' }}>{item.name}<MinusOutlined onClick={() => deleteBot(index)} style={{ position: 'relative', top: 4 }} /></div>
                                        )
                                    }
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
            <Button style={{ position: 'absolute', bottom: 18, left: 24, width: 232 }} type='primary' onClick={() => onUpdate()}>Lưu</Button>
        </Drawer>
    )
}

export default AssignBot
