import React from "react";
import {
  Layout,
  Space,
  Typography,
  Select,
  Carousel,
  Button,
  Row,
  Col,
  Divider,
  Table,
  Card,
  Avatar,
  Collapse,
  Rate,
  Input,
  Menu,
  Dropdown,
} from "antd";
import { MenuOutlined } from "@ant-design/icons";
// import './Home.css';
import "antd/dist/reset.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { checkTokenExpired } from "./shared/authoration";

const { Header, Content, Footer } = Layout;
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { Meta } = Card;
const { Panel } = Collapse;
const { Search } = Input;

function changeLang(value: any) {
  console.log(`selected ${value}`);
}

export default function Home() {
  let carousel: any = React.createRef();
  function changeSlide(i: any) {
    carousel.goTo(i, false);
  }
  // function borderOn(e:any) {
  //     // console.log(e.target.style.borderColor);
  //     e.target.style.borderColor='#E3F5FE';
  // }
  // function borderOff(e:any) {
  //     e.target.style.borderColor='white';
  // }

  const menu = (
    <Menu>
      <Menu.Item>
        <a href="" className="black" style={{ fontWeight: 600 }}>
          Trang chủ
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="" className="black" style={{ fontWeight: 600 }}>
          Giới thiệu
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="" className="black" style={{ fontWeight: 600 }}>
          Tin tức
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="" className="black" style={{ fontWeight: 600 }}>
          Liên lạc
        </a>
      </Menu.Item>
      <Menu.Item>
        {checkTokenExpired() ? (
          <Link to={{ pathname: `/order/pending` }}>
            <Button style={{ border: 0 }} type="primary">
              Bảng điều khiển
            </Button>
          </Link>
        ) : (
          <Link to={{ pathname: `/login` }}>
            <Button type="primary" style={{ border: "none" }}>
              Đăng Nhập
            </Button>
          </Link>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <StyleContainer>
      <Layout style={{ background: "white" }}>
        <Header
          style={{
            alignItems: "center",
            padding: "0% 12.5%",
            height: "64px",
            background: "white",
          }}
        >
          <Row justify="space-between" className="menu-full">
            <Col>
              <Space size={36}>
                <img
                  alt="logo"
                  style={{ width: "168px" }}
                  src="./icon/logo-black.png"
                />
                <a href="" className="black" style={{ fontWeight: 600 }}>
                  Trang chủ
                </a>
                <a href="" className="black" style={{ fontWeight: 600 }}>
                  Giới thiệu
                </a>
                <a href="" className="black" style={{ fontWeight: 600 }}>
                  Tin tức
                </a>
                <a href="" className="black" style={{ fontWeight: 600 }}>
                  Liên lạc
                </a>
              </Space>
            </Col>
            <Col>
              <Space align="center" size={36}>
                {checkTokenExpired() ? (
                  <Link to={{ pathname: `/order/pending` }}>
                    <Button style={{ border: 0 }} type="primary">
                      Bảng điều khiển
                    </Button>
                  </Link>
                ) : (
                  <Link to={{ pathname: `/login` }}>
                    <Button type="primary" style={{ border: "none" }}>
                      Đăng Nhập
                    </Button>
                  </Link>
                )}
                {/* <Select bordered={false} defaultValue='vnm' onChange={changeLang}>
                                <Option value='eng'>
                                    <Space align='center'>
                                        <img alt='usa' style={{width:'22px', height:'12px', borderRadius:'2px'}} src='/icon/usa.png' />
                                        <Text strong style={{width:'36px', textAlign:'right'}}>ENG</Text>
                                    </Space>
                                </Option>
                                <Option value='vnm'>
                                    <Space align='center'>
                                        <img alt='usa' style={{width:'22px', height:'12px', borderRadius:'2px'}} src='/icon/vietnam.png' />
                                        <Text strong style={{width:'36px', textAlign:'right'}}>VNM</Text>
                                    </Space>
                                </Option>
                            </Select> */}
              </Space>
            </Col>
          </Row>
          <Row justify="space-between" className="menu-dropdown">
            <Col>
              <img
                alt="logo"
                style={{ width: "168px" }}
                src="./icon/logo-black.png"
              />
            </Col>
            <Col>
              <Dropdown overlay={menu}>
                <MenuOutlined />
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content>
          <Space direction="vertical" size={72}>
            <div className="container">
              <video
                width="100%"
                height="600px"
                className="video-container"
                autoPlay
                muted
                loop
              >
                <source src="/truck.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="home-container">
              {/* <Carousel autoplay dotPosition='right' style={{left:'0px', right:'0px'}}>
                            <div style={{
                                    background: "url('/white-truck-right.jpg') no-repeat center",
                                    backgroundSize:'100% 100%'}}>
                            </div>
                            <div style={{
                                    background: "url('/from-above.jpg') no-repeat center",
                                    backgroundSize:'100% 100%'}}>
                            </div>
                            <div style={{
                                    background: "url('/trucks-unloading.jpg') no-repeat center",
                                    backgroundSize:'100% 100%'}}>
                            </div>
                        </Carousel> */}
              <Space direction="vertical" size={24} className="home-box">
                <Space size={6}>
                  <img src="/icon/head-line-white.png" />
                  <Text strong className="white">
                    Trang chủ
                  </Text>
                </Space>
                <h1 className="white">
                  Cuộc<span className="blue"> cách mạng</span> ngành công nghiệp
                  vận tải<span className="blue">.</span>
                </h1>
                <Paragraph className="white">
                  Nền tảng công nghệ AloTruck là đòn bẩy cho doanh nghiệp
                  logistics và sẽ thay đổi toàn bộ viễn cảnh của dịch vụ kho bãi
                  và phân phối hàng hóa trên toàn thế giới.
                </Paragraph>
                <Row justify="space-between" gutter={[0, 16]}>
                  <Col xs={24} md={11} xl={11}>
                    <Button
                      icon={
                        <img
                          src="/icon/down.svg"
                          style={{ paddingRight: "4px" }}
                        />
                      }
                      block
                      style={{
                        background: "white",
                        fontWeight: "bold",
                        height: "56px",
                        width: "216px",
                      }}
                    >
                      <a href="#explore-more" className="black">
                        Tìm hiểu thêm
                      </a>
                    </Button>
                  </Col>
                  <Col xs={24} md={11} xl={11}>
                    <Button
                      block
                      style={{
                        background: "transparent",
                        fontWeight: "bold",
                        color: "white",
                        borderWidth: "2px",
                        borderColor: "white",
                        height: "56px",
                        width: "216px",
                      }}
                    >
                      <a href="#app">Tải ứng dụng tài xế</a>
                    </Button>
                  </Col>
                </Row>
              </Space>
            </div>
            <Space
              direction="vertical"
              size={152}
              style={{ padding: "0% 12.5% 0%", width: "100%" }}
            >
              <Space direction="vertical" size={24}>
                <section id="explore-more" />
                <Space size={6}>
                  <img src="/icon/head-line.png" />
                  <Text strong>Giới thiệu</Text>
                </Space>
                <Row justify="space-around">
                  <Col md={24} xl={7}>
                    <h2>
                      Tại sao <span className="blue">chọn</span> AloTruck
                      <span className="blue">?</span>
                    </h2>
                  </Col>
                  <Col md={24} xl={{ span: 16, offset: 1 }}>
                    <Paragraph>
                      AloTruck là giải pháp công nghệ hàng đầu cho các công ty
                      vận tải và chủ nhà xe, bắt kịp xu hướng công nghệ 4.0,
                      công nghệ IoT trong ngành Logistic, cải thiện tỷ suất lợi
                      nhuận, tối ưu hóa về mặt chi phí và thời gian
                    </Paragraph>
                  </Col>
                </Row>
                <Row align="middle" justify="space-around">
                  <Col md={24} xl={7}>
                    <Row align="middle" gutter={[0, 12]}>
                      <Col md={12} xl={24}>
                        <Space
                          size={36}
                          split={<img src="/icon/line-vertical.png" />}
                        >
                          <Space direction="vertical" size={0}>
                            <h2
                              className="blue"
                              style={{ marginBottom: "0px" }}
                            >
                              50+
                            </h2>
                            <Title level={5} style={{ fontWeight: 600 }}>
                              Công ty vận chuyển
                            </Title>
                          </Space>
                          <Space direction="vertical" size={0}>
                            <h2
                              className="blue"
                              style={{ marginBottom: "0px" }}
                            >
                              200+
                            </h2>
                            <Title level={5} style={{ fontWeight: 600 }}>
                              Xe tải
                            </Title>
                          </Space>
                        </Space>
                      </Col>
                      <Col md={12} xl={24}>
                        <Paragraph>
                          AloTruck có mạng lưới rộng lớn đối tác là các chủ nhà
                          xe và công ty vận tải uy tín trên khắp cả nước
                        </Paragraph>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24} xl={{ span: 16, offset: 1 }}>
                    <Table
                      pagination={false}
                      columns={columns}
                      dataSource={data}
                    ></Table>
                  </Col>
                </Row>
              </Space>
              <Row justify="space-between" gutter={[0, 12]}>
                <Col md={24} xl={7}>
                  <Row align="middle" gutter={[0, 0]}>
                    <Col span={24}>
                      <Space size={6}>
                        <img src="./icon/head-line.png" />
                        <Text strong>Cách thức hoạt động</Text>
                      </Space>
                    </Col>
                    <Col md={8} xl={24}>
                      <Card className="card" onClick={() => changeSlide(0)}>
                        <Meta
                          avatar={<Avatar src="/icon/icon-wrapper-1.png" />}
                          title={<Title level={5}>Nhận đơn hàng</Title>}
                          description="Tài xế nhận đơn hàng từ phía công ty vận tải."
                        />
                      </Card>
                    </Col>
                    <Col md={8} xl={24}>
                      <Card className="card" onClick={() => changeSlide(1)}>
                        <Meta
                          avatar={<Avatar src="/icon/icon-wrapper-3.png" />}
                          title={
                            <Title level={5}>nhận hàng và vận chuyển</Title>
                          }
                          description="Tài xế đến điểm lấy hàng, nhận hàng và bắt đầu di chuyển đến điểm nhận hàng."
                        />
                      </Card>
                    </Col>
                    <Col md={8} xl={24}>
                      <Card className="card" onClick={() => changeSlide(2)}>
                        <Meta
                          avatar={<Avatar src="/icon/icon-wrapper-5.png" />}
                          title={<Title level={5}>trả hàng</Title>}
                          description="Tài xế trả hàng khi đến địa điểm nhận hàng và kết thúc đơn hàng."
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md={24} xl={16} className="carousel-small">
                  <Carousel
                    ref={(node: any) => (carousel = node)}
                    dotPosition="right"
                    style={{
                      height: "100%",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <div className="img-container">
                      <img className="carousel-img" src="/ship.jpg" />
                    </div>
                    <div className="img-container">
                      <img className="carousel-img" src="/yellow-truck.jpg" />
                    </div>
                    <div className="img-container">
                      <img className="carousel-img" src="/boxing.jpg" />
                    </div>
                    {/* <div style={{background:'red'}}></div>
                                    <div style={{background:'red'}}>5</div>
                                    <div style={{background:'red'}}>6</div> */}
                    {/* <div style={{backgroundImage:"url('/ship.jpg')", backgroundSize:'cover', height:'100%', width:'100%'}}></div>
                                    <div style={{backgroundImage:"url('/yellow-truck.jpg')", backgroundSize:'cover', height:'100%', width:'100%'}}></div>
                                    <div style={{backgroundImage:"url('/boxing.jpg')", backgroundSize:'cover', height:'100%', width:'100%'}}></div> */}
                  </Carousel>
                </Col>
              </Row>
              <Space direction="vertical" size={48}>
                <Row justify="space-around">
                  <Col sm={24} md={11} xl={7}>
                    <Space direction="vertical" size={24}>
                      <Space size={6}>
                        <img src="./icon/head-line.png" />
                        <Text strong>Đặc quyền</Text>
                      </Space>
                      <h2>
                        Làm <span className="blue">nhiều hơn</span> với AloTruck
                        <span className="blue">.</span>
                      </h2>
                      <Paragraph>
                        Nền tảng của AloTruck giúp cho việc quản lý các đơn hàng
                        dễ dàng, tiện lợi, khoa học đồng thời sử dụng thuật toán
                        để tối ưu hóa quá trình kết nối giữa công ty vận tải và
                        các chủ nhà xe, tiết kiệm thời gian, tiết kiệm nhân sự.
                      </Paragraph>
                    </Space>
                  </Col>
                  <Col
                    sm={24}
                    md={{ span: 12, offset: 1 }}
                    xl={{ span: 16, offset: 1 }}
                  >
                    <div
                      style={{
                        overflow: "visible",
                        background: "url('/map.png') no-repeat center",
                        height: "100%",
                        backgroundSize: "contain",
                      }}
                    >
                      {/* <img src='/map.png' /> */}
                    </div>
                  </Col>
                </Row>
                <Row align="middle" justify="space-between">
                  <Col xs={24} sm={11} md={6} xl={5}>
                    <img height="100%" src="/rec-yellow.png" />
                  </Col>
                  <Col
                    xs={24}
                    sm={{ span: 11, offset: 1 }}
                    md={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 2 }}
                  >
                    <Space direction="vertical">
                      <img src="/clipart-1.png" />
                      <Title level={4}>
                        Đơn hàng<span className="blue"> kết hợp.</span>
                      </Title>
                      <Paragraph style={{ height: "66px" }}>
                        Thuật toán xác định xe tối ưu, giảm thiểu tình trạng
                        thời gian chờ xe dài.
                      </Paragraph>
                    </Space>
                  </Col>
                  <Col
                    xs={24}
                    sm={11}
                    md={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                  >
                    <Space direction="vertical">
                      <img src="/clipart-2.png" />
                      <Title level={4}>
                        <span className="blue">Giám sát</span> đơn hàng
                        <span className="blue">.</span>
                      </Title>
                      <Paragraph style={{ height: "66px" }}>
                        Theo dõi lộ trình và tình trạng đơn hàng mọi lúc mọi
                        nơi.
                      </Paragraph>
                    </Space>
                  </Col>
                  <Col
                    xs={24}
                    sm={{ span: 11, offset: 1 }}
                    md={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                  >
                    <Space direction="vertical">
                      <img src="/clipart-3.png" />
                      <Title level={4}>
                        Quản lý <span className="blue">khoa học.</span>
                      </Title>
                      <Paragraph style={{ height: "66px" }}>
                        Nền tảng công nghệ giúp quản lý đội xe, đơn hàng một
                        cách tinh gọn, tiện lợi, nhanh chóng.
                      </Paragraph>
                    </Space>
                  </Col>
                </Row>
              </Space>
              <Row justify="space-between">
                <section id="app" />
                <Col md={24} xl={7} style={{ maxHeight: "fit-content" }}>
                  <Row justify="center">
                    <Col xs={24} md={24} xl={24}>
                      <Space direction="vertical" size={24}>
                        <Space size={6}>
                          <img src="/icon/head-line.png" />
                          <Text strong>Tài xế</Text>
                        </Space>
                        <h2>
                          Cho phép tài xế dễ dàng{" "}
                          <span className="blue">quản lý</span> đơn hàng
                          <span className="blue">.</span>
                        </h2>
                        <Paragraph>
                          Nền tảng tích hợp ngay trên điện thoại thông minh giúp
                          tài xế dễ dàng cập nhật thông tin về đơn hàng bất cứ
                          lúc nào.
                        </Paragraph>
                      </Space>
                    </Col>
                    <Col xs={6} md={6} xl={24}>
                      <img
                        src="/onephone.png"
                        style={{
                          maxWidth: "140%",
                          height: "auto",
                          marginLeft: "-90px",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={24}
                  xl={{ span: 15, offset: 2 }}
                  style={{ maxHeight: "100%" }}
                >
                  <Row justify="center">
                    <Col xs={12} md={12} xl={24}>
                      <img
                        src="/twophones.png"
                        style={{
                          maxWidth: "120%",
                          height: "auto",
                          marginRight: "-48px",
                          marginBottom: "-40px",
                          marginTop: "-64px",
                        }}
                      />
                      {/* <div style={{overflow:'visible', background: "url('/twophones.png') no-repeat center", height:'800px', width:'700px', backgroundSize:'contain'}}></div> */}
                    </Col>
                    <Col xs={24} md={24} xl={24}>
                      <Space
                        direction="vertical"
                        size={24}
                        style={{ width: "100%" }}
                      >
                        <Title level={5} style={{ fontWeight: 800 }}>
                          Tham gia đội ngũ <span className="blue">tài xế</span>{" "}
                          của AloTruck<span className="blue">!</span>
                        </Title>
                        <Paragraph>
                          Tải ngay ứng dụng điện thoài dành riêng cho bộ phận
                          tài xế xe tải của chúng tôi.
                        </Paragraph>
                        <Row>
                          <Col xs={24} md={8} lg={8} xl={8}>
                            {/* <Button icon={<img src='/icon/apple.svg' style={{paddingRight:'8px'}}/>} block size={'large'} style={{height:'56px', width:'199px'}}>
                                                        <Space direction='vertical'>
                                                            <Text style={{fontSize:'12px'}}>Tải xuống trên</Text>
                                                            <Text strong>App Store</Text>
                                                        </Space>
                                                    </Button> */}
                            <a
                              href="https://apps.apple.com/vn/app/alotruck/id1556193649"
                              target="_blank"
                            >
                              <img src="/apple-btn.png" height="56px" />
                            </a>
                          </Col>
                          <Col
                            xs={24}
                            md={8}
                            lg={{ span: 8, offset: 1 }}
                            xl={{ span: 8, offset: 1 }}
                          >
                            {/* <Button icon={<img src='/icon/gplay.svg' style={{paddingRight:'8px'}}/>} block size={'large'} style={{height:'56px', width:'199px'}}>
                                                        <Space direction='vertical'>
                                                            <Text style={{fontSize:'12px'}}>Lấy nó trên</Text>
                                                            <Text strong>Google Play</Text>
                                                        </Space>
                                                    </Button> */}
                            <a
                              href="https://play.google.com/store/apps/details?id=com.newsoft.alotruck"
                              target="_blank"
                            >
                              <img src="gplay-btn.png" height="56px" />
                            </a>
                          </Col>
                        </Row>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Space
                direction="vertical"
                size={24}
                style={{ overflow: "visible", width: "100%" }}
              >
                <Space size={6}>
                  <img src="/icon/head-line.png" />
                  <Text strong>Thắc mắc và giải đáp</Text>
                </Space>
                <Row>
                  {faq.map((category, i) => {
                    const Titl = category.name;
                    return (
                      <>
                        <Col span={24}>
                          <Collapse
                            bordered={false}
                            expandIconPosition="right"
                            className="site-collapse-custom-collapse"
                          >
                            <Panel
                              header={<Titl />}
                              key={category.key}
                              className="site-collapse-custom-panel"
                            >
                              <Paragraph>{category.children}</Paragraph>
                            </Panel>
                          </Collapse>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Space>
              <Space
                direction="vertical"
                size={48}
                align="center"
                style={{ width: "100%" }}
              >
                <Space direction="vertical" size={24} align="center">
                  <Space direction="vertical" size={4} align="center">
                    <img src="/icon/head-line.png" />
                    <Text strong>Nhận xét</Text>
                  </Space>
                  <h2>
                    <span className="blue">Khách hàng</span> nói gì về chúng tôi
                    <span className="blue">?</span>
                  </h2>
                </Space>
                <Row
                  justify="space-between"
                  style={{ width: "100%" }}
                  gutter={[0, 24]}
                >
                  {reviews.map((category, i) => (
                    <>
                      <Col md={24} xl={7}>
                        <Space
                          direction="vertical"
                          size={24}
                          className="card-big"
                        >
                          <img src="/icon/quote-left.png" />
                          <Paragraph
                            style={{
                              height: "110px",
                              minHeight: "40px",
                              width: "100%",
                            }}
                          >
                            {category.text}
                          </Paragraph>
                          <Space size={12}>
                            <Avatar size={56} src={category.avatar} />
                            <Space direction="vertical" size={0}>
                              <Title
                                level={5}
                                className="blue"
                                style={{
                                  fontWeight: "bolder",
                                  color: "#03A9F4",
                                  marginBottom: "0px",
                                }}
                              >
                                {category.name}
                              </Title>
                              <Text style={{ fontSize: "12px" }}>
                                {category.company}
                              </Text>
                              <Rate
                                allowHalf
                                disabled
                                defaultValue={category.rate}
                                style={{ fontSize: "16px" }}
                              />
                            </Space>
                          </Space>
                        </Space>
                      </Col>
                    </>
                  ))}
                </Row>
              </Space>
              {/* <Row justify='space-between'>
                            <Col> <img src='/logo-1.png'/> </Col>
                            <Col> <img src='/logo-2.png'/> </Col>
                            <Col> <img src='/logo-3.png'/> </Col>
                            <Col> <img src='/logo-4.png'/> </Col>
                            <Col> <img src='/logo-5.png'/> </Col>
                        </Row> */}
              <div></div>
            </Space>
          </Space>
        </Content>
        <Footer style={{ padding: "0px 0px" }}>
          <div
            style={{
              padding: "48px 12.5%",
              backgroundImage: "url('/contact-frame.jpg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Row justify="space-between" align="middle">
              <Col md={24} xl={7}>
                <Space direction="vertical" size={4}>
                  <Text strong style={{ color: "#263238" }}>
                    <span className="white">Hứng thú</span> với dịch vụ của
                    chúng tôi?
                  </Text>
                  <h1 style={{ color: "#263238" }}>
                    <span className="white">Tham gia </span>ngay thôi
                    <span className="white">!</span>
                  </h1>
                </Space>
              </Col>
              <Col md={24} xl={15}>
                <Space direction="vertical" size={12} style={{ width: "100%" }}>
                  <Search
                    placeholder="Điền email của bạn"
                    enterButton={<img src="/icon/send.svg" />}
                    color="#263238"
                    size="large"
                    style={{ width: "100%" }}
                  />
                  <Text strong style={{ fontSize: "12px", color: "#263238" }}>
                    *Gửi chúng tôi email của bạn và đội ngũ hỗ trợ sẽ liên lạc
                    với bạn nhanh nhất có thể
                  </Text>
                </Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              padding: "96px 12.5%",
              backgroundImage: "url('/footer.jpg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Row justify="space-between" gutter={[0, 24]}>
              <Col xs={24} md={8} xl={8}>
                <Space direction="vertical" size={48}>
                  <Space direction="vertical" size={24}>
                    <img src="/icon/alo-truck-logo.png" sizes="235px 25.63px" />
                    <Paragraph className="white">
                      Nền tảng AloTruck là đòn bẩy công nghệ cho các doanh
                      nghiệp trong ngành logistics khai phóng tiềm năng chinh
                      phục các mục tiêu lớn.
                    </Paragraph>
                  </Space>
                  <Space direction="vertical" size={24}>
                    <Paragraph className="white">
                      © Copyright 2021 by Alotruck. All rights reserved.
                    </Paragraph>
                    <Space size={24}>
                      <img src="/icon/facebook.svg" />
                      <img src="/icon/linkedin.svg" />
                      <img src="/icon/youtube.svg" />
                    </Space>
                  </Space>
                </Space>
              </Col>
              <Col xs={24} md={2} xl={2}></Col>
              <Col xs={7} md={4} xl={4}>
                <Space direction="vertical" size={24}>
                  <Title level={5} style={{ color: "#FFC107" }}>
                    Điều lệ
                  </Title>
                  <a href="" className="white">
                    Chính sách bảo mật
                  </a>
                  <a href="" className="white">
                    Điều khoản dịch vụ
                  </a>
                  <a href="" className="white">
                    Quy tắc ứng xử
                  </a>
                </Space>
              </Col>
              <Col xs={7} md={4} xl={4}>
                <Space direction="vertical" size={24}>
                  <Title level={5} style={{ color: "#FFC107" }}>
                    Dịch vụ
                  </Title>
                  <a href="" className="white">
                    Giới thiệu
                  </a>
                  <a href="" className="white">
                    Tin tức
                  </a>
                  <a href="" className="white">
                    Liên lạc
                  </a>
                </Space>
              </Col>
              <Col xs={7} md={4} xl={4}>
                <Space direction="vertical" size={24}>
                  <Title level={5} style={{ color: "#FFC107" }}>
                    Contact
                  </Title>
                  <Text className="white">
                    <span className="yellow">P:</span> 092 321 4411
                  </Text>
                  <Text className="white">
                    <span className="yellow">E:</span> alotruck.asia@gmail.com
                  </Text>
                  <Text className="white">
                    <span className="yellow">A:</span> 72 Trần Đăng Ninh, Cầu
                    Giấy, Hà Nội, Việt Nam
                  </Text>
                </Space>
              </Col>
            </Row>
          </div>
        </Footer>
      </Layout>
    </StyleContainer>
  );
}

const reviews = [
  {
    avatar: "/avatar-3.jpg",
    text: "Việc sử dụng AloTruck đã giúp chúng tôi tiết kiệm được rất nhiều thời gian cho việc tìm kiếm tài xế cho các đơn hàng. Ngoài ra, tôi không còn thấy tình trạng xe trống quá nhiều nữa.",
    name: "Nguyễn Trung Thành",
    company: "CEO Công ty CP DV vận tải DTI",
    rate: 5,
  },
  {
    avatar: "/avatar-2.jpg",
    text: "Tôi thấy mọi thứ thật chuyên nghiệp, hiện đại. Với một doanh nghiệp tầm cỡ quốc tế như chúng tôi thì việc ứng dụng công nghệ như này vào quá trình quản lý công việc là điều hết sức đúng đắn.",
    name: "Nguyễn Thế Phong",
    company: "CTO Thế Phong Logistic",
    rate: 5,
  },
  {
    avatar: "/avatar-1.jpg",
    text: "Doanh thu của công ty đã tăng lên khá nhiều từ khi dùng sản phẩm do tiết kiệm được thời gian và công sức con người. Cảm ơn đội ngũ vì đã tạo ra sản phẩm hữu ích như vậy.",
    name: "Minh Vũ",
    company: "Công ty TNHH Vận tải Apectrans",
    rate: 4,
  },
];

const faq = [
  {
    key: 1,
    name: () => {
      return (
        <>
          <Title level={5}>
            AloTruck có thu phí <span className="blue">hoa hồng</span> không?
          </Title>
        </>
      );
    },
    children:
      "Chúng tôi sẽ lấy một phần nhỏ tiền từ phía công ty vận tải và chủ nhà xe.",
  },
  {
    key: 2,
    name: () => {
      return (
        <>
          <Title level={5}>
            Tôi có cần <span className="blue">nhận tiền</span> để mua app
            AloTruck không?
          </Title>
        </>
      );
    },
    children: "Không. Nền tảng của chúng tôi hoàn toàn miễn phí.",
  },
  {
    key: 3,
    name: () => {
      return (
        <>
          <Title level={5}>
            Sự <span className="blue">khác nhau</span> giữa app AloTruck và
            website AloTruck là gì?
          </Title>
        </>
      );
    },
    children:
      "App AloTruck là dành cho tài xế. Website AloTruck là dành công ty vận tải, chủ nhà xe và điều phối viên.",
  },
  {
    key: 4,
    name: () => {
      return (
        <>
          <Title level={5}>
            Nền tảng của các bạn có <span className="blue">bảo mật</span> không?
          </Title>
        </>
      );
    },
    children:
      "Mọi thông tin của khách hàng đều được chúng tôi bảo mật tuyệt đối.",
  },
  {
    key: 5,
    name: () => {
      return (
        <>
          <Title level={5}>
            Làm sao tôi có thể bật <span className="blue">thông báo</span> cho
            app AloTruck trên điện thoại?
          </Title>
        </>
      );
    },
    children:
      "Khi cài app, chúng tôi sẽ hỏi bạn có muốn bật thông báo đẩy không. Bạn chỉ cần ấn vào có để bật thông báo. Nếu như đã tắt thông báo nhưng muốn bật lại, bạn có thể vào phần cài đặt để bật lại.",
  },
];

const center: AlignSetting = "center";
const columns = [
  {
    title: "",
    dataIndex: "text",
  },
  {
    title: () => (
      <>
        <Text className="bold">AloTruck</Text>
      </>
    ),
    dataIndex: "alotruck",
    align: center,
    render: () => (
      <>
        <img src="/icon/check.svg" />
      </>
    ),
  },
  {
    title: () => (
      <>
        <Text className="bold">Công ty vận chuyển khác</Text>
      </>
    ),
    dataIndex: "other",
    align: center,
    render: () => (
      <>
        <img src="/icon/x-circle.svg" />
      </>
    ),
  },
];

const data = [
  {
    key: "1",
    text: "Giám sát tài xế, tình trạng, lộ trình hàng hóa",
  },
  {
    key: "2",
    text: "Thuật toán xác định xe hỗ trợ tối ưu, tiết kiệm thời gian",
  },
  {
    key: "3",
    text: "Hệ thống quét QR Code giảm thiểu thủ tục giấy tờ",
  },
  {
    key: "4",
    text: "Quản lý bảo trì hãng sửa",
  },
];
const StyleContainer = styled.div`
  html {
    scroll-behavior: smooth;
  }
  .video-container {
    position: relative;
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
  .ant-space-item {
    margin-bottom: 0;
    text-align: left;
  }
  .home-container {
    /* style={{height:'600px', position:'absolute', top: '16%', left: '12.5%', right: '12.5%', zIndex:(21)}} */
    height: fit-content;
    position: absolute;
    left: 12.5%;
    right: 12.5%;
    z-index: 21;
  }
  .container {
    position: relative;
  }
  .container:after {
    content: "";
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    opacity: 0.4;
    z-index: 20;
  }
  .card {
    height: 100%;
    border-color: white;
    border-width: 2px;
    background-color: white;
  }
  .card:hover {
    border-color: #e3f5fe;
  }
  h1 {
    font-weight: 800;
    line-height: 124%;
    text-transform: capitalize;
    color: #263238;
  }

  h2 {
    font-weight: 800;
    line-height: 124%;
    text-transform: capitalize;
    color: #263238;
  }

  h5.ant-typography,
  .ant-typography h5,
  .bold {
    font-weight: 700;
  }

  h4.ant-typography,
  .ant-typography h4 {
    font-weight: 800;
  }

  .black {
    color: #263238;
  }

  .blue {
    color: #03a9f4;
  }
  .yellow {
    color: #ffc107;
  }
  .white {
    color: white;
  }

  .custom-icons-list > .anticon {
    margin-right: 6px;
  }
  .ant-row-rtl .custom-icons-list > .anticon {
    margin-right: 0;
    margin-left: 6px;
  }

  [data-theme="compact"]
    .site-collapse-custom-collapse
    .site-collapse-custom-panel,
  .site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 24px;
    overflow: visible;
    background: white;
  }

  .ant-collpase-header {
    font-weight: 800;
    font-size: 18px;
  }

  .card-big {
    height: 350px;
    border: 2px solid #e3f5fe;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 24px;
  }

  .ant-btn-primary {
    border-color: #263238;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    background: #263238;
    width: 102px;
  }

  .ant-carousel,
  .ant-carousel-vertical,
  .ant-carousel .slick-slider .slick-list {
    height: 100%;
  }
  .carousel-img {
    display: block;
    object-fit: cover;
    border-radius: 2px;
  }
  /* .img-container {
        text-align: center;
    } */
  @media only screen and (min-width: 769px) {
    h1 {
      font-size: 48px;
    }
    h2 {
      font-size: 36px;
    }
    .home-container {
      top: 16%;
    }
    .carousel-small {
      height: 478px;
      width: 100%;
    }
    .carousel-img {
      height: 554px;
      min-height: 300px;
    }
    .home-box {
      width: 450px;
    }
    .menu-full {
      visibility: visible;
    }
    .menu-dropdown {
      visibility: hidden;
    }
  }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 32px;
    }
    .home-container {
      top: 12%;
    }
    .carousel-small {
      height: 200px;
      width: 100%;
    }
    .carousel-img {
      height: 224px;
    }
    .home-box {
      width: 100%;
    }
    .menu-full {
      visibility: hidden;
      position: absolute;
    }
    .menu-dropdown {
      visibility: visible;
    }
  }
`;
