import API from "./../api";

export const getAllBOT = async () => {
    try {
        const response = await API.get('admin/bot/all').then(
            (res) => res.data
        );
        if (response.success) {
            const listData = [...response.data];
            listData.forEach((item: { key: any; _id: any }) => {
                item.key = item._id;
            });
            return {data: listData, total: response.totalItem};
        }
    } catch (error) {
        throw error;
    }
}

export const updateBot = async (id: string, data: object) => {
    try {
        const response = await API.put(`admin/bot/${id}`, data).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}

export const createBot = async (params: any) => {
    try {
        const response = await API.post(`admin/bot`, params).then(
            (res) => res.data
        );
        return response
    } catch (error) {
        throw error;
    }
}