import React, { useEffect, useState } from 'react'
import { Col, Button, Upload, Form, Input, Row, message } from 'antd'
import {rule_require, rule_number, rule_not_require} from '../../validateInput';
import { UploadOutlined } from '@ant-design/icons';
import { propsImage } from '../../imageHandle';


function BusinessLicense(props: any = {}) {
    const [licenseFront, setLicenseFront] = useState<any>([])
    const [licenseBack, setLicenseBack] = useState<any>([])

    useEffect(() => {
        if (props.license?.front != null && props.license?.front !== '') {
            setLicenseFront([{ name: props.license?.front, url: props.license?.front }]);
        }
        if (props.license?.back != null && props.license?.back !== '') {
            setLicenseBack([{ name: props.license?.back, url: props.license?.back }]);
        }
    }, [props])

    return (
        <Form.Item wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
            <Col xs={24}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                            label="Giấy phép kinh doanh"
                            name={['businessLicense', 'text']}
                            rules={[rule_require, rule_number]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder='Nhập số giấy phép kinh doanh'/>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            name={['businessLicense', 'front']}
                            rules={[rule_not_require]}
                            labelCol={{ span: 24 }}
                            label='Mặt trước'
                        >
                            <Upload
                                {...propsImage}
                                fileList={licenseFront}
                                onChange={
                                    ({ fileList: newFileList }: { fileList: any }) => {
                                        for (let i = 0; i < newFileList.length; i++) {
                                            newFileList[i].url = newFileList[i].response?.data.url;
                                            newFileList[i].name = newFileList[i].response?.data.url;
                                        }
                                        setLicenseFront(newFileList)
                                    }}
                            >
                                {licenseFront?.length < 1 && <Button icon={<UploadOutlined />}>Tải mặt trước</Button>}
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            name={['businessLicense', 'back']}
                            rules={[rule_not_require]}
                            label='Mặt sau'
                            labelCol={{ span: 24 }}
                        >
                            <Upload
                                {...propsImage}
                                fileList={licenseBack}
                                onChange={
                                    ({ fileList: newFileList }: { fileList: any }) => {
                                        for (let i = 0; i < newFileList.length; i++) {
                                            newFileList[i].url = newFileList[i].response?.data.url;
                                            newFileList[i].name = newFileList[i].response?.data.url;
                                        }
                                        setLicenseBack(newFileList)
                                    }}>
                                {licenseBack?.length < 1 && <Button icon={<UploadOutlined />}>Tải mặt trước</Button>}
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
            </Col>

        </Form.Item>
    )
}

export default BusinessLicense
