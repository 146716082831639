import API from "../api";

export const getAllCustomer = async (param : any = {}) => {
    try {
        const response = await API.get('admin/customer/all',{params:param}).then(
            (res) => res.data
        );
        if (response.success) {
            const listData = [...response.data];
            listData.forEach((item: { key: any; _id: any }) => {
                item.key = item._id;
            });
            return { data: listData};
        }
    } catch (error) {
        throw error;
    }
}