import React, { useEffect, useState } from "react";
import API from "../../../shared/api/api";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  InputNumber,
  Divider,
  Select,
  Typography,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { rule_number, rule_require } from "../../../shared/validateInput";
import axios from "axios";
import { updateBot } from "../../../shared/api/BOT";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Option } = Select;
function BotUpdate() {
  let { id }: any = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [cityList, setCityList] = useState<any>([]);
  useEffect(() => {
    getListCity();
    fetchData();
    return () => {};
  }, []);
  const fetchData = async () => {
    const response: any = await API.get(`admin/bot/${id}`).then(
      (res) => res.data
    );
    if (response.success) {
      console.log("response.data", response.data);

      form.setFieldsValue(response.data);
    }
  };
  const getListCity = () => {
    axios
      .get("https://vapi.vnappmob.com/api/province")
      .then((res: any) => {
        if (res.status === 200) {
          setCityList(res.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onUpdate = async () => {
    setLoadingBtn(true);
    const response = await updateBot(id, form.getFieldsValue());
    if (response.success) history.push("/partner/bot");
    setLoadingBtn(false);
  };
  return (
    <Row style={{ width: "100%" }}>
      <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
        <span
          onClick={() => {
            history.goBack();
          }}
        >
          <LeftOutlined />
          Quay lại
        </span>
      </Title>
      <Form
        form={form}
        {...layout}
        style={{ width: "100%" }}
        onFinish={onUpdate}
        onFinishFailed={() => {
          setLoadingBtn(false);
        }}
      >
        <Row gutter={[32, 0]} style={{ width: "100%" }}>
          <Col span={8}>
            <Form.Item label="Tên trạm" name="name" rules={[rule_require]}>
              <Input placeholder="Tên" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Tỉnh/TP"
              name={["address", "province"]}
              rules={[rule_require]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Chọn thành phố"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cityList.map((city: any) => (
                  <Option key={city.province_id} value={city.province_name}>
                    {city.province_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Địa điểm"
              name={["address", "text"]}
              rules={[rule_require]}
            >
              <Input placeholder="Địa điểm" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vé lượt 20"
              name={["turn", "twenty"]}
              rules={[rule_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vé lượt 40"
              name={["turn", "forty"]}
              rules={[rule_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item
              label="Vé tháng 20"
              name={["monthly", "twenty"]}
              rules={[rule_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vé tháng 40"
              name={["monthly", "forty"]}
              rules={[rule_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item
              label="Vé quý 20"
              name={["quarterly", "twenty"]}
              rules={[rule_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vé quý 40"
              name={["quarterly", "forty"]}
              rules={[rule_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item
              label="Vé năm 20"
              name={["yearly", "twenty"]}
              rules={[rule_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vé năm 40"
              name={["yearly", "forty"]}
              rules={[rule_number]}
            >
              <InputNumber
                formatter={(value) =>
                  ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
          <Button
            loading={isLoadingBtn}
            htmlType="submit"
            style={{
              width: "100%",
              backgroundColor: "#03A9F4",
              color: "white",
            }}
          >
            Cập nhật
          </Button>
        </Col>
      </Form>
    </Row>
  );
}

export default BotUpdate;
