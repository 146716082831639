import React from 'react'

function Policy(){
    return(
        <div style={{width: '100%', maxInlineSize: 1000, margin: 'auto', padding: '0 30'}}>
            <p style={{fontWeight: 400, fontSize: '2rem', textAlign: 'center'}}><strong>Privacy Notice</strong></p>
            <p style={{fontWeight: 400}}>This Privacy Notice describe how Alo Truck Inc, (collectively &ldquo;<strong>Alo Truck</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo; or &ldquo;<strong>our</strong>&rdquo;) collect, use, process and disclose your Personal Data through the use of Alo Truck&rsquo;s mobile applications and websites (respectively &ldquo;<strong>Apps</strong>&rdquo; and &ldquo;<strong>Websites</strong>&rdquo;), as well as products, features and other services, operated by Alo Truck (collectively, &ldquo;<strong>Services</strong>&rdquo;).</p>
            <p style={{fontWeight: 400}}>This Notice applies to our consumers, driver, agents, contractors and service providers (collectively &ldquo;<strong>you</strong>&rdquo;, &ldquo;<strong>your</strong>&rdquo; or &ldquo;<strong>yours</strong>&rdquo;).</p>
            <p style={{fontWeight: 400}}>&ldquo;<strong>Personal Data</strong>&rdquo; is any information which can be used to identify you or from which you are identifiable. This includes but is not limited to your name, nationality, telephone number, bank and credit card details, personal interests, email address, your image, government-issued identification numbers, biometric data, race, date of birth, marital status, religion, health information, vehicle and insurance information, employment information and financial information.</p>
            <ol>
            <li style={{fontWeight: 400}}><strong> COLLECTION OF PERSONAL DATA</strong></li>
            </ol>
            <p style={{fontWeight: 400}}>We collect Personal Data about you in the ways listed below. We may also combine the collected Personal Data with other Personal Data in our possession.</p>
            <p style={{fontWeight: 400}}><strong><em>You provide your Personal Data to us</em></strong></p>
            <p style={{fontWeight: 400}}>We collect your Personal Data when you voluntarily provide it to us. For example, you may provide your Personal Data to us when you:</p>
            <ul style={{fontWeight: 400}}>
            <li>complete a user profile or registration forms (such as your name, contact information and other identification information where needed);</li>
            <li>provide information to assess your eligibility to provide services as a Alo Truck driver partner or delivery partner (such as your driver&rsquo;s license information, vehicle information and background check results (as legally permissible));</li>
            <li>interact with our social media pages (such as your social media account ID, profile photo and any other publicly available data);</li>
            <li>participate in contests or events organised by us (such as the pictures, audio files, or videos you may submit, which may include images of yourself);</li>
            <li>verify your identity through various means (such as social media logins, submission of&nbsp;selfie images or independently verified payment card information);</li>
            <li>fill up demographic information in surveys (such as your age, gender, and other information you may volunteer such as your marital status, occupation and income information); and</li>
            <li>agree to use in-vehicle audio and/or video recording features.</li>
            </ul>
            <p style={{fontWeight: 400}}>In certain circumstances, you may need to provide your Personal Data in order to comply with legal requirements or contractual obligations, or where it is necessary to conclude a contract. Failure to provide such Personal Data, under such circumstance, may constitute failure to comply with legal requirements or contractual obligations, or inability to conclude a contract with you, as the case may be.</p>
            <p style={{fontWeight: 400}}>For purposes such as detection and prevention of fraud, we may also collect your Personal Data without consent, by relying on Legitimate Interests or other exemptions provided by the applicable data privacy laws.</p>
            <p style={{fontWeight: 400}}><strong><em>When our services are used</em></strong></p>
            <p style={{fontWeight: 400}}>Personal Data may be collected through the normal operation of our Apps, Websites and Services. Some examples are:</p>
            <ul style={{fontWeight: 400}}>
            <li>your location (to detect locations of loading/unloading and abnormal route variations);</li>
            <li>feedback, ratings and compliments;</li>
            <li>information about how you interacted with our Apps, Website or Services (such as features used and content viewed);</li>
            <li>device information (such as hardware model and serial number, IP address, file names and versions, and advertising identifiers or any information that may provide indication of device or app modification);&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li>personal data you enter in messages when you use our in-app communication features; and</li>
            <li>personal data that may be captured through your interaction with us, our agents, in-vehicle audio and/or video recording during a ride (such as your image or voice or both, and its related metadata).</li>
            </ul>
            <p style={{fontWeight: 400}}><strong><em>From other sources</em></strong></p>
            <p style={{fontWeight: 400}}>When we collect Personal Data, including but not limited to your name, contact information and other identification information where needed from other sources, we make sure that that data is transferred to us in accordance with applicable laws. Such sources include:</p>
            <ul style={{fontWeight: 400}}>
            <li>referral programmes;</li>
            <li>publicly available sources of data;</li>
            <li>governmental sources of data;</li>
            </ul>
            <ul style={{fontWeight: 400}}>
            <li>when you use our in-app chat; and</li>
            </ul>
            <ul style={{fontWeight: 400}}>
            <li>marketing services providers or partners.</li>
            </ul>
            <p style={{fontWeight: 400}}><strong><em>Personal Data about driver</em></strong></p>
            <p style={{fontWeight: 400}}>Include:</p>
            <ul style={{fontWeight: 400}}>
            <li>telematics data (such as your speed, acceleration, and braking data);</li>
            <li>device data (such as accelerometer data, GPS location, your IMEI number and the names of apps you have installed on your device);&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li>your vehicle registration data; and</li>
            <li>personal data that may be captured through your interaction with us, our agents, our in-vehicle audio and/or video recording during a ride (such as your image or voice or both, and its related metadata).</li>
            </ul>
            <p style={{fontWeight: 400}}><strong><em>Sensitive Personal Data</em></strong></p>
            <p style={{fontWeight: 400}}>Some of the Personal Data that we collect is sensitive in nature. This includes Personal Data pertaining to your race, national ID information, religious beliefs, background information (including financial and criminal records, where legally permissible), health data, disability, marital status and biometric data, as applicable. We collect this information only with your consent and/or in strict compliance with applicable laws.</p>
            <p style={{fontWeight: 400}}><strong><em>When you provide Personal Data of other individuals to us</em></strong></p>
            <p style={{fontWeight: 400}}>In some situations, you may provide Personal Data of other individuals (such as your spouse, family members or friends) to us. For example, you may add them as your emergency contact or when you use the in-app chat. If you provide us with their Personal Data, you represent and warrant that you have obtained their consent for their Personal Data to be collected, used and disclosed as set out in this Notice.</p>
            <ol>
            <li style={{fontWeight: 400}}><strong>II</strong>.&nbsp;<strong>USE OF PERSONAL DATA</strong></li>
            </ol>
            <p style={{fontWeight: 400}}>Alo Truck may use, combine and process your Personal Data for the following purposes (&ldquo;<strong>Purposes</strong>&rdquo;):&nbsp;&nbsp;</p>
            <p style={{fontWeight: 400}}><strong><em>Providing services and features</em></strong></p>
            <p style={{fontWeight: 400}}>Your Personal Data will be used to provide, personalise, maintain and improve our Apps, Websites and Services. This includes using your Personal Data to:</p>
            <ul style={{fontWeight: 400}}>
            <li>engage you to provide Services;</li>
            <li>create, administer and update your account;</li>
            <li>conduct due diligence checks and risk assessments / analysis;</li>
            <li>verify your identity;</li>
            <li>verify your age (where necessary);</li>
            <li>validate your route;</li>
            <li>track the progress of your trip and detect abnormal trip variations;</li>
            <li>enable features that personalise your App, such as lists of your regular places and previous destinations;</li>
            <li>make your experience more seamless, such as automatically filling in your registration information (such as your name or phone number) from one Service to another Service or when you participate in our surveys;&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li>perform internal operations necessary to provide our Services, including troubleshooting software bugs and operational problems, conducting data analysis, testing and research, monitoring and analysing usage and activity trends;</li>
            <li>protect the security or integrity of the Services and any facilities or equipment used to make the Services available;</li>
            </ul>
            <p style={{fontWeight: 400}}><strong><em>Safety and security</em></strong></p>
            <p style={{fontWeight: 400}}>We use your data to ensure the safety and security of our Services and all users. This includes:</p>
            <ul style={{fontWeight: 400}}>
            <li>screening driver and delivery partners before enabling their use of our Services;</li>
            <li>identifying unsafe driving behaviour such as speeding, harsh braking and acceleration, and providing personalised feedback to driver partners;</li>
            <li>verifying your identity when you log in to Alo Truck;</li>
            <li>using device, location, profile, usage and other Personal Data to prevent, detect and combat fraud or unsafe activities;</li>
            <li>sharing drivers&nbsp;location;</li>
            <li>monitoring compliance with our terms and conditions, policies and Driver&rsquo;s Code of Conduct; and</li>
            <li>detecting, preventing and prosecuting crime.</li>
            </ul>
            <p style={{fontWeight: 400}}>Alo Truck&nbsp;<strong><em>support</em></strong></p>
            <p style={{fontWeight: 400}}>We use Personal Data to resolve user support issues. For example, we may:</p>
            <ul style={{fontWeight: 400}}>
            <li>investigate and address concerns;</li>
            <li>monitor and improve our user support responses;</li>
            <li>respond to questions, comments and feedback; and</li>
            <li>inform you about steps taken to resolve user support issues.</li>
            </ul>
            <p style={{fontWeight: 400}}><strong><em>Research and development and security</em></strong></p>
            <p style={{fontWeight: 400}}>We may use the Personal Data we collect for testing, research, analysis and product development. This allows us to understand and analyse your needs and preferences, protect your Personal Data, improve and enhance the safety and security of our&nbsp; &nbsp; &nbsp; Services, develop new features, products and services, and facilitate insurance and finance solutions.</p>
            <p style={{fontWeight: 400}}><strong><em>Legal purposes</em></strong></p>
            <p style={{fontWeight: 400}}>We may use the Personal Data we collect to investigate and resolve claims or disputes, or as allowed or required by applicable law. We may also use Personal Data without consent, by relying on Legitimate Interests or other exemptions provided by the applicable data privacy laws, for purposes such as detection and prevention of fraud.&nbsp;</p>
            <p style={{fontWeight: 400}}>We may also use your Personal Data when we are required, advised, recommended, expected or requested to do so by our legal advisors or any local or foreign legal, regulatory, governmental or other authority.</p>
            <p style={{fontWeight: 400}}>For example, we may use your Personal Data to:</p>
            <ul style={{fontWeight: 400}}>
            <li>comply with court orders or other legal, governmental or regulatory requirements;</li>
            <li>enforce our Terms of Service or other agreements; and</li>
            <li>protect our rights or property in the event of a claim or dispute.</li>
            </ul>
            <p style={{fontWeight: 400}}>We may also use your Personal Data in connection with mergers, acquisitions, joint ventures, sale of company assets, consolidation, restructuring, financing, business asset transactions, or acquisition of all or part of our business by another company.</p>
            <p style={{fontWeight: 400}}><strong>Marketing and promotions</strong></p>
            <p style={{fontWeight: 400}}>We may use your Personal Data to market Alo Truck and Alo Truck&rsquo;s partners&rsquo;, sponsors&rsquo; and advertisers&rsquo; products, services, events or promotions. For example, we may:</p>
            <ul style={{fontWeight: 400}}>
            <li>send you alerts, newsletters, updates, mailers, promotional materials, special privileges, festive greetings; and</li>
            <li>notify, invite and manage your participation in our events or activities.</li>
            </ul>
            <p style={{fontWeight: 400}}>We may communicate such marketing to you by post, telephone call, short message service, online messaging service, push notification, by hand and by email.</p>
            <p style={{fontWeight: 400}}>If you wish to unsubscribe to the processing of your Personal Data for marketing and promotions, please click on the unsubscribe link in the relevant email or message. Alternatively, you may also update your preferences in our App settings.</p>
            <p style={{fontWeight: 400}}><strong>III. DISCLOSURE OF PERSONAL DATA</strong></p>
            <p style={{fontWeight: 400}}>We need to share Personal Data with various parties for the Purposes. These parties include:</p>
            <p style={{fontWeight: 400}}><strong><em>With the owner of Alo Truck accounts that you may use</em></strong></p>
            <p style={{fontWeight: 400}}>For example, your employer may receive trip data when you use Alo Truck App</p>
            <p style={{fontWeight: 400}}><strong><em>With our legal advisors and governmental authorities</em></strong></p>
            <p style={{fontWeight: 400}}>We may share your Personal Data with our legal advisors, law enforcement officials, government authorities and other third parties. This may take place to fulfil the legal purposes (mentioned above), or any of the following circumstances:</p>
            <ol>
            <li style={{fontWeight: 400}}>where it is necessary to respond to an emergency that threatens the life, health or safety of a person; or&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li style={{fontWeight: 400}}>where it is necessary in the public interest (e.g. in a public health crisis, for contact tracing purposes and safeguarding our community).</li>
            <li style={{fontWeight: 400}}><strong>RETENTION OF PERSONAL DATA</strong></li>
            </ol>
            <p style={{fontWeight: 400}}>We retain your Personal Data for the period necessary to fulfill the Purposes outlined in this Notice unless a longer retention period is required or allowed by law. Once your Personal Data is no longer necessary for the Services or Purposes, or we no longer have a legal or business purpose for retaining your Personal Data, we take steps to erase, destroy, anonymise or prevent access or use of such Personal Data for any purpose other than compliance with this&nbsp;Notice ,&nbsp;or for purposes of safety, security, fraud prevention and detection, in accordance with the requirements of applicable laws.</p>
            <ol>
            <li style={{fontWeight: 400}}><strong>COOKIES</strong></li>
            </ol>
            <p style={{fontWeight: 400}}>Alo Truck may use cookies, web beacons, tags, scripts, local shared objects such as HTML5, advertising identifiers and similar technologies (&ldquo;<strong>Cookies</strong>&rdquo;) in connection with your use of the Websites and Apps. Depending on the type of phone and operating software that you are using, you may be granted the option to disable our use of mobile identifiers (such as Apple&rsquo;s IDFA). Cookies may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send to you, and on our web pages. Cookies may transmit Personal Data about you and your use of the Service, such as your browser type, search preferences, IP address, data relating to advertisements that have been displayed to you or that you have clicked on, and the date and time of your use. Cookies may be persistent or stored only during an individual session.</p>
            <p style={{fontWeight: 400}}>You may deactivate cookies by adjusting your internet browser settings to disable, block or deactivate cookies, by deleting your browsing history and clearing the cache from your internet browser.&nbsp;</p>
            <p style={{fontWeight: 400}}>You may be able to limit our use of your personal data to display advertisements that may be of relevance to you, by adjusting your preference sharing of some of this Personal Data through our Alo Truck App (Settings &gt; Privacy &gt; Ads). You will still be seeing non targeted advertisements.</p>
            <p style={{fontWeight: 400}}><strong>VII. Protection of Personal Data</strong></p>
            <p style={{fontWeight: 400}}>We will take reasonable legal, organisational and technical measures to ensure that your Personal Data is protected. This includes measures to prevent Personal Data from getting lost, or used or accessed in an unauthorised way. We limit access to your Personal Data to our employees on a need to know basis. Those processing your Personal Data will only do so in an authorised manner and are required to treat your information with confidentiality.</p>
            <p style={{fontWeight: 400}}>Nevertheless, please understand that the transmission of information via the internet is not completely secure. Although we will do our best to protect your Personal Data, we cannot guarantee the security of your Personal Data transmitted through any online means, therefore, any transmission remains at your own risk.</p>
            <p style={{fontWeight: 400}}><strong>VIII.&nbsp; &nbsp;YOUR RIGHTS WITH RESPECT TO YOUR PERSONAL DATA</strong>&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <p style={{fontWeight: 400}}>In accordance with applicable laws and regulations, you may be entitled to:</p>
            <ul style={{fontWeight: 400}}>
            <li>ask us about the processing of your Personal Data, including to be provided with a copy of your Personal Data;&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li>request the correction and/or (in some cases) deletion of your Personal Data;</li>
            <li>in some cases, request the restriction of the processing of your Personal Data, or object to that processing;</li>
            <li>withdraw your consent to the processing of your Personal Data (where we are processing your Personal Data based on your consent);</li>
            <li>request receipt or transmission to another organisation, in a machine-readable form, of the Personal Data that you have provided to us where we are using your Personal Data based on consent or performance of a contract; and&nbsp;</li>
            <li>complain to the relevant data privacy authority if your data privacy rights are violated, or if you have suffered as a result of unlawful processing of your Personal Data.</li>
            </ul>
            <p style={{fontWeight: 400}}>Where you are given the option to share your Personal Data with us, you can always choose not to do so. If we have requested your consent to processing and you later choose to withdraw it, we will respect that choice in accordance with our legal obligations.</p>
            <p style={{fontWeight: 400}}>However, choosing not to share your Personal Data with us or withdrawing your consent to our use of it could mean that we are unable to perform the actions necessary to achieve the purposes of processing described in Section II (Use of Personal Data) or that you are unable to make use of the Services. After you have chosen to withdraw your consent, we may be able to continue to process your Personal Data to the extent required or otherwise permitted by applicable laws and regulations.</p>
            <p style={{fontWeight: 400}}>If you wish to make a request to exercise your rights, you can contact us through our contact details set out in Section X (How to Contact Us) below.</p>
            <p style={{fontWeight: 400}}>We will screen and verify all requests beforehand. In order to verify your authority to make the request, we may require you to provide supporting information or documentation to corroborate the request.</p>
            <p style={{fontWeight: 400}}>Once verified, we will give effect to your request within the timelines prescribed by applicable laws.</p>
            <ol>
            <li style={{fontWeight: 400}}><strong> AMENDMENTS AND UPDATES</strong></li>
            </ol>
            <p style={{fontWeight: 400}}>Alo Truck may modify, update or amend the terms in this Notice at any time. Such amendments shall be notified to you through the Application and/ or other appropriate means at least five (05) business days before the effective date. The updated version will be posted on the website&nbsp;<a href="https://alotruck.asia/">https://alotruck.asia/</a>&nbsp;It is your responsibility to review the Privacy Noticeregularly. Your continued use of the Apps, Websites or Services, purchase products from Alo Truck or continuing to communicate or engage with Alo Truck following the modifications, updates or amendments to this Notice, whether or not reviewed by you, shall constitute your agreement to be bound by such amendments.</p>
            <ol>
            <li style={{fontWeight: 400}}><strong> HOW TO CONTACT US</strong></li>
            </ol>
            <p style={{fontWeight: 400}}>P: 092 321 4411</p>
            <p style={{fontWeight: 400}}>E: alotruck.asia@gmail.com</p>
            <p style={{fontWeight: 400}}>A: 72 Trần Đăng Ninh, Cầu Giấy, H&agrave; Nội, Việt Nam</p>
        </div>
    )
}

export default Policy