import axios from "axios";
import { createBrowserHistory } from "history";
import { notification } from "antd";
require('dotenv').config();

const history = createBrowserHistory({ forceRefresh: true });

export const urlApi = process.env.REACT_APP_API_URL;// || "http://localhost:3001/";

let API = axios.create({
  baseURL: urlApi,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("assetToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use((response) => {
  const { code, success, message } = response.data;
  if (message) {
    if (success) notification.success({ message: message });
    else notification.error({ message: message });
  }
  if (code === "AUTHENTICATION_ERROR") {
    localStorage.setItem("assetToken", "");
    history.push("/login");
  }
  return response;
});

export default API;
