import React, { useRef, useState } from "react";
import * as _ from "lodash";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { FormInstance } from "antd/es/form/Form";

interface IProps {
  placeholder: string;
  defaultValue: string;
  form: FormInstance;
  setMarker: React.SetStateAction<any>;
}

export const AutocompleteAddress = (props: IProps) => {
  const { form, placeholder, defaultValue, setMarker } = props;
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.SearchBox>();

  const inputRef = useRef<any>(null);

  const onLoad = (e: google.maps.places.SearchBox) => {
    setAutocomplete(e);
  };

  const onPlacesChanged = () => {
    const address = autocomplete?.getPlaces()?.[0]?.formatted_address;
    const lat = autocomplete?.getPlaces()?.[0]?.geometry?.location?.lat();
    const lng = autocomplete?.getPlaces()?.[0]?.geometry?.location?.lng();
    const name = autocomplete?.getPlaces()?.[0]?.name;

    form?.setFieldValue("description", inputRef?.current?.value);
    form?.setFieldValue("name", name);

    form?.setFieldValue("address", address);

    form?.setFieldValue(["location", "lat"], lat);
    form?.setFieldValue(["location", "lng"], lng);
    setMarker({ lat: lat, lng: lng });
  };

  return (
    <div style={{ width: "100%" }}>
      <StandaloneSearchBox
        onLoad={onLoad}
        onPlacesChanged={() => onPlacesChanged()}
      >
        <>
          <input
            ref={inputRef}
            // disabled={list.length <= 1}
            placeholder={placeholder}
            defaultValue={defaultValue}
            style={{
              fontSize: "14px",
              width: "100%",
              lineHeight: 1.7,
            }}
          ></input>
        </>
      </StandaloneSearchBox>
    </div>
  );
}; // Usage of DebounceSelect
