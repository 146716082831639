import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, Image, Spin, Typography, Divider, Select, Space, Card, Table, Avatar, DatePicker } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import API from '../../../../shared/api/api';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { dateFormat, dateFormatYMD } from '../../../../shared/dataFormat';
import { NUMBER_OF_AXLES_OPTION, NUMBER_OF_FEETS_OPTIONS, PAYLOAD_OPTION, PRESERVATION_OPTIONS, VEHICLE_OPTION } from '../../../../shared/define';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
const { Title } = Typography;
function ShippingOrderDetail() {
    let { id }: any = useParams();
    const history = useHistory();
    const [listImage, setListImage] = useState<any>([]);
    const [spinning, setSpinning] = useState(true);
    const [form] = Form.useForm();
    const [vehicleType, setVehicleType] = useState<any>('');
    const [isloading, setIsLoading] = useState(true);

    useEffect(() => {
        // getTruckVendorList();
        loadData();
        return () => {
        }
    }, [])
    const loadData = () => {
        setSpinning(true)
        API.get(`admin/shipping-order/${id}`)
            .then(
                res => {
                    if (res.data.success) {
                        let listData = res.data.data;
                        for (let i = 0; i < listData.receipt.length; i++) {
                            listData.receipt[i] = { ...listData.receipt[i], time: dateFormat(listData.receipt[i].time) }
                        }
                        for (let i = 0; i < listData.delivery.length; i++) {
                            listData.delivery[i] = { ...listData.delivery[i], time: dateFormat(listData.delivery[i].time) }
                        }
                        form.setFieldsValue(listData);
                        setListImage(listData?.goods?.avatars);
                        setVehicleType(listData.vehicle.name);
                    }
                }
            )
            .catch(error => {
                console.log(error);
            })
            .finally(() => { setSpinning(false) })
    }

    return (
        <Row style={{ width: '100%' }}>
            <Spin spinning={spinning}>
                <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}><span onClick={() => { history.goBack() }}><LeftOutlined />Quay lại</span></Title>
                <Form form={form} {...layout} style={{ width: '100%' }} >
                    <Row gutter={[32, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Ảnh hàng"
                                name={['goods', 'avatar']}
                            >
                                <Space>
                                    {listImage.map((image: string, index: any) => (
                                        <Image key={index} width={110} height={110} src={image} />
                                    ))}
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row gutter={[32, 0]}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Tên khách hàng"
                                        name={['customer', 'name']}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Số điện thoại"
                                        name={['customer', 'phoneNumber']}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Tên hãng tàu"
                                        name={'shippingLines'}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Mã đơn"
                                        name={'code'}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                label="Tên hàng"
                                name={['goods', 'name']}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                label="Khối lượng"
                                name={['goods', 'volume']}
                            >
                                <Input disabled suffix='tấn' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                label="Tính chất hàng hóa"
                                name={['goods', 'preservation']}
                            >
                                <Select disabled>
                                    {PRESERVATION_OPTIONS.map((item: any, index: any) =>
                                        <Option key={index} value={item.value}>{item.label}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                label="Loại"
                                name={['vehicle', 'name']}
                            // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                            >
                                <Select disabled>
                                    {VEHICLE_OPTION.map((item: any, index: any) =>
                                        <Option key={index} value={item.value}>{item.label}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        {vehicleType === 'CONTAINER' &&
                            <Col xs={24} sm={8}>

                                <Form.Item
                                    label="Số trục xe"
                                    name={['vehicle', 'numberOfAxles']}
                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                >
                                    <Select disabled>
                                        {NUMBER_OF_AXLES_OPTION.map((item: any, index: any) =>
                                            <Option key={index} value={item.value}>{item.label}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        {vehicleType === 'CONTAINER' &&
                            <Col xs={24} sm={8}>

                                <Form.Item
                                    label="Loại Container"
                                    name={['vehicle', 'numberOfFeets']}
                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                >
                                    <Select disabled>
                                        {NUMBER_OF_FEETS_OPTIONS.map((item: any, index: any) =>
                                            <Option key={index} value={item.value}>{item.label}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        {vehicleType === 'TRUCK' &&
                            <Col xs={24} sm={8}>
                                <Form.Item
                                    label="Tải trọng"
                                    name={['vehicle', 'payload']}
                                >
                                    <Select
                                        disabled
                                    >
                                        {PAYLOAD_OPTION.map((item: any, index: any) =>
                                            <Option key={index} value={item.value}>{item.label}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        {vehicleType === 'TRUCK' &&
                            <Col xs={24} sm={8}></Col>
                        }
                        <Col xs={24} sm={8}>
                            <Form.Item
                                label="Chủ hàng"
                                name={['forwarder', 'name']}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Lưu ý khi đóng (trả) hàng"
                                name={['goods', 'packingInstruction']}
                            >
                                <Input.TextArea rows={4} disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Yêu cầu kĩ thuật xe"
                                name={['goods', 'technicalRequirement']}
                            >
                                <Input.TextArea rows={4} disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Title level={5} style={{ marginBottom: '25px' }}>Thông tin điểm nhận hàng</Title>
                    <Form.List name='receipt'>
                        {(fields) => (
                            <>
                                {fields.map((field: any) => (
                                    <Card key={field.key} style={{ margin: '0px 10px 10px 10px', position: 'relative' }}>

                                        <Row key={field.key} gutter={[32, 0]} style={{ marginLeft: '0', marginRight: '0' }}>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Tên người liên hệ "
                                                    name={[field.name, 'name']}
                                                    fieldKey={[field.fieldKey, 'name']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Số điện thoại"
                                                    name={[field.name, 'phoneNumber']}
                                                    fieldKey={[field.fieldKey, 'phoneNumber']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Thời điểm khởi hành"
                                                    name={[field.name, 'time']}
                                                    fieldKey={[field.fieldKey, 'time']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Khu vực nhận hàng dự kiến"
                                                    name={[field.name, 'area', 'province']}
                                                    fieldKey={[field.fieldKey, 'province']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Địa chỉ nhận hàng dự kiến"
                                                    name={[field.name, 'text']}
                                                    fieldKey={[field.fieldKey, 'text']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </>
                        )}
                    </Form.List>
                    <Title level={5} style={{ marginBottom: '25px' }}>Thông tin điểm trả hàng</Title>
                    <Form.List name='delivery'>
                        {(fields) => (
                            <>
                                {fields.map((field: any) => (
                                    <Card key={field.key} style={{ margin: '0px 10px 10px 10px', position: 'relative' }}>

                                        <Row key={field.key} gutter={[32, 0]} style={{ marginLeft: '0', marginRight: '0' }}>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Tên người liên hệ "
                                                    name={[field.name, 'name']}
                                                    fieldKey={[field.fieldKey, 'name']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Số điện thoại"
                                                    name={[field.name, 'phoneNumber']}
                                                    fieldKey={[field.fieldKey, 'phoneNumber']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Thời điểm khởi hành"
                                                    name={[field.name, 'time']}
                                                    fieldKey={[field.fieldKey, 'time']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Khu vực nhận hàng dự kiến"
                                                    name={[field.name, 'area', 'province']}
                                                    fieldKey={[field.fieldKey, 'province']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Địa chỉ nhận hàng dự kiến"
                                                    name={[field.name, 'text']}
                                                    fieldKey={[field.fieldKey, 'text']}
                                                // rules={[{ required: true, message: 'Không được để trống tên!' }]}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form>


            </Spin>
        </Row>
    )
}

export default ShippingOrderDetail
