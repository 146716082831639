import React, { useState, useEffect } from 'react';
import { Table, Drawer, Avatar, Popconfirm, Button, message, Typography, Row, Input, Col, Space, Select } from 'antd';
import API from '../../shared/api/api';
import moment from 'moment';
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './AssignVehicleDriver.css'
const { Title } = Typography;
const {Search} = Input
const { Option } = Select;

function AssignVehicleDriver() {

    const [vehicleList, setVehicleList] = useState([]);
    const [driverList, setDriverList] = useState([])
    const [trailerList, setTrailerList] = useState([])

    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false)
    const [assignType, setAssignType] = useState('DRIVER')

    const [vehicle, setVehicle] = useState(null)

    const [filter, setFilter] = useState({})
    const [driverFilter, setDriverFilter] = useState({})

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0
    })

    const fetchData = async () => {
        setLoading(true)
        const [vehicleRresponse, driverResponse, trailerResponse] : any = await Promise.all([
            API.get('admin/vehicle/', { params: { ...filter,  current: pagination.current, pageSite: pagination.pageSize}}).then(res => res.data),
            API.get('admin/driver/all').then(res => res.data),
            API.get('admin/trailer/all').then(res => res.data)
        ])

        if(vehicleRresponse.success && driverResponse.success && trailerResponse.success) {
            setVehicleList(vehicleRresponse.data)
            setPagination({...pagination, total: vehicleRresponse.totalItem})
            setDriverList(driverResponse.data)
            setTrailerList(trailerResponse.data)
        }
        
        setLoading(false)
    }

    const onAssign = async (driverId:any) => {
        const assignResponse = await API.put('admin/vehicle/' + vehicle + '/assign/' + driverId).then(res => res.data)
        if(assignResponse.success) message.success(assignResponse.message) 
        else message.error(assignResponse.message)
        setVisible(false)
        setVehicle(null)
        fetchData()
    }
    
    const onRemove = async (vehicleId:any) => {
        const removeResponse = await API.put('admin/vehicle/' + vehicleId + '/remove-driver').then(res => res.data)
        if(removeResponse.success) message.success(removeResponse.message) 
        else message.error(removeResponse.message)
        fetchData()
    }

    const onAssignTrailer = async (trailerId:any) => {
        const assignResponse = await API.put('admin/vehicle/' + vehicle + '/assign/trailer/' + trailerId).then(res => res.data)
        if(assignResponse.success) message.success(assignResponse.message) 
        else message.error(assignResponse.message)
        setVisible(false)
        setVehicle(null)
        fetchData()
    }
    
    const onRemoveTrailer = async (vehicleId:any) => {
        const removeResponse = await API.put('admin/vehicle/' + vehicleId + '/remove-trailer').then(res => res.data)
        if(removeResponse.success) message.success(removeResponse.message) 
        else message.error(removeResponse.message)
        fetchData()
    }

    useEffect(() => {
        fetchData()
    }, [filter, driverFilter, pagination.current])

    const renderAssignDriver = (driver : any) => 
        driver.vehicle ? 
            <Button disabled className="driver">
                <div>
                    <Avatar style={{marginRight: 10}} src={driver.avatar} />{driver.name}
                </div>
                { moment(driver.dateOfBirth).format('DD/MM/YYYY') }
            </Button> 
            : 
            <Button className="driver">
                <div>
                    <Avatar style={{marginRight: 10}} src={driver.avatar} />{driver.name}
                </div>
                <div className="driverAction">
                    <div className="driverDateOfBirth">{ moment(driver.dateOfBirth).format('DD/MM/YYYY') }</div>
                    <Popconfirm
                        title="Chỉ định xế cho xe?"
                        onConfirm={() => onAssign(driver._id)}
                        placement="topLeft"
                        okText="Xác nhận"
                        cancelText="Hủy"
                    >
                        <PlusCircleOutlined style={{fontSize: 20}} className="removeDriver" />
                    </Popconfirm>
                </div>
            </Button>

    const renderAssignedDriver = (driver : any, vehicleId : any) =>  
        driver ? 
            <Button className="driver">
                <div>
                    <Avatar style={{marginRight: 10}} src={driver.avatar} />{driver.name}
                </div>
                <div className="driverAction">
                    <div className="driverDateOfBirth">{ moment(driver.dateOfBirth).format('DD/MM/YYYY') }</div>
                    <Popconfirm
                        title="Gỡ xế khỏi xe?"
                        onConfirm={() => onRemove(vehicleId)}
                        okText="Xác nhận"
                        cancelText="Hủy"
                    >
                        <CloseCircleOutlined style={{fontSize: 20}} className="removeDriver" />
                    </Popconfirm>
                </div>
            </Button> 
            : 
            <Button className="driver addDriver" onClick={() => { setAssignType('DRIVER'); setVehicle(vehicleId); setVisible(true)}}> 
                <PlusCircleOutlined style={{fontSize: 20}} />
            </Button>

    
    const renderAssignTrailer = (trailer : any) => 
        trailer.vehicle ? 
            <Button disabled className="driver">
                {trailer.licensePlate}
            </Button> 
            : 
            <Button className="driver">
                <div>
                    {trailer.licensePlate}
                </div>
                <div className="driverAction">
                    <Popconfirm
                        title="Chỉ định xế cho xe?"
                        onConfirm={() => onAssignTrailer(trailer._id)}
                        placement="topLeft"
                        okText="Xác nhận"
                        cancelText="Hủy"
                    >
                        <PlusCircleOutlined style={{fontSize: 20}} className="removeDriver" />
                    </Popconfirm>
                </div>
            </Button>


    const renderAssignedTrailer = (trailer : any, vehicleId : any) =>  
        trailer ? 
            <Button className="driver">
                {trailer.licensePlate}
                <div className="driverAction">
                    <Popconfirm
                        title="Gỡ Rơ mooc khỏi xe?"
                        onConfirm={() => onRemoveTrailer(vehicleId)}
                        okText="Xác nhận"
                        cancelText="Hủy"
                    >
                        <CloseCircleOutlined style={{fontSize: 20}} className="removeDriver" />
                    </Popconfirm>
                </div>
            </Button> 
            : 
            <Button className="driver addDriver" onClick={() => {setAssignType('TRAILER'); setVehicle(vehicleId); setVisible(true)}}> 
                <PlusCircleOutlined style={{fontSize: 20}} />
            </Button>


    const columns : Array<object>  = [
        {
            title:'STT',
            dataIndex:'_id',
            render: (id : any, record : any, index : any) => index + 1
        },
        {
            title:'Loại',
            dataIndex:'type',
            render: (type : any) => <>{type.name}</>
        },
        {
            title:'Biển số',
            dataIndex:'licensePlate'
        },
        {
            title:'Đơn vị',
            dataIndex:'type',
            render: (type : any) => <>{type.name === 'CONTAINER' ? `${type.numberOfAxles} trục, ${type.numberOfFeets} feet` : `${type.payload} tấn`}</>
        },
        {
            title:'Công ty',
            dataIndex:'truckVendor',
            render: (truckVendor : any) => <>{truckVendor.name}</>
        },
        {
            title:'Tài xế',
            dataIndex:'driver',
            render: (driver : any, row : any) => renderAssignedDriver(driver, row._id)
        },
        {
            title:'Rơ mooc',
            dataIndex:'trailer',
            render: (trailer : any, row : any) => renderAssignedTrailer(trailer, row._id)
        },
        // {
        //     title:'Trạng thái',
        //     dataIndex:'truckVendor',
        //     render: (truckVendor : any) => <>Hoàn thành</>
        // }
    ]

    return (
        <>
            <Title level={4}>Ghép xe và xế ({pagination.total})</Title>
            <Row justify="space-between" gutter={8}>
                <Col>
                    <Space size={8}>
                        Chỉ định
                        <Select size="small" defaultValue="ALL" style={{ width: 150 }} onChange={(value) => setFilter({...filter, driverStatus: value}) }>
                            <Option value="ALL">Tất cả</Option>
                            <Option value="ASSIGNED">Đã chỉ định</Option>
                            <Option value="AVAILABLE">Chưa chỉ định</Option>
                        </Select>
                        <br /><br /><br />
                        Loại xe
                        <Select size="small" defaultValue="ALL" style={{ width: 150 }} onChange={(value) => setFilter({...filter, type: value})}>
                            <Option value="ALL">Tất cả</Option>
                            <Option value="CONTAINER">Xe Container</Option>
                            <Option value="TRUCK">Xe tải</Option>
                        </Select>
                    </Space>
                </Col>
                <Search disabled size="small" placeholder="Tìm kiếm" style={{ width: 150 }}  onChange={(value) => setFilter({...filter, text: value})} />
            </Row>
            <br />
            <Table 
                loading={loading} 
                columns={columns} 
                dataSource={vehicleList} 
                pagination={pagination}
                onChange={(value: any) => setPagination({ ...pagination, current: value.current })}
            />
            <Drawer
                placement="right"
                width={400}
                onClose={() => setVisible(false)}
                visible={visible}
                title={assignType === 'DRIVER' ? 'Thêm tài xế' : 'Thêm Rơ Mooc'}
                getContainer={false}
            >
                {/* <Search
                    placeholder="Tìm kiếm"
                    disabled
                    allowClear
                    style={{borderRadius: 5, width: '100%', margin: 5}} 
                    onSearch={(value) => setDriverFilter({...driverFilter, text: value})}
                />
                <br/> */}
                { assignType === 'DRIVER' ? driverList.map(driver => renderAssignDriver(driver)) : trailerList.map(driver => renderAssignTrailer(driver))}
            </Drawer>
            {/* <Drawer
                placement="right"
                width={400}
                onClose={() => setVisible(false)}
                visible={visible}
                title="Thêm rơ mooc"
                getContainer={false}
            >
                <Search
                    placeholder="Tìm kiếm"
                    disabled
                    allowClear
                    style={{borderRadius: 5, width: '100%', margin: 5}} 
                    onSearch={(value) => setDriverFilter({...driverFilter, text: value})}
                />
                <br/>
                { trailerList.map(driver => renderAssignTrailer(driver))}
            </Drawer> */}
        </>
    )
}

export default AssignVehicleDriver
