import React, { useState, useEffect } from 'react'
import { Drawer, Col, Row, Typography, Divider, Space, Input, Button, Spin, Radio } from 'antd';
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { getBotOfVehicle, getUnassignBotOfVehicle, updateBotOfVehicle } from '../../../shared/api/Vehicle';

const { Title } = Typography
function AssignBotVehicle(props: any) {
    const { id, visible, fetchForwarderList } = props
    const [isLoading, setIsLoading] = useState(false)
    const [unassignBot, setUnassignBot] = useState<any>([])
    const [assignBot, setAssignBot] = useState<any>([])

    useEffect(() => {
        id && getBotList()
    }, [id])

    const getBotList = async () => {
        setIsLoading(true)
        if (id != '') {
            const response1: any = await getBotOfVehicle(id)
            const response2: any = await getUnassignBotOfVehicle(id)
            setAssignBot(response1.data)
            setUnassignBot(response2.data)
            if (response1 && response2) setIsLoading(false)
        }
    }

    const onClose = () => {
        visible.setDrawerVisible(false)
    };
    const addBot = (index: any) => {
        const assign = [...assignBot]
        const unAssign = [...unassignBot]
        assign.push({ bot: unAssign[index], type: '' })
        unAssign.splice(index, 1)
        setAssignBot(assign)
        setUnassignBot(unAssign)
    }
    const deleteBot = (index: any) => {
        const assign = [...assignBot]
        const unAssign = [...unassignBot]
        unAssign.push(assign[index].bot)
        assign.splice(index, 1)
        setAssignBot(assign)
        setUnassignBot(unAssign)
    }
    const onChangeType = (index: any, value: any) => {
        const cloneData = [...assignBot]
        cloneData[index].type = value
        console.log(cloneData)
        setAssignBot(cloneData)
    }
    const onUpdate = async () => {
        const response: any = await updateBotOfVehicle(id, assignBot)
        if (response.success) {
            fetchForwarderList()
            onClose()
        }
    }
    return (
        <Drawer
            width={540}
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible.drawerVisible}
        >
            <Spin spinning={isLoading}>
                <Row gutter={[8, 0]}>
                    <Col span={12}><Title level={5}>Thêm BOT</Title></Col>
                    <Col span={12}><Space style={{ display: 'flex', justifyContent: 'space-between' }}><Title level={5}>Đã sở hữu</Title><CloseOutlined onClick={() => onClose()} style={{ position: 'relative', top: -16 }} /></Space></Col>
                    <Divider />
                    <Col span={12}>
                        <Row gutter={[8, 0]}>
                            {/* <Col span={12}><Search></Search></Col>
                            <Col span={12}><Input></Input></Col> */}

                            <Col span={24} style={{ marginTop: 0 }}>
                                {
                                    unassignBot.map((item: any, index: any) =>
                                        <div style={{ border: "1px solid #CFD8DC", borderRadius: 5, padding: '3px 12px', marginTop: 4, display: 'flex', justifyContent: 'space-between' }}>{item.name}<PlusOutlined onClick={() => addBot(index)} style={{ position: 'relative', top: 4 }} /></div>
                                    )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} >
                        <Row>
                            <Col span={24} style={{ marginTop: 0 }}>
                                <Col span={24} >
                                    {
                                        assignBot.map((item: any, index: any) =>
                                            <div style={{ border: "1px solid #CFD8DC", borderRadius: 5, padding: '3px 12px', marginTop: 4 }}>
                                                <Space style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 6 }}>
                                                    {item.bot.name}<MinusOutlined onClick={() => deleteBot(index)} style={{ position: 'relative', top: 1 }} />
                                                </Space>
                                                <Radio.Group value={item.type} onChange={(e: any) => onChangeType(index, e.target.value)}>
                                                    <Radio value={'MONTHLY'}>Tháng</Radio>
                                                    <Radio value={'QUARTERLY'}>Quý</Radio>
                                                    <Radio value={'YEARLY'}>Năm</Radio>
                                                </Radio.Group>
                                            </div>
                                        )
                                    }
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
            <Button style={{ position: 'absolute', bottom: 18, left: 24, width: 232 }} type='primary' onClick={() => onUpdate()}>Lưu</Button>
        </Drawer>
    )
}

export default AssignBotVehicle
