import FileSaver from "file-saver";
import moment from "moment";
import API from "./../api";

export const getAllOil = async () => {
  try {
    const response = await API.get("admin/oil").then((res) => res.data);
    if (response.success) {
      const listData = [...response.data];
      listData.forEach((item: { key: any; _id: any }) => {
        item.key = item._id;
      });
      return { data: listData, total: response.totalItem };
    }
  } catch (error) {
    throw error;
  }
};

export const updateOil = async (id: string, data: object) => {
  try {
    const response = await API.put(`admin/oil/${id}`, data).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createOil = async (params: any) => {
  try {
    const response = await API.post(`admin/oil`, params).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGasStation = async (params: any) => {
  try {
    const response = await API.get(`admin/gas-station`, params).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createGasStation = async (body: any) => {
  try {
    const response = await API.post(`admin/gas-station`, body).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateGasStation = async (body: any) => {
  try {
    const response = await API.put(`admin/gas-station/${body._id}`, body).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllRefueling = async (params: any) => {
  try {
    const response = await API.get(`admin/refueling`, params).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createRefueling = async (body: any) => {
  try {
    const response = await API.post(`admin/refueling`, body).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateRefueling = async (body: any) => {
  try {
    const response = await API.put(`admin/refueling/${body._id}`, body).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteRefueling = async (id: string) => {
  try {
    const response = await API.delete(`admin/refueling/${id}`).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRemainingFuel = async () => {
  try {
    const response = await API.get(`admin/refueling/remainingFuel`).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const lockRefueling = async (params: any) => {
  try {
    const response = await API.put(`admin/refueling/lockRefueling`, {
      params: params,
    }).then((res) => res.data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const exportRefueling = async (params: any) => {
  const query = {
    range: [
      moment.utc(params?.range[0]).format("YYYY-MM-DD HH:mm:ssZ"),
      moment.utc(params?.range[1]).format("YYYY-MM-DD HH:mm:ssZ"),
    ],
  };
  try {
    await API.get(`admin/report/refueling`, {
      params: query,
      responseType: "arraybuffer",
    }).then((response) => {
      var blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(
        blob,
        `report-refueling-${moment().format("DD-MM-YYYY")}.xlsx`
      );
    });
  } catch (error) {
    throw error;
  }
};
