import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Row, Input, Typography, Tooltip} from 'antd';
import API from '../../../shared/api/api';
import { PlusOutlined, EditOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../../shared/dataFormat';
const { Title } = Typography;
const { Search } = Input

function SeaportList() {
    const [seaport, setSeaport] = useState({
        list: [],
        loading: false,
        filter: {
            text: ''
        },
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0
        }
    })

    useEffect(() => {
        fetchSeaport()
    }, [seaport.filter, seaport.pagination.current])

    const fetchSeaport = async () => {
        setSeaport({...seaport, loading: true})
        const response: any = await API.get(
            'admin/seaport', 
            { params: { ...seaport.filter, current: seaport.pagination.current, pagesize: seaport.pagination.pageSize } 
        }).then(res => res.data)

        if (response.success) {
            setSeaport({
                ...seaport,
                list: response.data,
                pagination: {
                    ...seaport.pagination,
                    total: response.totalItem
                },
                loading: false
            })
        }
    }

    const columns: Array<object> = [
        {
            title: 'STT',
            width: 50,
            render: (id: any, record: any, index: any) => index + 1
        },
        {
            title: 'Tên',
            dataIndex: 'name',
        },
        {
            title: 'Tên đầy đủ',
            dataIndex:'fullName',
        },
        {
            title: 'Khu vực',
            dataIndex: ['address', 'province']
        }
    ]
    return (
        <>
            <Row justify="space-between" gutter={6}>
            <Title level={4}>Quản lý cảng</Title>
                <Space size={6}>
                    <Search
                        // disabled
                        placeholder="Search"
                        size="small"
                        allowClear
                        style={{ width: 150, margin: '0 10px' }}
                        onSearch={(value) => setSeaport({ ...seaport, filter: { ...seaport.filter, text: value } })}
                    />
                    <Button disabled type="primary" size="small" icon={<PlusOutlined />}>
                        <Link to={{
                                // pathname: '/partner/seaport/create',
                            }} style={{ color: 'white' }}>
                                Tạo mới
                        </Link>
                    </Button>
                </Space>
            </Row>
            <br />
            <Table
                scroll={{ x: 'max-content'}}
                rowKey='_id'
                loading={seaport.loading}
                columns={columns}
                pagination={seaport.pagination.total > seaport.pagination.pageSize && seaport.pagination}
                dataSource={seaport.list}
                onChange={(value: any) => setSeaport({ ...seaport, pagination: {...seaport.pagination, current: value.current }})}
            />
        </>
    )
}

export default SeaportList
