import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Row, Input, Typography, Tooltip } from 'antd';
import API from '../../../shared/api/api';
import { PlusOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../../shared/dataFormat';
const { Title } = Typography;
const { Search } = Input;

function CustomerList() {
  const [customer, setCustomer] = useState({
    list: [],
    loading: false,
    filter: {
      text: '',
    },
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });

  useEffect(() => {
    fetchCustomer();
  }, [customer.filter, customer.pagination.current]);

  const fetchCustomer = async () => {
    setCustomer({ ...customer, loading: true });
    const response: any = await API.get('admin/customer', {
      params: {
        ...customer.filter,
        current: customer.pagination.current,
        pagesize: customer.pagination.pageSize,
      },
    }).then((res) => res.data);

    if (response.success) {
      setCustomer({
        ...customer,
        list: response.data,
        pagination: {
          ...customer.pagination,
          total: response.totalItem,
        },
        loading: false,
      });
    }
  };

  const columns: Array<object> = [
    {
      title: 'STT',
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'code',
    },
    {
      title: 'Tên đầy đủ',
      dataIndex: 'fullName',
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Nhân viên CSKH',
      dataIndex: 'accountManager',
    },
    {
      title: 'Mã số thuể',
      dataIndex: ['businessLicense', 'text'],
    },
    {
      title: 'Tỉnh/TP',
      dataIndex: ['address', 'province'],
    },
    {
      title: 'Địa chỉ',
      dataIndex: ['address', 'text'],
    },
    {
      title: 'Forwarder',
      dataIndex: ['forwarder', 'name'],
    },

    {
      title: 'Tác vụ',
      align: 'center',
      width: 100,
      render: (customer: any) => (
        <Space size="middle">
          {/* <Tooltip title="Xem">
                        <Link to={{
                            pathname: `/resource/driver/detail/${driver._id}`,
                        }}>
                            <EyeOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip> */}
          <Tooltip title="Sửa">
            <Link
              to={{
                pathname: `/partner/customer/${customer._id}/edit/`,
              }}
            >
              <EditOutlined style={{ fontSize: 16 }} />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Row justify="space-between" gutter={6}>
        <Title level={4}>Quản lý khách hàng</Title>
        <Space size={6}>
          <Search
            // disabled
            placeholder="Search"
            size="small"
            allowClear
            style={{ width: 150, margin: '0 10px' }}
            onSearch={(value) =>
              setCustomer({
                ...customer,
                filter: { ...customer.filter, text: value },
              })
            }
          />
          <Button type="primary" size="small" icon={<PlusOutlined />}>
            <Link
              to={{
                pathname: '/partner/customer/create',
              }}
              style={{ color: 'white' }}
            >
              Tạo mới
            </Link>
          </Button>
        </Space>
      </Row>
      <br />
      <Table
        scroll={{ x: 'max-content' }}
        rowKey="_id"
        loading={customer.loading}
        columns={columns}
        pagination={
          customer.pagination.total > customer.pagination.pageSize &&
          customer.pagination
        }
        dataSource={customer.list}
        onChange={(value: any) =>
          setCustomer({
            ...customer,
            pagination: { ...customer.pagination, current: value.current },
          })
        }
      />
    </>
  );
}

export default CustomerList;
