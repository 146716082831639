import { AutoComplete } from "antd";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { searchPlaces } from "../../../../shared/api/Route";

export const TagList: React.FC<{
  value?: any[];
  onChange?: (value: any[]) => void;
  placeholder: string;
  setId: any;
}> = ({ value, onChange, placeholder, setId }) => {
  const [listPlaces, setListPlaces] = useState<any[]>([]);
  const handleSearch = async (txt: string) => {
    const response: any = await searchPlaces(txt);
    if (response) {
      if (response.data) {
        const result = response.data.map((item: any) => {
          return {
            label: `${item.name}, ${item.address}`,
            value: `${item.name}, ${item.address}`,
            data: item,
          };
        });
        setListPlaces(result);
      }
    }
  };

  const debouncedChangeHandler = useMemo(
    () => _.debounce((text) => handleSearch(text), 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const onSearch = (text: string) => {
    debouncedChangeHandler(text);
  };

  return (
    <AutoComplete
      options={listPlaces}
      onSearch={onSearch}
      defaultValue={value?.[0]?.text}
      onChange={(value, option) => {
        const newItem = {
          text: `${option?.data?.name}, ${option?.data?.address}`,
          place: option?.data?._id,
          area: option?.data?.dId,
        };

        if (option) {
          onChange?.([newItem]);
          setId?.(option?.data?.dId);
        }
      }}
      placeholder={placeholder}
    ></AutoComplete>
  );
};
