import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Row, Input, Typography, Col, Select, Tooltip } from 'antd';
import API from '../../../shared/api/api';
import { PlusOutlined, EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { successNotification } from '../../../shared/notification'
import moment from 'moment';
import AssignBotVehicle from './AssignBotVehicle';

const { Title } = Typography;
const { Search } = Input
const { Option } = Select;

function VehicleList() {
    const [driverList, setdriverList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({})

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0
    })

    const [drawerVisible, setDrawerVisible] = useState<Boolean>(false)
    const [id, setId] = useState("")

    const columns: Array<object> = [
        {
            title: 'STT',
            dataIndex: '_id',
            render: (id: any, record: any, index: any) => index + 1
        },
        {
            title: 'Biển số',
            dataIndex: 'licensePlate'
        },
        {
            title: 'Loại',
            dataIndex: 'type',
            render: (type: any) => <>{type.name}</>
        },
        {
            title: 'Đơn vị',
            dataIndex: 'type',
            render: (type: any) => <>{type?.name === 'CONTAINER' ? `${type?.numberOfAxles} trục, ${type?.numberOfFeets} feet` : `${type?.payload} tấn`}</>
        },
        {
            title: 'Hạn đăng kiểm',
            dataIndex: 'registrationExpireDate',
            render: (registrationExpireDate: any) => moment(registrationExpireDate).format('DD/MM/YYYY')
        },
        {
            title: 'Hạn phù hiệu',
            dataIndex: 'transportBadgeExpireDate',
            render: (transportBadgeExpireDate: any) => moment(transportBadgeExpireDate).format('DD/MM/YYYY')
        },
        {
            title: 'Hạn BHNS',
            dataIndex: 'civilLiabilityInsuranceExpireDate',
            render: (civilLiabilityInsuranceExpireDate: any) => moment(civilLiabilityInsuranceExpireDate).format('DD/MM/YYYY')
        },
        {
            title: 'Công ty',
            dataIndex: 'truckVendor',
            render: (truckVendor: any) => <>{truckVendor?.name}</>
        },
        {
            title: 'Tài xế',
            dataIndex: 'driver',
            render: (driver: any) => <>{driver ? driver.name : 'Chưa có'}</>
        },
        {
            title: 'Trạm thu phí',
            dataIndex: 'bots',
            render: (text: any, record: any) => <><Button>{record.bots ? record.bots.length : '0'}</Button> <Button onClick={() => { setDrawerVisible(true); setId(record._id) }} ><PlusOutlined /></Button></>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'driver',
            render: (driver: any) => <>Đang chạy</>
        },
        {
            title: 'Tác vụ',
            align: "center",
            width: 100,
            render: (vehicle: any) => (
                <Space size="middle">
                    <Tooltip title="Xem">
                        <Link to={{
                            pathname: `/resource/vehicle/detail/${vehicle._id}`,
                        }}>
                            <EyeOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Sửa">
                        <Link to={{
                            pathname: `/resource/vehicle/edit/${vehicle._id}`,
                        }}>
                            <EditOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip>
                    {/* <Tooltip title="Xóa">
                        <DeleteOutlined style={{ fontSize: 16, color: 'red' }} onClick={() => { onDelete(vehicle) }} />
                    </Tooltip> */}
                </Space>
            ),
        },
    ]

    useEffect(() => {
        fetchForwarderList()
    }, [filter, pagination.current])
    
    const fetchForwarderList = async () => {
        setLoading(true)
        const response = await API.get('admin/vehicle/', { params: { ...filter, current: pagination.current } }).then(res => res.data)
        if (response.success) {
            setdriverList(response.data)
            setPagination({ ...pagination, total: response.totalItem })
            setLoading(false)
        }
    }

    

    const onDelete = (vehicle: any) => {
        API.delete(`admin/vehicle/${vehicle._id}`)
            .then(
                res => {
                    if (res.data.success) {
                        if (res.data.success === false) {
                            return
                        } else {
                            successNotification('Xóa thành công', '');
                            fetchForwarderList();
                            return
                        }
                    }
                })
    }
    return (
        <>
            <AssignBotVehicle fetchForwarderList={fetchForwarderList} id={id} visible={{ drawerVisible: drawerVisible, setDrawerVisible: (option: Boolean) => setDrawerVisible(option) }} />
            <Title level={4}>Quản lý xe ({pagination.total})</Title>
            <Row justify="space-between" gutter={8}>
                <Col>
                    <Space size={8}>
                        Chỉ định
                        <Select size="small" defaultValue="ALL" style={{ width: 150 }} onChange={(value) => setFilter({ ...filter, driverStatus: value })}>
                            <Option value="ALL">Tất cả</Option>
                            <Option value="ASSIGNED">Đã chỉ định</Option>
                            <Option value="AVAILABLE">Chưa chỉ định</Option>
                        </Select>
                        <br /><br /><br />
                        Loại xe
                        <Select size="small" defaultValue="ALL" style={{ width: 150 }} onChange={(value) => setFilter({ ...filter, type: value })}>
                            <Option value="ALL">Tất cả</Option>
                            <Option value="CONTAINER">Xe Container</Option>
                            <Option value="TRUCK">Xe tải</Option>
                        </Select>
                    </Space>
                </Col>
                <Space>
                    <Search
                        placeholder="Search"
                        allowClear
                        disabled
                        size="small"
                        style={{ width: 150, margin: '0 10px' }}
                        onChange={(value) => setFilter({ ...filter, text: value })}
                    />
                    <Link to={{ pathname: '/resource/vehicle/create' }} style={{ color: 'white' }}>
                        <Button size="small" type="primary" icon={<PlusOutlined />}>
                            Tạo mới
                        </Button>
                    </Link>
                </Space>
            </Row>
            <br />
            <Table
                scroll={{ x: 'max-content' }}
                loading={loading}
                rowKey={(record: any) => record._id}
                columns={columns}
                dataSource={driverList}
                pagination={pagination}
                onChange={(value: any) => setPagination({ ...pagination, current: value.current })}
            />
        </>
    )
}



export default VehicleList
