import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Popover,
  Button,
  message,
  Typography,
  Descriptions,
  Input,
  Space,
  Select,
  DatePicker,
  Tooltip,
  Modal,
  Form,
  InputNumber,
} from 'antd';
import {
  EnvironmentOutlined,
  PlusCircleOutlined,
  ImportOutlined,
  SplitCellsOutlined,
  ExportOutlined,
  ReloadOutlined,
  CopyOutlined,
  InfoCircleOutlined,
  EnterOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import API from '../../../shared/api/api';
import './AssignOrderVehicle.css';
import { dateFormat } from '../../../shared/dataFormat';
import {
  ROUTE_STATUS_OPTIONS,
  PRESERVATION_OPTIONS,
  PRESERVATION_OPTIONS_ICON,
  COMBINE_RATE,
} from '../../../shared/define';
import moment from 'moment';
import styled from 'styled-components';
import { getRouteOfVehicle } from '../../../shared/api/Vehicle';
import { getRoutes } from '../../../shared/api/Route';
import { getAllTruckVendor } from '../../../shared/api/TruckVendor';
import { getAllForwarder } from '../../../shared/api/Forwarder';
import { assignRouteToVehicle } from '../../../shared/api/Order/AssigningOrder';

const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const combinedDefault = {
  combined: false,
  route: '',
  combinedRoute: '',
  combinedRate: 0.5,
  container: '',
  truckVendor: '',
  date: new Date(),
};

const specificationRender: any = {
  IMPORT: {
    icon: <ImportOutlined style={{ marginRight: 5 }} />,
    style: {
      background: '#ec8230',
      borderColor: '#ec8230',
    },
    text: 'N',
  },
  EXPORT: {
    icon: <ExportOutlined style={{ marginRight: 5 }} />,
    style: {
      background: '#1890ff',
      borderColor: '#1890ff',
    },
    text: 'X',
  },
  ROAD: {
    icon: <SplitCellsOutlined style={{ marginRight: 5 }} />,
    style: {
      background: '#00bd01',
      borderColor: '#00bd01',
    },
    text: 'B',
  },
};

function AssignOrderVehicle() {
  const [contId, setContId] = useState('');
  const [contInput, setConInput] = useState('');
  const [vehicle, setVehicle] = useState({
    list: [],
    current: '',
    loading: false,
    count: 0,
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    filter: {},
  });

  const [route, setRoute] = useState({
    list: [],
    current: '',
    loading: false,
    count: 0,
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    filter: {},
  });

  const [truckVendor, setTruckVendor] = useState({
    list: [],
  });

  const [forwarder, setForwarder] = useState({
    list: [],
  });

  const [combined, setCombined] = useState(combinedDefault);
  // const [kmKoHang, setKmKoHang] = useState<any>('');

  useEffect(() => {
    fetchRoute();
  }, [route.filter, route.pagination.current]);

  useEffect(() => {
    fetchVehicle();
  }, [vehicle.filter, vehicle.pagination.current]);

  useEffect(() => {
    fetchTruckVendor();
    fetchForwarder();
  }, []);
  const fetch = () => {
    fetchRoute();
    fetchVehicle();
  };

  const fetchRoute = async () => {
    const response = await getRoutes({
      ...route.filter,
      pageSize: route.pagination.pageSize,
      current: route.pagination.current,
      assigned: 'FALSE',
      hasTruckVendor: 'TRUE',
    });

    setRoute({
      ...route,
      list: response.data,
      count: response.totalItem,
      pagination: {
        ...route.pagination,
        total: response.totalItem,
      },
      loading: false,
    });
  };

  const fetchVehicle = async () => {
    const response = await getRouteOfVehicle({
      ...vehicle.filter,
      pageSize: vehicle.pagination.pageSize,
      current: vehicle.pagination.current,
    });
    setVehicle({
      ...vehicle,
      current: '',
      list: response.data,
      count: response.totalItem,
      pagination: {
        ...vehicle.pagination,
        total: response.totalItem,
      },
      loading: false,
    });
  };

  const fetchTruckVendor = async () => {
    const response: any = await getAllTruckVendor();
    setTruckVendor({
      list: response.data,
    });
  };

  const fetchForwarder = async () => {
    const response: any = getAllForwarder();
    setForwarder({
      list: response.data,
    });
  };

  const assignRoute = async (id: any) => {
    if (vehicle.current) {
      const response = await assignRouteToVehicle({
        routeId: id,
        vehicleId: vehicle.current,
      });
      if (response.success) {
        setCombined({ ...combined, ...response.data });
        fetch();
      }
    }
  };

  const handleRouteAction = async (action: string, id: string) => {
    const response = await API.put(`admin/route/${id}/${action}`).then(
      (res) => res.data
    );
    if (response.success) fetch();
  };

  const handleCombinedRoute = async () => {
    const response = await API.put(
      `admin/route/${combined.route}/combinedExpense/${combined.combinedRoute}`,
      { rate: combined.combinedRate }
    ).then((res) => res.data);
    if (response.success) {
      setCombined(combinedDefault);
    }
  };

  const renderRoutes = (item: any) => (
    <Space>
      {item?.routes &&
        item?.routes?.map((item: any) => (
          <Popover content={renderRouteData(item)} trigger="hover">
            <Button
              type="primary"
              style={specificationRender[item.specification].style}
              icon={specificationRender[item.specification].icon}
            >
              {`${item?.receipt[0]?.area?.name} (${
                item?.receipt[0]?.area?.pName
              }) -
            ${item?.delivery[0]?.area?.name} (${
                item?.delivery[0]?.area?.pName
              }) (${
                ROUTE_STATUS_OPTIONS.find(
                  (element: any) => (element as any).value === item.status
                )?.label
              })`}
              {/* {`${item.receipt[0]?.area?.province
                ?.replace('Thành phố', '')
                ?.replace('Tỉnh', '')} (${renderArea(
                item?.receipt[0]?.area?.area
              )}) - ${item.delivery[0].area?.province
                ?.replace('Thành phố', '')
                ?.replace('Tỉnh', '')} (${renderArea(
                item?.delivery[0]?.area?.area
              )}) (${
                ROUTE_STATUS_OPTIONS.find(
                  (element: any) => (element as any).value === item.status
                )?.label
              })`} */}
            </Button>
          </Popover>
        ))}
      <Button
        disabled={(moment(item.registrationExpireDate) < moment() ||
          moment(item.transportBadgeExpireDate) < moment())}
        style={{
          backgroundColor: vehicle.current === item._id ? '#007eff' : '',
          color: vehicle.current === item._id ? 'white' : '',
        }}
        onClick={() => setVehicle({ ...vehicle, current: item._id })}
        icon={<PlusCircleOutlined style={{ fontSize: 14 }} />}
      ></Button>
    </Space>
  );

  const renderArea = (str: string) => {
    return str
      ?.replace('Huyện', '')
      ?.replace('Quận', 'Q')
      ?.replace('Thị xã', 'Tx')
      ?.replace('Thành phố', 'Tp');
  };

  const renderRoute = (item: any) => (
    <Popover content={renderRouteData(item)} trigger="hover" placement="left">
      <Button
        type="primary"
        onClick={() => assignRoute(item._id)}
        style={specificationRender[item.specification].style}
      >
        <Row justify="space-between" align="middle">
          <div>
            {specificationRender[item.specification].icon}
            {`${item?.receipt[0]?.area?.name} (${item?.receipt[0]?.area?.pName}) -
            ${item?.delivery[0]?.area?.name} (${item?.delivery[0]?.area?.pName})`}
          </div>
        </Row>
      </Button>
    </Popover>
  );

  const renderRouteData = (item: any) => (
    <Descriptions bordered style={{ maxWidth: 600 }}>
      <Descriptions.Item label="Mã chặng">{item?.code}</Descriptions.Item>
      <Descriptions.Item label="Tên hàng">
        {' '}
        {item?.goods?.volume} tấn {item?.goods?.name}
      </Descriptions.Item>
      <Descriptions.Item label="Khách hàng">
        {item?.order?.customer?.name}
      </Descriptions.Item>
      <Descriptions.Item label="Số container">
        {item?.container ? (
          item?.container
        ) : (
          <Input
            value={contInput}
            onChange={(e) => {
              setConInput(e.target.value);
            }}
            suffix={
              <StyleWrapper>
                <Space>
                  <EnterOutlined
                    onClick={() => {
                      setConInput(contId);
                    }}
                  />
                  <CheckOutlined
                    onClick={() => {
                      updateContainer(item._id);
                    }}
                  />
                </Space>
              </StyleWrapper>
            }
          />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Trạng thái">
        {
          ROUTE_STATUS_OPTIONS.find(
            (element: any) => (element as any).value === item?.status
          )?.label
        }
      </Descriptions.Item>
      <Descriptions.Item label="N/X">
        {specificationRender[item?.specification].text}
      </Descriptions.Item>
      {item?.goods?.technicalRequirement && (
        <Descriptions.Item label="Yêu cầu" span={3}>
          {item?.goods?.technicalRequirement}
        </Descriptions.Item>
      )}
      {item?.goods?.packingInstruction && (
        <Descriptions.Item label="Hướng dẫn" span={3}>
          {item?.goods?.packingInstruction}
        </Descriptions.Item>
      )}
      {item?.receipt &&
        item?.receipt?.map((receipt: any, index: any) => (
          <>
            <Descriptions.Item
              span={3}
              label={`Điểm nhận ${item?.receipt?.length > 1 ? index + 1 : ''}`}
            >
              Ngày {dateFormat(receipt?.time)} tại {receipt.text}{' '}
              {receipt?.area?.area} {receipt?.area?.rovince}
            </Descriptions.Item>
          </>
        ))}
      {item?.delivery &&
        item?.delivery.map((delivery: any, index: any) => (
          <>
            <Descriptions.Item
              span={3}
              label={`Điểm trả ${item?.receipt?.length > 1 ? index + 1 : ''}`}
            >
              Ngày {dateFormat(delivery?.time)} tại {delivery.text}{' '}
              {delivery?.area?.area} {delivery?.area?.rovince}
            </Descriptions.Item>
          </>
        ))}
      {item.status !== 'FINISH' && (
        <Descriptions.Item label="Tác vụ" span={3}>
          <Row gutter={6}>
            {item?.status === 'PENDING' && item?.vehicle && (
              <Col span={12}>
                <Button
                  onClick={() => handleRouteAction('remove', item?._id)}
                  type="primary"
                  style={{ width: '100%' }}
                >
                  Gỡ đơn
                </Button>
              </Col>
            )}
            {item?.status === 'PENDING' && !item?.vehicle && (
              <Col span={12}>
                <Button
                  onClick={() => handleRouteAction('reassignTruckVendor', item?._id)}
                  type="primary"
                  danger
                  style={{ width: '100%' }}
                >
                  Chỉ định lại nhà xe
                </Button>
              </Col>
            )}
            <Col span={12}>
              <Button
                onClick={() => handleRouteAction('cancel', item?._id)}
                type="primary"
                danger
                style={{ width: '100%' }}
              >
                Hủy đơn
              </Button>
            </Col>
          </Row>
        </Descriptions.Item>
      )}
    </Descriptions>
  );

  const renderVehicleData = (item: any) => (
    <Descriptions bordered style={{ maxWidth: 600 }}>
      <Descriptions.Item span={3} label="Tài xế">
        {item?.driver?.name}
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Số điện thoại">
        {item?.driver?.owner.phoneNumber}
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Nhà xe">
        {item?.truckVendor?.name}
      </Descriptions.Item>
      {moment(item.registrationExpireDate) < moment() &&
        <Descriptions.Item span={3} label="Hạn đăng kiểm" labelStyle={{color: 'orange'}} contentStyle={{color: 'orange'}}>
          {moment(item?.registrationExpireDate).format("DD/MM/YYYY")}
        </Descriptions.Item>
      }
      {moment(item.transportBadgeExpireDate) < moment() &&
        <Descriptions.Item span={3} label="Hạn phù hiệu" labelStyle={{color: 'orange'}} contentStyle={{color: 'orange'}}>
          {moment(item?.transportBadgeExpireDate).format("DD/MM/YYYY")}
        </Descriptions.Item>
      }
    </Descriptions>
  );
  const updateContainer = async (routeId: string) => {
    const response = await API.put(`admin/route/${routeId}/container`, {
      container: contInput,
    }).then((res) => res.data);
    if (response.success) fetchRoute();
  };
  const columns: Array<object> = [
    {
      title: 'STT',
      dataIndex: '_id',
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: 'Biển số',
      dataIndex: 'licensePlate',
      render: (licensePlate: any, record: any) => (
        <Popover content={renderVehicleData(record)} trigger="hover">
          <Button style={
            //expired registration or badge
            (moment(record.registrationExpireDate) < moment() ||
            moment(record.transportBadgeExpireDate) < moment()) && {
              color: 'orange'
            }
          }
          >{licensePlate}</Button>
        </Popover>
      ),
    },
    {
      title: 'Mooc',
      dataIndex: 'type',
      render: (type: any) => (
        <>
          {type?.name === 'CONTAINER'
            ? `${type?.numberOfAxles} trục, ${type?.numberOfFeets} feet`
            : `${type?.payload} tấn`}
        </>
      ),
    },
    // {
    //     title: <>Vị trí hiện tại <Tooltip title="Nếu tài xế có đơn đang vận chuyển, vị trí khả dụng là điểm trả của đơn đó. Nếu không hệ thống xử dụng điểm trả của đơn mới hoàn thành trong ngày"><InfoCircleOutlined style={{ color: '#0082fb' }} /></Tooltip></>,
    //     dataIndex: 'routes',
    //     render: (routes: any, record: any) => (routes && routes[routes.length - 1]?.delivery[0]?.area.province) ? routes[routes.length - 1]?.delivery[0]?.area.province : (record?.justFinishedRoutes?.delivery[0]?.area.province ? record?.justFinishedRoutes?.delivery[0].area.province : 'Không có')
    // },
    {
      title: (
        <>
          Đơn hoàn thành{' '}
          <Tooltip title="Số container của đơn hàng mới hoàn thành trong ngày của tài xế">
            <InfoCircleOutlined style={{ color: '#0082fb' }} />
          </Tooltip>
        </>
      ),
      dataIndex: 'justFinishedRoutes',
      render: (justFinishedRoutes: any) =>
        justFinishedRoutes &&
        justFinishedRoutes?.container?.replace(/\s/g, '') ? (
          <Popover
            content={renderRouteData(justFinishedRoutes)}
            trigger="hover"
          >
            <Button
              type="primary"
              style={
                specificationRender[justFinishedRoutes.specification].style
              }
            >
              {specificationRender[justFinishedRoutes.specification].icon}
              {justFinishedRoutes.container}
              <CopyOutlined
                onClick={() => {
                  setContId(justFinishedRoutes.container);
                  message.info('Đã sao chép số cont');
                }}
              />
            </Button>
          </Popover>
        ) : (
          'Không có'
        ),
    },
    {
      title: 'Chặng đơn',
      dataIndex: 'routes',
      render: (routes: any, record: any) => renderRoutes(record),
    },
  ];
  console.log('route.list');

  const routeColumns: Array<object> = [
    {
      title: 'Chặng đơn',
      // dataIndex:'routes',
      render: (routes: any, record: any) => renderRoute(record),
    },
    {
      title: 'Container',
      dataIndex: ['container'],
    },
    {
      title: 'Tên hàng',
      dataIndex: ['goods', 'name'],
    },
    {
      title: 'Khối lượng (hàng)',
      dataIndex: ['goods', 'volume'],
    },
    {
      title: 'Cont',
      dataIndex: ['order', 'vehicle', 'numberOfFeets'],
      render: (numberOfFeets: any) => <>{numberOfFeets} feet</>,
    },
    // {
    //     title: 'N/X',
    //     dataIndex: 'specification',
    //     render: (specification: any) => <>{specificationRender[specification].text}</>
    // },
  ];

  return (
    <>
      <Row justify="space-between" gutter={20}>
        <Col span={12} style={{ borderRight: '1px solid #eee' }}>
          <Title level={4}>
            Ghép chặng ({route.count}) với xe ({vehicle.count}){' '}
            <Tooltip title="Chỉ định chặng cho xe và tài xế đang quản lý xe đó. Chức năng cốt lõi của hệ thống Truck Vendor">
              <InfoCircleOutlined style={{ color: '#0082fb' }} />
            </Tooltip>
          </Title>
          <Row justify="space-between" gutter={6}>
            <Col>
              <Space size={6}>
                <Select
                  showSearch
                  dropdownMatchSelectWidth={false}
                  size="small"
                  style={{ width: 320 }}
                  placeholder="Nhà xe"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(value) =>
                    setVehicle({
                      ...vehicle,
                      filter: { ...vehicle.filter, truckVendor: value },
                    })
                  }
                >
                  <Option value="ALL">Tất cả</Option>
                  {truckVendor.list.map((truckVendor: any) => (
                    <Option key={truckVendor._id} value={truckVendor._id}>
                      {truckVendor?.shortName}
                    </Option>
                  ))}
                </Select>
              </Space>
            </Col>
            <Space size={6}>
              <Button
                size="small"
                onClick={fetch}
                icon={<ReloadOutlined />}
              ></Button>
              <Tooltip title="Biển số">
                <Search
                  // disabled
                  placeholder="Biển số"
                  size="small"
                  allowClear
                  style={{ width: 150, margin: '0 10px' }}
                  onSearch={(value) =>
                    setVehicle({
                      ...vehicle,
                      filter: { ...vehicle.filter, text: value },
                    })
                  }
                />
              </Tooltip>
            </Space>
          </Row>
          <br />
          <Table
            rowClassName={(record: any) =>
              record._id === vehicle.current
                ? 'table-row-dark'
                : 'table-row-light'
            }
            loading={vehicle.loading}
            columns={columns}
            dataSource={vehicle.list}
            scroll={{ x: 'max-content' }}
            pagination={
              vehicle.pagination.total > vehicle.pagination.pageSize &&
              vehicle.pagination
            }
            onChange={(value: any) =>
              setVehicle({
                ...vehicle,
                pagination: { ...vehicle.pagination, current: value.current },
              })
            }
          />
        </Col>
        <Col span={12}>
          <Row justify="end" style={{ marginTop: 4 }}>
            <Space style={{ marginBottom: 10 }}>
              <Tooltip title="Số cont">
                <Search
                  // disabled
                  placeholder="Số cont"
                  size="small"
                  allowClear
                  style={{ width: 150 }}
                  onSearch={(value) =>
                    setRoute({
                      ...route,
                      filter: { ...route.filter, text: value },
                    })
                  }
                />
              </Tooltip>
            </Space>
          </Row>
          <Row justify="space-between">
            <Space>
              {/* <RangePicker
                                size="small" style={{ width: 180 }}
                                onChange={(date, dateString) => setRoute({ ...route, filter: { ...route.filter, range: dateString[0] && dateString[1] && dateString } })}
                                ranges={{
                                    'Hôm nay': [moment(), moment()],
                                    'Hôm qua': [moment().subtract(1, 'd'), moment().subtract(1, 'd')],
                                }}
                            /> */}
              <Button
                type="primary"
                size="small"
                style={specificationRender.IMPORT.style}
              >
                <ImportOutlined />
              </Button>{' '}
              Nhập
              <Button
                type="primary"
                size="small"
                style={{ ...specificationRender.EXPORT.style, marginLeft: 15 }}
              >
                <ExportOutlined />
              </Button>{' '}
              Xuất
            </Space>
            <Space>
              <Select
                showSearch
                size="small"
                style={{ width: 320 }}
                placeholder="Nhà xe"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(value) =>
                  setRoute({
                    ...route,
                    filter: { ...route.filter, truckVendor: value },
                  })
                }
              >
                <Option value="ALL">Tất cả</Option>
                {truckVendor.list.map((truckVendor: any) => (
                  <Option key={truckVendor._id} value={truckVendor._id}>
                    {truckVendor?.shortName}
                  </Option>
                ))}
              </Select>
            </Space>
          </Row>
          <br />
          <Table
            rowKey={(record: any) => {
              return record._id;
            }}
            columns={routeColumns}
            dataSource={route.list}
            loading={route.loading}
            pagination={
              route.pagination.total > route.pagination.pageSize &&
              route.pagination
            }
            onChange={(value: any) =>
              setRoute({
                ...route,
                pagination: { ...route.pagination, current: value.current },
              })
            }
          />
        </Col>
      </Row>
      <Modal
        title="Đơn kết hợp"
        open={combined.combined}
        okText="Xác nhận"
        cancelText="Không phải đơn kết hợp"
        maskClosable={false}
        onCancel={() => {
          setCombined({ ...combined, combined: false });
        }}
        onOk={handleCombinedRoute}
      >
        Hệ thống nhận diện đơn kết hợp chi phí.
        <br />
        Cùng số container <b>{combined.container}</b> của nhà xe{' '}
        <b>{combined.truckVendor}</b> ngày{' '}
        <b>{moment(combined.date).format('DD/MM/YYYY')}</b>
        <br />
        <br />
        Chỉ số kết hợp chi phí
        <Select
          onChange={(value) =>
            setCombined({ ...combined, combinedRate: value })
          }
          defaultValue={0.5}
          style={{ width: '100%' }}
        >
          {COMBINE_RATE.map((item: any) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}
const StyleWrapper = styled.div`
  .anticon:hover {
    color: rgba(19, 194, 194, 1);
  }
`;
export default AssignOrderVehicle;
