import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, Upload, Divider, Select, Typography, Spin, InputNumber } from 'antd';
import { UploadOutlined, LeftOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import ImgCrop from 'antd-img-crop';
import { listCity } from '../../../store/address';
import { useParams } from 'react-router-dom'
import API from '../../../shared/api/api';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import {rule_not_require, rule_number, rule_phoneNumber, rule_require} from '../../../shared/validateInput'
import { beforeUpload, onPreview, propsImage } from '../../../shared/imageHandle';
import AddressForm from '../../../shared/Components/AddressForm.tsx/AddressForm';
import BusinessLicense from '../../../shared/Components/BusinessLicense/BusinessLicense';
import UserUpdateInfomation from './../../../Components/UserUpdateInfomation';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
const { Title } = Typography;
function UpdateVendor() {
    const [owner, setOwner] = useState(null)
    const [form] = Form.useForm();
    const [fileAvatarList, setFileAvatarList] = useState<any>([]);
    const [spinning, setSpinning] = useState<boolean>(true);
    const [isLoadingBtn, setLoadingBtn] = useState<boolean>(false);
    const [license, setLicense] = useState({})
    const [idFront, setIdFront] = useState<any>([])
    const [idBack, setIdBack] = useState<any>([])
    const history = useHistory();
    let { id }: any = useParams();
    useEffect(() => {
        loadData();
    }, [])
    const loadData = () => {
        setSpinning(true)
        API.get(`admin/truck-vendor/${id}`)
            .then(
                res => {
                    if (res.data.success) {
                        setOwner(res.data.data.owner)
                        let listData = res.data.data;
                        if(listData?.IDCard?.front && listData?.IDCard?.back){
                            setIdFront([{ name: listData?.IDCard?.front, url: listData?.IDCard?.front, thumbUrl: listData?.IDCard?.front, response: { data: { url: listData?.IDCard?.front } } }])
                            setIdBack([{ name: listData?.IDCard?.back, url: listData?.IDCard?.back, thumbUrl: listData?.IDCard?.back, response: { data: { url: listData?.IDCard?.back } } }])
                        }
                        setFileAvatarList([{ name: listData.avatar, url: listData.avatar, thumbUrl: listData.avatar, response: { data: { url: listData.avatar } } }])
                        setLicense(listData.businessLicense)
                        form.setFieldsValue(listData)
                    }
                }
            )
            .catch(error => {
                console.log(error);
            })
            .finally(() => { setSpinning(false) })
    }

    const onUpdating = async () => {
        setLoadingBtn(true)
        const customData = {
            ...form.getFieldsValue(),
            avatar: fileAvatarList[0]?.response?.data.url,
            IDCard: {
                ...form.getFieldsValue().IDCard,
                front: idFront[0]?.response?.data.url,
                back: idBack[0]?.response?.data.url,
            },
            businessLicense: {
                ...form.getFieldsValue().businessLicense,
                front: form.getFieldsValue().businessLicense?.front?.fileList?.['0']?.response?.data?.url || form.getFieldsValue().businessLicense?.front,
                back: form.getFieldsValue().businessLicense?.back?.fileList?.['0']?.response?.data?.url || form.getFieldsValue().businessLicense?.back,
            },
        }
        const response = await API.put(`admin/truck-vendor/${id}`, customData)
            .then(res => res.data)
        if (response.success) history.push('/partner/truck-vendor')
    }
    return (
        <Row style={{ width: '100%' }}>
            <Spin spinning={spinning}>
                <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}><span onClick={() => { history.goBack() }}><LeftOutlined />Quay lại</span></Title>
                <Form initialValues={{ addressCountry: 'Việt Nam' }} form={form} {...layout} style={{ width: '100%' }} onFinish={onUpdating} onFinishFailed={() => { setLoadingBtn(false) }}>
                    <Row gutter={[32, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                                label="Ảnh doanh nghiệp"
                                name="avatar"
                                valuePropName="avatar"
                            >
                                <ImgCrop rotate>
                                    <Upload
                                        listType="picture-card"
                                        {...propsImage}
                                        fileList={fileAvatarList}
                                        onChange={
                                            ({ fileList: newFileList }: { fileList: any }) => {
                                                setFileAvatarList(newFileList)
                                            }}
                                        onPreview={onPreview}
                                        beforeUpload={beforeUpload}
                                    >
                                        {fileAvatarList.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Tên doanh nghiệp"
                                        name="name"
                                        rules={[rule_require]}
                                    >
                                        <Input placeholder='Nhập tên doanh nghiệp'/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                <Form.Item
                                    label="Tên viết tắt"
                                    name="shortName"
                                    rules={[rule_require]}
                                >
                                    <Input placeholder='Nhập tên viết tắt' />
                                </Form.Item>
                            </Col>
                                <Col xs={24} sm={12}>
                                <Form.Item
                                    label="Tên người đại diện"
                                    name="representativeName"
                                    rules={[rule_require]}
                                >
                                    <Input placeholder='Nhập tên người đại diện'/>
                                </Form.Item>
                            </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label="Hotline"
                                        name="hotline"
                                        rules={[rule_require,rule_phoneNumber]}
                                    >
                                        <Input placeholder='Nhập số hotline'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Divider />
                        <Row gutter={[32, 0]} style={{ padding: '0 16px' }}>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Col xs={24}>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item
                                                label="CMND/CCCD Người đại diện"
                                                name={['IDCard', 'number']}
                                                // rules={[rule_require, rule_number]}
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input placeholder='Nhập số CMND/CCCD'/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item
                                                name={['IDCard', 'front']}
                                                rules={[rule_not_require]}
                                                labelCol={{ span: 24 }}
                                                label='Mặt trước'
                                            >
                                                <ImgCrop aspect={3/2} rotate>
                                                    <Upload
                                                        listType="picture-card"
                                                        {...propsImage}
                                                        fileList={idFront}
                                                        onChange={
                                                            ({ fileList: newFileList }: { fileList: any }) => {
                                                                setIdFront(newFileList)
                                                            }}
                                                        onPreview={onPreview}
                                                        beforeUpload={beforeUpload}
                                                    >
                                                        {idFront.length < 1 && '+ Upload'}
                                                    </Upload>
                                                </ImgCrop>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item
                                                name={['IDCard', 'back']}
                                                rules={[rule_not_require]}
                                                labelCol={{ span: 24 }}
                                                label='Mặt sau'
                                            >
                                                <ImgCrop aspect={3/2} rotate>
                                                    <Upload
                                                        listType="picture-card"
                                                        {...propsImage}
                                                        fileList={idBack}
                                                        onChange={
                                                            ({ fileList: newFileList }: { fileList: any }) => {
                                                                setIdBack(newFileList)
                                                            }}
                                                        onPreview={onPreview}
                                                        beforeUpload={beforeUpload}
                                                    >
                                                        {idBack.length < 1 && '+ Upload'}
                                                    </Upload>
                                                </ImgCrop>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <BusinessLicense license={license} />
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row>
                                <Col xs={24}>
                                    <Form.Item
                                        label="Thế mạnh"
                                        name="strengths"
                                    >
                                        <Input.TextArea rows={4} placeholder='Nhập thế mạnh' />
                                    </Form.Item>
                                </Col>
                                {/*<Col xs={24} >*/}
                                {/*    <Form.Item*/}
                                {/*        label="Chi phí ĐHVT"*/}
                                {/*        name="directOperating"*/}
                                {/*        rules={[rule_number]}*/}
                                {/*    >*/}
                                {/*        <InputNumber*/}
                                {/*            formatter={value => ` ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}*/}
                                {/*            style={{ width: '100%' }}*/}
                                {/*        />*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                            </Row>
                        </Col>

                        </Row>
                        <Divider></Divider>
                        <AddressForm />

                    </Row>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
                        <Button loading={isLoadingBtn} htmlType='submit' style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }}>Cập nhật</Button>
                    </Col>
                </Form>
                { owner && <UserUpdateInfomation owner={owner} />}
            </Spin>
        </Row>
    )
}

export default UpdateVendor
