import React, { useEffect, useState } from "react";
import API from "../../../shared/api/api";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Spin,
  Select,
  Typography,
  Table,
  Tooltip,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { rule_require } from "../../../shared/validateInput";
import moment from "moment";
import AddressForm from "../../../shared/Components/AddressForm.tsx/AddressForm";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Option } = Select;
function CustomerUpdate() {
  let { id }: any = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const [forwarders, setFowarders] = useState([]);
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [priceList, setPriceList] = useState([]);

  useEffect(() => {
    fetchForwarder();
    fetchCustomer();
  }, []);

  const fetchForwarder = async () => {
    const response: any = await API.get("admin/forwarder/all").then(
      (res) => res.data
    );
    if (response.success) {
      setFowarders(response.data);
    }
  };

  const fetchCustomer = async () => {
    const response: any = await API.get(`admin/customer/${id}`).then(
      (res) => res.data
    );
    if (response.success) {
      form.setFieldsValue(response.data);
    }
  };

  const onUpdate = async (values: any) => {
    setLoadingBtn(true);

    const response = await API.put(`admin/customer/${id}`, values).then(
      (res) => res.data
    );

    if (response.success) history.push("/partner/customer");

    setLoadingBtn(false);
  };

  const columns: Array<object> = [
    {
      title: "STT",
      dataIndex: "_id",
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Mã nhận",
      dataIndex: ["receipt", "code"],
    },
    {
      title: "Điểm nhận",
      dataIndex: "receipt",
      render: (receipt: any) => (
        <Tooltip placement="topLeft" title={`${receipt.name} ${receipt.pName}`}>
          <div className="address">
            {receipt.name}, {receipt.pName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mã trả",
      dataIndex: ["delivery", "code"],
    },
    {
      title: "Điểm trả",
      dataIndex: "delivery",
      render: (delivery: any) => (
        <Tooltip
          placement="topLeft"
          title={`${delivery.name}, ${delivery.pName}`}
        >
          <div className="address">
            {delivery.name}, {delivery.pName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Khoảng cách",
      dataIndex: "distance",
      render: (distance: any) => distance + "km",
    },
    {
      title: "Có hàng",
      dataIndex: "haveGoodsDistance",
      render: (haveGoodsDistance: any) => Math.floor(haveGoodsDistance) + "km",
    },
    {
      title: "Không hàng",
      dataIndex: "noGoodsDistance",
      render: (noGoodsDistance: any) => Math.floor(noGoodsDistance) + "km",
    },
    {
      title: "Giá container 20",
      dataIndex: ["price", "cont20"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá container 40",
      dataIndex: ["price", "cont40"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá CY 20",
      dataIndex: ["price", "cy20"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá CY 40",
      dataIndex: ["price", "cy40"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Tiền đi đường",
      dataIndex: "salary",
      render: (salary: any) =>
        salary
          ? salary?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
  ];

  const loadlist = () => {
    setIsLoading(true);
    API.get("admin/freight", {
      params: {
        current: pagination.current,
        pageSize: pagination.pageSize,
        customer: id,
      },
    })
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setPriceList(listData);
          setPagination({ ...pagination, total: res.data.totalItem });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadlist();
  }, [pagination.current, pagination.pageSize]);

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>
        <Form
          form={form}
          {...layout}
          style={{ width: "100%" }}
          onFinish={onUpdate}
          onFinishFailed={() => {
            setLoadingBtn(false);
          }}
        >
          <Row gutter={[32, 0]} style={{ width: "100%" }}>
            <Col span={8}>
              <Form.Item label="Tên" name="name" rules={[rule_require]}>
                <Input placeholder="Tên" />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label="Tên đầy đủ"
                name="fullName"
                rules={[rule_require]}
              >
                <Input placeholder="Tên đầy đủ" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Mã số thuế"
                name={["businessLicense", "text"]}
                rules={[rule_require]}
              >
                <Input placeholder="Mã số thuế" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Số điện thoại"
                name="phoneNumber"
                rules={[rule_require]}
              >
                <Input placeholder="Số điện thoại" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Forwarder"
                name={["forwarder", "_id"]}
                rules={[rule_require]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn forwarder"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {forwarders.map((vendor: any) => (
                    <Option key={vendor._id} value={vendor._id}>
                      {vendor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <AddressForm />
            <Col span={8}>
              <Form.Item
                label="Mã khách hàng"
                name="code"
                rules={[rule_require]}
              >
                <Input placeholder="Mã khách hàng" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Nhân viên CSKH"
                name="accountManager"
                rules={[rule_require]}
              >
                <Input placeholder="Nhân viên CSKH" />
              </Form.Item>
            </Col>
          </Row>

          <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
            <Button
              loading={isLoadingBtn}
              htmlType="submit"
              style={{
                width: "100%",
                backgroundColor: "#03A9F4",
                color: "white",
              }}
            >
              Cập nhật
            </Button>
          </Col>
        </Form>
      </Row>
      <Row>
        <Title level={4}>Danh sách giá cước ({pagination.total})</Title>
        <br />
      </Row>
      <Row>
        <Table
          scroll={{ x: "max-content" }}
          loading={isloading}
          bordered={false}
          columns={columns}
          dataSource={priceList}
          pagination={pagination.total > pagination.pageSize && pagination}
          onChange={(value: any) =>
            setPagination({ ...pagination, current: value.current })
          }
        />
      </Row>
    </>
  );
}

export default CustomerUpdate;
