import React, { useEffect, useState } from 'react'
import { Col, Select, Form, Input, Row } from 'antd'
import axios from 'axios';
import { rule_require } from '../../validateInput';
import API from "../../api/api";

const { Option } = Select;
export default function AddressForm(props: any) {
    const [cityList, setCityList] = useState<any>([]);
    useEffect(() => {
        getListCity();
    }, [])
    const getListCity = async () => {
        const response = await API.get(`/admin/region/province`).then(
          (res) => res.data
        );
        setCityList(response.data)
    }
    return (
        <Form.Item wrapperCol={{ span: 24 }} style={{ width: '100%', padding: '0 16px' }}>
            <Row gutter={[32, 0]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                        label="Quốc gia"
                        name={['address', 'country']}
                        rules={[rule_require]}
                        labelCol={{ span: 24 }}
                        initialValue='Việt Nam'
                    >
                        <Select
                            disabled={props.isEdit}
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Chọn quốc gia"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option value="Việt Nam">Việt Nam</Option>
                            {/* <Option value="Quốc gia khác">Quốc gia khác</Option> */}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                        label="Tỉnh/TP"
                        name={['address', 'province']}
                        rules={[rule_require]}
                        labelCol={{ span: 24 }}
                    >
                        <Select
                            disabled={props.isEdit}
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Chọn tỉnh/tp"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {cityList.map((city: any) =>
                                <Option key={city._id} value={city.name}>{city.name}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                        label="Địa chỉ"
                        name={['address', 'text']}
                        rules={[rule_require]}
                        labelCol={{ span: 24 }}
                    >
                        <Input placeholder='Nhập địa chỉ' disabled={props.isEdit}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item>
    )
}
