import {Select} from "antd";

const {Option} = Select;

export const CombinationList: React.FC<{
  value?: any[];
  showCustomer: boolean;
  onChange?: (value: any) => void;
  listOptions: any[];
}> = ({value, onChange, listOptions}) => {
  return (
    <Select
      size="middle"
      placeholder="Chọn tuyến"
      style={{width: 300}}
      onChange={(value, option) => {
        onChange?.(value);
      }}
    >
      {listOptions?.map((item) => {
        return (
          <Option
            value={item._id}
          >{`[${item?.customer?.name}][${item?.code}] ${item?.description}`}</Option>
        );
      })}
    </Select>
  );
};
