import React, { useEffect, useState } from 'react';
import API from '../../../shared/api/api';
import { Col, Row, Form, Input, Button, DatePicker, Divider, Select, Typography } from 'antd';
import { useHistory } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons';
import { rule_require } from '../../../shared/validateInput';
import AddressForm from '../../../shared/Components/AddressForm.tsx/AddressForm';
import { createShip } from '../../../shared/api/Ship';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Option } = Select;
function ShipCreate() {
    const history = useHistory();
    const [form] = Form.useForm();

    const [isLoadingBtn, setLoadingBtn] = useState(false);

    const onCreate = async (values: any) => {
        setLoadingBtn(true)
        const response = await createShip(values)
        if (response.success) history.push('/partner/ship')

        setLoadingBtn(false)
    }

    return (
        <Row style={{ width: '100%' }}>
            <Title level={5} style={{ marginBottom: '25px', cursor: 'pointer' }}><span onClick={() => { history.goBack() }}><LeftOutlined />Quay lại</span></Title>
            <Form form={form} {...layout} style={{ width: '100%' }} onFinish={onCreate} onFinishFailed={() => { setLoadingBtn(false) }}>
                <Row gutter={[32, 0]} style={{ width: '100%' }}>
                    <Col span={8}>
                        <Form.Item
                            label="Tên viết tắt"
                            name='name'
                            rules={[rule_require]}
                        >
                            <Input placeholder='Tên' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Tên đầy đủ"
                            name='fullName'
                            rules={[rule_require]}
                        >
                            <Input placeholder='Tên đầy đủ' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Mã số thuế"
                            name={['businessLicense', 'text']}
                            rules={[rule_require]}
                        >
                            <Input placeholder='Mã số thuế' />
                        </Form.Item>
                    </Col>
                </Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
                    <Button loading={isLoadingBtn} htmlType='submit' style={{ width: '100%', backgroundColor: '#03A9F4', color: 'white' }} >Thêm hãng tàu</Button>
                </Col>
            </Form>
        </Row>
    )
}

export default ShipCreate
