import React, { useState, useEffect } from "react";
import API from "../../../shared/api/api";
import {
  Space,
  Button,
  Table,
  Typography,
  Row,
  Input,
  Select,
  Col,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  ReloadOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { successNotification } from "../../../shared/notification";
import axios from "axios";

const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;

function FowarderList() {
  const [forwarderList, setForwarderList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const columns: Array<object> = [
    {
      title: "STT",
      dataIndex: "_id",
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Thế mạnh",
      dataIndex: "strengths",
    },
    {
      title: "Số điện thoại",
      dataIndex: "owner",
      render: (owner: any) => <>{owner.phoneNumber}</>,
    },
    {
      title: "Hotline",
      dataIndex: "hotline",
    },
    {
      title: "Mã số thuế",
      dataIndex: ["businessLicense", "text"],
      // align: "left",
      // render: (owner: any) => <>{owner?.phoneNumber}</>
    },
    {
      title: "Email",
      dataIndex: "owner",
      render: (owner: any) => <>{owner.email}</>,
    },
    {
      title: "Tình trạng",
      dataIndex: "verified",
      render: (verified: any) => <>{verified ? "Đã duyệt" : "Chưa duyệt"}</>,
    },
    {
      title: "Tác vụ",
      align: "center",
      width: 100,
      render: (forwarder: any) => (
        <Space size="middle">
          <Tooltip title="Xem">
            <Link
              to={{
                pathname: `/partner/forwarder/detail/${forwarder._id}`,
              }}
            >
              <EyeOutlined style={{ fontSize: 16 }} />
            </Link>
          </Tooltip>
          <Tooltip title="Sửa">
            <Link
              to={{
                pathname: `/partner/forwarder/edit/${forwarder._id}`,
              }}
            >
              <EditOutlined style={{ fontSize: 16 }} />
            </Link>
          </Tooltip>
          {/* <Tooltip title="Xóa">
                        <DeleteOutlined style={{ fontSize: 16, color: 'red' }} onClick={()=> {onDelete(forwarder)}}/>
                    </Tooltip> */}
        </Space>
      ),
    },
  ];
  const onDelete = (driver: any) => {
    API.delete(`admin/forwarder/${driver._id}`).then((res) => {
      const { success } = res.data;
      if (success) {
        if (res.data.success === false) {
          return;
        } else {
          successNotification("Xóa thành công", "");
          setIsLoading(true);
          fetchForwarderList();
          return;
        }
      }
    });
  };
  const fetchForwarderList = async () => {
    setIsLoading(true);
    const response = await API.get("admin/forwarder/", {
      params: { ...filter, current: pagination.current },
    }).then((res) => res.data);
    if (response.success) {
      setForwarderList(response.data);
      setPagination({ ...pagination, total: response.totalItem });
    }
    setIsLoading(false);
  };

  const getListProvince = () => {
    axios
      .get("https://vapi.vnappmob.com/api/province")
      .then((res: any) => {
        console.log(
          res.data.results.map((province: any) => province.province_name)
        );
        if (res.status === 200) {
          setProvinceList(res.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchForwarderList();
  }, [filter, pagination.current]);

  useEffect(() => {
    getListProvince();
  }, []);

  return (
    <>
      <Title level={4}>Quản lý chủ hàng ({pagination.total})</Title>
      <Row justify="space-between" gutter={8}>
        <Col>
          <Space size={8}>
            Khu vực
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{ width: 150 }}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({ ...filter, province: value })
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {provinceList.map((city: any) => (
                <Option key={city.province_id} value={city.province_name}>
                  {city.province_name}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Space>
          <Button
            size="small"
            onClick={fetchForwarderList}
            icon={<ReloadOutlined />}
          ></Button>
          <Search
            size="small"
            placeholder="Search"
            allowClear
            style={{ width: 150, margin: "0 10px" }}
            onSearch={(value) => setFilter({ ...filter, text: value })}
          />
          <Link
            to={{
              pathname: "/partner/forwarder/create",
            }}
            style={{ color: "white" }}
          >
            <Button size="small" type="primary" icon={<PlusOutlined />}>
              Tạo mới
            </Button>
          </Link>
        </Space>
      </Row>
      <br />
      <Table
        loading={isloading}
        columns={columns}
        dataSource={forwarderList}
        rowKey="_id"
      />
    </>
  );
}

export default FowarderList;
