import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Row, Input, Typography, Tooltip, Col, Select, DatePicker } from 'antd';
import API from '../../../shared/api/api';
import { PlusOutlined, EyeOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../../shared/dataFormat';
import { successNotification } from '../../../shared/notification';
import { TRAILER_TYPE } from '../../../shared/define';

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
function TrailerList() {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({})
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    })
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setLoading(true)
        const response: any = await API.get('admin/trailer'
        // , { params: { ...filter, current: pagination.current } }
        ).then(res => res.data)
        if (response.success) {
            const data = response.data
            console.log(data)
            const customData = data.map((item: any,index:any) => (
                {
                    ...item,
                    key:index+1
                }))
            setDataList(customData)
            setPagination({ ...pagination, total: response.totalItem })
        }
        setLoading(false)
    }
    const onDelete = (id: any) => {
        API.delete(`admin/trailer/${id}`)
            .then(
                res => {
                    if (res.data.success) {
                        if (res.data.success === false) {
                            return
                        } else {
                            successNotification('Xóa thành công', '');
                            fetchData();
                            return
                        }
                    }
                })
    }
    const columns: Array<object> = [
        {
            title: 'STT',
            dataIndex: 'key',
            width: 40,
        },
        {
            title: 'Biển số',
            dataIndex: 'licensePlate',
        },
        // {
        //     title: 'Biển đăng kiểm',
        //     dataIndex:'',
        // },
        {
            title: 'Ngày hết hạn đăng kiểm',
            dataIndex:'registrationExpireDate',
            render:(date:any) => dateFormat(date)
        },
        {
            title: 'Công ty',
            dataIndex:['truckVendor','name'],
        },
        {
            title: 'Loại Mooc',
            dataIndex:'type',
            render: (option: any) => {
                const obj = TRAILER_TYPE.find((element: any) => (element as any).value === option);
                return obj?.label
            }
        },

        {
            title: 'Tác vụ',
            align: "center",
            width: 100,
            render: (trailer: any) => (
                <Space size="middle">
                    {/* <Tooltip title="Xem">
                        <Link to={{
                            pathname: `/resource/driver/detail/${driver._id}`,
                        }}>
                            <EyeOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip> */}
                    <Tooltip title="Sửa">
                        <Link to={{
                            pathname: `/resource/trailer/edit/${trailer._id}`,
                        }}>
                            <EditOutlined style={{ fontSize: 16 }} />
                        </Link>
                    </Tooltip>
                    {/* <Tooltip title="Xóa">
                        <DeleteOutlined style={{ fontSize: 16, color: 'red' }} onClick={() => {onDelete(trailer._id)}} />
                    </Tooltip> */}
                </Space>
            ),
        },
        
        // {
        //     title: 'Trạng thái',
        //     dataIndex:'',
        // },
    ]
    return (
        <>
            <Row justify="space-between" gutter={6}>
            <Title level={4}>Quản lý rơ mooc</Title>
                {/* <Col>
                    <Space size={6}>
                        Hàng
                        <Select defaultValue="ALL" style={{ width: 120 }} onChange={(value) => setFilter({ ...filter, preservation: value })}>
                            <Option value="ALL">Tất cả</Option>
                            <Option value="NORMAL">Thường</Option>
                            <Option value="COLD">Lạnh</Option>
                        </Select>
                        <br /><br />

                        Ngày
                        <RangePicker style={{ width: 180 }} onChange={(date, dateString) => setFilter({ ...filter, range: dateString[0] && dateString[1] && dateString })} />
                        <br /><br />

                        Cont
                        <Select defaultValue="ALL" style={{ width: 120 }} onChange={(value) => setFilter({ ...filter, numberOfFeets: value })}>
                            <Option value="ALL">Tất cả</Option>
                            <Option value="20">20</Option>
                            <Option value="40">40</Option>
                            <Option value="45">45</Option>
                        </Select>
                    </Space>

                </Col> */}
                <Space size={6}>
                    {/* <Button onClick={fetchData} icon={<ReloadOutlined />}></Button>
                    <Search
                        placeholder="Search"
                        allowClear
                        style={{ width: 150, margin: '0 10px' }}
                        onSearch={(value) => setFilter({ ...filter, text: value })}
                    /> */}
                    <Button type="primary" size="small" icon={<PlusOutlined />}>
                        <Link to={{
                                pathname: '/resource/trailer/create',
                            }} style={{ color: 'white' }}>
                                Tạo mới
                        </Link>
                    </Button>
                </Space>
            </Row>
            <br />
            <Table
                scroll={{ x: 'max-content'}}
                loading={loading}
                columns={columns}
                pagination={pagination.total > 10 && pagination}
                dataSource={dataList}
                onChange={(value: any) => setPagination({ ...pagination, current: value.current })}
            />
        </>
    )
}

export default TrailerList
