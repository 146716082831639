import React, { useState } from "react";
import { Form, Layout, Menu, Modal, Col, Row, Input } from "antd";
import {
  UserOutlined,
  BellOutlined,
  LoginOutlined,
  KeyOutlined,
  MenuUnfoldOutlined,
  FileAddOutlined,
  ShareAltOutlined,
  DoubleRightOutlined,
  AreaChartOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  MenuFoldOutlined,
  TagOutlined,
} from "@ant-design/icons";
import "antd/dist/reset.css";
import { clearLocalStorage } from "../shared/authoration";
import "./Main.css";
import history from "../shared/history";
import API from "../shared/api/api";
import { Route, Switch, Link } from "react-router-dom";
import TruckVendorList from "../Modules/TruckVendor/VendorList/TruckVendorList";
import FowarderList from "../Modules/Fowarder/FowarderList/FowarderList";
import DriverList from "../Modules/Driver/DriverList/DriverList";
import OrderList from "../Modules/Order/PendingOrder/OrderList/OrderList";
import AssignVehicleDriver from "./../Modules/AssignVehicleDriver/AssignVehicleDriver";
import CreateVendor from "../Modules/TruckVendor/CreateVendor/CreateVendor";
import DetailVendor from "../Modules/TruckVendor/DetailVendor/DetailVendor";
import UpdateVendor from "../Modules/TruckVendor/UpdateVendor/UpdateVendor";
import CreateDriver from "../Modules/Driver/CreateDriver/CreateDriver";
import DetailDriver from "../Modules/Driver/DetailDriver/DetailDriver";
import UpdateDriver from "../Modules/Driver/UpdateDriver/UpdateDriver";
import CreateForwarder from "../Modules/Fowarder/CreateForwarder/CreateForwarder";
import DetailForwarder from "../Modules/Fowarder/DetailForwarder/DetailForwarder";
import UpdateForwarder from "../Modules/Fowarder/UpdateForwarder/UpdateForwarder";
import VehicleList from "../Modules/Vehicle/VehicleList/VehicleList";
import CreateVehicle from "../Modules/Vehicle/CreateVehicle/CreateVehicle";
import DetailVehicle from "../Modules/Vehicle/DetailVehicle/DetailVehicle";
import UpdateVehicle from "../Modules/Vehicle/UpdateVehicle/UpdateVehicle";
import DetailOrder from "../Modules/Order/PendingOrder/DetailOrder/DetailOrder";
import CreateOrder from "../Modules/Order/PendingOrder/CreateOrder/CreateOrder";
import UpdateOrder from "../Modules/Order/PendingOrder/UpdateOrder/UpdateOrder";
import {
  rule_max_character,
  rule_min_character,
  rule_require,
} from "../shared/validateInput";
import OrderWithRouteList from "../Modules/AssignRoute/OrderWithRouteList/OrderWithRouteList";
import PriceList from "../Modules/Price/PriceList/PriceList";
import PriceContractorList from "../Modules/Price/PriceContractorList/PriceContractorList";
import TrackingOrder from "../Modules/Tracking/TrackingOrder/TrackingOrder";
import CreateRoute from "../Modules/Route/CreateRoute/CreateRoute";
import CreateBasicRoute from "../Modules/Route/CreateBasicRoute/CreateBasicRoute";
import CreateAttributeRoute from "../Modules/Route/CreateAttributeRoute/CreateAttributeRoute";
import UpdateBasicRoute from "../Modules/Route/UpdateBasicRoute/UpdateBasicRoute";
import UpdateRouteWithPath from "../Modules/Route/UpdateRouteWithPath/UpdateRouteWithPath";
import UpdateRouteContractorWithPath from "../Modules/Route/UpdateRouteContractorWithPath/UpdateRouteContractorWithPath";
import AssignOrderVehicle from "../Modules/Order/AssignOrderVehicle/AssignOrderVehicle";
import AssignRouteTruckVendor from "../Modules/Route/AssignRouteTruckVendor/AssignRouteTruckVendor";
import ShippingOrderList from "../Modules/Order/ShippingOrder/ShippingOrderList/ShippingOrderList";
import ShippingOrderDetail from "../Modules/Order/ShippingOrder/ShippingOrderDetail/ShippingOrderDetail";
import HistoryOrder from "../Modules/Order/History/HistoryOrder";
import TrailerList from "../Modules/Trailer/TrailerList/TrailerList";
import TrailerCreate from "../Modules/Trailer/TrailerCreate/TrailerCreate";
import TrailerUpdate from "../Modules/Trailer/TrailerUpdate/TrailerUpdate";
import CustomerList from "../Modules/Customer/CustomerList/CustomerList";
import CustomerCreate from "../Modules/Customer/CustomerCreate/CustomerCreate";
import CustomerUpdate from "../Modules/Customer/CustomerUpdate/CustomerUpdate";
import Dashboard from "../Modules/Dashboard/Dashboard";
import ShipList from "../Modules/Ship/ShipList";
import ShipCreate from "../Modules/Ship/CreateShip";
import ShipUpdate from "../Modules/Ship/UpdateShip";
import BotList from "../Modules/BOT/BotList/BotList";
import BotCreate from "../Modules/BOT/BotCreate/BotCreate";
import BotUpdate from "../Modules/BOT/BotUpdate/BotUpdate";
import OilList from "../Modules/Oil/OilList/OilList";
import RepairList from "../Modules/Repair/RepairList";
import RepairCreate from "../Modules/Repair/RepairCreate";
import RepairUpdate from "../Modules/Repair/RepairUpdate";
import SeaportList from "../Modules/Seaport/SeaportList";
import UpdateHistoryOrder from "../Modules/Order/History/UpdateHistoryOrder/UpdateHistoryOrder";
import CreateRouteWithPath from "../Modules/Route/CreateRouteWithPath";
import CreateRouteContractorWithPath from "../Modules/Route/CreateRouteContractorWithPath";
import PlacesList from "../Modules/Places/PlacesList";
import EditPlace from "../Modules/Places/EditPlace";
import CreatePlace from "../Modules/Places/CreatePlace";
import { RefuelOilList } from "../Modules/RefuelOil/RefuelOilList";
import { GasStation } from "../Modules/GasStation/GasStation";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
function Main() {
  const [form] = Form.useForm();
  const logout = () => {
    clearLocalStorage();
    history.push("/login");
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const response = await API.post(
      "change-password",
      form.getFieldsValue()
    ).then((res) => res.data);
    if (response.success) setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  let href = window.location.href.split("/");
  const getMenuIdByUrl = () => {
    let href3 = href[3] ? href[3] : "";
    let href4 = href[4] ? "/" + href[4] : "";
    let href5 = href[5] ? "/" + href[5] : "";
    return href3 + href4 + href5;
  };
  const styleContent = () => {
    if (href[3] == "statistic") {
      return {
        padding: 0,
        margin: 0,
        minHeight: 680,
      };
    }
    if (
      (href[3] == "order" && href[4] == "pending" && href[5] == "create") ||
      (href[3] == "order" && href[4] == "pending" && href[5] == "edit")
    ) {
      return {
        padding: 14,
        margin: 0,
        minHeight: 680,
        backgroundColor: "#F0F2F5",
      };
    } else
      return {
        padding: 14,
        margin: 0,
        minHeight: 680,
      };
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Modal
        title="Thay đổi mật khẩu"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row style={{ width: "100%" }}>
          <Form form={form} {...layout} style={{ width: "100%" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Mật khẩu cũ"
                name="oldPassword"
                rules={[rule_require, rule_min_character, rule_max_character]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Mật khẩu mới"
                name="newPassword"
                rules={[rule_require, rule_min_character, rule_max_character]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Nhập lại mật khẩu mới"
                name="repeatNewPassword"
                rules={[rule_require, rule_min_character, rule_max_character]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Form>
        </Row>
      </Modal>
      <Header
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "50px",
          lineHeight: "52px",
        }}
      >
        <div style={{ alignItems: "center" }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
              style: { fontSize: 16, padding: "0 20px" },
            }
          )}
          <img
            alt="logo"
            style={{ width: 110, position: "relative", top: "-2px" }}
            src="/icon/alo-truck-logo.png"
          />
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          style={{ display: "inline-block" }}
        >
          <SubMenu
            key="1"
            icon={<BellOutlined style={{ fontSize: "20px" }} />}
          ></SubMenu>
          <SubMenu
            className="rightMenu"
            key="2"
            icon={<UserOutlined style={{ fontSize: "20px" }} />}
          >
            <Menu.Item
              onClick={showModal}
              key="setting:1"
              icon={<KeyOutlined />}
            >
              Thay đổi mật khẩu
            </Menu.Item>
            <Menu.Item
              onClick={logout}
              key="setting:2"
              icon={<LoginOutlined />}
            >
              Log out
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
      <Layout>
        <Sider
          width={200}
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          collapsedWidth={58}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Menu
            mode="inline"
            style={{ height: "100%", borderRight: 0 }}
            theme="dark"
            defaultOpenKeys={[href[3]]}
            defaultSelectedKeys={[getMenuIdByUrl()]}
          >
            {/* <SubMenu key="statistic" icon={<img alt='icon' style={{ height: '14px', width: '14px', marginRight: '10px' }} src='/icon/package.png' />} title="Đơn hàng"> */}
            <Menu.Item
              key="statistic"
              icon={<AreaChartOutlined style={{ color: "#03A8F3" }} />}
            >
              <Link to="/statistic">Thống kê</Link>
            </Menu.Item>
            {/* </SubMenu> */}
            <SubMenu
              key="order"
              icon={
                <img
                  alt="icon"
                  style={{ height: "14px", width: "14px", marginRight: "10px" }}
                  src="/icon/package.png"
                />
              }
              title="Đơn hàng"
            >
              <Menu.Item key="order/pending" icon={<FileAddOutlined />}>
                <Link to="/order/pending">Tạo duyệt đơn chờ</Link>
              </Menu.Item>
              <Menu.Item key="order/assign/route" icon={<ShareAltOutlined />}>
                <Link to="/order/assign/route">Chỉ định nhà xe</Link>
              </Menu.Item>
              <Menu.Item key="order/assign/vehicle" icon={<ShareAltOutlined />}>
                <Link to="/order/assign/vehicle">Ghép chặng xe xế</Link>
              </Menu.Item>
              <Menu.Item key="order/shipping" icon={<DoubleRightOutlined />}>
                <Link to="/order/shipping">Đang vận chuyển</Link>
              </Menu.Item>
              <Menu.Item key="order/history" icon={<ClockCircleOutlined />}>
                <Link to="/order/history">Lịch sử đơn hàng</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="tracking"
              icon={
                <img
                  style={{ height: "14px", width: "14px", marginRight: "10px" }}
                  src="/icon/tracking.png"
                />
              }
              title="Tracking"
            >
              <Menu.Item key="tracking/order" icon={<EnvironmentOutlined />}>
                <Link to="/tracking/order">Đơn vận chuyển</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="resource"
              icon={
                <img
                  alt="icon"
                  style={{ height: "14px", width: "14px", marginRight: "10px" }}
                  src="/icon/truck.png"
                />
              }
              title="Quản lí phương tiện"
            >
              <Menu.Item
                key="resource/vehicle"
                icon={
                  <img
                    style={{
                      height: "12px",
                      width: "21.72px",
                      marginRight: "10px",
                    }}
                    src="/icon/container.png"
                  />
                }
              >
                <Link to="/resource/vehicle">Phương tiện</Link>
              </Menu.Item>
              <Menu.Item
                key="resource/driver"
                icon={
                  <img
                    style={{
                      height: "12px",
                      width: "10.72px",
                      marginRight: "16px",
                    }}
                    src="/icon/user.png"
                  />
                }
              >
                <Link to="/resource/driver">Tài xế và giao nhận</Link>
              </Menu.Item>
              <Menu.Item
                key="resource/assign/vehicle"
                icon={
                  <img
                    style={{
                      height: "12px",
                      width: "18px",
                      marginRight: "10px",
                    }}
                    src="/icon/assign.png"
                  />
                }
              >
                <Link to="/resource/assign/vehicle/driver">Ghép xe xế</Link>
              </Menu.Item>
              <Menu.Item
                key="resource/trailer"
                icon={
                  <img
                    style={{
                      height: "9px",
                      width: "15.25px",
                      marginRight: "16px",
                    }}
                    src="/icon/trailer.png"
                  />
                }
              >
                <Link to="/resource/trailer">Rơ mooc</Link>
              </Menu.Item>
              <Menu.Item
                key="resource/repair"
                icon={
                  <img
                    style={{
                      height: "10px",
                      width: "auto",
                      marginRight: "16px",
                    }}
                    src="/icon/repair.png"
                  />
                }
              >
                <Link to="/resource/repair">Chi phí sửa chữa</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="partner"
              icon={
                <img
                  alt="icon"
                  style={{
                    height: "13.5px",
                    width: "14px",
                    marginRight: "10px",
                  }}
                  src="/icon/partner.png"
                />
              }
              title="Quản lí đối tác"
            >
              <Menu.Item
                key="partner/forwarder"
                icon={
                  <img
                    style={{
                      height: "14px",
                      width: "17px",
                      marginRight: "10px",
                    }}
                    src="/icon/forwarder.png"
                  />
                }
              >
                <Link to="/partner/forwarder">Quản lý Forwarder</Link>
              </Menu.Item>
              <Menu.Item
                key="partner/truck-vendor"
                icon={
                  <img
                    style={{
                      height: "12px",
                      width: "21.72px",
                      marginRight: "10px",
                    }}
                    src="/icon/container.png"
                  />
                }
              >
                <Link to="/partner/truck-vendor">Quản lý chủ xe </Link>
              </Menu.Item>
              <Menu.Item
                key="partner/customer"
                icon={
                  <img
                    style={{
                      height: "12px",
                      width: "10.72px",
                      marginRight: "16px",
                    }}
                    src="/icon/user.png"
                  />
                }
              >
                <Link to="/partner/customer">Khách hàng</Link>
              </Menu.Item>
              <Menu.Item
                key="partner/ship"
                icon={
                  <img
                    style={{
                      height: "16px",
                      width: "auto",
                      marginRight: "16px",
                    }}
                    src="/icon/ship.png"
                  />
                }
              >
                <Link to="/partner/ship">Hãng tàu</Link>
              </Menu.Item>
              <Menu.Item
                key="partner/bot"
                icon={
                  <img
                    style={{
                      height: "18px",
                      width: "auto",
                      marginRight: "16px",
                    }}
                    src="/icon/bot.png"
                  />
                }
              >
                <Link to="/partner/bot">Trạm thu phí</Link>
              </Menu.Item>

              <Menu.Item
                key="/place/all"
                icon={
                  <img
                    style={{
                      height: "18px",
                      width: "auto",
                      marginRight: "16px",
                    }}
                    src="/icon/tracking-icon.png"
                  />
                }
              >
                <Link to="/place/all">Địa điểm giao nhận</Link>
              </Menu.Item>
              <Menu.Item
                key="partner/seaport"
                icon={
                  <img
                    style={{
                      height: "18px",
                      width: "auto",
                      marginRight: "16px",
                    }}
                    src="/icon/bot.png"
                  />
                }
              >
                <Link to="/partner/seaport">Cảng biển</Link>
              </Menu.Item>
              <Menu.Item
                key="partner/oil"
                icon={
                  <img
                    style={{
                      height: "10px",
                      width: "auto",
                      marginRight: "16px",
                    }}
                    src="/icon/oil.png"
                  />
                }
              >
                <Link to="/partner/oil">Quản lý giá dầu</Link>
              </Menu.Item>
              <Menu.Item
                key="manager/refuel-oil"
                icon={
                  <img
                    style={{
                      height: "10px",
                      width: "auto",
                      marginRight: "16px",
                    }}
                    src="/icon/oil.png"
                  />
                }
              >
                <Link to="/manager/refuel-oil">Quản lý đổ dầu</Link>
              </Menu.Item>
              <Menu.Item
                key="manager/gas-station"
                icon={
                  <img
                    style={{
                      height: "10px",
                      width: "auto",
                      marginRight: "16px",
                    }}
                    src="/icon/oil.png"
                  />
                }
              >
                <Link to="/manager/gas-station">Quản lý trạm xăng dầu</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="setting"
              icon={
                <img
                  style={{ height: "14px", width: "14px", marginRight: "10px" }}
                  src="/icon/setting.png"
                />
              }
              title="Cài đặt"
            >
              <Menu.Item
                key="setting/price"
                icon={
                  <img
                    style={{
                      height: "15px",
                      width: "14.72px",
                      marginRight: "12px",
                    }}
                    src="/icon/freight.png"
                  />
                }
              >
                <Link to="/setting/price">Cài đặt doanh thu</Link>
              </Menu.Item>
              <Menu.Item
                key="setting/priceContractor"
                icon={
                  <img
                    style={{
                      height: "15px",
                      width: "14.72px",
                      marginRight: "12px",
                    }}
                    src="/icon/freight.png"
                  />
                }
              >
                <Link to="/setting/priceContractor">Cước thầu phụ</Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout style={{ padding: "10px 0px 0px 10px" }}>
          <Content className="site-layout-background" style={styleContent()}>
            <Switch>
              <Route exact path="/partner/forwarder">
                <FowarderList />
              </Route>
              <Route exact path="/partner/forwarder/create">
                <CreateForwarder />
              </Route>
              <Route exact path="/partner/forwarder/detail/:id">
                <DetailForwarder />
              </Route>
              <Route exact path="/partner/forwarder/edit/:id">
                <UpdateForwarder />
              </Route>

              <Route exact path="/partner/truck-vendor">
                <TruckVendorList />
              </Route>
              <Route exact path="/partner/truck-vendor/detail/:id">
                <DetailVendor />
              </Route>
              <Route exact path="/partner/truck-vendor/edit/:id">
                <UpdateVendor />
              </Route>
              <Route exact path="/partner/truck-vendor/create">
                <CreateVendor />
              </Route>

              <Route exact path="/resource/driver">
                <DriverList />
              </Route>
              <Route exact path="/resource/driver/create">
                <CreateDriver />
              </Route>
              <Route exact path="/resource/driver/detail/:id">
                <DetailDriver />
              </Route>
              <Route exact path="/resource/driver/edit/:id">
                <UpdateDriver />
              </Route>

              <Route exact path="/partner/oil">
                <OilList />
              </Route>

              <Route exact path="/resource/repair">
                <RepairList />
              </Route>
              <Route exact path="/resource/repair/create">
                <RepairCreate />
              </Route>
              <Route exact path="/resource/repair/:id/edit">
                <RepairUpdate />
              </Route>

              <Route exact path="/resource/vehicle">
                <VehicleList />
              </Route>
              <Route exact path="/resource/vehicle/create">
                <CreateVehicle />
              </Route>
              <Route exact path="/resource/vehicle/edit/:id">
                <UpdateVehicle />
              </Route>
              <Route exact path="/resource/vehicle/detail/:id">
                <DetailVehicle />
              </Route>
              <Route exact path="/resource/assign/vehicle/driver">
                <AssignVehicleDriver />
              </Route>
              <Route exact path="/resource/trailer">
                <TrailerList />
              </Route>
              <Route exact path="/resource/trailer/create">
                <TrailerCreate />
              </Route>
              <Route exact path="/resource/trailer/edit/:id">
                <TrailerUpdate />
              </Route>

              <Route exact path="/order/pending">
                <OrderList />
              </Route>
              <Route exact path="/pending-order/detail/:id">
                <DetailOrder />
              </Route>
              <Route exact path="/order/pending/create">
                <CreateOrder />
              </Route>
              <Route exact path="/order/pending/edit/:id">
                <UpdateOrder />
              </Route>
              <Route exact path="/order/assign/vehicle">
                <AssignOrderVehicle />
              </Route>
              <Route exact path="/order/shipping">
                <ShippingOrderList />
              </Route>
              <Route exact path="/order/shipping/:id">
                <ShippingOrderDetail />
              </Route>
              <Route exact path="/order/history">
                <HistoryOrder />
              </Route>
              <Route exact path="/order/history/edit/:id">
                <UpdateHistoryOrder />
              </Route>

              <Route exact path="/setting/price">
                <PriceList />
              </Route>
              <Route exact path="/setting/priceContractor">
                <PriceContractorList />
              </Route>
              <Route exact path="/route/order/:id/create">
                <CreateRoute />
              </Route>
              <Route exact path="/basic-route/create">
                <CreateBasicRoute />
              </Route>
              <Route exact path="/route-with-path/create">
                <CreateRouteWithPath />
              </Route>
              <Route exact path="/route-contractor-with-path/create">
                <CreateRouteContractorWithPath />
              </Route>
              <Route exact path="/basic-route/edit/:id">
                <UpdateBasicRoute />
              </Route>
              <Route exact path="/route-with-path/edit/:id">
                <UpdateRouteWithPath />
              </Route>
              <Route exact path="/route-contractor-with-path/edit/:id">
                <UpdateRouteContractorWithPath />
              </Route>
              <Route exact path="/attribute-route/create">
                <CreateAttributeRoute />
              </Route>

              <Route exact path="/order/assign/route">
                <OrderWithRouteList />
              </Route>
              <Route
                exact
                path="/assign/order/:orderid/route/:routeid/truck-vendor"
              >
                <AssignRouteTruckVendor />
              </Route>

              <Route exact path="/tracking/order">
                <TrackingOrder />
              </Route>
              <Route exact path="/statistic">
                <Dashboard />
              </Route>

              <Route exact path="/partner/customer">
                <CustomerList />
              </Route>
              <Route exact path="/partner/customer/create">
                <CustomerCreate />
              </Route>
              <Route exact path="/partner/customer/:id/edit">
                <CustomerUpdate />
              </Route>

              <Route exact path="/partner/ship">
                <ShipList />
              </Route>
              <Route exact path="/partner/ship/create">
                <ShipCreate />
              </Route>
              <Route exact path="/partner/ship/:id/edit">
                <ShipUpdate />
              </Route>

              <Route exact path="/partner/bot">
                <BotList />
              </Route>
              <Route exact path="/place/all">
                <PlacesList />
              </Route>
              <Route exact path="/place/create">
                <CreatePlace />
              </Route>
              <Route exact path="/place/:id/edit">
                <EditPlace />
              </Route>
              <Route exact path="/partner/bot/create">
                <BotCreate />
              </Route>
              <Route exact path="/partner/bot/:id/edit">
                <BotUpdate />
              </Route>
              <Route exact path="/partner/seaport">
                <SeaportList />
              </Route>
              <Route exact path="/manager/refuel-oil">
                <RefuelOilList />
              </Route>
              <Route exact path="/manager/gas-station">
                <GasStation />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Main;
